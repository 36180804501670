import orderBy from "lodash/orderBy";
import config from "config";
import FlightTicket from "../wmelon/models/FlightTicket";
import FlightTicketBinds from "../wmelon/models/FlightTicketBinds";
import { getTicketPNR } from "./tickets";

export function getSortedTickets(
  tickets: FlightTicket[],
  binds: FlightTicketBinds[],
  order: "desc" | "asc"
) {
  return orderBy(
    tickets.map((ticket: FlightTicket) => {
      const bind = binds.find((it) => (it._raw as any).ticket_id === ticket.id);
      if (bind) {
        (ticket as any).binded_at = (bind.created_at as any).getTime();
      }

      return ticket;
    }),
    "binded_at",
    order
  );
}

export function getShareLink(token: string) {
  return `${config.LINK_URL}/share/${token}`;
}

export function getTicketInfoParams(
  ticket: {
    id?: string;
    flight_ticket_id?: string;
    flight_number: string;
    flight_date: string | Date;
    airline: string;
    identifiers: string | string[];
    flight_origin: string;
    flight_destination: string;
  },
  withId?: boolean,
  history?: boolean
) {
  return {
    flight_number: ticket.flight_number,
    date: (typeof ticket.flight_date === "string"
      ? new Date(ticket.flight_date)
      : ticket.flight_date
    ).getTime(),
    pnr: getTicketPNR(ticket),
    airline: ticket.airline,
    flight_origin: ticket.flight_origin,
    flight_destination: ticket.flight_destination,
    history: history || false,
    ...(withId
      ? { flight_ticket_id: ticket.id || ticket.flight_ticket_id }
      : {}),
  };
}
