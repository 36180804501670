import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import en from './translations/en.json'
import de from './translations/de.json'
import fi from './translations/fi.json'
import fr from './translations/fr.json'
import it from './translations/it.json'
import tr from './translations/tr.json'
import ar from './translations/ar.json'


i18n.use(LanguageDetector).use(initReactI18next).init({
  // lng: 'en',
  compatibilityJSON: 'v3',
  detection: {
    order: ['navigator']
  },
  fallbackLng: 'en',
  resources: {
    en: {
      translation: en,
    },
    de: {
      translation: de,
    },
    fi: {
      translation: fi,
    },
    fr: {
      translation: fr,
    },
    it: {
      translation: it,
    },
    tr: {
      translation: tr,
    },
    ar: {
      translation: ar,
    },
  },
})

export default i18n
