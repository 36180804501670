import { getLocaleDate } from "utils/date";
import orderBy from "lodash/orderBy";
import groupBy from "lodash/groupBy";
import config from "config";
import FlightTicket from "../wmelon/models/FlightTicket";
import FlightTicketBinds from "../wmelon/models/FlightTicketBinds";
import { ShareStatus } from "types/flights";
import FlightTicketShare from "wmelon/models/FlightTicketShare";
import AuthApiService from "./AuthApi";
import FuzzySet from "fuzzyset";
import { getArray } from "./utils";

export function getSortedTickets(
  tickets: FlightTicket[],
  binds: FlightTicketBinds[],
  order: "desc" | "asc"
) {
  return orderBy(
    tickets.map((ticket: FlightTicket) => {
      const bind = binds.find((it) => (it._raw as any).ticket_id === ticket.id);
      if (bind) {
        (ticket as any).binded_at = (bind.created_at as any).getTime();
      }

      return ticket;
    }),
    "binded_at",
    order
  );
}

export function getTicketPNR(ticket: {
  airline: string;
  identifiers: string | string[];
}) {
  const identifiers = getArray<string>(ticket.identifiers);
  let identifier = identifiers.find((it: string) =>
    it.toLowerCase().includes(`${ticket.airline}:`.toLowerCase())
  );

  if (!identifier) {
    identifier = identifiers[0];
  }

  return identifier ? identifier.split(":")[1] : "VIRTUAL";
}

export function getShareLink(token: string) {
  return `${config.LINK_URL}/share/${token}`;
}

export function getGrouppedTickets(
  list: FlightTicket[],
  order: "desc" | "asc"
): { date: string; data: FlightTicket[][] }[] {
  const grouppedByDate = groupBy(list, (ticket) => {
    return (ticket.flight_date as any).toISOString();
  });

  const result: { date: string; data: FlightTicket[][] }[] = [];

  Object.keys(grouppedByDate).forEach((date: string) => {
    const ticketsByDate = grouppedByDate[date];

    const groupped: Record<string, FlightTicket[]> = {};
    ticketsByDate.forEach((ticket: FlightTicket) => {
      const pnr = getTicketPNR(ticket);
      const key =
        `${ticket.flight_number}_${ticket.flight_origin}_${ticket.flight_destination}_${ticket.airline}_${pnr}`.toLowerCase();
      groupped[key] = (groupped[key] || []).concat(ticket);
    });

    result.push({
      date,
      data: Object.values(groupped).sort(
        (ticketsA: FlightTicket[], ticketsB: FlightTicket[]) => {
          const ticketA: any = ticketsA[0] as FlightTicket;
          const ticketB: any = ticketsB[0] as FlightTicket;
          return (
            ((ticketB.binded_at && new Date(ticketB.binded_at).getTime()) ||
              0) -
            ((ticketA.binded_at && new Date(ticketA.binded_at).getTime()) || 0)
          );
        }
      ),
    });
  });

  return orderBy(result, "date", [order]);
}

export function getIsVirtual(ticket: { identifiers: string | string[] }) {
  if (!ticket) {
    return false;
  }
  return getArray<string>(ticket.identifiers).every((it) =>
    it.toLowerCase().includes("virtual")
  );
}

export function mapTicketOwnerName(name?: string | null) {
  if (!name) {
    return null;
  }
  return name
    .trim()
    .replace(/\w+/g, (match) => match[0])
    .toUpperCase();
}

export function getTicketOwner(ticket: FlightTicket) {
  return mapTicketOwnerName(
    `${ticket.passenger_firstname || ""} ${ticket.passenger_lastname || ""}`
  );
}

export function getPNRS(ticket: { identifiers: string | string[] }) {
  if (!ticket) {
    return "";
  }
  return getArray<string>(ticket.identifiers)
    .map((identifier: string) => {
      return identifier.split(":")[1];
    })
    .join(", ");
}

export function getShareStatus(
  shares: FlightTicketShare[]
): ShareStatus.shared_by | ShareStatus.shared_to | null {
  if (!shares.length) {
    return null;
  }

  if (shares[0].shared_by === AuthApiService.shared().getUserId()) {
    return ShareStatus.shared_by;
  }

  return ShareStatus.shared_to;
}

export function getTicketDates(ticket: {
  flight_date: string;
  flight_departure_time?: string | null;
  flight_arrival_time?: string | null;
}): {
  departure_at: string | null;
  arrival_at: string | null;
} {
  const date = getLocaleDate(ticket.flight_date, "DD MMM");

  if (date === "N/a") {
    return { departure_at: null, arrival_at: null };
  }

  return {
    departure_at: ticket.flight_departure_time
      ? `${date} • ${ticket.flight_departure_time}`
      : date,
    arrival_at: ticket.flight_arrival_time
      ? `${date} • ${ticket.flight_arrival_time}`
      : date,
  };
}

function combinations(array: string[]) {
  return new Array(1 << array.length)
    .fill(null)
    .map((e1, i) => array.filter((e2, j) => i & (1 << j)));
}

function permute(permutation: string[]) {
  var length = permutation.length,
    result = [permutation.slice()],
    c = new Array(length).fill(0),
    i = 1,
    k,
    p;

  while (i < length) {
    if (c[i] < i) {
      k = i % 2 && c[i];
      p = permutation[i];
      permutation[i] = permutation[k];
      permutation[k] = p;
      ++c[i];
      i = 1;
      result.push(permutation.slice());
    } else {
      c[i] = 0;
      ++i;
    }
  }
  return result;
}

export function getCombinations(myArray: string[], min: number) {
  var allValues = combinations(myArray);

  var response = allValues;

  for (let v of allValues) {
    response = response.concat(permute(v));
  }

  return Array.from(
    new Set(
      response.filter((arr) => arr.length >= min).map((row) => row.join(" "))
    )
  );
}

export function getCombinationsPercentageOfMatch(
  docFullName: string,
  fullname: string
) {
  const time = Date.now();
  try {
    const docFullNameArr = docFullName.toLowerCase().split(/\W+/g);
    const time1 = Date.now();
    const docFullNameCombinations = getCombinations(
      docFullNameArr,
      docFullNameArr.length
    );
    console.log(
      "[getCombinationsPercentageOfMatch]",
      Date.now() - time1,
      docFullName,
      docFullNameCombinations.length,
      fullname
    );

    if (docFullNameCombinations.indexOf(fullname) > -1) {
      console.log(
        "[getCombinationsPercentageOfMatch] duration: indexOf",
        Date.now() - time
      );
      return true;
    }

    const fuzzySet = FuzzySet();
    fuzzySet.add(fullname);

    const time2 = Date.now();
    for (
      let idx = 0, count = docFullNameCombinations.length;
      idx < count;
      idx += 1
    ) {
      const time3 = Date.now();
      const result = fuzzySet.get(docFullNameCombinations[idx]);
      if (result && result[0][0] >= 0.8) {
        console.log(
          "[getCombinationsPercentageOfMatch] duration:",
          idx,
          Date.now() - time,
          Date.now() - time2,
          Date.now() - time3,
          docFullNameCombinations[idx]
        );
        return true;
      }
    }

    console.log(
      "[getCombinationsPercentageOfMatch] duration:",
      Date.now() - time
    );
    return false;
  } catch (err) {
    console.log("[getCombinationsPercentageOfMatch] failed:", err);
    console.log(
      "[getCombinationsPercentageOfMatch] duration:",
      Date.now() - time
    );
    return false;
  }
}
