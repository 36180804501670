import { Q } from "@nozbe/watermelondb";
import { Avatar } from "antd";
import User from "wmelon/models/User";
import { UserOutlined } from "@ant-design/icons";
import "./Header.css";
import { useMediaQuery } from "react-responsive";
import { withObservables } from "@nozbe/watermelondb/react";
import { database } from "wmelon/database";
import { APP_COLORS } from "utils/colors";
import { Observable } from "@nozbe/watermelondb/utils/rx";
interface UserAvatarProps {
  userId: string;
  users: User[];
  cssClass?: string;
  onPress: () => void;
}

function UserAvatar({ onPress, users, cssClass }: UserAvatarProps) {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const user = users[0];

  return (
    <Avatar
      style={{
        border: "none",
        verticalAlign: "middle",
        marginRight: isMobile ? 0 : 15,
        background: APP_COLORS.light.background,
      }}
      className={cssClass}
      size={40}
      src={user && !!user?.avatar ? user?.avatar : null}
      icon={<UserOutlined />}
      alt="avatar"
      onClick={onPress}
    />
  );
}

const enhance = withObservables<
  Omit<UserAvatarProps, "users">,
  { users: Observable<User[]> }
>(["userId"], ({ userId }: { userId: string | null }) => {
  return {
    users: database
      .get<User>(User.table)
      .query(Q.where("id", userId), Q.take(1))
      .observeWithColumns(["avatar"]),
  };
});

const EnhancedUserAvatar = enhance(UserAvatar);
export default EnhancedUserAvatar;
