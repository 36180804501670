import { useCallback, useEffect, useMemo } from "react";
import { Button, Modal, TabsProps } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import Tabs from "components/Tabs";
import Unread from "./components/UnRead";
import Read from "./components/Read";
import { APP_COLORS } from "utils/colors";
import { debounce } from "lodash";
import useDBSync from "hooks/useDBSync";
import {
  markAllAsRead,
  markAsDeleted,
  markAsRead,
} from "wmelon/middleware/notification";
import Notification from "wmelon/models/Notification";
import { initBadges } from "utils/badges";
export const TABS_KEY = {
  unread: "unread",
  read: "read",
};
const Notifications = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { source } = useParams();

  const sync = useDBSync();

  const callSync = useCallback(
    debounce(() => {
      return sync().catch((err) =>
        console.error("[Notifications] failed to sync:", err)
      );
    }, 500),
    [sync]
  );

  useEffect(() => {
    return () => {
      initBadges(0);
      callSync && callSync.cancel();
    };
  }, [callSync]);

  const readOneMessage = useCallback(
    (id) => {
      markAsRead(id)
        .then(() => {})
        .catch(() => {
          Modal.error({ title: t("Notification.failedToMarkAsRead") });
        });
    },
    [t]
  );

  const readAllMessages = useCallback(async () => {
    markAllAsRead().catch(() => {
      Modal.error({ title: t("Notification.failedToMarkAsRead") });
    });
  }, [t]);

  const deleteSelectedNotification = useCallback(
    (notification: Notification) => {
      if (notification) {
        markAsDeleted(notification.id).catch(() => {
          Modal.error({ title: t("Notification.failedToRemove") });
        });
      }
    },
    [t]
  );

  const onPressAction = useCallback(
    (actionKey: "read" | "allRead" | "delete", notification: Notification) => {
      switch (actionKey) {
        case "read":
          readOneMessage(notification?.id);
          callSync();
          break;
        case "allRead":
          readAllMessages();
          callSync();
          break;
        case "delete":
          Modal.info({
            title: t("PleaseConfirm"),
            content: t("Notification.confirmDelete"),
            onOk: () => {
              deleteSelectedNotification(notification);
            },
            onCancel: () => {},
            okText: t("Delete"),
            cancelText: t("Cancel"),
          });
          break;
      }
    },
    [readOneMessage, readAllMessages, deleteSelectedNotification, callSync, t]
  );

  const tabs: TabsProps["items"] = useMemo(() => {
    return [
      {
        key: TABS_KEY.unread,
        label: t("TabViewsTitle.Unread"),
        children: <Unread onPressAction={onPressAction} />,
      },
      {
        key: TABS_KEY.read,
        label: t("TabViewsTitle.Read"),
        children: <Read onPressAction={onPressAction} />,
      },
    ];
  }, [t, onPressAction]);

  return (
    <div className="Notifications">
      <div className="Notifications-header">
        <Button
          type="text"
          icon={
            <LeftOutlined
              style={{
                color: APP_COLORS.light.primary,
                fontSize: 20,
              }}
            />
          }
          onClick={() => {
            navigate("/tickets");
          }}
        />
        <span
          style={{
            color: APP_COLORS.light.primary,
          }}
        >
          {t("HeaderTitle.Notification")}
        </span>
      </div>
      <Tabs
        defaultActiveKey={source}
        onChange={(path) => {
          navigate(`/notifications/${path}`);
        }}
        tabs={tabs}
      />
    </div>
  );
};

const mapState = (state: any) => ({
  isSyncing: state.app.isSyncing,
});

const mapDispatch = {};
const connector = connect(mapState, mapDispatch);
const EnhancedNotifications = connector(Notifications);

export default EnhancedNotifications;
