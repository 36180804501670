import { configureStore } from "@reduxjs/toolkit";
import appState, { initialState as appInitialState } from "redux/features/app";
import session, {
  initialState as sessionInitialState,
} from "redux/features/session";
import documents, {
  initialState as documentsInitialState,
} from "redux/features/documents";
import loader, {
  initialState as loaderInitialState,
} from "redux/features/loader";
import validation, {
  initialState as validationInitialState,
} from "redux/features/validation";
import history, {
  initialState as historyInitialstate,
} from "./features/history";
import { ReduxState } from "./ReduxState";

import singleton from "../services/base/singleton";
import { useDispatch } from "react-redux";

const deepClone = (objectToClone: any) =>
  JSON.parse(JSON.stringify(objectToClone));

async function getPreloadState(): Promise<ReduxState> {
  const state = {
    app: deepClone(appInitialState),
    session: deepClone(sessionInitialState),
    documents: deepClone(documentsInitialState),
    loader: deepClone(loaderInitialState),
    validation: deepClone(validationInitialState),
    history: deepClone(historyInitialstate),
  };

  return state;
}

const getStoreBase = (preloadedState: any) => {
  return configureStore({
    preloadedState,
    reducer: {
      app: appState,
      session,
      documents,
      loader,
      validation,
      history,
    },
  });
};

const getStore = singleton(async () => {
  const preloadedState = await getPreloadState();
  return getStoreBase(preloadedState);
});

const store = getStoreBase({});
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;

export default getStore;
