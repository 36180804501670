import moment from "moment";
import { database } from "wmelon/database";
import { appEvent } from "./customEvent";
import { hasUnsyncedChanges } from "@nozbe/watermelondb/sync";
import { syncBack } from "wmelon/sync";

export function resetDB() {
  return database
    .write(async () => {
      return await database.unsafeResetDatabase();
    })
    .then((item) => {
      console.log("[resetDB] success:", item);
    })
    .catch((err) => console.log("[resetDB] failed:", err));
}

export function getQueryValue(value: any) {
  return value !== undefined ? value : null;
}

export function getQueryDateValue(value: any, format: string) {
  if (value === undefined) {
    return null;
  }
  const momD = moment(value);
  return momD.isValid() ? momD.format(format) : null;
}

export function refreshDB() {
  appEvent("syncing", { syncing: true, failed: false });
  appEvent("lastPulledAt", { lastPulledAt: null });
  return hasUnsyncedChanges({ database })
    .then((isUnsynced: boolean) => {
      if (isUnsynced) {
        return syncBack("");
      } else {
        return Promise.resolve();
      }
    })
    .then(() => resetDB())
    .then(() => syncBack(""))
    .catch((err) => console.error("[refreshDB] failed to refresh DB:", err));
}
