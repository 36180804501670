import { useState, useEffect, useMemo, useCallback } from "react";
import { connect } from "react-redux";
import orderBy from "lodash/orderBy";
import moment from "moment";
import { ReduxState } from "../../../redux/ReduxState";
import { useHistory } from "../../../hooks/useHistory";
import { HistoryItem } from "../../../types/history";
import TicketsList from "./TicketsList";
import HistoryTicketItemContainer from "./HistoryTicketItemContainer";
import ModalCallCenter from "components/ModalCallCenter";
import { useNavigate } from "react-router-dom";
import { getArray } from "utils/utils";

export interface IProps {
  items: HistoryItem[];
  params: {
    flight_number: string | null;
    date: string | null;
    pnr: string | null;
    airline: string | null;
    flight_origin: string | null;
    flight_destination: string | null;
  };
}

/*temp const*/
const HistoryTicketsList = ({ items, params }: IProps) => {
  const navigate = useNavigate();
  const { loadList } = useHistory();

  const [loading, setLoading] = useState(true);
  const [showCallDialog, setShowCallDialog] = useState(false);

  useEffect(() => {
    if (!items.length && !loading) {
      console.log("[HistoryTicketsList] no tickets:");
      navigate("/tickets");
    }
  }, [items, loading]); // eslint-disable-line

  useEffect(() => {
    if (!items.length && params.date) {
      loadList(moment(parseInt(params.date, 10)).format("YYYY-MM-DD"))
        .then(() => setLoading(false))
        .catch((err) => {
          console.log("[HistoryTicketsList] failed to load list:", err);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [params.date]); // eslint-disable-line

  const list = useMemo(() => {
    return orderBy(items, "binded_at", "desc");
  }, [items]);

  const renderTicket = useCallback((item: HistoryItem) => {
    return (
      <HistoryTicketItemContainer
        item={item}
        onCall={() => setShowCallDialog(true)}
      />
    );
  }, []);

  return (
    <>
      <TicketsList data={list} renderItem={renderTicket} />
      <ModalCallCenter
        text={""}
        withOutShortId
        visible={showCallDialog}
        onClose={() => setShowCallDialog(false)}
      />
    </>
  );
};

const mapState = (state: ReduxState, props: Omit<IProps, "items">) => {
  const {
    flight_number,
    date,
    pnr,
    airline,
    flight_origin,
    flight_destination,
  } = props.params;
  const formattedDate = date
    ? moment(parseInt(date, 10)).format("YYYY-MM-DD")
    : null;
  return {
    items: state.history.items.filter((it) => {
      return (
        it.flight_date === formattedDate &&
        it.flight_number === flight_number &&
        getArray<string>(it.identifiers).some(
          (identifier) => identifier.indexOf(`:${pnr}`) > -1
        ) &&
        it.airline === airline &&
        it.flight_origin === flight_origin &&
        it.flight_destination === flight_destination
      );
    }),
  };
};

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);
export default connector(HistoryTicketsList);
