import { Model } from "@nozbe/watermelondb";
import { date, field, relation } from "@nozbe/watermelondb/decorators";
import { Associations } from "@nozbe/watermelondb/Model";

export default class FlightTicketShare extends Model {
  static table = "flightTicketShares";

  static associations: Associations = {
    flightTickets: { type: "belongs_to", key: "flight_ticket_id" },
  };

  @field("flight_ticket_id") flight_ticket_id!: string;
  @relation("flightTickets", "flight_ticket_id") ticket: any;

  @field("token") token!: string;
  @field("shared_by") shared_by!: string;

  @field("used_by") used_by?: string;
  @date("used_at") used_at?: string;
  @date("created_at") created_at!: string;
}
