import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ColorPicker, Button, Input, Typography } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import Tag from "wmelon/models/Tag";
import { useTags } from "hooks/useTags";
import "./Tags.css";
import { withObservables } from "@nozbe/watermelondb/react";
import { database } from "wmelon/database";
import { APP_COLORS } from "utils/colors";
import { Observable } from "@nozbe/watermelondb/utils/rx";

enum Modes {
  view,
  create,
  edit,
  delete,
}

export interface TagsProps {
  visible: boolean;
  onClose: () => void;
  canEdit: boolean;
  tagsList: Tag[];
  onSelectTag?: (id: string) => Promise<void>;
}

function Tags({ visible, onClose, tagsList, canEdit, onSelectTag }: TagsProps) {
  const { t } = useTranslation();

  const [tagsText, setTagText] = useState("");
  const [tagColor, setTagColor] = useState("#808080");
  const [mode, setMode] = useState<Modes>(Modes.view);
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const bottomSheetRef = useRef<any>(null);
  const colorPickerRef = useRef<any>(null);

  const currentColorPicker = colorPickerRef.current;

  const onSelect = useCallback((tagId: string) => {
    console.log("SELECT:", tagId);
    setSelectedId(tagId);
    setMode(Modes.view);
  }, []);

  const onEdit = useCallback((tag: Tag) => {
    setMode(Modes.edit);
    setSelectedId(tag.id);
    setTagColor(tag.color);
    setTagText(tag.name);
  }, []);

  const { createTag, removeTag, updateTag } = useTags();

  const closeModal = useCallback(() => {
    console.log("[TagsModal] closeModal");
    onClose();
    setTagText("");
    setTagColor("#808080");
    setMode(Modes.view);
    setSelectedId(null);
    bottomSheetRef.current?.close();
  }, [onClose]);

  const onConfirm = useCallback(() => {
    if (canEdit) {
      if (mode === Modes.edit && selectedId) {
        updateTag(selectedId, tagsText, tagColor);
      } else if (tagsText !== "") {
        createTag({ name: tagsText, color: tagColor });
      }
    } else if (mode === Modes.create) {
      createTag({ name: tagsText, color: tagColor }).then((tag) => {
        console.log("[TagsModal] create tag:", tag?._raw?.id);
        onSelectTag && onSelectTag(tag?._raw?.id);
        closeModal();
      });
    } else {
      onSelectTag && selectedId && onSelectTag(selectedId);
      closeModal();
    }

    setTagText("");
    setTagColor("#808080");
    setSelectedId(null);
    setMode(Modes.view);
  }, [
    tagsText,
    tagColor,
    selectedId,
    mode,
    onSelectTag,
    closeModal,
    canEdit,
    createTag,
    updateTag,
  ]);

  const onDelete = useCallback(
    (id: string) => {
      removeTag(id);
      setSelectedId(null);
      setMode(Modes.view);
    },
    [removeTag]
  );

  const onColorChange = useCallback((newColor) => {
    setTagColor(newColor);
  }, []);

  const onPressNewTag = useCallback(() => {
    console.log("[TagsModal] onPressNewTag");
    setMode(Modes.create);
    setSelectedId(null);
    setTagColor("#808080");
    setTagText("");
  }, []);

  const renderTags = useCallback(
    (item: Tag, index: number) => {
      return mode === Modes.edit && selectedId === item._raw.id ? (
        <div key={index} className="Tag edit">
          <ColorPicker
            className="Tag-color"
            disabledAlpha={false}
            value={tagColor}
            onChange={(value) => {
              setTagColor(value.toHexString());
            }}
          />
          <Input
            placeholder={t("Tags.placeholder")}
            bordered={false}
            value={tagsText}
            onChange={(event) => {
              setTagText(event.target.value);
            }}
          />
        </div>
      ) : (
        <div
          key={index}
          className="Tag"
          style={{
            backgroundColor:
              selectedId === item.id ? "rgba(54, 81, 254, 0.08)" : "#FFF",
          }}
          onClick={canEdit ? undefined : () => onSelect(item.id)}
        >
          <ColorPicker
            className="Tag-color"
            disabledAlpha={false}
            value={item.color ?? APP_COLORS.light.tagsDefaultColor}
            disabled={!canEdit}
          />
          <Typography style={{ width: "100%" }}>{item.name}</Typography>
          <Button
            type="link"
            icon={<EditOutlined style={{ color: APP_COLORS.light.primary }} />}
            disabled={!canEdit}
            onClick={() => onEdit(item)}
          />
        </div>
      );
    },
    [selectedId, t, tagsText, tagColor, canEdit, mode, onEdit, onSelect]
  );

  useEffect(() => {
    if (currentColorPicker) {
      currentColorPicker.addEventListener("touchstart", onColorChange, {
        passive: false,
      });
    }

    return () => {
      if (currentColorPicker) {
        currentColorPicker.removeEventListener("touchstart", onColorChange);
      }
    };
  }, [onColorChange, currentColorPicker]);

  const btns = useMemo(() => {
    return selectedId && mode === Modes.delete
      ? {
          okText: t("Tags.delete"),
          okButtonProps: { danger: true },
          cancelBtnText: t("Tags.cancel"),
          onConfirm: () => onDelete(selectedId),
          onClose: () => {
            setSelectedId(null);
            setMode(Modes.view);
          },
        }
      : {
          okText: canEdit ? t("Tags.save") : t("Tags.useittag"),
          okButtonProps: {
            style: { color: APP_COLORS.light.primary },
            disabled:
              ([Modes.create, Modes.edit].includes(mode) && !tagsText) ||
              (mode === Modes.view && !selectedId),
          },
          cancelBtnText: t("Tags.cancel"),
          onClose: () => closeModal(),
          onConfirm: () => onConfirm(),
        };
  }, [selectedId, mode, tagsText, onConfirm, onDelete, closeModal, canEdit, t]);

  return (
    <Modal
      title={t("Tags.title")}
      centered
      okText={btns.okText}
      onOk={btns.onConfirm}
      okButtonProps={btns.okButtonProps}
      onCancel={btns.onClose}
      open={visible}
    >
      <div className="Tags-container">
        <div className="Tags-radio-group">{tagsList.map(renderTags)}</div>
      </div>
      {mode === Modes.create ? (
        <div className="Tags-new-tag">
          <div className="Tag selected" ref={colorPickerRef}>
            <ColorPicker
              className="Tag-color"
              onChange={(event) => {
                onColorChange(event.toHexString());
              }}
              value={tagColor}
              disabledAlpha={false}
            />
            <Input
              placeholder={t("Tags.placeholder")}
              bordered={false}
              value={tagsText}
              onChange={(event) => {
                setTagText(event.target.value);
              }}
            />
          </div>
        </div>
      ) : (
        <div className="Tags-new-tag">
          <Button
            icon={<PlusOutlined />}
            type="link"
            onClick={onPressNewTag}
            style={{ color: APP_COLORS.light.primary }}
          >
            {t("Tags.newTag")}
          </Button>
        </div>
      )}
    </Modal>
  );
}

const enhance = withObservables<
  Omit<TagsProps, "tagsList">,
  { tagsList: Observable<Tag[]> }
>([], () => ({
  tagsList: database.get<Tag>(Tag.table).query().observe(),
}));
const EnhancedTags = enhance(Tags);
export default EnhancedTags;
