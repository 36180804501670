import { Database } from "@nozbe/watermelondb";
import LokiJSAdapter from "@nozbe/watermelondb/adapters/lokijs";
import { v4 as uuid } from "uuid";
// import 'react-native-get-random-values'
import { schema } from "./schema";
import migrations from "./migrations";
import Session from "./models/Sessions";
import Document from "./models/Document";
import Ticket from "./models/Ticket";
import Notification from "./models/Notification";
import PnrPassenger from "./models/PnrPassenger";
import Form from "./models/Form";
import File from "./models/File";
import Tag from "./models/Tag";
import SchemaCountryGroups from "./models/SchemaCountryGroups";
import SchemaVaccines from "./models/SchemaVaccines";
import Airline from "./models/Airline";
import User from "./models/User";
import Country from "./models/Country";
import Airport from "./models/Airport";
import { setGenerator } from "@nozbe/watermelondb/utils/common/randomId";
import TagBind from "./models/TagBind";
import Bag from "./models/Bag";
import Damage from "./models/Damage";
import FlightTicket from "./models/FlightTicket";
import FlightTicketBinds from "./models/FlightTicketBinds";
import FlightTicketShare from "./models/FlightTicketShare";

setGenerator(() => uuid());
const adapter = new LokiJSAdapter({
  schema,
  // (You might want to comment out migrations for development purposes -- see Migrations documentation)
  migrations,
  useWebWorker: false,
  useIncrementalIndexedDB: true,

  onQuotaExceededError: (error) => {
    console.error("[database] quota exceeded error:", error);
  },
  onSetUpError: (error) => {
    console.error("[database] failed to setup:", error);
  },
  extraIncrementalIDBOptions: {
    onDidOverwrite: () => {
      // Called when this adapter is forced to overwrite contents of IndexedDB.
      // This happens if there's another open tab of the same app that's making changes.
      // Try to synchronize the app now, and if user is offline, alert them that if they close this
      // tab, some data may be lost
    },
    onversionchange: () => {
      // database was deleted in another browser tab (user logged out), so we must make sure we delete
      // it in this tab as well - usually best to just refresh the page
      window.location.reload();
    },
  },
});

export const database = new Database({
  adapter,
  modelClasses: [
    Session,
    Document,
    Ticket,
    Notification,
    PnrPassenger,
    Form,
    File,
    Tag,
    TagBind,
    SchemaCountryGroups,
    SchemaVaccines,
    Airline,
    User,
    Bag,
    Damage,
    Country,
    Airport,
    FlightTicket,
    FlightTicketBinds,
    FlightTicketShare,
  ],
});
