import get from "lodash/get";
import { JsonForm, JsonFormJson, JsonFormJsonSchema } from "../model/JsonForm";
import {
  FormProcessorResponse,
  ResultStatus,
} from "../model/FormProcessorResponse";
import { FormProcessorParseProps } from "../model/FormProcessorParseProps";
import { ErrorTexts } from "../ErrorTexts";
import eudccValueSet from "../assets/eudcc";
import DateTimeUtils from "../DateTimeUtils";
import config from "../../../config";
import { getFieldPath } from "utils/jsonform";
import Recognizer from "./Recognizer";

export default class EUDCCRecognizer extends Recognizer {
  static VALUE_SETS = {
    "v.co": eudccValueSet.country2Codes,
    "t.co": eudccValueSet.country2Codes,
    "r.co": eudccValueSet.country2Codes,
    "v.tg": eudccValueSet.diseaseAgentTargeted,
    "t.tg": eudccValueSet.diseaseAgentTargeted,
    "r.tg": eudccValueSet.diseaseAgentTargeted,
    "t.tr": eudccValueSet.testResult,
    "t.tt": eudccValueSet.testType,
    "v.ma": eudccValueSet.vaccineMahManf,
    // 'v.mp': eudccValueSet.vaccineMedicinalProduct,
    "v.vp": eudccValueSet.vaccineProphylaxis,
  };

  static getGUID(): string {
    return "EUDCC";
  }

  process(
    props: FormProcessorParseProps,
    form: JsonForm,
    allowedDocumentTypes: string[]
  ): FormProcessorResponse {
    const jsonFormSchema = JSON.parse(form.json) as JsonFormJson;
    const values = props.formData || {};
    const jsonSchema = jsonFormSchema.schema as JsonFormJsonSchema;
    return this.parse(
      props.parsed,
      values || {},
      props.images,
      jsonSchema,
      allowedDocumentTypes
    );
  }

  public parse(
    eudcc: any,
    values: any,
    images: Blob[],
    jsonSchema: JsonFormJsonSchema,
    allowedDocumentTypes: string[]
  ): FormProcessorResponse {
    const propertiesNode = jsonSchema.properties;

    if (!propertiesNode || !Object.keys(propertiesNode).length) {
      return {
        status: ResultStatus.WRONG_PARAMS,
        error: ErrorTexts.SCHEMA_PROPERTIES_NOT_FOUND,
        data: null,
        recognizer: null,
        fields: {
          empty: [],
          invalid: [],
          parsed: [],
        },
        documentType: null,
        doc_data: {
          date_of_expiry: null,
          passenger: null,
          meta: null,
        },
      };
    }

    let documentType = "";

    if (eudcc) {
      if (eudcc?.data?.["-260"]?.["1"]?.v) {
        documentType = "vaccination";
      } else if (eudcc?.data?.["-260"]?.["1"]?.t) {
        documentType = "test";
      } else if (eudcc?.data?.["-260"]?.["1"]?.r) {
        documentType = "recovery";
      } else {
        return {
          status: ResultStatus.NOT_RECOGNIZED,
          error: ErrorTexts.NOT_RECOGNIZED,
          data: null,
          recognizer: null,
          fields: {
            empty: [],
            invalid: [],
            parsed: [],
          },
          documentType: null,
          doc_data: {
            date_of_expiry: eudcc?.data?.["-260"]?.["1"]?.r?.du,
            passenger: null,
            meta: null,
          },
        };
      }

      if (
        !allowedDocumentTypes.some(
          (dt) => dt.toLowerCase() === documentType.toLowerCase()
        )
      ) {
        return {
          status: ResultStatus.DOC_TYPE_NOT_VALID,
          error: ErrorTexts.DOCUMENT_TYPE_NOT_ALLOWED,
          data: null,
          recognizer: null,
          fields: {
            empty: [],
            invalid: [],
            parsed: [],
          },
          documentType: documentType,
          doc_data: {
            date_of_expiry: eudcc?.data?.["-260"]?.["1"]?.r?.du,
            passenger: null,
            meta: null,
          },
        };
      }
    }

    let parsedFields: string[] = [];

    Object.keys(propertiesNode).forEach((structureKey) => {
      const fields = propertiesNode[structureKey];

      if (structureKey.toLowerCase() === "documentfile") {
        return;
      }

      if (fields.recognizerFieldId === config.JSONFORMS.FULLNAME_FIELD) {
        const givenName = get(eudcc, `data.-260.1.nam.gn`);
        const surName = get(eudcc, `data.-260.1.nam.fn`);
        if (givenName || surName) {
          values[structureKey] = `${surName || ""} ${givenName || ""}`.trim();
          parsedFields.push(structureKey);
        } else {
          const [surnamePath] = getFieldPath(
            jsonSchema,
            EUDCCRecognizer.getGUID(),
            config.CERTIFICATE_FIELDS.SURNAME.toString(),
            ""
          );
          const [givenNamePath] = getFieldPath(
            jsonSchema,
            EUDCCRecognizer.getGUID(),
            config.CERTIFICATE_FIELDS.GIVEN_NAME.toString(),
            ""
          );
          if (surnamePath || givenNamePath) {
            values[structureKey] = `${get(values, surnamePath) || ""} ${
              get(values, givenNamePath) || ""
            }`.trim();
          }
        }
        return;
      }

      if (
        fields.recognizer?.toLowerCase() !==
        EUDCCRecognizer.getGUID().toLowerCase()
      ) {
        return;
      }

      if (structureKey.toLowerCase() === "CertificateValid".toLowerCase()) {
        values["CertificateValid"] = !!eudcc.valid;
        eudcc && parsedFields.push("CertificateValid");
        return;
      }

      if (structureKey.toLowerCase() === "CertificateType".toLowerCase()) {
        values["CertificateType"] = documentType;
        documentType && parsedFields.push("CertificateType");
        return;
      }

      const recognizerFieldId = fields["recognizerFieldId"];
      const ids = Array.isArray(recognizerFieldId)
        ? recognizerFieldId
        : [recognizerFieldId];

      if (!ids.length) {
        return;
      }

      ids.forEach((id: string) => {
        let jsonValue = null;
        let isParsed = false;

        if (eudcc) {
          if (
            id.toLowerCase().startsWith("v.") ||
            id.toLowerCase().startsWith("t.") ||
            id.toLowerCase().startsWith("r.")
          ) {
            const parts = id.split(".");
            jsonValue = `data.-260.1.${parts[0]}`
              .split(".")
              .reduce((previous, current) => previous[current], eudcc)[0][
              parts[1]
            ];
          } else {
            jsonValue = `data.-260.1.${id}`
              .split(".")
              .reduce((previous, current) => previous[current], eudcc);
          }
          isParsed = true;
        } else {
          jsonValue = values[structureKey];
        }

        const value = this.getValue(id, fields, jsonValue);

        if (value != null) {
          values[structureKey] = value;
          isParsed && parsedFields.push(structureKey);
        }
      });
    });

    return {
      status: ResultStatus.OK,
      data: values,
      documentType: documentType,
      recognizer: EUDCCRecognizer.getGUID(),
      fields: {
        empty: this.getEmptyRequiredFields(jsonSchema, values, images),
        invalid: [],
        parsed: parsedFields,
      },
      doc_data: {
        date_of_expiry: eudcc?.data?.["-260"]?.["1"]?.r?.du,
        passenger: null,
        meta: null,
      },
    };
  }

  private getValue(
    id: string,
    fields: any,
    jsonValue: string | null
  ): string | null {
    if (!jsonValue) return "";

    let value = jsonValue;

    if (Object.keys(EUDCCRecognizer.VALUE_SETS).includes(id)) {
      // @ts-ignore
      const vs = EUDCCRecognizer.VALUE_SETS[id];
      value = vs?.valueSetValues?.[`${jsonValue}`]?.display;
      if (!value) value = jsonValue;
    }

    const format = fields.format;
    return DateTimeUtils.toStandard(value, format);
  }
}
