import { Button } from "antd";
import { useTranslation } from "react-i18next";
import "./style.css";
import { APP_COLORS } from "utils/colors";
interface Props {
  onDelete: () => void;
  onLogout: () => void;
}

const Buttons = ({ onDelete, onLogout }: Props) => {
  const { t } = useTranslation();
  return (
    <div
      className='buttonsContainer'
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "5%",
        width: "100%",
      }}
    >
      {/* <Button type='primary'>{t("Profile.shareProfile")}</Button> */}
      {/* <Button
        type='primary'
        style={{
          marginBottom: "2%",
        }}
        onClick={onDelete}
      >
        {t("Profile.DeleteProfile")}
      </Button> */}
      <Button
        type='primary'
        onClick={onLogout}
        style={{
          color: APP_COLORS.light.primary_text_color,
          backgroundColor: APP_COLORS.light.primary,
        }}
      >
        {t("Profile.logout")}
      </Button>
    </div>
  );
};

export default Buttons;
