import { useMemo, useCallback, useState } from "react";
import uniq from "lodash/uniq";
import { HistoryItem } from "types/history";
import { getSessionStatus } from "utils/session";
import PNRItem from "./PNRItem";

interface HistoryPNRItemProps {
  tickets: HistoryItem[];
  onSelect: (tickets: HistoryItem[]) => Promise<any>;
}

const HistoryPNRItem = ({ tickets, onSelect }: HistoryPNRItemProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  console.log("PNR_ITEM:", tickets);
  const statuses: Record<
    string,
    { status: string | null; sessionStatus: string | null }
  > = useMemo(() => {
    return tickets.reduce(
      (
        acc: Record<
          string,
          { status: string | null; sessionStatus: string | null }
        >,
        ticket: HistoryItem
      ) => {
        if (ticket.status && ticket.handler) {
          const sessionStatus = getSessionStatus({
            status: ticket.status,
            handler: ticket.handler,
          });
          return {
            ...acc,
            [ticket.flight_ticket_id]: {
              status: sessionStatus.state || null,
              sessionStatus: ticket.status,
            },
          };
        }

        return acc;
      },
      {}
    );
  }, [tickets]);

  const status = useMemo(() => {
    let statusesList: string[] = [];

    for (let key in statuses) {
      const group = statuses[key];
      group.status && statusesList.push(group.status);
    }

    if (statusesList.length < tickets.length) {
      return "wait";
    }

    statusesList = uniq(statusesList);
    if (statusesList.every((state) => state === "success")) {
      return "success";
    } else if (statusesList.every((state) => state === "rejected")) {
      return "rejected";
    } else {
      return "wait";
    }
  }, [statuses, tickets.length]);

  const onHandlePress = useCallback(() => {
    setLoading(true);
    onSelect(tickets).finally(() => setLoading(false));
  }, [tickets, onSelect]);

  const onStartValidation = useCallback(() => {}, []);

  return (
    <PNRItem
      tickets={tickets}
      compact={false}
      canValidate={false}
      loading={loading}
      syncing={false}
      onSelect={onHandlePress}
      onStartValidation={onStartValidation}
      status={status}
    />
  );
};

export default HistoryPNRItem;
