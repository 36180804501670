import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Q } from "@nozbe/watermelondb";
import moment from "moment";
import { database } from "wmelon/database";
import { v4 as uuid } from "uuid";
import {
  initSession as initReduxSession,
  setSessionOwner as setReduxSessionOwner,
  setSessionHandler as setReduxSessionHandler,
  setETicket as setReduxETicket,
  clearState,
  restartSession as restartReduxSession,
  SessionState,
  setHasUnverifiedDocuments,
} from "redux/features/session";
import {
  ReduxDocument,
  addDocument,
  clearState as clearDocumentState,
} from "redux/features/documents";
import {
  setTickets,
  setValidated as setValidatedRedux,
  setSessionToTicket,
  setScheme,
  setDocumentsRequest,
} from "redux/features/validation";
import { useAppDispatch } from "redux/store";
import { getHandler, generateToken } from "../utils/session";
import { getQueryValue } from "utils/db";
import Session from "wmelon/models/Sessions";
import { Scheme } from "../types/scheme";

import config from "config";
import { getIsDefaultScheme } from "utils/scheme";
import { getAirportsByCodes } from "wmelon/middleware/airport";
import Airport from "wmelon/models/Airport";
import FlightTicket from "wmelon/models/FlightTicket";
import { generalAPI } from "../services/base/api";
import { ReduxState } from "redux/ReduxState";
import { SessionAdditionalDocumentRequest } from "types/validator";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import Document from "wmelon/models/Document";
import { getArray } from "utils/utils";
import { useNavigate } from "react-router-dom";

export interface UseReduxSessionResponse {
  setSessionHandler: (ticketId: string, statusId: string) => Promise<void>;
  setSessionOwner: (
    owner_first_name: string | null,
    owner_last_name: string,
    owner_dob: string | null,
    owner_document_number: string | null
  ) => Promise<void>;
  setETicket: (ticket: FlightTicket) => Promise<void>;
  createSession: (shortId: string) => Promise<Session>;
  startValidation: (tickets: FlightTicket[]) => Promise<void>;
  runByTicketId: (ticketId: string) => Promise<void>;
  setHasUnverifiedDocuments: (value: boolean) => void;
  initSessionByDocumentsRequest: (
    request: SessionAdditionalDocumentRequest
  ) => Promise<void>;
  initSessionByDocumentRequestId: (
    requestId: string,
    sessionId: string
  ) => Promise<void>;
  restartSession: () => Promise<void>;
  clearSession: (clearScheme: boolean) => Promise<void>;
}

export function useReduxSession(): UseReduxSessionResponse {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const sessionState: SessionState = useSelector(
    (state: ReduxState) => state.session
  );
  const reduxScheme = useSelector(
    (state: ReduxState) => state.validation.scheme
  );
  const dispatch = useAppDispatch();

  const setSessionHandler = useCallback(
    (ticketId: string, statusId: string) => {
      const resp = getHandler(statusId);
      dispatch(setValidatedRedux({ id: ticketId, status: statusId }));
      if (!resp) {
        return Promise.resolve();
      }

      return dispatch(
        setReduxSessionHandler({
          status: resp.status,
          handler: resp.handler,
        })
      ).then(() => {});
    },
    [dispatch]
  );

  const setSessionOwner = useCallback(
    (
      owner_first_name: string | null,
      owner_last_name: string,
      owner_dob: string | null,
      owner_document_number: string | null
    ) => {
      return dispatch(
        setReduxSessionOwner({
          owner_first_name,
          owner_last_name,
          owner_dob,
          owner_document_number,
        })
      ).then(() => {});
    },
    [dispatch]
  );

  const setETicket = useCallback(
    (ticket: FlightTicket) => {
      console.log("SET_ETICKET:", ticket);
      return dispatch(
        setReduxETicket({
          flight_origin: ticket.flight_origin,
          flight_destination: ticket.flight_destination,
          flight_ticket_id: ticket.id,
        })
      ).then(() => {});
    },
    [dispatch]
  );

  const restartSession = useCallback(async () => {
    dispatch(
      setValidatedRedux({ id: sessionState.flight_ticket_id, status: null })
    );
    dispatch(
      setSessionToTicket({ id: sessionState.flight_ticket_id, sessionId: null })
    );
    dispatch(clearDocumentState());
    dispatch(restartReduxSession());
  }, [sessionState.flight_ticket_id, dispatch]);

  const clearSession = useCallback(
    async (clearScheme: boolean) => {
      clearScheme && dispatch(setScheme(null));
      clearScheme && dispatch(setDocumentsRequest(null));
      dispatch(clearState());
    },
    [dispatch]
  );

  const getCountryCodes = useCallback(
    (
      ticket: FlightTicket
    ): Promise<{
      origin: string;
      destination: string;
    }> => {
      return getAirportsByCodes([
        ticket.flight_origin,
        ticket.flight_destination,
      ]).then((list: Airport[]) => {
        const origin = list.find(
          (it) => it.iata === ticket.flight_origin.toUpperCase()
        );
        const destination = list.find(
          (it) => it.iata === ticket.flight_destination.toUpperCase()
        );
        return {
          origin: origin?.countryCode || "",
          destination: destination?.countryCode || "",
        };
      });
    },
    []
  );

  const getScheme = useCallback(
    (
      ticket: FlightTicket,
      origin: string,
      destination: string
    ): Promise<Scheme> => {
      if (reduxScheme) {
        const date = (ticket.flight_date as any).getTime();
        // date <= moment(reduxScheme.start).valueOf()

        const isActiveScheme = date <= moment(reduxScheme.start).valueOf();
        const isDefaultScheme =
          reduxScheme.departure === config.DEFAULT_SCHEME_COUNTRY_CODE &&
          reduxScheme.arrival === config.DEFAULT_SCHEME_COUNTRY_CODE;

        if (
          isActiveScheme &&
          ((reduxScheme.transporters.some((it) => it.code === ticket.airline) &&
            ((reduxScheme.departure === origin &&
              reduxScheme.arrival === destination) ||
              (reduxScheme.departure === config.DEFAULT_SCHEME_COUNTRY_CODE &&
                reduxScheme.arrival === destination))) ||
            isDefaultScheme)
        ) {
          return Promise.resolve(reduxScheme);
        }
      }

      return generalAPI.loadScheme(ticket.id);
    },
    [reduxScheme]
  );

  const initSession = useCallback(
    (ticket: FlightTicket, parent?: Session) => {
      return getCountryCodes(ticket).then(
        ({ origin, destination }: { origin: string; destination: string }) => {
          console.log(
            "[useReduxSession].initSession params:",
            typeof ticket.flight_date,
            ticket.flight_date,
            ticket.airline,
            origin,
            destination
          );

          if (!origin && !destination) {
            throw new Error("AIRPORT_NOT_FOUND");
          }

          return getScheme(ticket, origin, destination).then(
            (scheme: Scheme) => {
              console.log(
                "[useReduxSession].initSession all schemas:",
                ticket.airline,
                `${scheme.id} ${scheme.departure} => ${scheme.arrival};start-${
                  scheme.start
                };end-${scheme.end};tr-${scheme.transporters.map(
                  (i) => i.code
                )}`
              );
              console.log(
                `[useReduxSession].initSession session props: origin`,
                ticket.flight_origin,
                origin
              );
              console.log(
                `[useReduxSession].initSession session props: destination`,
                ticket.flight_destination,
                destination
              );
              if (!scheme) {
                throw new Error("SCHEMA_NOT_FOUND");
              }
              console.log("SCHEME:", scheme.id, scheme.rev, {
                carrier: ticket.airline,
                origin: origin,
                destination: destination,
                flight_ticket_id: ticket.id,
              });
              dispatch(setScheme(scheme));
              dispatch(
                initReduxSession({
                  id: null,
                  short_id: generateToken(),
                  flight: ticket.flight_number,
                  carrier: ticket.airline,
                  origin: origin,
                  destination: destination,
                  depart_port: ticket.flight_origin,
                  arrival_port: ticket.flight_destination,
                  transit: [],
                  flight_date: moment(ticket.flight_date).format("YYYY-MM-DD"),
                  scheme_id: scheme.id.toString(),
                  scheme_version: scheme.rev.toString(),
                  scheme_type: getIsDefaultScheme(scheme) ? "default" : null,
                  assign_to: null,
                  flight_ticket_id: ticket.id,
                  ...(parent
                    ? {
                        owner_first_name: parent.owner_first_name || null,
                        owner_last_name: parent.owner_last_name || null,
                        owner_dob: parent.owner_dob || null,
                        owner_document_number:
                          parent.owner_document_number || null,
                      }
                    : {}),
                })
              );
            }
          );
        }
      );
    },
    [dispatch, getCountryCodes, getScheme]
  );

  const createSession = useCallback(
    (shortId: string) => {
      console.log(
        "CREATE_SESSION:",
        sessionState,
        sessionState?.flight_ticket_id
      );
      if (!sessionState?.flight_ticket_id) {
        throw new Error("session_state_is_empty");
      }

      return database
        .get<FlightTicket>(FlightTicket.table)
        .query(Q.where("id", getQueryValue(sessionState?.flight_ticket_id)))
        .fetch()
        .then((tickets: FlightTicket[]) => {
          if (!tickets.length) {
            throw new Error("flight_ticket__sharing_revoked");
          }

          return database.write(() => {
            return database.get<Session>(Session.table).create((record) => {
              record.short_id = shortId;
              record.flight = sessionState.flight;
              record.carrier = sessionState.carrier;
              record.origin = sessionState.origin;
              record.destination = sessionState.destination;

              sessionState.depart_port &&
                (record.depart_port = sessionState.depart_port);
              sessionState.arrival_port &&
                (record.arrival_port = sessionState.arrival_port);
              record.transit = sessionState.transit;
              record.flight_date = sessionState.flight_date;
              record.scheme_id = sessionState.scheme_id;
              record.scheme_version = sessionState.scheme_version;
              record.status = sessionState.status;
              record.handler = sessionState.handler;
              sessionState.owner_first_name &&
                (record.owner_first_name = sessionState.owner_first_name);
              sessionState.owner_last_name &&
                (record.owner_last_name = sessionState.owner_last_name);
              sessionState.owner_dob &&
                (record.owner_dob = sessionState.owner_dob);
              sessionState.owner_document_number &&
                (record.owner_document_number =
                  sessionState.owner_document_number);
              sessionState.scheme_type &&
                (record.scheme_type = sessionState.scheme_type);
              sessionState.assign_to &&
                (record.assign_to = sessionState.assign_to);
              record.flight_ticket_id = sessionState.flight_ticket_id;
              record.created_at = Date.now().toString();
            });
          });
        });
    },
    [sessionState]
  );

  const runByTicketId = useCallback(
    (ticketId: string) => {
      dispatch(setDocumentsRequest(null));
      return database
        .get<FlightTicket>(FlightTicket.table)
        .query(Q.where("id", getQueryValue(ticketId)))
        .then((tickets: FlightTicket[]) => {
          if (!tickets.length) {
            throw new Error("no_tickets");
          }

          return initSession(tickets[0]);
        });
    },
    [initSession, dispatch]
  );

  const startValidation = useCallback(
    (tickets: FlightTicket[]) => {
      console.log(
        "[useReduxSession] start validation:",
        tickets.map((it) => it.id)
      );
      dispatch(setDocumentsRequest(null));
      dispatch(setTickets(tickets.map((it) => ({ id: it.id }))));
      return initSession(tickets[0]);
    },
    [dispatch, initSession]
  );
  const setHasUnverifiedDocumentsToRedux = useCallback(
    (value: boolean) => {
      dispatch(setHasUnverifiedDocuments(value));
    },
    [dispatch]
  );

  const initSessionByDocumentsRequest = useCallback(
    (request: SessionAdditionalDocumentRequest) => {
      dispatch(setDocumentsRequest(request));
      console.log("initSessionByDocumentsRequest:1", request);
      return database
        .get<Session>(Session.table)
        .query(Q.where("id", request.session_id))
        .fetch()
        .then((sessions) => {
          if (sessions.length >= 9) {
            throw new Error("maximumAttempts");
          }

          const [session] = sessions;
          if (!session) {
            throw new Error("not_available_session");
          }
          console.log("initSessionByDocumentsRequest:2", request);
          return database
            .get<FlightTicket>(FlightTicket.table)
            .query(Q.where("id", session.flight_ticket_id))
            .fetch()
            .then(([ticket]) => {
              if (!ticket) {
                throw new Error("not_available_ticket");
              }

              dispatch(setTickets([{ id: ticket.id }]));
              return initSession(ticket, session);
            })
            .then(() => {
              const requestedTypes: string[] = request.documents.reduce(
                (acc, it) => {
                  if (it._type === "document") {
                    if (it.type) {
                      return [...acc, it.type];
                    } else {
                      return acc;
                    }
                  }

                  return [
                    ...acc,
                    ...(it.sub_documents
                      ?.map((it) => it.type)
                      .filter((it) => !!it) || []),
                  ];
                },
                [] as string[]
              );

              return database
                .get<Document>(Document.table)
                .query(
                  Q.where("session_id", session.id),
                  Q.where("type", Q.notIn(requestedTypes))
                )
                .fetch()
                .then((documents) => {
                  documents.forEach((item) => {
                    const doc: ReduxDocument = {
                      id: uuid(),
                      category: item.category,
                      form_id: item.formId || null,
                      form_version: item.formVersion || null,
                      type: item.type,
                      provider: item.provider,
                      recognizer: item.recognizer || null,
                      data: item.data,
                      autoVerificationResult:
                        item.auto_verification_result || null,
                      files: getArray<{
                        link: string;
                        hash: string;
                        position: number;
                      }>(item.files),
                      verifiedManual: item.verified_manual || false,
                      verifiedAuto: item.verified_auto || false,
                      createdAt: Date.now(),
                      cloned: true,
                    };
                    dispatch(addDocument(doc));
                  });
                });
            });
        })
        .then(() => navigate("/document_request"));
    },
    [initSession, dispatch, navigate]
  );

  const initSessionByDocumentRequestId = useCallback(
    (requestId, sessionId) => {
      return generalAPI
        .getDocumentRequest(requestId, sessionId)
        .then((response) => {
          return initSessionByDocumentsRequest(response);
        })
        .catch((err) => {
          console.log("ERROR:", err);
          if (err.message === "invalidated") {
            Modal.error({
              title: t("Error"),
              content: t(["document_request_invalidated", "error"]),
            });
          } else if (err.message === "maximumAttempts") {
            Modal.error({
              title: t("Ticket_and_Info.maximumAttempts"),
              content: t("Ticket_and_Info.callSupport"),
            });
          } else {
            Modal.error({
              title: t("Error"),
              content: t([err.message, "error"]),
            });
          }
        });
    },
    [initSessionByDocumentsRequest, t]
  );

  return useMemo(
    () => ({
      setSessionHandler,
      setSessionOwner,
      setETicket,
      createSession,
      startValidation,
      runByTicketId,
      setHasUnverifiedDocuments: setHasUnverifiedDocumentsToRedux,
      initSessionByDocumentsRequest,
      initSessionByDocumentRequestId,
      restartSession,
      clearSession,
    }),
    [
      setSessionHandler,
      setSessionOwner,
      setETicket,
      createSession,
      startValidation,
      setHasUnverifiedDocumentsToRedux,
      runByTicketId,
      initSessionByDocumentsRequest,
      initSessionByDocumentRequestId,
      restartSession,
      clearSession,
    ]
  );
}
