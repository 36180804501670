import { useCallback, useMemo, useState, useEffect } from "react";
import "./style.css";
import { App, Button, Input, Modal, Spin, Typography } from "antd";
import { LoadingOutlined, LeftOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import ManualPNR from "./components/ManualPNR";
import { useFlights } from "hooks/useFlights";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import scanIcon from "assets/images/qrscan.svg";
import Scanner from "./components/Scanner";
import { Flight } from "types/flights";
import { APP_COLORS } from "utils/colors";

const INITIAL_VALUES = {
  flight: null as Flight | null,
  flightDate: null as string | null,
  time: null as string | null,
};
const AddFlight = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [pnr, setPnr] = useState("");
  const [isManual, setIsManual] = useState<boolean>(false);
  const [scanError, setScanError] = useState(false);
  const [isFocused, setIsFocused] = useState(true);

  const { checkPNR, createVirtualTicket } = useFlights();
  const navigate = useNavigate();

  const onAddFlight = useCallback(
    async (pnr: string, auto?: boolean) => {
      console.log("pnr", pnr, scanError);
      if (pnr) {
        setIsLoading(true);
        const path = (pnr.split("/").pop() || "").split("_");

        (path.length > 1
          ? checkPNR(path[1], path[0], false, "add_flight")
          : checkPNR(pnr, null, false, "add_flight")
        )
          .then((resp: { exist: boolean; binded: boolean } | any) => {
            console.log(resp);
            setIsLoading(false);
            if (!resp.binded) {
              if (resp.exist) {
                Modal.confirm({
                  title: t("AddingFlight.thisPNRHasAlreadyBeenAdded") as string,
                  content: t(
                    "AddingFlight.wouldYouLikeToAddPassengerToPNRQuestion"
                  ),
                  cancelText: t("abort"),
                  onCancel: () => setPnr(""),
                  okText: t("continue"),
                  onOk: () => setIsManual(true),
                  okButtonProps: {
                    style: {
                      backgroundColor: APP_COLORS.light.primary,
                      color: APP_COLORS.light.primary_text_color,
                    },
                  },
                  cancelButtonProps: {},
                });
              } else {
                setIsManual(true);
              }
            } else {
              setPnr("");
              setIsManual(false);
            }
          })
          .catch((err) => {
            console.log("[AddFlghtScreen] failed to load:", err);
            setIsLoading(false);
            setIsManual(false);
            !auto && setPnr("");
          });
      }
    },
    [checkPNR, t, scanError]
  );
  const onCancel = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onCreatePNR = useCallback(
    (values) => {
      const [flight_departure_time, flight_arrival_time] =
        values.time.split(";");
      const data = {
        airline: values.flight.airline?.icao,
        flight_number: values.flight.id,
        flight_date: values.flightDate,
        flight_origin: values.flight.origin.iata,
        flight_destination: values.flight.destination.iata,
        flight_departure_time,
        flight_arrival_time,
      };

      setIsLoading(true);
      const path: any = pnr.split("_");
      (path.length > 1
        ? createVirtualTicket(path[1], data, "add_flight")
        : createVirtualTicket(pnr, data, "add_flight")
      ).finally(() => setIsLoading(false));
    },
    [pnr, createVirtualTicket]
  );

  const callSubmit = useCallback(
    (query: string) => onAddFlight(query, true),
    [onAddFlight]
  );

  const onScan = useCallback(
    (evn) => {
      setScanError(false);

      const qr = evn?.split("/").pop();

      if (qr && qr.match(/[A-Z]{0,3}_[0-9A-Z]*/)) {
        setPnr(qr);

        setIsManual(false);
        setIsFocused(true);
        if (qr !== pnr) {
          callSubmit(qr);
        }
      } else {
        setScanError(true);
        Modal.info({
          title: t("AddingFlight.wrongQr"),
          content: t("AddingFlight.wrongQrDescription"),
          okText: t("ok"),
        });
      }
    },
    [pnr, callSubmit, t]
  );

  const pnrValidationScheme = useMemo(() => {
    return Yup.object().shape({
      flight: Yup.object().required(t("BagScreen.required")),
      flightDate: Yup.string().required(t("BagScreen.required")),
      time: Yup.string().required(t("BagScreen.required")),
    });
  }, [t]);
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    enableReinitialize: true,
    validationSchema: pnrValidationScheme,
    onSubmit: onCreatePNR,
  });
  useEffect(() => {
    setIsManual(false);
    formik.resetForm();
  }, [pnr]); // eslint-disable-line

  return (
    <App style={{ height: "100%" }}>
      <div className="AddFlight">
        <div className="AddFlight-header">
          <Button
            type="text"
            icon={
              <LeftOutlined
                style={{ color: APP_COLORS.light.primary, fontSize: 20 }}
              />
            }
            onClick={() => {
              navigate(-1);
            }}
          />
          <span>{t("TabViewsTitle.addFlight")}</span>
        </div>
        <div className="AddFlight-container">
          <div className="AddFlight-content">
            <div className="AddFlight-scanner">
              {isFocused ? (
                <div
                  className="AddFlight-scan-container"
                  onClick={() => setIsFocused(false)}
                >
                  <div className="AddFlight-scan-qr">
                    <img src={scanIcon} alt="" />
                    <span
                      style={{
                        color: APP_COLORS.light.primary,
                      }}
                    >
                      {t("AddingFlight.QRScan")}
                    </span>
                  </div>
                </div>
              ) : (
                <Scanner
                  handleScan={onScan}
                  onClose={() => {
                    setIsFocused(true);
                  }}
                />
              )}
            </div>
            <div className="AddFlight-data">
              <Typography
                style={{
                  fontSize: 16,
                  color: APP_COLORS.light.primary,
                  marginBottom: 4,
                  marginTop: 5,
                }}
              >
                {t("AddingFlight.enterYourBookingCode")}
              </Typography>
              <Input
                style={{
                  textTransform: "uppercase",
                }}
                type="text"
                value={pnr}
                onChange={(text) => {
                  setPnr(text.target.value.replace(/[^a-zA-Z0-9_\-@]/g, ""));
                  setIsFocused(true);
                }}
                placeholder={t("AddingFlight.inputPlaceholder")}
              />
              <Typography
                style={{
                  fontSize: 16,
                  color: APP_COLORS.light.primary,
                  marginBottom: 4,
                  marginTop: 5,
                }}
              >
                {t("AddingFlight.thebookingCode")}
              </Typography>
              {isManual ? <ManualPNR formik={formik} /> : null}
              <Button
                type="default"
                disabled={!pnr}
                onClick={
                  isManual
                    ? () => formik.handleSubmit()
                    : () => onAddFlight(pnr)
                }
                style={{
                  width: "100%",
                  marginBottom: "2%",
                  marginTop: 10,
                  color: APP_COLORS.light.primary_text_color,
                  backgroundColor: APP_COLORS.light.primary,
                }}
              >
                {t("AddingFlight.verifyMyBook")}
                {isLoading && (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{ marginLeft: 10, fontSize: 24 }}
                        spin
                      />
                    }
                  />
                )}
              </Button>
              <Button
                type="default"
                onClick={onCancel}
                style={{
                  width: "100%",
                  backgroundColor: APP_COLORS.light.primary,
                  color: APP_COLORS.light.primary_text_color,
                }}
              >
                {t("AddingFlight.skip")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </App>
  );
};

export default AddFlight;
