import { Button, Card, Col, Row } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { isSafari, isMobileSafari } from "react-device-detect";
import { APP_COLORS } from "utils/colors";
interface IProps {
  title: string;
  onPress: () => void;
}
function LineCard({ title, onPress }: IProps) {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card
          style={{ marginBottom: 20 }}
          onClick={onPress}
          headStyle={{
            border: "none",
            fontWeight: isMobileSafari || isSafari ? "599" : "600",
          }}
          hoverable
          title={title}
          bordered={true}
          extra={
            <Button block type='link' onClick={onPress}>
              <LinkOutlined style={{ color: APP_COLORS.light.black, fontSize: 20 }} />
            </Button>
          }
        ></Card>
      </Col>
    </Row>
  );
}

export default LineCard;
