import { ReactNode, useCallback, useMemo } from "react";
import { withObservables } from "@nozbe/watermelondb/react";
import { database } from "wmelon/database";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import uniq from "lodash/uniq";
import orderBy from "lodash/orderBy";
import { Q } from "@nozbe/watermelondb";
import EnhancedTicketResult from "../TicketResult";
import AnimatedPreloader from "components/AnimatedPreloader";
import FlightTicket from "wmelon/models/FlightTicket";
import Session from "wmelon/models/Sessions";
import { ValidationTicket } from "redux/features/validation";
import { getQueryValue } from "utils/db";

import "./FinalPage.css";
import { APP_COLORS } from "utils/colors";
import { Observable } from "@nozbe/watermelondb/utils/rx";
export interface IProps {
  icon?: any;
  title: string;
  description: string | ReactNode;
  sessions: Session[];

  initializationState: {
    process: boolean;
    count: number;
    percent: number;
    uploaded: string;
    total: string;
  };
  initializing: boolean;
  sessionId: string | null;

  buttons: ReactNode;
  loading: boolean;
  ticketId: string;

  tickets: FlightTicket[];
  validationTickets: ValidationTicket[];

  hideByTicket?: boolean;
  isSuccess?: boolean;
  animationOnly?: boolean;

  onCancel?: () => Promise<void>;
}

function FinalPage({
  icon,
  title,
  description,
  sessions,

  initializationState,
  initializing,
  sessionId,

  buttons,
  loading,

  onCancel,
  ticketId,
  tickets,
  validationTickets,

  hideByTicket,
  isSuccess,
  animationOnly,
}: IProps) {
  const { t } = useTranslation();

  const isSynced = useMemo(() => {
    if (!sessions.length) {
      return false;
    }

    return sessions[0]._raw._status === "synced";
  }, [sessions]);

  const sortedTickets: {
    key: string;
    ticket: FlightTicket;
    sessionId: string | null;
    status: string;
  }[] = useMemo(() => {
    return uniq(orderBy(validationTickets, ["validatedAt"]))
      .filter((item) => item.status)
      .map((item) => {
        return {
          key: item.id as string,
          ticket: tickets.find(
            (ticket) => ticket.id === item.id
          ) as FlightTicket,
          sessionId: item.sessionId as string | null,
          status: item.status as string,
        };
      })
      .filter((it) => !!it.ticket);
  }, [validationTickets, tickets]);

  const list: {
    key: string;
    ticket: FlightTicket;
    status: string;
    sessionId: string | null;
  }[] = useMemo(() => {
    if (sortedTickets.length === 1) {
      const list = hideByTicket
        ? sortedTickets.filter((it) => it.ticket.id !== ticketId)
        : sortedTickets;
      return list;
    }

    let list = hideByTicket
      ? sortedTickets.filter((it) => it.ticket.id !== ticketId)
      : sortedTickets;

    return list;
  }, [sortedTickets, ticketId, hideByTicket]);

  if (initializing) {
    console.log(
      "[FinalPage] tickets:init",
      sortedTickets.map((it) => it.key),
      `;sId-"${sessionId}";isSynced-"${isSynced}";hideTck-"${hideByTicket}";c-"${list.length}"`,
      initializationState
    );
  } else {
    console.log(
      "[FinalPage] tickets:not_init",
      sortedTickets.map((it) => it.key),
      `;init-"${initializing}";sId-"${sessionId}";isSynced-"${isSynced}";hideTck-"${hideByTicket}";c-"${list.length}"`,
      initializationState
    );
  }

  const renderTicket = useCallback(
    (
      {
        item,
      }: {
        item: {
          key: string;
          ticket: FlightTicket;
          status: string;
          sessionId: string | null;
        };
      },
      fullwidth: boolean
    ) => {
      return (
        <EnhancedTicketResult
          key={item.ticket.id}
          ticket={item.ticket}
          fullwidth={fullwidth}
          statusId={item.status}
          sessionId={item.sessionId}
        />
      );
    },
    []
  );

  const renderIcon = useCallback(() => {
    if (initializing || animationOnly) {
      return (
        <AnimatedPreloader
          percent={initializing ? initializationState.percent : 100}
          isSuccess={isSuccess}
          showUpload={
            initializing ||
            isSynced ||
            (initializing && initializationState.percent >= 100)
          }
        />
      );
    }

    return (
      <div className="FinalPage-icon">
        <img src={icon} alt="" />
      </div>
    );
  }, [
    animationOnly,
    icon,
    initializationState,
    initializing,
    isSuccess,
    isSynced,
  ]);

  return (
    <div className="FinalPage">
      <div className="FinalPage-container">
        <span className="FinalPage-title">{title}</span>
        {initializing ? (
          <div className="FinalPage-content">
            {renderIcon()}
            {initializationState.count ? (
              <div className="FinalPage-total">
                <span className="FinalPage-total-text">
                  {t("RegulaScreens.upload_files", {
                    count: initializationState.count,
                  })}
                </span>
                <span className="FinalPage-total-upload">
                  {initializationState.uploaded} / {initializationState.total}
                </span>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="FinalPage-content">
            {renderIcon()}
            <div className="FinalPage-description">{description}</div>
            <div className="FinalPage-tickets">
              {list.map((item) => renderTicket({ item }, false))}
            </div>
            <div className="FinalPage-buttons">
              {buttons}
              <Button
                type="primary"
                onClick={onCancel}
                style={{
                  color: APP_COLORS.light.primary,
                  backgroundColor: APP_COLORS.light.default_btn,
                  borderColor: APP_COLORS.light.primary,
                }}
              >
                {t("Cancel")}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const enhance: any = withObservables<
  Omit<IProps, "sessions">,
  { sessions: Observable<Session[]> }
>(["sessionId"], ({ sessionId }: { sessionId: string | null }) => ({
  sessions: database
    .get<Session>(Session.table)
    .query(Q.where("id", getQueryValue(sessionId)))
    .observeWithColumns(["_status"]),
}));

const EnhancedFinalPage = enhance(FinalPage);
export default EnhancedFinalPage;
