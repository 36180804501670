import config from "../config";
import { TextFieldType } from "@regulaforensics/document-reader-webclient";
import moment from "moment";

import get from "lodash/get";
import set from "lodash/set";
import { Credentials } from "types/validator";

export function getFieldPath(
  schema: any,
  recognizer: string | null,
  field: string,
  path: string
): string[] {
  if (!schema || !recognizer) {
    return [];
  }
  if (schema.type === "object") {
    let result: string[] = [];

    Object.keys(schema.properties).forEach((key: string) => {
      const res = getFieldPath(
        schema.properties[key],
        recognizer,
        field,
        `${path ? `${path}.` : ""}${key}`
      );
      if (res) {
        result = result.concat(res);
      }
    });

    return result;
  } else {
    if (
      (schema.recognizerFieldId || "").toLowerCase() === field &&
      (schema.recognizer || config.RECOGNIZERS.REGULA).toLowerCase() ===
      recognizer.toLowerCase()
    ) {
      return [path];
    }
  }

  return [];
}

export const getData = (
  data: any,
  saveFormat: string | undefined,
  resultFormat: string
): string | undefined => {
  if (!data) {
    return undefined;
  }

  const date = moment(data, saveFormat);
  if (!date.isValid()) {
    return undefined;
  }

  return date.format(resultFormat);
};
export function getLabelByUiScheme(uischema, field) {
  if (uischema?.elements) {
    for (let idx = 0; idx < uischema?.elements.length; idx += 1) {
      const opts = getLabelByUiScheme(uischema?.elements[idx], field);
      if (opts) {
        return opts;
      }
    }
  } else if (uischema?.scope && uischema?.scope.includes(field)) {
    return uischema?.label;
  }
  return null;
}

export function getFormFields(schema, blocklist: string[], path): string[] {
  let result: string[] = [];

  if (schema.type === "object") {
    Object.keys(schema.properties).forEach((field) => {
      result = [
        ...result,
        ...getFormFields(
          schema.properties[field],
          blocklist,
          `${path ? `${path}.` : ""}${field}`
        ),
      ];
    });
  } else if (
    !blocklist.length ||
    blocklist.indexOf(schema.recognizerFieldId) === -1
  ) {
    result.push(path);
  }

  return result;
}
export function getDocumentData(data: Record<string, any>, schema: any) {
  const fields = getFormFields(
    schema,
    [
      config.JSONFORMS.MRZ_FIELD,
      config.JSONFORMS.RFID_FIELD,
      config.JSONFORMS.ICAO_CODE_FIELD,
      config.JSONFORMS.DOCUMENT_NAME_FIELD,
      config.JSONFORMS.REGULA_ID,
      config.JSONFORMS.ACCEPT_ID
    ],
    ""
  );

  return fields.reduce(
    (acc, field: string) => ({
      ...acc,
      [field]: get(data as any, field as any),
    }),
    {}
  );
}
export function getOptionsByUiScheme(uischema, field, readOnlyFields) {
  if (uischema.elements) {
    for (let idx = 0; idx < uischema.elements.length; idx += 1) {
      const opts = getOptionsByUiScheme(
        uischema.elements[idx],
        field,
        readOnlyFields
      );
      if (opts) {
        return opts;
      }
    }
  } else if (uischema.scope && uischema.scope.includes(field)) {
    const opts = {
      ...uischema.options,
      readOnly: readOnlyFields.includes(field),
    };
    if (Object.keys(opts).length > 1 || opts.readOnly) {
      return opts;
    }
    return null;
  }
  return null;
}

export function getIsVisibleField(uischema, field) {
  if (uischema?.elements) {
    return uischema?.elements.some((elem) => getIsVisibleField(elem, field));
  } else if (uischema?.scope && uischema.scope.includes(field)) {
    return true;
  }

  return false;
}

export function replaceFieldTypes(
  schema: any,
  fields: { field: string; recognizer: string; type: string }[]
) {
  fields.forEach((it: { field: string; recognizer: string; type: string }) => {
    const path = getFieldPath(schema, it.recognizer, it.field, "");
    if (path && path.length) {
      set(schema, `properties.${path.join(".properties.")}.type`, it.type);
    }
  });

  return schema;
}

export function getFormOwner(
  data: Record<string, any>,
  schema: any,
  recognizer: string | null
): Credentials | null {
  const isRegula =
    recognizer && recognizer.toLowerCase() === config.RECOGNIZERS.REGULA;

  if (isRegula) {
    const [ownerGNPath] = getFieldPath(
      schema,
      recognizer,
      TextFieldType.GIVEN_NAMES.toString(),
      ""
    );
    const [ownerSNPath] = getFieldPath(
      schema,
      recognizer,
      TextFieldType.SURNAME.toString(),
      ""
    );
    const [ownerFullnamePath] = getFieldPath(
      schema,
      recognizer,
      TextFieldType.SURNAME_AND_GIVEN_NAMES.toString(),
      ""
    );
    const [ownerDOBPath] = getFieldPath(
      schema,
      recognizer,
      TextFieldType.DATE_OF_BIRTH.toString(),
      ""
    );

    const gn = ownerGNPath ? get(data, ownerGNPath) : null;
    const ln = ownerSNPath ? get(data, ownerSNPath) : null;
    const fullName = ownerFullnamePath ? get(data, ownerFullnamePath) : null;
    const dob = ownerDOBPath ? get(data, ownerDOBPath) : null;

    if (ln) {
      return {
        first_name: gn || null,
        last_name: ln,
        dob,
      };
    } else if (fullName) {
      return {
        first_name: null,
        last_name: fullName,
        dob,
      };
    } else {
      return null;
    }
  } else {
    const [ownerGNPath] = getFieldPath(
      schema,
      recognizer,
      config.CERTIFICATE_FIELDS.GIVEN_NAME,
      ""
    );
    const [ownerSNPath] = getFieldPath(
      schema,
      recognizer,
      config.CERTIFICATE_FIELDS.SURNAME,
      ""
    );
    const [ownerDOBPath] = getFieldPath(
      schema,
      recognizer,
      config.CERTIFICATE_FIELDS.DATE_OF_BIRTH,
      ""
    );

    const gn = ownerGNPath ? get(data, ownerGNPath) : null;
    const ln = ownerSNPath ? get(data, ownerSNPath) : null;

    return gn && ln
      ? {
        first_name: gn || null,
        last_name: ln || null,
        dob: ownerDOBPath ? get(data, ownerDOBPath) : null,
      }
      : null;
  }
}
