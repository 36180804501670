import React from "react";
import { useTranslation } from "react-i18next";
import "./PassengerInfo.css";
export interface PassengerInfoProps {
  passenger: {
    firstName: string | null;
    lastName: string | null;
    dob: string | null;
    documentNumber: string | null;
  };
}

function PassengerInfo({ passenger }: PassengerInfoProps) {
  const { t } = useTranslation();

  return (
    <div className="PassengerInfo">
      <div className="PassengerInfo-row">
        <div className="PassengerInfo-column left">
          <span className="PassengerInfo-title">{t("Profile.name")}</span>
          <span className="PassengerInfo-value">
            {passenger.firstName || t("na")}
          </span>
        </div>
        <div className="PassengerInfo-column right">
          <span className="PassengerInfo-title">{t("Profile.Surname")}</span>
          <span className="PassengerInfo-value">
            {passenger.lastName || t("na")}
          </span>
        </div>
      </div>
      <div className="PassengerInfo-row">
        <div className="PassengerInfo-column left">
          <span className="PassengerInfo-title">{t("Profile.Birthday")}</span>
          <span className="PassengerInfo-value">
            {passenger.dob || t("na")}
          </span>
        </div>
        <div className="PassengerInfo-column right">
          <span className="PassengerInfo-title">
            {t("Profile.TravelDocument")}
          </span>
          <span className="PassengerInfo-value">
            {passenger.documentNumber || t("na")}
          </span>
        </div>
      </div>
    </div>
  );
}

export default PassengerInfo;
