import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload, notification } from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import { getBase64 } from "utils/file";
import { useTranslation } from "react-i18next";
import { NOTIFICATION_KEYS } from "constants/contants";
export default function UserPicture() {
  const { t } = useTranslation();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [api, contextHolder] = notification.useNotification();

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    console.log(fileList);
    if (fileList.filter((file) => file.status === "done")) {
      return setFileList(newFileList);
    } else if (fileList.filter((file) => file.status === "error")) {
      api.info({
        key: NOTIFICATION_KEYS.ADD_BAG_UPLOAD_IMAGE,
        message: t("Info"),
        description: t("Profile.uploadImage"),
        placement: "topRight",
      });
    }
  };

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  console.log(fileList);
  return (
    <div>
      <div>
        <Upload
          action='https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188'
          listType='picture-card'
          className='upload-list-inline'
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleChange}
          accept='.jpg,.jpeg,.png'
        >
          {fileList.length > 10 ? null : uploadButton}
        </Upload>
      </div>

      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt='example' style={{ width: "100%" }} src={previewImage} />
      </Modal>
      {contextHolder}
    </div>
  );
}
