import moment from "moment";
import "moment/locale/de";
import "moment/locale/fr";
import "moment/locale/it";
import "moment/locale/tr";
import "moment/locale/fi";
import "moment/locale/ar";
moment.locale("en");

export function getLocaleDate(date: string | number, formatDate: string) {
  if (!date) {
    return "N/a";
  }

  const deviceLocale = window.navigator.language?.split('-').shift()
  
  if (deviceLocale && ['de', 'fr', 'it', 'tr', 'fi', 'ar'].includes(deviceLocale)) {
    return moment(date).locale(deviceLocale).format(formatDate)
  } else {
    return moment(date).locale('en').format(formatDate)
  }
}

export function getSeconds(ts: number) {
  return Math.floor(ts / 1000);
}
