import { appSchema, tableSchema } from "@nozbe/watermelondb";

const schema = appSchema({
  version: 17,
  tables: [
    tableSchema({
      name: "sessions",
      columns: [
        // Define columns for 'sessions' table
        { name: "short_id", type: "string" },
        { name: "flight", type: "string" },
        { name: "carrier", type: "string", isOptional: true },
        { name: "origin", type: "string" },
        { name: "destination", type: "string" },
        { name: "depart_port", type: "string", isOptional: true },
        { name: "arrival_port", type: "string", isOptional: true },
        { name: "transit", type: "string", isOptional: true },
        { name: "flight_date", type: "string" },
        { name: "scheme_id", type: "string" },
        { name: "scheme_version", type: "string" },
        { name: "status", type: "string" },
        { name: "handler", type: "string" },
        { name: "owner_first_name", type: "string", isOptional: true },
        { name: "owner_last_name", type: "string", isOptional: true },
        { name: "owner_dob", type: "string", isOptional: true },
        { name: "owner_document_number", type: "string", isOptional: true },
        { name: "scheme_type", type: "string", isOptional: true },
        { name: "assign_to", type: "string", isOptional: true },
        { name: "flight_ticket_id", type: "string" },
        { name: "created_at", type: "number" },
      ],
    }),
    tableSchema({
      name: "notifications",
      columns: [
        // Define columns for 'notifications' table
        { name: "created_at", type: "number" },
        { name: "updated_at", type: "number" },
        { name: "deleted_at", type: "number", isOptional: true },
        { name: "device_id", type: "string", isOptional: true },
        { name: "user_id", type: "string" },
        { name: "session_id", type: "string" },
        { name: "payload", type: "string", isOptional: true },
        { name: "flight_ticket_id", type: "string" },
        { name: "action", type: "string" },
        { name: "read_at", type: "number", isOptional: true },
      ],
    }),
    tableSchema({
      name: "documents",
      columns: [
        // Define columns for 'documents' table
        { name: "session_id", type: "string" },
        { name: "category", type: "number" },
        { name: "form_id", type: "number", isOptional: true },
        { name: "form_version", type: "number", isOptional: true },
        { name: "type", type: "string" },
        { name: "provider", type: "string" },
        { name: "recognizer", type: "string", isOptional: true },
        { name: "data", type: "string" },
        { name: "auto_verification_result", type: "string", isOptional: true },
        { name: "files", type: "string", isOptional: true },
        { name: "verified_manual", type: "boolean", isOptional: true },
        { name: "verified_auto", type: "boolean", isOptional: true },
      ],
    }),
    tableSchema({
      name: "tickets",
      columns: [
        // Define columns for 'tickets' table
        { name: "session_id", type: "string" },
        { name: "status", type: "string" },
        { name: "document_id", type: "string", isOptional: true },
        { name: "pkpass_document_id", type: "string", isOptional: true },
        { name: "archive_document_id", type: "string", isOptional: true },
      ],
    }),
    tableSchema({
      name: "passengers_pnr",
      columns: [
        { name: "created_at", type: "number" },
        { name: "created_by", type: "number" },
        { name: "updated_at", type: "number" },
        { name: "updated_by", type: "string", isOptional: true },
        { name: "session_id", type: "string" },
        { name: "status", type: "string" },
        { name: "validation_id", type: "string", isOptional: true },
        { name: "document_id", type: "string", isOptional: true },
        { name: "pkpass_document_id", type: "string", isOptional: true },
        { name: "archive_document_id", type: "string", isOptional: true },
        { name: "created_from", type: "string", isOptional: true },
        { name: "updated_from", type: "string", isOptional: true },
        { name: "deleted", type: "boolean" },
      ],
    }),
    tableSchema({
      name: "forms",
      columns: [
        { name: "documentId", type: "string", isOptional: true },
        { name: "documentTypes", type: "string" },
        { name: "groupId", type: "string", isOptional: true },
        { name: "json", type: "string" },
        { name: "recognizer", type: "string" },
        { name: "title", type: "string" },
      ],
    }),
    tableSchema({
      name: "files",
      columns: [
        { name: "hash", type: "string", isOptional: true },
        { name: "data", type: "string" },
        { name: "position", type: "number" },
        { name: "document_id", type: "string" },
      ],
    }),

    tableSchema({
      name: "profile",
      columns: [
        { name: "avatar", type: "string" },
        { name: "name", type: "string" },
        { name: "surname", type: "string" },
        { name: "dob", type: "string" },
        { name: "email", type: "string" },

        { name: "created_at", type: "number" },
        { name: "updated_at", type: "number" },
        { name: "deleted_at", type: "string", isOptional: true },

        { name: "mobile", type: "string", isOptional: true },
        { name: "passport", type: "string" },
        { name: "passport_exp", type: "string" },

        { name: "mobile_id", type: "boolean", isOptional: true },
        { name: "face_id", type: "boolean", isOptional: true },
        { name: "email_sms", type: "boolean", isOptional: true },

        { name: "trusted_device", type: "string", isOptional: true },
      ],
    }),
    tableSchema({
      name: "tags",
      columns: [
        { name: "name", type: "string" },
        { name: "color", type: "string" },
      ],
    }),
    tableSchema({
      name: "tagBinds",
      columns: [
        { name: "tag_id", type: "string" },
        { name: "bind_object", type: "string" },
        { name: "object_id", type: "string" },
      ],
    }),
    tableSchema({
      name: "schemaCountryGroups",
      columns: [
        { name: "name", type: "string" },
        { name: "code2", type: "string" },
        { name: "code3", type: "string" },
      ],
    }),
    tableSchema({
      name: "schemaVaccines",
      columns: [
        { name: "key", type: "string" },
        { name: "display", type: "string" },
        { name: "lang", type: "string" },
        { name: "active", type: "string" },
        { name: "system", type: "string" },
        { name: "version", type: "string" },
        { name: "field", type: "string", isOptional: true },
      ],
    }),
    tableSchema({
      name: "airlines",
      columns: [
        { name: "icao", type: "string" },
        { name: "iata", type: "string" },
        { name: "name", type: "string" },
        { name: "icon", type: "string", isOptional: true },
      ],
    }),
    tableSchema({
      name: "users",
      columns: [
        { name: "avatar", type: "string" },
        { name: "name", type: "string" },
        { name: "surname", type: "string" },
        { name: "dob", type: "string" },
      ],
    }),
    tableSchema({
      name: "bags",
      columns: [
        { name: "files", type: "string" },
        { name: "name", type: "string" },
        { name: "contact", type: "string" },
        { name: "comment", type: "string" },
        { name: "flights", type: "string" },
        { name: "routes", type: "string" },
        { name: "tag_id", type: "string" },
      ],
    }),
    tableSchema({
      name: "damageBag",
      columns: [
        { name: "bag_id", type: "string" },
        { name: "files", type: "string" },
        { name: "name", type: "string" },
        { name: "tag_id", type: "string" },
        { name: "contact", type: "string" },
        { name: "comment", type: "string" },
      ],
    }),
    tableSchema({
      name: "countries",
      columns: [
        { name: "name", type: "string" },
        { name: "alpha2", type: "string" },
        { name: "alpha3", type: "string" },
      ],
    }),
    tableSchema({
      name: "airports",
      columns: [
        { name: "icao", type: "string" },
        { name: "iata", type: "string", isOptional: true },
        { name: "name", type: "string" },
        { name: "countryCode", type: "string" },
      ],
    }),

    tableSchema({
      name: "flightTickets",
      columns: [
        { name: "identifiers", type: "string" },

        { name: "ticket_number", type: "string" },
        { name: "airline", type: "string" },

        { name: "flight_number", type: "string" },
        { name: "flight_date", type: "number" },
        { name: "flight_departure_time", type: "string", isOptional: true },
        { name: "flight_arrival_time", type: "string", isOptional: true },
        { name: "flight_origin", type: "string" },
        { name: "flight_destination", type: "string" },

        { name: "passenger_document_hash", type: "string", isOptional: true },
        { name: "passenger_firstname_hash", type: "string", isOptional: true },
        { name: "passenger_lastname_hash", type: "string", isOptional: true },

        { name: "passenger_document", type: "string", isOptional: true },
        { name: "passenger_firstname", type: "string", isOptional: true },
        { name: "passenger_lastname", type: "string", isOptional: true },
      ],
    }),
    tableSchema({
      name: "flightTicketBinds",
      columns: [
        { name: "ticket_id", type: "string" },
        { name: "ticket_number", type: "string" },

        { name: "created_at", type: "number" },
      ],
    }),
    tableSchema({
      name: "flightTicketShares",
      columns: [
        { name: "flight_ticket_id", type: "string" },
        { name: "token", type: "string" },
        { name: "shared_by", type: "string" },

        { name: "used_by", type: "string", isOptional: true },
        { name: "used_at", type: "number", isOptional: true },

        { name: "created_at", type: "number" },
      ],
    }),
  ],
});

export { schema };
