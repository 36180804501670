import {Model} from '@nozbe/watermelondb'
import {field} from '@nozbe/watermelondb/decorators'

export default class Form extends Model {
  static table = 'forms'

  @field('documentId') documentId?: string
  @field('documentTypes') documentTypes!: string
  @field('groupId') groupId?: string
  @field('json') json!: string
  @field('recognizer') recognizer!: string
  @field('title') title!: string
}
