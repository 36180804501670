import { Button as AntdButton, ButtonProps } from 'antd';
import './Button.css';

export interface IProps extends ButtonProps {

}

function Button(props: ButtonProps) {

  return (
    <AntdButton
      className={`Button ${props.className} ${props.type || 'default'}`}
      {...props}
    >
      {props.children}
    </AntdButton>
  );
}

export default Button;
