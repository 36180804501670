export class ErrorTexts {
  static RECOGNIZER_NOT_SUPPORT = 'recognizer not supported'
  static NOT_RECOGNIZED = 'not recognized'
  static SCHEMA_PROPERTIES_NOT_FOUND = 'field $.schema.properties not found in form'
  static MRZ_NOT_VALID = 'mrz not valid'
  static DOCUMENT_TYPE_NOT_ALLOWED = 'document type not allowed for form'
  static REMOTE_SERVER_ERROR = 'remote server error'
  static NO_DATA = 'no_data'
  static DOCUMENT_IS_NOT_VALID = 'document_is_not_valid'
  static MORE_PAGES_AVAILABLE = 'MORE_PAGES_AVAILABLE'
}
