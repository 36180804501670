import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isSyncing } from "wmelon/sync";

export const setSyncing = createAsyncThunk(
  "app/setSyncing",
  (data: boolean) => {
    return data;
  }
);

export const setResyncing = createAsyncThunk(
  "app/setResyncing",
  (data: boolean) => {
    return data;
  }
);

export const setLastPulledAt = createAsyncThunk(
  "app/setLastPulledAt",
  (data: number | null) => {
    return data;
  }
);

export const setRefreshing = createAsyncThunk(
  "app/setRefreshing",
  (data: boolean) => {
    return data;
  }
);

export const setManualState = createAsyncThunk(
  "app/setManualState",
  async (data: boolean, { rejectWithValue }) => {
    try {
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const setFailedSync = createAsyncThunk(
  "app/setFailedSync",
  (data: number) => {
    return data;
  }
);

export const setSyncStage = createAsyncThunk(
  "app/setSyncStage",
  (data: "start" | "pull_1" | "push_1" | "pull_2" | "push_2" | "upload") => {
    return data;
  }
);

export const setUploadState = createAsyncThunk(
  "app/setUploadState",
  (data: {
    documentId: string;
    page: number;
    bytesUploaded: number;
    bytesTotal: number;
  }) => {
    return data;
  }
);

export const clearUploadState = createAsyncThunk(
  "app/clearUploadState",
  () => {}
);

export interface AppState {
  syncing: boolean;
  resyncing: boolean;
  showManual: boolean;
  refreshing: boolean;
  lastPulledAt: number | null;
  failedSync: number;
  syncProgress: {
    stage:
      | "start"
      | "pull_1"
      | "push_1"
      | "pull_2"
      | "push_2"
      | "upload"
      | null;
    uploads: Record<string, { bytesTotal: number; bytesUploaded: number }>;
  };
}

export const initialState: AppState = {
  syncing: isSyncing || false,
  resyncing: false,
  showManual: false,
  refreshing: false,
  lastPulledAt: null,
  failedSync: 0,
  syncProgress: { stage: null, uploads: {} },
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setSyncing.fulfilled, (state, action) => {
        state.syncing = action.payload;
      })
      .addCase(setResyncing.fulfilled, (state, action) => {
        state.resyncing = action.payload;
      })
      .addCase(setManualState.fulfilled, (state, action) => {
        state.showManual = action.payload;
      })
      .addCase(setRefreshing.fulfilled, (state, action) => {
        state.refreshing = action.payload;
      })
      .addCase(setLastPulledAt.fulfilled, (state, action) => {
        state.lastPulledAt = action.payload;
      })
      .addCase(setFailedSync.fulfilled, (state, action) => {
        state.failedSync = action.payload;
      })
      .addCase(setSyncStage.fulfilled, (state, action) => {
        state.syncProgress.stage = action.payload;
      })
      .addCase(setUploadState.fulfilled, (state, action) => {
        state.syncProgress.uploads[
          `${action.payload.documentId}_${action.payload.page}`
        ] = {
          bytesTotal: action.payload.bytesTotal,
          bytesUploaded: action.payload.bytesUploaded,
        };
      })
      .addCase(clearUploadState.fulfilled, (state) => {
        state.syncProgress = { stage: null, uploads: {} };
      });
  },
});

export default appSlice.reducer;
