import { Model } from '@nozbe/watermelondb'
import { relation, field } from '@nozbe/watermelondb/decorators'
import Session from './Sessions'

export default class Ticket extends Model {
  static table = 'tickets'

  @field('status') status!: string

  @relation('documents', 'document_id') document!: Document
  @relation('documents', 'pkpass_document_id') pkpassDocumentId!: Document
  @relation('documents', 'archive_document_id') archiveDocumentId!: Document

  @relation('sessions', 'session_id') session!: Session
}
