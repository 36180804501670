import { useCallback, useEffect, useState, useMemo } from "react";
import { withObservables } from "@nozbe/watermelondb/react";
import { database } from "wmelon/database";
import { Q } from "@nozbe/watermelondb";
import { connect } from "react-redux";
import { Modal, Button, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FinalPage from "./components/FinalPage";
import { useSessionInitializer } from "hooks/useSessionInitializer";
import { useReduxSession } from "hooks/useReduxSession";
import { useTimer } from "hooks/useTimer";
import { getQueryValue } from "utils/db";
import { SessionState } from "redux/features/session";
import Session from "wmelon/models/Sessions";
import FlightTicket from "wmelon/models/FlightTicket";
import { ValidationTicket } from "redux/features/validation";
import { getTicketInfoParams } from "utils/flights";
import { getShortId } from "utils/session";
import errorIcon from "../../assets/images/finalfailure.svg";
import ModalCallCenter from "components/ModalCallCenter";
import { APP_COLORS } from "utils/colors";
import { Observable } from "@nozbe/watermelondb/utils/rx";

export interface IProps {
  validationTickets: ValidationTicket[];
  sessionState: SessionState;
  isSyncing: boolean;
  sessions: Session[];
  unsynced: Session[];
  tickets: FlightTicket[];
}

const FinalFailed = ({
  validationTickets,
  sessionState,
  isSyncing,
  sessions,
  tickets,
  unsynced,
}: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isConnected = true;

  const [counter, startTimer] = useTimer("syncLimit");

  const {
    nextTicketId,
    sessionId,
    restartSession,
    cancelSession,
    initializeSession,
    loadingState,
    initializing,
    percent,
  } = useSessionInitializer(sessions, unsynced, startTimer);
  const { runByTicketId } = useReduxSession();

  const [isOpenCallDialog, setOpenCallDialog] = useState(false);

  useEffect(() => {
    if (sessionState && !sessionState.flight_ticket_id) {
      navigate("/tickets/upcoming");
    }
  }, [sessionState, navigate]);

  const shortId = useMemo(() => {
    const ticket = validationTickets.find(
      (it) => it.id === sessionState.flight_ticket_id
    );

    if (ticket?.sessionId) {
      const session =
        sessions.find((it) => it.id === ticket.sessionId) ||
        unsynced.find((it) => it.id === ticket.sessionId);
      return session?.short_id || null;
    }

    return null;
  }, [validationTickets, sessionState.flight_ticket_id, sessions, unsynced]);

  const onCancelSession = useCallback(() => {
    return cancelSession(true, true);
  }, [cancelSession]);

  const onGoToTicket = useCallback(() => {
    const ticket = tickets.find(
      (it) => it.id === sessionState.flight_ticket_id
    );
    cancelSession(!ticket, true).finally(() => {
      if (ticket) {
        const {
          flight_number,
          date,
          pnr,
          airline,
          flight_origin,
          flight_destination,
          flight_ticket_id,
        } = getTicketInfoParams(ticket, true);
        navigate(
          `/tickets/${flight_number}/${date}/${pnr}/${airline}/${flight_origin}/${flight_destination}/${flight_ticket_id}`,
          { state: { from: "final" } }
        );
      }
    });
  }, [cancelSession, navigate, sessionState.flight_ticket_id, tickets]);

  const onNextPassenger = useCallback(() => {
    setTimeout(() => {
      nextTicketId &&
        cancelSession(false, false)
          .then(() => runByTicketId(nextTicketId))
          .then(() => navigate("/preparation", { state: { from: "final" } }))
          .catch((err) => {
            Modal.error({
              title: t("RegulaScreens.failedToInitSession"),
              okText: t("ok"),
              onOk: () => cancelSession(true, true),
            });
          });
    }, 5000);
  }, [cancelSession, nextTicketId, runByTicketId, navigate, t]);

  const onCallSupport = useCallback(() => {
    if (sessionId) {
      setOpenCallDialog(true);
      return;
    }
    return initializeSession()
      .then(() => {
        if (isConnected) {
          setOpenCallDialog(true);
        } else {
          Modal.error({
            title: t("RegulaScreens.failedToSyncDatabase"),
            content: t("RegulaScreens.noConnection"),
            okText: t("ok"),
            onOk: () => onCancelSession(),
          });
        }
      })
      .catch((err) => console.error("[FinalFailed] init failed:", err));
  }, [sessionId, initializeSession, isConnected, t, onCancelSession]);

  const loading = !!loadingState || isSyncing;

  console.log("[FinalFailed] sessionState", sessionState);

  return (
    <>
      <FinalPage
        icon={errorIcon}
        title={`${t("FinalFailed.title")}!`}
        description={t("FinalFailed.description")}
        initializationState={percent}
        initializing={initializing}
        sessionId={sessionId}
        isSuccess={false}
        animationOnly
        buttons={
          <>
            <Button
              disabled={loading || initializing || counter > 0}
              type="primary"
              onClick={restartSession}
              style={{
                color: APP_COLORS.light.primary_text_color,
                backgroundColor: APP_COLORS.light.primary,
              }}
              icon={
                loading || initializing ? (
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 24 }} spin />
                    }
                  />
                ) : null
              }
            >
              {t("tryAgain")}
            </Button>
            <Button
              type="primary"
              onClick={onCallSupport}
              style={{
                color: APP_COLORS.light.primary_text_color,
                backgroundColor: APP_COLORS.light.primary,
              }}
              icon={
                loading || initializing ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 24,
                          color: APP_COLORS.light.primary_text_color,
                        }}
                        spin
                      />
                    }
                  />
                ) : null
              }
            >
              {counter
                ? t("canRepeatIn", { count: counter })
                : t("FinalFailed.callCenter")}
            </Button>
            {sessionId ? (
              nextTicketId ? (
                <Button
                  type="primary"
                  disabled={loading || initializing || counter > 0}
                  onClick={onNextPassenger}
                  style={{
                    color: APP_COLORS.light.primary_text_color,
                    backgroundColor: APP_COLORS.light.primary,
                  }}
                  icon={
                    loading || initializing ? (
                      <Spin
                        indicator={
                          <LoadingOutlined style={{ fontSize: 24 }} spin />
                        }
                      />
                    ) : null
                  }
                >
                  {counter
                    ? t("canRepeatIn", { count: counter })
                    : t("RegulaScreens.nextPassenger")}
                </Button>
              ) : (
                <Button
                  type="primary"
                  disabled={loading || initializing || counter > 0}
                  onClick={onGoToTicket}
                  style={{
                    color: APP_COLORS.light.primary_text_color,
                    backgroundColor: APP_COLORS.light.primary,
                  }}
                  icon={
                    loading || initializing ? (
                      <Spin
                        indicator={
                          <LoadingOutlined style={{ fontSize: 24 }} spin />
                        }
                      />
                    ) : null
                  }
                >
                  {counter
                    ? t("canRepeatIn", { count: counter })
                    : t("RegulaScreens.go_to_ticket")}
                </Button>
              )
            ) : null}
          </>
        }
        ticketId={sessionState.flight_ticket_id}
        tickets={tickets}
        validationTickets={validationTickets}
        loading={loading}
        onCancel={initializing ? undefined : onCancelSession}
      />
      <ModalCallCenter
        text={shortId ?? t("na")}
        visible={isOpenCallDialog}
        onClose={() => {
          setOpenCallDialog(false);
        }}
        onNextPassenger={nextTicketId ? onNextPassenger : undefined}
      />
    </>
  );
};

const mapState = (state: any) => ({
  sessionState: state.session,
  isSyncing: state.app.syncing,
  validationTickets: state.validation.tickets,
});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

const enhance = withObservables<
  Omit<IProps, "sessions" | "unsynced" | "tickets">,
  {
    sessions: Observable<Session[]>;
    unsynced: Observable<Session[]>;
    tickets: Observable<FlightTicket[]>;
  }
>(
  ["sessionState", "validationTickets"],
  ({
    sessionState,
    validationTickets,
  }: {
    sessionState: SessionState;
    validationTickets: ValidationTicket[];
  }) => ({
    sessions: database
      .get<Session>(Session.table)
      .query(
        Q.where(
          "flight_ticket_id",
          getQueryValue(sessionState?.flight_ticket_id)
        ),
        Q.where("_status", "synced")
      )
      .observe(),
    unsynced: database
      .get<Session>(Session.table)
      .query(
        Q.where(
          "flight_ticket_id",
          getQueryValue(sessionState?.flight_ticket_id)
        ),
        Q.where("_status", Q.notEq("synced"))
      )
      .observe(),
    tickets: database
      .get<FlightTicket>(FlightTicket.table)
      .query(Q.where("id", Q.oneOf(validationTickets.map((it) => it.id))))
      .observe(),
  })
);

const EnhancedFinalFailed = connector(enhance(FinalFailed));
export default EnhancedFinalFailed;
