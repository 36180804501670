import {Model} from '@nozbe/watermelondb'
import {field} from '@nozbe/watermelondb/decorators'

export default class Airline extends Model {
  static table = 'airlines'

  @field('icao') icao!: string
  @field('iata') iata!: string
  @field('name') name!: string
  @field('icon') icon?: string
}
