import config from "config";
import { ValidationResult } from "../types/validator";
import {
  CerberSchemeStep,
  Scheme,
  SchemeDocument,
  SchemeDocumentsGroup,
} from "../types/scheme";
import { getArray } from "./utils";

const EXPIRED_KEYS = JSON.parse(config.JSONFORMS.TRAVEL_DOCUMENT_EXPIRED_ERROR);

export function getIsDefaultSchemeError(result: ValidationResult) {
  if (!result?.resolvers) {
    return false;
  }
  return Object.keys(result.resolvers).some(
    (field) => field.indexOf(config.JSONFORMS.DEFAULT_SCHEME_ERROR) > -1
  );
}

export function getIsExpiredTravelDocError(result: ValidationResult) {
  if (!result?.submitted_errors) {
    return false;
  }

  return Object.keys(result.submitted_errors).some(
    (key: string) => EXPIRED_KEYS.indexOf(key) > -1
  );
}

export function getParsedScheme(schema: Scheme) {
  try {
    const result = {
      id: schema.id,
      rev: schema.rev,
      is_transit: schema.is_transit,
      name: schema.name,
      departure: schema.departure,
      arrival: schema.arrival,
      start: schema.start,
      end: schema.end,
      editor_schema:
        typeof schema.editor_schema === "string"
          ? JSON.parse(schema.editor_schema)
          : schema.editor_schema,
      cerber_schema:
        typeof schema.cerber_schema === "string"
          ? JSON.parse(schema.cerber_schema)
          : schema.cerber_schema,
      full_data_documents: getArray<SchemeDocument>(schema.full_data_documents),
      full_data_group_documents: getArray<SchemeDocumentsGroup>(
        schema.full_data_group_documents
      ),

      transporters:
        typeof schema.transporters === "string"
          ? JSON.parse(schema.transporters)
          : schema.transporters,
      description: schema.description,
      created_at: schema.created_at,
      updated_at: schema.updated_at,
      documents: schema.documents,
    };

    if (
      Array.isArray(result.cerber_schema) &&
      Array.isArray(result.full_data_documents) &&
      Array.isArray(result.full_data_group_documents)
    ) {
      return result;
    } else {
      return null;
    }
  } catch (err) {
    console.error("[getParsedScheme] failed to parse:", err);
    return null;
  }
}

export function getIsDefaultScheme(scheme: Scheme) {
  return scheme.arrival === config.DEFAULT_SCHEME_COUNTRY_CODE;
}

export function getOptionsByUiScheme(uischema: any, field: string) {
  let options;

  getOptions(uischema);

  function getOptions(obj: any) {
    for (let prop in obj) {
      if (typeof obj[prop] === "object") {
        getOptions(obj[prop]);
      } else {
        if (Object.keys(obj).includes("options") && obj.scope.includes(field)) {
          return (options = obj.options);
        }
      }
    }
  }

  return options;
}

export function getStep(
  steps: CerberSchemeStep[],
  id: string | number,
  type: "group" | "document",
  withForm?: boolean
) {
  return steps.find((it: CerberSchemeStep) => {
    return (
      (withForm ? it.needForm : true) &&
      it.documentOrGroup &&
      it.documentOrGroup.value === id &&
      it.documentOrGroup.type === type
    );
  });
}
