import { FC, Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { ConfigProvider, Layout } from "antd";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { isMobile, isIOS, osVersion, isTablet } from "react-device-detect";
import i18n from "services/i18n";
import Sidebar from "components/Sidebar";
import ContentWrapper from "components/ContentWrapper";
import logo from "./assets/images/logoSidebar.svg";
import AuthorizedContext from "contexts/AuthorizedContext";
import AppStack from "navigation/AppStack";
import SyncProvider from "coordinators/SyncProvider";
import Header from "components/Header";
import MobileSidebar from "components/MobileSidebar";
import getTabsManager from "./utils/tabs";
import getStore from "./redux/store";
import StorePopover from "components/StorePopover";
import "./theme/variables.css";
import "./App.css";
import { APP_COLORS } from "utils/colors";

const { Footer } = Layout;

const App: FC = () => {
  const [authorized, setAuthorized] = useState<boolean>(false);
  const [store, setStore] = useState<any>(null);

  const [theme] = useState({
    token: {
      colorPrimary: APP_COLORS.light.background,
      // colorText: '#FFF',
      fontFamily: "Manrope",
    },
    components: {
      Tabs: {
        cardBg: "#b5b5b5",
        itemColor: "#000",
        itemActiveColor: APP_COLORS.light.background,
        horizontalMargin: "0",
        cardGutter: 5,
      },
      Button: {
        controlHeight: 48,
        controlHeightSM: 32,
        borderRadius: 16,
        borderRadiusLG: 16,
        colorText: APP_COLORS.light.outline_primary_btn_color,
        colorBgContainerDisabled: "#00000029",
      },
      Input: {
        controlHeight: 48,
        borderRadius: 16,
      },
      Select: {
        controlHeight: 48,
        borderRadius: 16,
      },
    },
  });

  const isOldIos = useMemo(() => {
    return isIOS && Number(osVersion) < 15.4;
  }, []);

  useEffect(() => {
    getTabsManager();
  }, []);

  useEffect(() => {
    if (!isOldIos) {
      return;
    }

    const handleResize = () => {
      setTimeout(() => {
        const height =
          (window as any).visualViewport?.innerHeight || window.innerHeight;
        let vh = height * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        document.documentElement.style.height = `${height}px`;
      }, 50);
    };

    if ((window as any).visualViewport) {
      window?.visualViewport?.addEventListener("resize", handleResize);
      handleResize();
      return () =>
        window?.visualViewport?.removeEventListener("resize", handleResize);
    } else {
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [isOldIos]);

  useEffect(() => {
    getStore()
      .then((store) => {
        setStore(store);
      })
      .catch((err) => {
        console.error("[App].getStore", err);
      });
  }, []);

  const addClassNameToBody = useCallback((cssClass: string) => {
    const elements = document.getElementsByTagName("body");
    if (elements[0]) {
      elements[0].className = cssClass;
    }
  }, []);

  useEffect(() => {
    if (isMobile) {
      addClassNameToBody(
        `mobile ${isTablet ? "tablet" : ""} ${isIOS ? "isIos" : ""}`
      );
    }
  }, []); // eslint-disable-line

  const authorizedValue = useMemo(
    () => ({ authorized, setAuthorized }),
    [authorized, setAuthorized]
  );
  if (!store) {
    return <Suspense fallback={<div>loading</div>} />;
  }

  return (
    <ConfigProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <AuthorizedContext.Provider value={authorizedValue}>
            <Layout style={{ minHeight: isOldIos ? "100vh" : "100svh" }}>
              {authorized ? (
                isMobile ? (
                  <MobileSidebar />
                ) : (
                  <Sidebar logo={logo} />
                )
              ) : null}
              <Layout
                style={{
                  background: theme.token.colorPrimary,
                  height: isOldIos ? "calc(var(--vh, 1vh) * 100)" : "100svh",
                  position: "relative",
                }}
              >
                <Header slogan="CompPass" />
                <ContentWrapper>
                  <AppStack />
                </ContentWrapper>
                {isMobile ? null : (
                  <Footer
                    style={{
                      textAlign: "center",
                      background: theme.token.colorPrimary,
                      color: APP_COLORS.light.primary,
                    }}
                  >
                    CompPass
                  </Footer>
                )}
              </Layout>
            </Layout>
            <SyncProvider />
            <StorePopover />
          </AuthorizedContext.Provider>
        </Provider>
      </I18nextProvider>
    </ConfigProvider>
  );
};

export default App;
