import sha256 from "crypto-js/sha256";
import CryptoJS from "crypto-js";
import config from "../config";
import Session from "wmelon/models/Sessions";
import { SessionState } from "redux/features/session";

export const SESSION_STATUSES = {
  WAIT_FOR_OWNER: "wait_for_owner",
  VALIDATION: "validation",
  WAIT_FOR_PAID: "wait_for_paid",
  WAIT_FOR_DOC_VALIDATION: "wait_for_doc_validation",
  WAIT_FOR_TICKET: "wait_for_ticket",
  COMPLETED: "completed",
  AUTO_REJECTED: "auto_rejected",
  MANUAL_REJECTED: "manual_rejected",
  CANCELLED: "cancelled",
  WAIT_FOR_CHECK_VALIDATION: "wait_for_check_validation",
  MANUAL_INACTIVE: "manual_inactive",
};

export const HANDLERS = {
  VALIDATOR: "validator",
  MANUAL: "manual",
  //fasttrack-backend
  TICKET: "ticket",
  RECOGNIZE: "recognize",
  ETICKET: "etiket_validator",
  PNR: "pnr",
  LOCAL_VALIDATOR: "local_validator",
  RESTRICTION: "restriction",
};

export function getHandler(statusId: string) {
  switch (statusId) {
    case config.SESSION_STATUS_IDS.REJECTED_MANUALLY:
      return {
        status: SESSION_STATUSES.MANUAL_REJECTED,
        handler: HANDLERS.MANUAL,
      };
    case config.SESSION_STATUS_IDS.ALLOWED_MANUALLY:
      return {
        status: SESSION_STATUSES.COMPLETED,
        handler: HANDLERS.MANUAL,
      };
    case config.SESSION_STATUS_IDS.COMPLETED_BY_TICKET:
      return {
        status: SESSION_STATUSES.COMPLETED,
        handler: HANDLERS.TICKET,
      };
    case config.SESSION_STATUS_IDS.REJECTED_BY_RECOGNIZER:
      return {
        status: SESSION_STATUSES.AUTO_REJECTED,
        handler: HANDLERS.RECOGNIZE,
      };
    case config.SESSION_STATUS_IDS.CANCELLED_MANUALLY:
      return {
        status: SESSION_STATUSES.CANCELLED,
        handler: HANDLERS.MANUAL,
      };
    case config.SESSION_STATUS_IDS.REJECTED_BY_ETICKET:
      return {
        status: SESSION_STATUSES.AUTO_REJECTED,
        handler: HANDLERS.ETICKET,
      };
    case config.SESSION_STATUS_IDS.AUTOREJECTED_BY_VALIDATOR:
      return {
        status: SESSION_STATUSES.AUTO_REJECTED,
        handler: HANDLERS.VALIDATOR,
      };
    case config.SESSION_STATUS_IDS.PASSED_BY_VALIDATOR:
      return {
        status: SESSION_STATUSES.WAIT_FOR_TICKET,
        handler: HANDLERS.VALIDATOR,
      };
    case config.SESSION_STATUS_IDS.PASSED_BY_LOCAL_VALIDATOR:
      return {
        status: SESSION_STATUSES.WAIT_FOR_CHECK_VALIDATION,
        handler: HANDLERS.VALIDATOR,
      };
    default:
      return null;
  }
}

// export function getHASH(str: string) {
//   return sha256(str).toString().toUpperCase()
// }

const TOKEN_SYMBOLS = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "J",
  "K",
  "L",
  "M",
  "N",
  "P",
  "R",
  "S",
  "T",
  "V",
  "X",
  "Y",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
];

const TOKEN_DIGIT_POSITION_MULTIPLIER = [
  9, 8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2,
];

export function generateToken(length = 8) {
  const result: string[] = [];

  const getCheckSumChar = (tkn: any) => {
    let checkSumTotal = 0;

    if (`${tkn}`.length !== 8) return -1;

    new Array(tkn).fill(0).forEach((it, i) => {
      checkSumTotal +=
        TOKEN_SYMBOLS.indexOf(tkn.at(i)) *
        (TOKEN_DIGIT_POSITION_MULTIPLIER.at(i) as number);
    });

    const remain = checkSumTotal % 11;

    let char = remain <= 1 ? "A" : remain;
    if (remain === 10) char = "X";

    return char;
  };

  const getRandomTokenChar = (): string => {
    const idx = Math.floor(Math.random() * TOKEN_SYMBOLS.length);
    return TOKEN_SYMBOLS.at(idx) as string;
  };

  for (let idx = 0; idx < length; idx += 1) {
    result.push(getRandomTokenChar());
  }

  const token = result.join("");
  const checkChar = getCheckSumChar(token);

  return `${token.substring(0, 4)}${checkChar}${token.substring(5)}`;
}

export function getSessionStatus(session: any): {
  state?: string;
  handler?: string;
  status?: string;
  level?: number;
} {
  if (!session) {
    return {};
  }
  const states = {
    success: [
      {
        handler: HANDLERS.VALIDATOR,
        status: SESSION_STATUSES.COMPLETED,
        level: 0,
      },
      {
        handler: HANDLERS.MANUAL,
        status: SESSION_STATUSES.COMPLETED,
        level: 0,
      },
      {
        handler: HANDLERS.LOCAL_VALIDATOR,
        status: SESSION_STATUSES.COMPLETED,
        level: 0,
      },
    ],
    rejected: [
      {
        handler: HANDLERS.VALIDATOR,
        status: SESSION_STATUSES.AUTO_REJECTED,
        level: 2,
      },
      {
        handler: HANDLERS.MANUAL,
        status: SESSION_STATUSES.MANUAL_REJECTED,
        level: 2,
      },
      {
        handler: HANDLERS.LOCAL_VALIDATOR,
        status: SESSION_STATUSES.AUTO_REJECTED,
        level: 2,
      },
      {
        handler: HANDLERS.PNR,
        status: SESSION_STATUSES.AUTO_REJECTED,
        level: 2,
      },
      {
        handler: HANDLERS.RESTRICTION,
        status: SESSION_STATUSES.AUTO_REJECTED,
        level: 2,
      },
      {
        handler: HANDLERS.MANUAL,
        status: SESSION_STATUSES.MANUAL_INACTIVE,
        level: 2,
      },
      {
        handler: HANDLERS.VALIDATOR,
        status: SESSION_STATUSES.MANUAL_INACTIVE,
        level: 2,
      },
      {
        handler: HANDLERS.MANUAL,
        status: SESSION_STATUSES.CANCELLED,
        level: 2,
      },
      {
        handler: HANDLERS.VALIDATOR,
        status: SESSION_STATUSES.CANCELLED,
        level: 2,
      },
      {
        handler: HANDLERS.LOCAL_VALIDATOR,
        status: SESSION_STATUSES.CANCELLED,
        level: 2,
      },
    ],
    wait: [
      {
        handler: HANDLERS.VALIDATOR,
        status: SESSION_STATUSES.WAIT_FOR_DOC_VALIDATION,
        level: 1,
      },
      {
        handler: HANDLERS.MANUAL,
        status: SESSION_STATUSES.WAIT_FOR_DOC_VALIDATION,
        level: 1,
      },
      {
        handler: HANDLERS.VALIDATOR,
        status: SESSION_STATUSES.WAIT_FOR_TICKET,
        level: 1,
      },
      {
        handler: HANDLERS.LOCAL_VALIDATOR,
        status: SESSION_STATUSES.WAIT_FOR_TICKET,
        level: 1,
      },
      {
        handler: HANDLERS.MANUAL,
        status: SESSION_STATUSES.WAIT_FOR_TICKET,
        level: 1,
      },
    ],
  };

  for (let state in states) {
    //@ts-ignore
    const checks = states[state];
    for (let i = 0; i < checks.length; i += 1) {
      const check = checks[i];
      if (
        session.handler === check.handler &&
        session.status === check.status
      ) {
        return {
          state,
          ...check,
        };
      }
    }
  }
  return {
    state: "wait",
    handler: HANDLERS.LOCAL_VALIDATOR,
    status: session.status,
  };
}

export function getStyledShortId(shortId: string) {
  const chunks: string[] = [];

  for (let i = 0, o = 0; i < 2; ++i, o += 3) {
    chunks.push(shortId.substr(o, 3));
  }

  chunks.push(shortId.substr(6, 2));
  chunks.push(shortId.substr(8));

  return chunks.filter((it) => !!it).join("-");
}

const alphabet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "J",
  "K",
  "L",
  "M",
  "N",
  "P",
  "R",
  "S",
  "T",
  "V",
  "X",
  "Y",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
];

function adler32(str: any) {
  const MOD_ADLER = 65521;
  let a = 1,
    b = 0;

  for (let i = 0; i < str.length; i++) {
    a = (a + str.charCodeAt(i)) % MOD_ADLER;
    b = (b + a) % MOD_ADLER;
  }

  return ((b << 16) | a) >>> 0;
}

function hexToCustomBase(hex: any) {
  let decimal = parseInt(hex, 16);
  let customBaseValue = "";
  while (decimal > 0) {
    let remainder = decimal % 25;
    customBaseValue = alphabet[remainder] + customBaseValue;
    decimal = Math.floor(decimal / 25);
  }

  return customBaseValue || "A"; // Return 'A' if the input is '0'
}

export function getStringHash(inputString: string, attempt: number) {
  const md5Hash = CryptoJS.MD5(`${inputString}`).toString(CryptoJS.enc.Hex);
  console.log(md5Hash);
  const adlerHash = adler32(md5Hash).toString(16);
  console.log(adlerHash);
  return `${hexToCustomBase(adlerHash)}${attempt}`;
}

export function getShortId(prevSessions: Session[], flight_ticket_id: string) {
  if (prevSessions.length) {
    const shortId = prevSessions[prevSessions.length - 1].short_id;
    return `${shortId.slice(0, -1)}${prevSessions.length + 1}`;
  }

  return getStringHash(flight_ticket_id, prevSessions.length + 1);
}
export function getHASH(str: string) {
  return sha256(str).toString().toUpperCase();
}
export function getSessionInfo(session: SessionState | any) {
  return {
    // short_id: session.short_id,
    // user_id: session.user_id,
    // provider: session.provider as any,
    provider: "user",
    flight: session.flight,
    carrier: session.carrier,
    origin: session.origin,
    destination: session.destination,
    depart_port: session.depart_port,
    arrival_port: session.arrival_port,
    transit: session.transit,
    flight_date: session.flight_date,
  };
}

export function getSessionStatusIcon(status: string) {
  return {
    wait: require("../assets/images/warning.svg"),
    success: require("../assets/images/smallstamp.svg"),
    rejected: require("../assets/images/error_stamp.svg"),
  }[status];
}
