const customEvent = (
  eventName: string,
  data: any
): CustomEvent | null => {
  if (window) {
    return new CustomEvent(eventName, {
      detail: { data },
    });
  }
  return null;
};


export const appEvent = (
    status: string,
    extraData: { [key: string]: any }
  ): void => {
    const typeOfEvent = customEvent(status, extraData);
    document.dispatchEvent(typeOfEvent as Event);
  };