import { Model } from "@nozbe/watermelondb";
import { field, date } from "@nozbe/watermelondb/decorators";

export default class Session extends Model {
  static table = "sessions";

  @field("short_id") short_id!: string;
  @field("flight") flight!: string;
  @field("carrier") carrier?: string;
  @field("origin") origin!: string;
  @field("destination") destination!: string;
  @field("depart_port") depart_port?: string;
  @field("arrival_port") arrival_port?: string;
  @field("transit") transit?: string[] | null;
  @field("flight_date") flight_date!: string;
  @field("scheme_id") scheme_id!: string;
  @field("scheme_version") scheme_version!: string;
  @field("status") status!: string;
  @field("handler") handler!: string;
  @field("owner_first_name") owner_first_name?: string;
  @field("owner_last_name") owner_last_name?: string;
  @field("owner_document_number") owner_document_number?: string;
  @field("owner_dob") owner_dob?: string;
  @field("scheme_type") scheme_type?: string;
  @field("assign_to") assign_to?: string;
  @field("flight_ticket_id") flight_ticket_id!: string;
  @date("created_at") created_at!: string;
}
