export type NotificationAction =
  | 'accepted'
  | 'rejected'
  | 'redirected_to_agent'
  | 'share_revoked'
  | 'additional_document_request'

export const NotificationActions: Record<NotificationAction, NotificationAction> = {
  accepted: 'accepted',
  rejected: 'rejected',
  redirected_to_agent: 'redirected_to_agent',
  share_revoked: 'share_revoked',
  additional_document_request: 'additional_document_request',
}
