import { useMemo, useCallback, useEffect } from "react";
import {
  getFieldPath,
  getFormFields,
  replaceFieldTypes,
} from "../utils/jsonform";
import config from "config";
import Form from "wmelon/models/Form";
import { SessionAdditionalDocument } from "types/validator";
import { TextFieldType } from "@regulaforensics/document-reader-webclient";

function getHasField(
  schema: any,
  fieldId: string,
  recognizer: string
): boolean {
  if (schema.type === "object") {
    return Object.keys(schema.properties).some((field: string) => {
      return getHasField(schema.properties[field], fieldId, recognizer);
    });
  }

  return (
    schema.recognizerFieldId === fieldId &&
    (schema.recognizer || "").toLowerCase() === recognizer
  );
}

function sanitizeJSON(data) {
  if (typeof data === "string") {
    return data.replace(/[<>&'"]/g, (c: string) => {
      switch (c) {
        case "<":
          return "&lt;";
        case ">":
          return "&gt;";
        case "&":
          return "&amp;";
        case "'":
          return "&apos;";
        case '"':
          return "&quot;";
        default:
          return c;
      }
    });
  } else if (typeof data === "object") {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        data[key] = sanitizeJSON(data[key]);
      }
    }
  }
  return data;
}

function sanitizeFormData(raw: string) {
  try {
    const parsedData = JSON.parse(raw);

    if (
      typeof parsedData.schema !== "object" ||
      typeof parsedData.uischema !== "object"
    ) {
      console.log("[sanitizeFormData] invalid json:");
      return { schema: null, uischema: null };
    }

    return {
      schema: sanitizeJSON(parsedData.schema),
      uischema: sanitizeJSON(parsedData.uischema),
    };
  } catch (err) {
    console.error("[sanitizeFormData] failed to parse:", err);
    return { schema: null, uischema: null };
  }
}

export interface UseFormValuesResponse {
  formId: number | null;
  form: any | null;
  jsonschema: any | null;
  uischema: any | null;
  title: string | null;
  formRecognizer: string | null;
  hint: string | null;

  fields: string[];
  isManualForm: boolean;
  getAutoFields: () => Record<string, any>;

  shouldRecognizeDocument: boolean;
}

const useDocumentRequestFormValues = (
  item: SessionAdditionalDocument | null,
  forms: Form[]
): UseFormValuesResponse => {
  const form = useMemo(() => {
    if (!item) {
      return null;
    }

    const isGroup = item._type === "group_document";

    return (forms || []).find((it) =>
      isGroup
        ? it.groupId === item.id
        : (it.documentId || "").toString() === item.id.toString()
    );
  }, [forms, item]);

  const { jsonschema, uischema, title, formRecognizer, formId } =
    useMemo(() => {
      if (!item || !form) {
        return {
          jsonschema: null,
          uischema: null,
          title: null,
          formRecognizer: null,
          formId: null,
        };
      }

      const { schema, uischema } = sanitizeFormData(form.json);
      const isGroup = item._type === "group_document";
      return {
        jsonschema: replaceFieldTypes(schema, [
          {
            field: config.JSONFORMS.MRZ_FIELD,
            recognizer: config.RECOGNIZERS.CUSTOM,
            type: "string",
          },
          {
            field: config.JSONFORMS.RFID_FIELD,
            recognizer: config.RECOGNIZERS.CUSTOM,
            type: "string",
          },
          {
            field: config.JSONFORMS.REGULA_ID,
            recognizer: config.RECOGNIZERS.CUSTOM,
            type: "string",
          },
        ]),
        uischema: uischema,
        title: (isGroup ? item.label : item.name) || form.title,
        formRecognizer: form.recognizer.toLowerCase(),
        formId: parseInt(form.id, 10),
      };
    }, [form, item]);

  useEffect(() => {
    jsonschema && console.log("JSON_SCHEMA:", jsonschema);
  }, [jsonschema]);

  const fields: string[] = useMemo(() => {
    if (!jsonschema) {
      return [];
    }

    return getFormFields(jsonschema, [], "");
  }, [jsonschema]);

  const isManualForm = useMemo(() => {
    if (!fields.length) {
      return false;
    }

    if (formRecognizer === config.RECOGNIZERS.CUSTOM) {
      return fields.indexOf(config.JSONFORMS.DOCUMENT_FIELD) === -1;
    } else {
      return false;
    }
  }, [fields, formRecognizer]);

  const getAutoFields = useCallback(() => {
    if (!jsonschema || !form) {
      return {};
    }

    const path = getFieldPath(
      jsonschema,
      form.recognizer,
      config.JSONFORMS.ACCEPT_ID,
      ""
    );

    return path[0]
      ? {
          [path[0]]: "1",
        }
      : {};
  }, [jsonschema, form]);

  const shouldRecognizeDocument = useMemo(() => {
    if (!jsonschema) {
      return true;
    }

    return !getHasField(
      jsonschema,
      TextFieldType.OTHER.toString(),
      config.RECOGNIZERS.REGULA
    );
  }, [jsonschema]);

  return useMemo(
    () => ({
      formId,
      form,
      jsonschema,
      uischema,
      title,
      formRecognizer,
      hint: null,

      fields,
      isManualForm,
      getAutoFields,

      shouldRecognizeDocument,
    }),
    [
      formId,
      form,
      jsonschema,
      uischema,
      title,
      formRecognizer,

      fields,
      isManualForm,
      getAutoFields,

      shouldRecognizeDocument,
    ]
  );
};

export default useDocumentRequestFormValues;
