import { Model } from '@nozbe/watermelondb'
import { field } from '@nozbe/watermelondb/decorators'

export default class SchemaCountryGroups extends Model {
  static table = 'schemaCountryGroups'

  @field('name') name!: string
  @field('code2') code2!: string
  @field('code3') code3!: string
}
