import { Q } from "@nozbe/watermelondb";
import { database } from "wmelon/database";
import Notification from "wmelon/models/Notification";

async function createNotification(notificationData: Partial<Notification>) {
  return await database.write(async () => {
    const newNotification = await database
      .get<Notification>(Notification.table)
      .create((notification) => {
        Object.assign(notification, notificationData);
      });
    return newNotification;
  });
}

async function updateNotification(
  notification: Notification,
  notificationData: Partial<Notification>
) {
  return await database.write(async () => {
    await notification.update((n) => {
      Object.assign(n, notificationData);
    });
  });
}

async function deleteNotification(notification: Notification) {
  return await database.write(async () => {
    await notification.destroyPermanently();
  });
}

function markAsDeleted(id: string) {
  return database.write(() => {
    return database
      .get<Notification>(Notification.table)
      .find(id)
      .then((notification) => {
        return notification.markAsDeleted();
      });
  });
}

async function getNotificationById(id: string) {
  return await database.collections
    .get<Notification>(Notification.table)
    .find(id);
}

async function getAllNotifications() {
  return await database.collections
    .get<Notification>(Notification.table)
    .query()
    .fetch();
}

async function markAsRead(id: string) {
  return database.write(() => {
    return database
      .get<Notification>(Notification.table)
      .find(id)
      .then((notification) => {
        return notification.update(() => {
          notification.readAt = new Date();
        });
      });
  });
}

async function markAllAsRead() {
  return database.write(() => {
    return database
      .get<Notification>(Notification.table)
      .query(Q.where("read_at", Q.eq(null)))
      .fetch()
      .then((notifications) => {
        return Promise.all(
          notifications.map((notification) => {
            return notification.update(() => {
              notification.readAt = new Date();
            });
          })
        );
      });
  });
}

async function getUnreadNotificationsCount() {
  return database
    .get<Notification>(Notification.table)
    .query(Q.where("read_at", Q.eq(null)))
    .fetchCount()
    .catch((err) => {
      console.error("[getUnreadNotificationsCount] failed to get count:", err);
      return 0;
    });
}

export {
  createNotification,
  updateNotification,
  deleteNotification,
  getNotificationById,
  getAllNotifications,
  markAsRead,
  markAllAsRead,
  markAsDeleted,
  getUnreadNotificationsCount,
};
