import {Model} from '@nozbe/watermelondb'
import {date, field, relation} from '@nozbe/watermelondb/decorators'
import {Associations} from "@nozbe/watermelondb/Model";


export default class FlightTicketBinds extends Model {
  static table = 'flightTicketBinds'

  static associations: Associations = {
    flightTickets: { type: 'belongs_to', key: 'ticket_id' },
  }

  @relation('flightTickets', 'ticket_id') ticket: any
  @field('ticket_number') ticket_number!: string

  @date('created_at') created_at!: string
}
