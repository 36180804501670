import "./style.css";
import { Button, Flex, Layout, Typography } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UserPicture from "../components/UserPicture";
import { APP_COLORS } from "utils/colors";

const { Header } = Layout;

function AddBag() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className='AddBag'>
      <Header className='AddBag-header'>
        <Flex>
          <Button
            type='text'
            icon={
              <LeftOutlined
                style={{
                  color: APP_COLORS.light.primary_text_color,
                  fontSize: 20,
                }}
              />
            }
            onClick={() => {
              navigate(-1);
            }}
          />
          <Typography.Text
            style={{
              alignSelf: "center",
              color: APP_COLORS.light.primary_text_color,
              fontSize: 20,
            }}
          >
            {t("HeaderTitle.AddBag")}
          </Typography.Text>
        </Flex>
      </Header>
      <div className='AddBag-content'>
        <Typography.Text strong>{t("BagScreen.photo")}</Typography.Text>
        <UserPicture />
      </div>
    </div>
  );
}

export default AddBag;
