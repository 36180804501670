import FuzzySet from "fuzzyset";

function isEmpty(data) {
  try {
    if (data == null || data == undefined) return true;
    if (Array.isArray(data) || typeof data === "string") return !data.length;
    if (typeof data == "object") return Object.keys(data).length === 0;
  } catch (e) {
    console.log("IsEmty error: ", e);
    return true;
  }
}

function error(errors, field, message) {
  if (field in errors) {
    if (errors[field].indexOf(message) == -1) {
      errors[field].push(message);
    }
  } else {
    errors = Object.assign(errors, { [field]: [message] });
  }
}

function to_upper_case(data) {
  if (data == null) {
    return null;
  }

  if (typeof data === "string") {
    return data.toUpperCase();
  }

  if (Array.isArray(data)) {
    let tmp_array = [];
    for (const i of data) {
      if (typeof i === "string") {
        tmp_array.push(i.toUpperCase());
      } else {
        tmp_array.push(i);
      }
    }
    return tmp_array;
  }

  return data;
}

function validate_field(ruels, scheme_field_name, data, validate_data) {
  let errors = {};
  for (const [rule_name, rule_value] of Object.entries(ruels)) {
    if (rule_name == "noneof") {
      for (const rules_block of rule_value) {
        if (
          isEmpty(
            validate_field(rules_block, scheme_field_name, data, validate_data)
          )
        ) {
          error(
            errors,
            scheme_field_name,
            "one or more functioninitions validate"
          );
          break;
        }
      }
      continue;
    }

    if (rule_name == "required") {
      continue;
    }

    if (rule_name == "submitted") {
      if (data !== rule_value) {
        error(errors, scheme_field_name, "Data is not submitted");
      }
      continue;
    }

    if (rule_name == "valid_before") {
      try {
        let params = rule_value;
        let compared_timestamp = [];
        let compared_date = "";

        if (!("valueSource" in params) || !("compared_date" in params)) {
          error(errors, scheme_field_name, "valid_before: not full params");
          continue;
        }

        if (params["valueSource"] == "value") {
          compared_date = params["compared_date"];
          compared_timestamp =
            typeof compared_date === "string"
              ? Date.parse(compared_date)
              : compared_date;
        } else if (params["valueSource"] == "field") {
          compared_date = validate_data[params["compared_date"]];
          compared_timestamp =
            typeof compared_date === "string"
              ? Date.parse(compared_date)
              : compared_date;
        } else {
          error(
            errors,
            scheme_field_name,
            `valid_before: incorrect params: ${params["valueSource"]}`
          );
        }

        if (
          (typeof data === "string" ? Date.parse(data) : data) >
          compared_timestamp
        ) {
          error(
            errors,
            scheme_field_name,
            `valid_before ${data} and ${compared_date}`
          );
        }
      } catch (e) {
        error(
          errors,
          scheme_field_name,
          `evalid_before ${scheme_field_name} and ${data}`
        );
        console.log(e);
      }
      continue;
    }

    if (rule_name == "valid_after") {
      try {
        let params = rule_value;
        let compared_timestamp = [];
        let compared_date = "";

        if (!("valueSource" in params) || !("compared_date" in params)) {
          error(errors, scheme_field_name, "valid_after: not full params");
          continue;
        }

        if (params["valueSource"] == "value") {
          compared_date = params["compared_date"];
          compared_timestamp =
            typeof compared_date === "string"
              ? Date.parse(compared_date)
              : compared_date;
        } else if (params["valueSource"] == "field") {
          compared_date = validate_data[params["compared_date"]];
          compared_timestamp =
            typeof compared_date === "string"
              ? Date.parse(compared_date)
              : compared_date;
        } else {
          error(
            errors,
            scheme_field_name,
            `valid_after: incorrect params: ${params["valueSource"]}`
          );
        }

        if (
          (typeof data === "string" ? Date.parse(data) : data) <
          compared_timestamp
        ) {
          error(
            errors,
            scheme_field_name,
            `valid_after ${data} and ${compared_date}`
          );
        }
      } catch (e) {
        error(
          errors,
          scheme_field_name,
          `evalid_after ${scheme_field_name} and ${data}`
        );
        console.log(e);
      }
      continue;
    }

    if (rule_name == "valid_date") {
      try {
        let params = rule_value;

        if (
          !("sign" in params) ||
          !("scale" in params) ||
          !("value" in params) ||
          !("direction" in params) ||
          !("compared_date" in params)
        ) {
          error(errors, scheme_field_name, "valid_date: not full params");
        }

        let sign = params["sign"];
        let scale = params["scale"];
        let direction = params["direction"];
        let params_value = params["value"];
        let compared_date =
          typeof validate_data[params["compared_date"]] === "string"
            ? Date.parse(validate_data[params["compared_date"]])
            : validate_data[params["compared_date"]];

        let div_scale = 1;
        if (scale == "day") {
          div_scale = 1; // # 1 # 60*60*24
        }
        //# else if  scale == 'month':
        //#     div_scale = 2628000 # 60*60*24*(365/12)
        else if (scale == "year") {
          div_scale = 365; //# 365 # 60*60*24*365
        }

        if (Array.isArray(data)) {
          for (const value of data) {
            let diff =
              (direction * (Date.parse(value) - compared_date)) / 86400000;
            // console.log(diff)
            let diff_in_scale = diff / div_scale; //#divmod(diff, div_scale)[0]

            if (sign == "<") {
              if (diff_in_scale > params_value) {
                error(
                  errors,
                  scheme_field_name,
                  `valid_date: document number: %d, valid < ${data.index(
                    value
                  )} ${params_value} ${scale} ${
                    direction < 0 ? "before" : "after"
                  } ${params["compared_date"]}`
                );
              }
            } else if (sign == ">") {
              if (diff_in_scale < params_value) {
                error(
                  errors,
                  scheme_field_name,
                  `valid_date: document number: %d, valid > ${data.index(
                    value
                  )} ${params_value} ${scale} ${
                    direction < 0 ? "before" : "after"
                  } ${params["compared_date"]}`
                );
              }
            }
          }
        } else {
          let diff =
            (direction * (Date.parse(data) - compared_date)) / 86400000;
          //# console.log(diff)
          let diff_in_scale = diff / div_scale; //#divmod(diff, div_scale)[0]
          //# console.log(diff_in_scale)
          if (sign == "<") {
            if (diff_in_scale > params_value) {
              error(
                errors,
                scheme_field_name,
                `valid_date: document valid < ${data} ${params_value} ${scale} ${
                  direction < 0 ? "before" : "after"
                } ${params["compared_date"]}`
              );
            }
          } else if (sign == ">") {
            if (diff_in_scale < params_value) {
              error(
                errors,
                scheme_field_name,
                `valid_date: document valid > ${data} ${params_value} ${scale} ${
                  direction < 0 ? "before" : "after"
                } ${params["compared_date"]}`
              );
            }
          }
        }
      } catch (e) {
        error(
          errors,
          scheme_field_name,
          `valid_date ${scheme_field_name} and ${data}`
        );
        console.log(e);
      }

      continue;
    }

    if (rule_name == "partial_match") {
      try {
        let tmp_rule_valueKeys = [];
        if (Array.isArray(rule_value)) {
          tmp_rule_valueKeys = rule_value;
        } else {
          tmp_rule_valueKeys.push(rule_value);
        }

        let tmp_rule_values = [];
        for (let k of tmp_rule_valueKeys) {
          if (validate_data.hasOwnProperty(k)) {
            tmp_rule_values.push(validate_data[k]);
          } else {
            tmp_rule_values.push(k);
          }
        }
        let fs = FuzzySet();
        fs.add(data);

        for (let v of tmp_rule_values) {
          let res = fs.get(v)[0][0].toFixed(2);
          console.log("Partial match:", data, "|", v, "===", res);
          if (res < 0.66) {
            error(
              errors,
              scheme_field_name,
              `'${data}' partial match '${v}' (${rule_value}) == ${res} < 0.66`
            );
          }
        }
      } catch (e) {
        error(
          errors,
          scheme_field_name,
          `${data} not partial match ${rule_value}`
        );
        console.log(e);
      }

      continue;
    }

    if (rule_name == "should_match") {
      try {
        let tmp_value = null;
        let field2values = [];
        for (const m of rule_value) {
          field2values.push(validate_data[m]);
        }

        if (Array.isArray(data)) {
          for (let i = 0; i < data.length; i++) {
            if (data[i] != data[i + 1]) {
              error(errors, scheme_field_name, `should match ${rule_value}`);
              continue;
            }
          }
          tmp_value = data[0];
        } else {
          tmp_value = data;
        }

        for (const value of field2values) {
          let sm_value = value;
          if (Array.isArray(sm_value)) {
            for (let i = 0; i < sm_value.length; i++) {
              if (sm_value[i] != sm_value[i + 1]) {
                error(errors, scheme_field_name, `should match ${rule_value}`);
                break;
              }
            }
            sm_value = sm_value[0];
          }
          if (to_upper_case(tmp_value) != to_upper_case(sm_value)) {
            error(errors, scheme_field_name, `should match ${rule_value}`);
          }
        }
      } catch (e) {
        error(
          errors,
          scheme_field_name,
          `eshould match ${rule_value} === ${data}`
        );
        console.log(e);
      }

      continue;
    }

    if (rule_name == "strcontains") {
      try {
        let tmp_rule_value = to_upper_case(rule_value);
        let tmp_data = to_upper_case(data);

        if (Array.isArray(tmp_rule_value)) {
          for (const str_data of tmp_rule_value) {
            if (str_data[0] == "%" && str_data[-1] == "%") {
              let tmp = validate_data[str_data.replace("%", "")];
              if (tmp && tmp[1]) {
                str_data = tmp[1];
              }
            }

            if (Array.isArray(tmp_data)) {
              for (const v of tmp_data) {
                if (!v.includes(str_data)) {
                  error(errors, scheme_field_name, `strcontains ${rule_value}`);
                }
              }
            } else if (!tmp_data.includes(str_data)) {
              error(errors, scheme_field_name, `strcontains ${rule_value}`);
            }
          }
        } else if (Array.isArray(tmp_data)) {
          if (tmp_rule_value[0] == "%" && tmp_rule_value[-1] == "%") {
            let tmp = validate_data[tmp_rule_value.replace("%", "")];
            if (tmp && tmp[1]) {
              tmp_rule_value = tmp[1];
            }
          }

          for (const v of tmp_data) {
            if (!v.includes(tmp_rule_value)) {
              error(errors, scheme_field_name, `strcontains ${rule_value}`);
            }
          }
        } else {
          if (tmp_rule_value[0] == "%" && tmp_rule_value[-1] == "%") {
            let tmp = validate_data[tmp_rule_value.replace("%", "")];
            if (tmp && tmp[1]) {
              tmp_rule_value = tmp[1];
            }
          }
          if (!data.includes(tmp_rule_value)) {
            error(errors, scheme_field_name, `strcontains ${rule_value}`);
          }
        }
      } catch (e) {
        error(
          errors,
          scheme_field_name,
          `estrcontains ${rule_value} in ${data}`
        );
        console.log(e);
      }

      continue;
    }

    if (rule_name == "starts_with") {
      try {
        let tmp_rule_value = to_upper_case(rule_value);
        let tmp_data = to_upper_case(data);
        if (Array.isArray(tmp_data)) {
          for (const v of tmp_data) {
            if (!v.startsWith(tmp_rule_value)) {
              error(errors, scheme_field_name, `starts_with ${rule_value}`);
              break;
            }
          }
        } else if (!tmp_data.startsWith(tmp_rule_value)) {
          error(errors, scheme_field_name, `starts_with ${rule_value}`);
        }
      } catch (e) {
        error(
          errors,
          scheme_field_name,
          `estarts_with ${rule_value} start with ${data}`
        );
        console.log(e);
      }

      continue;
    }

    if (rule_name == "ends_with") {
      try {
        let tmp_rule_value = to_upper_case(rule_value);
        let tmp_data = to_upper_case(data);
        if (Array.isArray(tmp_data)) {
          for (const v of tmp_data) {
            if (!v.endsWith(tmp_rule_value)) {
              error(errors, scheme_field_name, `ends_with ${rule_value}`);
              break;
            }
          }
        } else if (!tmp_data.endsWith(tmp_rule_value)) {
          error(errors, scheme_field_name, `ends_with ${rule_value}`);
        }
      } catch (e) {
        error(
          errors,
          scheme_field_name,
          `eends_with ${rule_value} end with ${data}`
        );
        console.log(e);
      }

      continue;
    }

    if (rule_name == "max_ne") {
      try {
        if (data >= rule_value) {
          error(errors, scheme_field_name, `max_ne ${rule_value} ${data}`);
        }
      } catch (e) {
        error(errors, scheme_field_name, `max_ne ${rule_value} ${data}`);
        console.log(e);
      }
      continue;
    }

    if (rule_name == "min_ne") {
      try {
        if (data <= rule_value) {
          error(errors, scheme_field_name, `min_ne ${rule_value} ${data}`);
        }
      } catch (e) {
        error(errors, scheme_field_name, `min_ne ${rule_value} ${data}`);
        console.log(e);
      }
      continue;
    }

    if (rule_name == "allowed") {
      try {
        let tmp_rule_value = to_upper_case(rule_value);
        if (tmp_rule_value.indexOf("***") != -1) {
          continue;
        }
        let tmp_data = to_upper_case(data);
        if (Array.isArray(tmp_data)) {
          for (const d of tmp_data) {
            if (tmp_rule_value.indexOf(d) == -1) {
              error(
                errors,
                scheme_field_name,
                `${d} unallowed value in ${rule_value}`
              );
              break;
            }
          }
        } else {
          if (tmp_rule_value.indexOf(tmp_data) == -1) {
            error(
              errors,
              scheme_field_name,
              `${data} unallowed value in ${rule_value}`
            );
          }
        }
      } catch (e) {
        error(
          errors,
          scheme_field_name,
          `${data} unallowed value in ${rule_value}`
        );
        console.log(e);
      }
      continue;
    }

    if (rule_name == "contains") {
      try {
        let tmp_rule_value = to_upper_case(rule_value);
        let tmp_data = to_upper_case(data);
        if (Array.isArray(tmp_rule_value)) {
          let contains = true;
          for (const r of tmp_rule_value) {
            if (!(r in tmp_data)) {
              contains = false;
              break;
            }
          }

          if (!contains) {
            error(errors, scheme_field_name, `not contains ${rule_value}`);
          }
        } else {
          if (!tmp_rule_value.toString().includes(tmp_data.toString())) {
            error(errors, scheme_field_name, `not contains ${rule_value}`);
          }
        }
      } catch (e) {
        error(errors, scheme_field_name, `not contains ${rule_value}`);
        console.log(e);
      }
      continue;
    }

    if (rule_name == "combinator_or") {
      //# by functionault validate AND
      console.log(`Use combinator_or, rule: ${rule_value}, data: ${data}`);
      continue;
    }

    console.log(
      `Unknown rule: ${rule_name}, rule value: ${rule_value}, data: ${data}`
    );
    error(
      errors,
      scheme_field_name,
      `Unknown rule: ${rule_name}, rule value: ${rule_value}, data: ${data}`
    );
  }
  return errors;
}

export default function cerberValidate(scheme, validate_data) {
  if (isEmpty(scheme) || isEmpty(validate_data)) {
    // return { "Validator": ["Scheme or data is null"] };
    console.log("\tScheme or data is null");
    return {};
  }

  let errors = {};
  for (const [scheme_field_name, scheme_field_value] of Object.entries(
    scheme
  )) {
    //# check field exist and required in data

    if (
      !validate_data.hasOwnProperty(scheme_field_name) ||
      isEmpty(validate_data[scheme_field_name])
    ) {
      // if (scheme_field_value.hasOwnProperty("required") && scheme_field_value["required"]) {
      error(errors, scheme_field_name, "required field");
      // }

      continue;
    }

    let data = validate_data[scheme_field_name];
    Object.assign(
      errors,
      validate_field(
        scheme[scheme_field_name],
        scheme_field_name,
        data,
        validate_data
      )
    );
  }

  return errors;
}

// module.exports = cerberValidate;

// let scheme = {
//   "[G]test manual doc.submitted": { "submitted": true, "required": true },
//   "[G]test manual doc.regula.5": {
//     "valid_before": { "valueSource": "value", "compared_date": "2023-02-10" }
//   },
//   "[G]test manual doc.regula.6": {
//     "valid_after": { "valueSource": "field", "compared_date": "flight_date" }
//   },
//   "[G]VisaForIN.regula.3": {
//     "valid_date": {
//       "value": 10,
//       "scale": "day",
//       "sign": ">",
//       "direction": 1,
//       "compared_date": "flight_date",
//     }
//   },
//   "[G]AnyIDforIN.regula.5": {
//     "should_match": ["[G]VisaForIN.regula.6", "[G]VisaForIN.regula.7"]
//   },
//   "tourist-evisa.regula.8": {
//     "noneof": [{ "allowed": ["qqq1"] }],
//     "strcontains": ["QQQ"],
//     "starts_with": "QQQ",
//     "ends_with": "QQQ",
//     "required": true,
//   },
//   "test": {
//     "required": false
//   },
//   "qqq": {
//     "allowed": [123, 456, 789, "aaa"]
//   },
//   "aaa": {
//     "contains": "xxx"
//   }
// };

// let data = {
//   "flight_date": "2023-03-10",
//   "[G]test manual doc.submitted": true,
//   "[G]test manual doc.regula.5": "2023-02-09",
//   "[G]test manual doc.regula.6": "2023-03-11",
//   "[G]VisaForIN.regula.3": "2023-03-25",
//   "[G]AnyIDforIN.regula.5": "Test name",
//   "[G]VisaForIN.regula.6": "Test NAME",
//   "[G]VisaForIN.regula.7": "TESt name",
//   "tourist-evisa.regula.8": "qqq",
//   "test": 123,
//   "qqq": "AAA",
//   "aaa": "XXX"
// };

// console.log(crberValidate(scheme, data));

// let scheme = {
//     "[G]AnyIDforIN.regula.5": {"partial_match": ["[G]VisaForIN.regula.7", "[G]VisaForIN.regula.6"]}
// }

// let data = {
//     "[G]AnyIDforIN.regula.5": "Test name",
//     "[G]VisaForIN.regula.6": "Test NAME",
//     "[G]VisaForIN.regula.7": "TESt nasqmeedscxd"}

// console.log(cerberValidate(scheme, data))
// let scheme = {
//   "qqq": {
//     "allowed": ["***"],
//     // "required": true
//   }
// }

// let data = {
//   "qqq": ""
// }

// console.log(cerberValidate(scheme, data))
