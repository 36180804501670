import config from "config";
import AuthApiService from "utils/AuthApi";
import { database } from "wmelon/database";
import uploadFiles from "services/base/uploader";
import { Q, DirtyRaw } from "@nozbe/watermelondb";
import File from "wmelon/models/File";
import restApi from "../../utils/RESTApi";
import { Scheme } from "../../types/scheme";
import {
  NextcloudPermitDoccheckResult,
  SessionAdditionalDocumentRequest,
  SessionAdditionalDocumentRequestRaw,
} from "types/validator";

export const generalAPI = {
  bindPnr(identifier, provider = "OAW", deviceId: string | null) {
    return restApi.post(
      `${
        config.FLIGHT_TICKET_EP
      }/provider/${provider}/identifier/${identifier}/bind${
        deviceId ? `?device_id=${deviceId}` : ""
      }`,
      {}
    );
  },
  createVirtualTicket(
    identifier,
    data: {
      airline: string;
      flight_number: string;
      flight_date: string;
      flight_origin: string;
      flight_destination: string;
      flight_departure_time: string;
      flight_arrival_time: string;
    },
    deviceId: string | null
  ) {
    return restApi.post(
      `${config.FLIGHT_TICKET_EP}/provider/virtual/identifier/${identifier}${
        deviceId ? `?device_id=${deviceId}` : ""
      }`,
      data
    );
  },
  getTicketStatus(identifier): Promise<{ exist: boolean }> {
    return restApi.get(
      `${config.FLIGHT_TICKET_EP}/provider/virtual/identifier/${identifier}/status`
    );
  },
  loadFlights<T>(date: string, signal?: AbortSignal): Promise<T[]> {
    return restApi.get(config.SCHEDULE_EP, {
      params: { date },
      signal: signal,
    });
  },

  shareTicket(ticketId: string, deviceId: string | null): Promise<DirtyRaw> {
    return restApi.post(
      `${config.FLIGHT_TICKET_EP}/${ticketId}/share${
        deviceId ? `?device_id=${deviceId}` : ""
      }`,
      {}
    );
  },
  unshareTicket(shareId: string, deviceId: string | null) {
    return restApi.del(
      `${config.FLIGHT_TICKET_EP}/share/${shareId}${
        deviceId ? `?device_id=${deviceId}` : ""
      }`,
      {}
    );
  },
  bindTicketByShareToken(token: string, deviceId: string | null) {
    return restApi.post(
      `${config.FLIGHT_TICKET_EP}/share/${token}/bind${
        deviceId ? `?device_id=${deviceId}` : ""
      }`,
      {}
    );
  },
  loadScheme(id: string): Promise<Scheme> {
    return restApi.get(`${config.FLIGHT_TICKET_EP}/${id}/schema`, {});
  },

  loadHistory(date: string) {
    return restApi.get(`${config.HISTORY_EP}?date=${date}`);
  },
  loadHistoryTicket(id: string) {
    return restApi.get(`${config.HISTORY_EP}/${id}`);
  },
  getDocumentRequest(
    requestId: string,
    sessionId: string
  ): Promise<SessionAdditionalDocumentRequest> {
    return restApi
      .post(`${config.DOCUMENT_REQUEST_EP}/${requestId}/activate`, {
        session_id: sessionId,
      })
      .then((resp: SessionAdditionalDocumentRequestRaw) => {
        return {
          ...resp,
          id: requestId,
          session_id: sessionId,
        };
      });
  },

  async uploadDocument(
    document,
    onProgress?: (
      documentId: string,
      page: number,
      bytesUploaded: number,
      bytesTotal: number
    ) => void
  ) {
    const files: File[] = (await database
      .get("files")
      .query(Q.where("document_id", document.id))
      .fetch()) as File[];

    if (!files.length) {
      return;
    }

    let metaFiles = {
      userId: AuthApiService.shared().getUserId(),
      documentId: document.id,
      sessionId: document.session?.id,
    };

    console.log("[uploadDocument]", metaFiles, files);
    await uploadFiles(document, files, metaFiles, onProgress);

    await database.write(() => {
      return Promise.all(
        files.map((file) => {
          return file
            .destroyPermanently()
            .catch((err) =>
              console.warn("[uploadDocument] failed to destroy file:", err)
            );
        })
      );
    });
  },
  validatePermit(data: {
    docType: "validate_doc";
    data: string;
    validator: string;
  }): Promise<NextcloudPermitDoccheckResult["data"]> {
    return restApi.post(`${config.ONLINE_VALIDATION_EP}/docvalidate`, data);
  },
};

export const syncApi = {};
