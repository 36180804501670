import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Q } from "@nozbe/watermelondb";
import { Button, Modal } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { SessionState } from "redux/features/session";
import {
  UseReduxSessionResponse,
  useReduxSession,
} from "hooks/useReduxSession";
import { UseDocumentsResponse, useDocuments } from "hooks/useDocuments";
import FlightTicket from "wmelon/models/FlightTicket";
import { getQueryValue } from "utils/db";

import "./PreparationScreen.css";
import { withObservables } from "@nozbe/watermelondb/react";
import { database } from "wmelon/database";
import { APP_COLORS } from "utils/colors";
import DBTicketItemContainer from "screens/Tickets/components/DBTicketItemContainer";
import { Observable } from "@nozbe/watermelondb/utils/rx";

export interface PreparationScreenProps {
  sessionState: SessionState;
  tickets: FlightTicket[];
}

function PreparationScreen({ sessionState, tickets }: PreparationScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clearSession }: UseReduxSessionResponse = useReduxSession();
  const { clearDocuments }: UseDocumentsResponse = useDocuments();

  const [loading, setLoading] = useState<boolean>(false);

  const [ticket] = tickets;

  const onStartValidation = useCallback(() => {
    navigate("/validator");
  }, [navigate]);

  const onCancelSession = useCallback(() => {
    setLoading(true);
    clearSession(true)
      .then(() => clearDocuments())
      .catch((err) => {
        console.error("[Validator] failed to clear session:", err);
      })
      .finally(() => navigate("/tickets/upcoming"));
  }, [clearDocuments, clearSession, navigate]);

  useEffect(() => {
    if (!tickets.length && sessionState.flight_ticket_id) {
      console.log("NO_TICKETS:");
      Modal.error({
        title: t("Error"),
        content: t("flight_ticket__sharing_revoked"),
        okText: t("ok"),
        onOk: () => onCancelSession(),
      });
    }
  }, [onCancelSession, sessionState?.flight_ticket_id, t, tickets]);

  return (
    <div className="PreparationScreen">
      <div className="PreparationScreen-header">
        <Button
          type="text"
          icon={
            <LeftOutlined
              style={{
                color: APP_COLORS.light.primary,
                fontSize: 20,
              }}
            />
          }
          onClick={() => navigate("/tickets/upcoming")}
        />
        <span>{t("PreparationScreen.prepareDocuments")}</span>
      </div>
      <div className="PreparationScreen-container">
        <div className="PreparationScreen-content">
          {ticket ? (
            <DBTicketItemContainer
              ticket={ticket}
              onStartValidation={() => Promise.resolve()}
              onShare={() => Promise.resolve()}
              onUnshare={() => Promise.resolve()}
              onEditTag={() => {}}
              viewer
              onCall={() => {}}
            />
          ) : null}
          <div className="PreparationScreen-buttons">
            <Button
              type="primary"
              disabled={loading}
              onClick={onStartValidation}
              style={{
                width: "100%",
                border: "1px solid #FFF",
                backgroundColor: APP_COLORS.light.primary,
              }}
            >
              {t("сontinue")}
            </Button>
            <Button
              onClick={onCancelSession}
              disabled={loading}
              style={{
                width: "100%",
                color: APP_COLORS.light.default_btn_text_color,
              }}
            >
              {t("Cancel")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapState = (state: any) => ({
  sessionState: state.session,
});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

const enhance = withObservables<
  Omit<PreparationScreenProps, "tickets">,
  { tickets: Observable<FlightTicket[]> }
>(["sessionState"], ({ sessionState }: { sessionState: SessionState }) => ({
  tickets: database
    .get<FlightTicket>(FlightTicket.table)
    .query(Q.where("id", getQueryValue(sessionState?.flight_ticket_id)))
    .observe(),
}));

const EnhancedPreparationScreen = enhance(PreparationScreen);
export default connector(EnhancedPreparationScreen);
