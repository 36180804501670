import {  ReactNode } from "react";
import "./Checkbox.css";
import { isSafari, isMobileSafari } from "react-device-detect";

export interface IProps {
  checked: boolean;
  onChange: (value: boolean) => void;
  color?: string;
  children?: ReactNode;
}

function Checkbox({ checked, onChange, color, children }: IProps) {
  return (
    <div className='Checkbox'>
      <button
        className='Checkbox-border'
        style={color ? { borderColor: color } : undefined}
        onClick={() => onChange(!checked)}
      >
        {checked ? (
          <div
            className='Checkbox-mark'
            style={color ? { backgroundColor: color } : undefined}
          />
        ) : null}
      </button>
      {children ? (
        <div
          className='Checkbox-label'
          style={
            color
              ? { color, fontWeight: isSafari || isMobileSafari ? 599 : "bold" }
              : undefined
          }
        >
          {children}
        </div>
      ) : null}
    </div>
  );
}

export default Checkbox;
