import get from "lodash/get";
import { ReduxDocument } from "redux/features/documents";
import config from "config/index";

export const DOCUMENT_TYPES = {
  fasttrack_manual_scan: "fasttrack_manual_scan",
  fasttrack_not_recognized: "fasttrack_not_recognized",
  fasttrack_ticket_manual: "fasttrack_ticket_manual",
  fasttrack_offload: "fasttrack_offload",
  fasttrack_baggage: "fasttrack_baggage",
  fasttrack_unfinished: "fasttrack_unfinished",
  fasttrack_to_recognize: "fasttrack_to_recognize",
};

const DOCUMENT_TYPES_WITHOUT_OWNER = [
  DOCUMENT_TYPES.fasttrack_not_recognized,
  DOCUMENT_TYPES.fasttrack_manual_scan,
  DOCUMENT_TYPES.fasttrack_to_recognize,
  DOCUMENT_TYPES.fasttrack_ticket_manual,
  DOCUMENT_TYPES.fasttrack_offload,
  DOCUMENT_TYPES.fasttrack_unfinished,
];

const JSONFORM_PROVIDER = {
  FORM: "form",
  MANUAL: "manual",
};

export const DOCUMENT_PROVIDERS = {
  regula: "Regula",
  eudcc: "EUDCC",
  custom: "Custom",
  manual: "manual",
};

export function getProvider(type: string) {
  return DOCUMENT_TYPES_WITHOUT_OWNER.includes(type)
    ? JSONFORM_PROVIDER.MANUAL
    : JSONFORM_PROVIDER.FORM;
}

export function getVerifiedManual(type: any, category: any) {
  if (DOCUMENT_TYPES_WITHOUT_OWNER.indexOf(type) === -1) {
    if (category === config.DOCUMENT_CATEGORIES.ADDITIONAL_DOCUMENT) {
      return 1;
    }
    return 0;
  }
  return 0;
}

export function mapDocumentToOld(doc: ReduxDocument | any) {
  return {
    id: doc.id,
    // user_id: doc.u
    session_id: doc.session_id,
    form_id: doc.form_id?.toString(),
    form_version: doc.form_version,
    type: doc.type,
    data: doc.data,
    category: doc.category?.toString(),
    recognizer: doc.recognizer,
    verified_manual: doc.verifiedManual ? 1 : 0,
    verified_auto: doc.verifiedAuto ? 1 : 0,
    auto_verification_result: doc.autoVerificationResult,
    provider: doc.provider,
    version: "1",
  };
}

export default function uriToBlob(uri) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = () => {
      const blob = xhr.response;
      resolve(blob);
    };
    xhr.onerror = (err) => {
      reject(err);
    };
    xhr.open("GET", uri);
    xhr.send();
  });
}

export function getIsAutorecognized(
  data: Record<string, any>,
  allFields: string[],
  parsedFields: string[]
) {
  if (!parsedFields.length) {
    return false;
  }

  const filledFields: string[] = [];
  allFields.forEach((field: string) => {
    if (field !== "documentFile" && get(data, field) !== undefined) {
      filledFields.push(field);
    }
  });

  return (
    filledFields.length === parsedFields.length &&
    filledFields.every((field) => parsedFields.indexOf(field) > -1)
  );
}

export function getFullLink(link: string) {
  return `${config.STORAGE_HOST}/${link}`;
}

export function getIsFileLink(link: string) {
  return (link || "").startsWith(config.STORAGE_HOST);
}
