import { useCallback, useMemo } from 'react'
import { generalAPI } from '../services/base/api'
import { useAppDispatch } from '../redux/store'
import { setItems } from '../redux/features/history'
import { HistoryItem } from '../types/history'

export interface UseHistoryResponse {
  loadList: (date: string | null) => Promise<HistoryItem[]>
  loadTicket: (id: string) => Promise<HistoryItem[]>
}

export function useHistory(): UseHistoryResponse {
  const dispatch = useAppDispatch()

  const loadList = useCallback(
    (date: string | null): Promise<HistoryItem[]> => {
      if (!date) {
        dispatch(
          setItems({
            date: null,
            items: [],
          }),
        )
        return Promise.resolve([])
      }
      return generalAPI
        .loadHistory(date)
        .then((response: { history: HistoryItem[] }) => {
          console.log('ITEMS:', response, response.history[0]?.identifiers)
          dispatch(
            setItems({
              date,
              items: response.history,
            }),
          )
          return response.history
        })
        .catch((err) => {
          console.error('[useHistory] loadList failed:', err)
          dispatch(
            setItems({
              date,
              items: [],
            }),
          )
          throw err
        })
    },
    [dispatch],
  )

  const loadTicket = useCallback((id: string): Promise<HistoryItem[]> => {
    return generalAPI.loadHistoryTicket(id).then((resp: { history: HistoryItem[] }) => resp.history)
  }, [])

  return useMemo(
    () => ({
      loadList,
      loadTicket,
    }),
    [loadList, loadTicket],
  )
}
