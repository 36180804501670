import { useCallback, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { useReduxSession } from "./useReduxSession";
import { useSession } from "./useSession";
import { useDocuments } from "./useDocuments";
import { SessionState } from "../redux/features/session";
import Session from "../wmelon/models/Sessions";
import { useTranslation } from "react-i18next";
import { setSessionToTicket } from "redux/features/validation";
import { getShortId } from "../utils/session";
import config from "../config";
import { getSeconds } from "../utils/date";
import { useSyncProgress } from "./useSyncProgress";
import { ValidationTicket } from "../redux/features/validation";
import useDBSync from "./useDBSync";
import { APP_COLORS } from "utils/colors";
import { ReduxState } from "redux/ReduxState";

export interface UseSessionInitializer {
  sessionId: string | null;
  loadingState: string | null;
  initializing: boolean;
  initializeSession: () => Promise<void>;
  restartSession: () => Promise<void | void[]>;
  cancelSession: (navigateTo: boolean, clearScheme: boolean) => Promise<void>;
  percent: {
    process: boolean;
    count: number;
    percent: number;
    uploaded: string;
    total: string;
  };
  nextTicketId: string | null;
}

export const useSessionInitializer = (
  sessions: Session[],
  unsyncedSessions: Session[],
  startTimer: (duration: number) => void
): UseSessionInitializer => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const sync = useDBSync(true, true);

  const sessionState: SessionState = useSelector(
    (state: ReduxState) => state.session
  );
  const validationTickets: ValidationTicket[] = useSelector(
    (state: ReduxState) => state.validation.tickets
  );
  const documentsRequest = useSelector(
    (state: ReduxState) => state.validation.documentsRequest
  );

  const isConnected = true;
  const sessionAPI = useReduxSession();
  const { removeSession } = useSession();
  const documentsAPI = useDocuments();

  const [loadingState, setLoadingState] = useState<string | null>(null);

  const { clear, percent } = useSyncProgress();

  const nextTicketId = useMemo(() => {
    const ticketId = validationTickets.find((it) => !it.status)?.id || null;
    console.log(
      `[useSessionInitializer] nextTicket:"${ticketId}";current="${sessionState.flight_ticket_id}"`,
      validationTickets
    );
    return ticketId;
  }, [validationTickets, sessionState.flight_ticket_id]);

  const sessionId = useMemo(() => {
    return (
      validationTickets.find((it) => it.id === sessionState.flight_ticket_id)
        ?.sessionId || null
    );
  }, [validationTickets, sessionState.flight_ticket_id]);
  console.log("SeSSION_ID:", sessionId, validationTickets);

  // const canRestart =
  //   !!sessionState.flight_ticket_id &&
  //   sessionState.origin &&
  //   sessionState.destination;

  const removeUnsynced = useCallback(() => {
    console.log("[useSessionInitializer] removeUnsynced");
    return Promise.all(
      unsyncedSessions.map((it: Session) => {
        return Promise.all([
          removeSession(it.id),
          documentsAPI.removeDocuments(it.id),
        ]);
      })
    ).catch((err) =>
      console.error("[useSessionInitializer] failed to remove unsynced:", err)
    );
  }, [unsyncedSessions, removeSession, documentsAPI]);

  const restartSession = useCallback(() => {
    console.log("[useSessionInitializer] restartSession");
    setLoadingState("RegulaScreens.dataCleaningInProgress");
    return Promise.all([
      sessionAPI.restartSession(),
      documentsAPI.clearDocuments(),
    ])
      .catch((err) => {
        console.error(
          "[useSessionInitializer] failed to restart session:",
          err
        );
      })
      .finally(() => {
        if (sessions.length >= 9) {
          setLoadingState(null);
          Modal.error({
            title: t("Ticket_and_Info.maximumAttempts"),
            content: t("Ticket_and_Info.callSupport"),
            okText: t("ok"),
            onOk: () => navigate("/tickets/upcoming"),
          });
          return;
        } else {
          console.log("DOC_REQUEST:RESTART:", documentsRequest);
          if (documentsRequest) {
            sessionAPI
              .initSessionByDocumentsRequest(documentsRequest)
              .then(() => {
                setLoadingState(null);
                navigate("/document_request");
              });
          } else {
            setLoadingState(null);
            navigate("/validator");
          }
        }
      });
  }, [
    sessionAPI,
    documentsAPI,
    sessions.length,
    t,
    navigate,
    documentsRequest,
  ]);

  const cancelSession = useCallback(
    (navigateTo: boolean, clearScheme: boolean) => {
      console.log("[useSessionInitializer] cancelSession");
      setLoadingState("RegulaScreens.dataCleaningInProgress");
      return Promise.all([
        sessionAPI
          .clearSession(clearScheme)
          .catch((err) =>
            console.log(
              "[useSessionInitializer] failed to clear session state:",
              err
            )
          ),
        documentsAPI
          .clearDocuments()
          .catch((err) =>
            console.log(
              "[useSessionInitializer] failed to clear documents state:",
              err
            )
          ),
      ])
        .then(() => {})
        .catch((err) => {
          console.error(
            "[useSessionInitializer] failed to clear session:",
            err
          );
        })
        .finally(() => {
          navigateTo && navigate("/tickets/upcoming");
          clear();
        });
    },
    [sessionAPI, documentsAPI, navigate, clear]
  );

  const initializeSession = useCallback(() => {
    setLoadingState(
      sessionId
        ? "RegulaScreens.dataUpload"
        : "RegulaScreens.sessionInitialisation"
    );
    clear();

    if (!isConnected) {
      Modal.error({
        title: t("RegulaScreens.failedToSyncDatabase"),
        content: t("RegulaScreens.noConnection"),
        okText: t("ok"),
        onOk: () => cancelSession(true, true),
        onCancel: () => cancelSession(true, true),
      });

      return Promise.reject();
    }

    if (sessionId) {
      return (
        sync()
          // .then(() => cancelSession(false))
          .then(() => {
            setLoadingState(null);
          })
          .catch((err) => {
            console.error(
              "[useSessionInitializer] failed to create session:",
              err
            );
            setLoadingState(null);
            clear();
            if (err.message === "frequent_call") {
              startTimer(
                getSeconds(err.ts + config.SYNC_INTERVAL - Date.now())
              );
              Modal.error({
                title: t("RegulaScreens.failedToSyncDatabase"),
                content: t("RegulaScreens.frequentCall"),
                okText: t("ok"),
              });
            } else {
              Modal.error({
                title: t("RegulaScreens.failedToSyncDatabase"),
                content: t("RegulaScreens.pleaseTryAgainLater"),
                okText: t("ok"),
                okButtonProps: {
                  style: {
                    // backgroundColor: APP_COLORS.light.primary,
                    color: APP_COLORS.light.default_text_color,
                  },
                },
              });
            }
          })
      );
    }

    if (sessions.length >= 9) {
      Modal.error({
        title: t("Ticket_and_Info.maximumAttempts"),
        content: t("Ticket_and_Info.callSupport"),
        okText: t("ok"),
      });
      setLoadingState(null);
      return Promise.reject();
    }

    let sessionCreated = false;
    return (
      removeUnsynced()
        .then(() =>
          sessionAPI.createSession(
            getShortId(sessions, sessionState.flight_ticket_id)
          )
        )
        .then((session: Session) => {
          console.log(
            "SESSION_CREATED:",
            sessionState.flight_ticket_id,
            session.id
          );
          dispatch(
            setSessionToTicket({
              id: sessionState.flight_ticket_id,
              sessionId: session.id,
            })
          );
          return documentsAPI.moveToDatabase(session);
        })
        .then(() => {
          sessionCreated = true;
          if (isConnected) {
            setLoadingState("RegulaScreens.dataUpload");
            return sync();
          } else {
            return Promise.resolve();
          }
        })
        // .then(() => cancelSession(false))
        .then(() => {
          setLoadingState(null);
        })
        .catch((err) => {
          console.error(
            "[useSessionInitializer] failed to create session:",
            err
          );
          setLoadingState(null);
          clear();
          if (err.message === "frequent_call") {
            startTimer(getSeconds(err.ts + config.SYNC_INTERVAL - Date.now()));
            Modal.error({
              title: t("RegulaScreens.failedToSyncDatabase"),
              content: t("RegulaScreens.frequentCall"),
              okText: t("ok"),
            });
          } else if (err.message === "flight_ticket__sharing_revoked") {
            Modal.error({
              title: t("flight_ticket__sharing_revoked"),
              okText: t("ok"),
              onOk: () => cancelSession(true, true),
            });
          } else {
            Modal.error({
              title: t(
                sessionCreated
                  ? "RegulaScreens.failedToSyncDatabase"
                  : "RegulaScreens.failedToInitSession"
              ),
              content: sessionCreated
                ? t("RegulaScreens.pleaseTryAgainLater")
                : null,
              okText: t("ok"),
              okButtonProps: {
                style: {
                  // backgroundColor: APP_COLORS.light.primary,
                  color: APP_COLORS.light.default_text_color,
                },
              },
            });
          }
          throw err;
        })
    );
  }, [
    sessionId,
    clear,
    isConnected,
    sessions,
    removeUnsynced,
    t,
    cancelSession,
    sync,
    startTimer,
    sessionAPI,
    sessionState.flight_ticket_id,
    dispatch,
    documentsAPI,
  ]);

  const initializing = !!sessionId && percent.process && percent.percent < 100;

  return useMemo(
    () => ({
      sessionId,
      loadingState,
      initializing,
      initializeSession,
      restartSession,
      cancelSession,
      percent,
      nextTicketId,
    }),
    [
      sessionId,
      initializing,
      loadingState,
      initializeSession,
      restartSession,
      cancelSession,
      percent,
      nextTicketId,
    ]
  );
};
