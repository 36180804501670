import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SESSION_STATUSES, HANDLERS } from "utils/session";

export interface SessionState {
  id: string | null;
  short_id: string;
  flight: string;
  carrier: string;
  origin: string;
  destination: string;
  depart_port: string | null;
  arrival_port: string | null;
  transit: string[];
  flight_date: string;
  scheme_id: string;
  scheme_version: string;
  status: string;
  handler: string;
  owner_first_name: string | null;
  owner_last_name: string | null;
  owner_dob: string | null;
  owner_document_number: string | null;
  scheme_type: string | null;
  assign_to: string | null;
  flight_ticket_id: string;
  has_unverified_documents: boolean;
}

export const setSessionOwner = createAsyncThunk(
  "session/setSessionOwner",
  (data: {
    owner_first_name: string | null;
    owner_last_name: string | null;
    owner_dob: string | null;
    owner_document_number: string | null;
  }) => {
    return data;
  }
);

export const setSessionHandler = createAsyncThunk(
  "session/setSessionHandler",
  (data: { status: string; handler: string }) => {
    return data;
  }
);

export const setETicket = createAsyncThunk(
  "session/setETicket",
  (data: {
    flight_origin: string;
    flight_destination: string;
    flight_ticket_id: string;
  }) => {
    return data;
  }
);

export const clearState = createAsyncThunk("session/clearState", () => {});

export const initialState: SessionState = {
  id: null,
  short_id: "",
  flight: "",
  carrier: "",
  origin: "",
  destination: "",
  depart_port: null,
  arrival_port: null,
  transit: [],
  flight_date: "",
  scheme_id: "",
  scheme_version: "",
  status: "",
  handler: "",
  owner_first_name: null,
  owner_last_name: null,
  owner_dob: null,
  owner_document_number: null,
  scheme_type: null,
  assign_to: null,
  flight_ticket_id: "",
  has_unverified_documents: false,
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    reset: (state) => {
      state = initialState;
    },
    initSession: (
      state,
      action: PayloadAction<{
        id: string | null;
        short_id: string;
        flight: string;
        carrier: string;
        origin: string;
        destination: string;
        depart_port: string | null;
        arrival_port: string | null;
        transit: string[];
        flight_date: string;
        scheme_id: string;
        scheme_version: string;
        scheme_type: string | null;
        assign_to: string | null;
        flight_ticket_id: string;
        owner_first_name?: string | null;
        owner_last_name?: string | null;
        owner_dob?: string | null;
        owner_document_number?: string | null;
      }>
    ) => {
      state.id = action.payload.id;
      state.short_id = action.payload.short_id;
      state.flight = action.payload.flight;
      state.carrier = action.payload.carrier;
      state.origin = action.payload.origin;
      state.destination = action.payload.destination;
      state.depart_port = action.payload.depart_port;
      state.arrival_port = action.payload.arrival_port;
      state.transit = action.payload.transit;
      state.flight_date = action.payload.flight_date;
      state.scheme_id = action.payload.scheme_id;
      state.scheme_version = action.payload.scheme_version;
      state.scheme_type = action.payload.scheme_type;
      state.assign_to = action.payload.assign_to;
      state.flight_ticket_id = action.payload.flight_ticket_id;
      state.owner_first_name = action.payload.owner_first_name || null;
      state.owner_last_name = action.payload.owner_last_name || null;
      state.owner_dob = action.payload.owner_dob || null;
      state.owner_document_number =
        action.payload.owner_document_number || null;
      state.status = SESSION_STATUSES.WAIT_FOR_OWNER;
      state.handler = HANDLERS.VALIDATOR;
      state.has_unverified_documents = false;
    },
    setHasUnverifiedDocuments: (state, action: PayloadAction<boolean>) => {
      state.has_unverified_documents = action.payload;
    },
    restartSession: (state) => {
      state.owner_first_name = null;
      state.owner_last_name = null;
      state.owner_dob = null;
      state.owner_document_number = null;
      state.status = SESSION_STATUSES.WAIT_FOR_OWNER;
      state.handler = HANDLERS.VALIDATOR;
      state.has_unverified_documents = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setSessionOwner.fulfilled, (state, action) => {
        state.owner_first_name = action.payload.owner_first_name;
        state.owner_last_name = action.payload.owner_last_name;
        state.owner_dob = action.payload.owner_dob;
        state.owner_document_number = action.payload.owner_document_number;
      })
      .addCase(setSessionHandler.fulfilled, (state, action) => {
        state.status = action.payload.status;
        state.handler = action.payload.handler;
      })
      .addCase(setETicket.fulfilled, (state, action) => {
        state.depart_port = action.payload.flight_origin;
        state.arrival_port = action.payload.flight_destination;
        state.flight_ticket_id = action.payload.flight_ticket_id;
      })
      .addCase(clearState.fulfilled, (state) => {
        Object.keys(initialState).forEach((field: string) => {
          state[field] = initialState[field];
        });
      });
  },
});

export const { initSession, setHasUnverifiedDocuments, restartSession } =
  sessionSlice.actions;

export default sessionSlice.reducer;
