import { createContext } from "react";

const AuthorizedContext = createContext<{
  authorized: boolean;
  setAuthorized: (value: boolean) => void;
}>({
  authorized: false,
  setAuthorized: () => {},
});

export default AuthorizedContext;
