import { Tabs as AntTabs } from "antd";
import type { TabsProps as AntTabProps } from "antd";

import "./Tabs.css";
import { APP_COLORS } from "utils/colors";

export interface TabsProps {
  tabs: AntTabProps["items"];
  onChange?: (activeKey: string) => void;
  defaultActiveKey?: string;
}

export default function Tabs({ tabs, onChange, defaultActiveKey }: TabsProps) {
  return (
    <AntTabs
      className='Tabs'
      defaultActiveKey={defaultActiveKey}
      centered
      size='large'
      items={tabs}
      indicatorSize={0}
      onChange={onChange}
      animated={{
        inkBar: true,
        tabPane: true,
      }}
      tabBarStyle={{
        borderRadius: "32px",
        background: APP_COLORS.light.tab_bg,
        maxWidth: 368,
        padding: 4,
        width: "100%",
        overflow: "hidden",
      }}
    />
  );
}
