import { useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Menu, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import airplaneBlackIcon from "../../assets/images/airplane_black.svg";
import supportBlackIcon from "../../assets/images/support_black.svg";
import airplaneWhiteIcon from "../../assets/images/airplane_white.svg";
import supportWhiteIcon from "../../assets/images/support_white.svg";

import "./MobileSidebar.css";

export default function MobileSidebar() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const canShowSidebar = useMemo(() => {
    return ["/tickets/upcoming", "/tickets/past", "/support"].some(
      (route) => location.pathname === route
    );
  }, [location]);

  const isTickets = useMemo(() => {
    return ["/tickets/upcoming", "/tickets/past"].includes(location.pathname)
  }, [location])

  return canShowSidebar ? (
    <div className='MobileSidebar'>
      <div className='MobileSidebar-submenu'>
        {isTickets ? (
          <Button
            className='MobileSidebar-add-flight'
            icon={<PlusOutlined />}
            onClick={() => navigate("/add_flight")}
          >
            {t("TabViewsTitle.addFlight")}
          </Button>
        ) : null}
      </div>
      <Menu
        onClick={() => { }}
        mode='horizontal'
        className='MobileSidebar-menu'
        defaultSelectedKeys={[location.pathname]}
        selectedKeys={[location.pathname]}
        items={[
          {
            key: isTickets ? location.pathname : '',
            label: t("TabViewsTitle.Home"),
            icon: (
              <img
                src={ isTickets ? airplaneBlackIcon : airplaneWhiteIcon
                }
                alt=''
              />
            ),
            onClick: () => {
              navigate("/tickets/upcoming");
            },
          },
          {
            key: "/support",
            label: t("Support.Support"),
            icon: (
              <img
                src={
                  location.pathname === "/support"
                    ? supportBlackIcon
                    : supportWhiteIcon
                }
                alt=''
              />
            ),
            onClick: () => {
              navigate("/support");
            },
          },
        ]}
      />
    </div>
  ) : null;
}
