import { useCallback, useEffect, useState } from "react";
import uniq from "lodash/uniq";
import useDateTS from "hooks/useDateTS";
import moment from "moment";
import { database } from "wmelon/database";
import FlightTicket from "wmelon/models/FlightTicket";
import { Q } from "@nozbe/watermelondb";
import Session from "wmelon/models/Sessions";
import Document from "wmelon/models/Document";
import Ticket from "wmelon/models/Ticket";
import Notification from "wmelon/models/Notification";
import { useSelector } from "react-redux";
import { ReduxState } from "redux/ReduxState";
import { initBadges } from "utils/badges";
import { NotificationActions } from "types/notification";

function OutdatedDataProvider() {
  const ts = useDateTS(60 * 1000);
  const [prevTS, setPrevTS] = useState<number | null>(null);

  const syncing = useSelector((state: ReduxState) => state.app.syncing);

  const deleteOutdatedNotifications = useCallback((date: number) => {
    return database
      .get<Notification>(Notification.table)
      .query(
        Q.where("created_at", Q.lt(date)),
        Q.or(
          Q.where("session_id", Q.notEq(null)),
          Q.where("action", Q.oneOf([NotificationActions.share_revoked]))
        )
      )
      .fetch()
      .then((notifications) => {
        const session_ids: string[] = notifications
          .map((it) => it.sessionId || "")
          .filter(Boolean);
        return database
          .get<Session>(Session.table)
          .query(Q.where("id", Q.oneOf(session_ids)))
          .fetchIds()
          .then((sessionIds) => {
            const notificationsWithoutSession = notifications.filter(
              (it) => !sessionIds.includes(it.sessionId || "")
            );
            const deletedSessionIds: string[] = notificationsWithoutSession
              .map((it) => it.sessionId || "")
              .filter(Boolean);

            return Promise.all([
              database
                .get<Document>(Document.table)
                .query(Q.where("session_id", Q.oneOf(deletedSessionIds)))
                .fetch(),
              database
                .get<Ticket>(Ticket.table)
                .query(Q.where("session_id", Q.oneOf(deletedSessionIds)))
                .fetch(),
            ]).then(([documents, tickets]) => {
              return database.write(() => {
                return database.batch([
                  ...documents.map((it) => it.prepareDestroyPermanently()),
                  ...tickets.map((it) => it.prepareDestroyPermanently()),
                  ...notifications.map((it) => it.prepareDestroyPermanently()),
                ]);
              });
            });
          });
      });
  }, []);

  const deleteOutdatedTickets = useCallback((date: number) => {
    return database
      .get<FlightTicket>(FlightTicket.table)
      .query(Q.where("flight_date", Q.lt(date)))
      .fetch()
      .then((flightTickets) => {
        return Promise.all(
          flightTickets.map((ticket) =>
            database
              .get<Session>(Session.table)
              .query(
                Q.where("flight_ticket_id", ticket.id),
                Q.where("_status", "synced")
              )
              .fetch()
          )
        ).then((grouppedSessions) => {
          const sessions = grouppedSessions.reduce(
            (acc, it) => acc.concat(it),
            []
          );
          const sessionIds = sessions.map((it) => it.id);
          return Promise.all([
            database
              .get<Document>(Document.table)
              .query(Q.where("session_id", Q.oneOf(sessionIds)))
              .fetch(),
            database
              .get<Ticket>(Ticket.table)
              .query(Q.where("session_id", Q.oneOf(sessionIds)))
              .fetch(),
          ]).then(([documents, tickets]) => {
            const unsyncedSessionIds: string[] = uniq([
              ...documents
                .filter((it) => it._raw._status !== "synced")
                .map((it) => (it._raw as any).session_id),
              ...tickets
                .filter((it) => it._raw._status !== "synced")
                .map((it) => (it._raw as any).session_id),
            ]);
            const sessionsToDelete = sessions.filter(
              (it) => !unsyncedSessionIds.includes(it.id)
            );
            const outdatedSessionTickets = sessionsToDelete.map(
              (it) => it.flight_ticket_id
            );
            const ticketsToDelete = flightTickets
              .filter(
                (it, idx) =>
                  !grouppedSessions[idx].length ||
                  outdatedSessionTickets.includes(it.id)
              )
              .map((it) => it.id);
            return database.write(async () => {
              return database.batch([
                ...flightTickets
                  .filter((it) => ticketsToDelete.includes(it.id))
                  .map((it) => it.prepareDestroyPermanently()),
                ...sessionsToDelete.map((it) => it.prepareDestroyPermanently()),
                ...documents
                  .filter(
                    (it) => !unsyncedSessionIds[(it._raw as any).session_id]
                  )
                  .map((it) => it.prepareDestroyPermanently()),
                ...tickets
                  .filter(
                    (it) => !unsyncedSessionIds[(it._raw as any).session_id]
                  )
                  .map((it) => it.prepareDestroyPermanently()),
                ...(await database
                  .get<Notification>(Notification.table)
                  .query(Q.where("flight_ticket_id", Q.oneOf(ticketsToDelete)))
                  .fetch()
                  .then((notifications) => {
                    return notifications.map((it) =>
                      it.prepareDestroyPermanently()
                    );
                  })),
              ]);
            });
          });
        });
      });
  }, []);

  useEffect(() => {
    if (ts !== prevTS && !syncing) {
      const date = moment(ts).add(-3, "month").endOf("day").valueOf();
      deleteOutdatedTickets(date)
        .then(() => deleteOutdatedNotifications(date))
        .then(() => {
          initBadges(0).catch((err) =>
            console.log("[OutdatedDataProvider] failed to init badges:", err)
          );
          setPrevTS(prevTS);
        })
        .catch((err) => {
          initBadges(0).catch((err) =>
            console.log("[OutdatedDataProvider] failed to init badges:", err)
          );
          console.error("[OutdatedDataProvider] failed:", err);
        });
    }
  }, [prevTS, syncing]); // eslint-disable-line

  return null;
}

export default OutdatedDataProvider;
