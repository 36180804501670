import { IFormProcessor } from "./IFormProcessor";
import EUDCCRecognizer from "./recognizers/EUDCCRecognizer";
import DefaultCustomRecognizer from "./recognizers/DefaultCustomRecognizer";
import RegulaRecognizer from "./recognizers/RegulaRecognizer";
import {
  ResultStatus,
  FormProcessorResponse,
} from "./model/FormProcessorResponse";
import { ErrorTexts } from "./ErrorTexts";
import { JsonForm } from "./model/JsonForm";
import EVISARecognizer from "./recognizers/EVISARecognizer";

export default class FormProcessor implements IFormProcessor {
  jsonForm;
  allowedDocumentTypes;
  recognizers;
  shouldRecognizeDocument;
  quickMode;

  constructor(
    jsonForm: JsonForm,
    allowedDocumentTypes: string[],
    shouldRecognizeDocument: boolean,
    quickMode: boolean
  ) {
    this.jsonForm = jsonForm;
    this.allowedDocumentTypes = allowedDocumentTypes;
    this.shouldRecognizeDocument = shouldRecognizeDocument;
    this.quickMode = quickMode;
    const regulaRecognizer = new RegulaRecognizer();
    const eUDCCRecognizer = new EUDCCRecognizer();
    const defaultCustomRecognizer = new DefaultCustomRecognizer();
    const evisaRecognizer = new EVISARecognizer();
    this.recognizers = {
      [`${RegulaRecognizer.getGUID().toLowerCase()}`]: regulaRecognizer,
      [`${EUDCCRecognizer.getGUID().toLowerCase()}`]: eUDCCRecognizer,
      [`${DefaultCustomRecognizer.getGUID().toLowerCase()}`]:
        defaultCustomRecognizer,
      [`${EVISARecognizer.getGUID().toLowerCase()}`]: evisaRecognizer,
    };
  }

  parse(parsRequest): FormProcessorResponse {
    const recognizerType = (this.jsonForm.recognizer || "custom").toLowerCase();
    const recognizer = this.recognizers[`${recognizerType}`];

    if (!recognizer) {
      return {
        status: ResultStatus.WRONG_PARAMS,
        error: ErrorTexts.RECOGNIZER_NOT_SUPPORT,
        data: null,
        recognizer: null,
        fields: {
          empty: [],
          invalid: [],
          parsed: [],
        },
        documentType: null,
        doc_data: {
          date_of_expiry: null,
          passenger: null,
          meta: null,
        },
      };
    }

    return recognizer.process(
      parsRequest,
      this.jsonForm,
      this.allowedDocumentTypes,
      this.shouldRecognizeDocument,
      this.quickMode
    );
  }
}
