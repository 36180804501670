export enum SharedStatus {
  not_shared,
  shared_by,
  shared_to,
}

export interface HistoryItem {
  flight_ticket_id: string;

  identifiers: string[];

  ticket_number: string;
  airline: string;
  flight_number: string;
  flight_date: string;
  flight_departure_time: string | null;
  flight_arrival_time: string | null;
  flight_origin: string;
  flight_destination: string;

  owner_first_name: string | null;
  owner_last_name: string | null;
  owner_document_number: string | null;

  user_id: string | null;
  short_id: string | null;
  status: string | null;
  handler: string | null;

  created_at: number;

  shared: number;

  document: string | null;
  qr: string | null;
  pkpass_document: string | null;
}
