import country2Codes from './country-2-codes.json'
import diseaseAgentTargeted from './disease-agent-targeted.json'
import testResult from './test-result.json'
import testType from './test-type.json'
import vaccineMahManf from './vaccine-mah-manf.json'
import vaccineMedicinalProduct from './vaccine-medicinal-product.json'
import vaccineProphylaxis from './vaccine-prophylaxis.json'

const EUDCC = {
    country2Codes,
    diseaseAgentTargeted,
    testResult,
    testType,
    vaccineMahManf,
    vaccineMedicinalProduct,
    vaccineProphylaxis
}

export default EUDCC;
