import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Typography, notification } from "antd";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useNavigate } from "react-router-dom";
import AuthorizedContext from "contexts/AuthorizedContext";
import PhoneNumber from "components/controllers/PhoneNumber";
import OTPField from "components/controllers/OTPField";
import Button from "components/Button";
import Checkbox from "components/controllers/Checkbox";
import { useTimer } from "hooks/useTimer";
import AuthApiService from "utils/AuthApi";
import logoImg from "assets/images/logo.svg";
import { isSafari, isMobileSafari } from "react-device-detect";

import "./AuthScreen.css";
import { APP_COLORS } from "utils/colors";

const NOTIFICATION_KEY = "AUTH_NOTIFICATION";
const OTP_LENGTH = 6;

function AuthScreen() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();

  const { setAuthorized } = useContext(AuthorizedContext);
  const [stage, setStage] = useState<"phone" | "otp">("phone");
  const [phone, setPhone] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [attempt, setAttempt] = useState<number>(0);

  const isValidPhone = useMemo(
    () => phone && isValidPhoneNumber(phone),
    [phone]
  );

  const [counter, startTimer, stopTimer] = useTimer("");

  const onHandlePhone = useCallback((name: string, value: string) => {
    setPhone(value);
  }, []);

  const onRequestOTP = useCallback(async () => {
    if (!phone) {
      setError("required");
      setTimeout(() => {
        setError(null);
      }, 3000);
      return;
    }
    setLoading(true);
    AuthApiService.shared()
      .sendCode(phone)
      .then((status) => {
        if (status) {
          startTimer(60, () => {});
          setOtp("");
          setLoading(false);
          setStage("otp");
        } else {
          throw new Error("Auth.server_error");
        }
      })
      .catch((err: any) => {
        setLoading(false);
        if (err.message) {
          api.error({
            key: NOTIFICATION_KEY,
            message: t("Error"),
            description: t([err.message, "Auth.server_error"]),
            placement: "topRight",
          });
        }
      });
  }, [phone, t, startTimer, api]);

  const onConfirmOTP = useCallback(() => {
    console.log("OTP:", otp);
    if (otp?.length !== OTP_LENGTH) {
      return;
    }

    setLoading(true);
    AuthApiService.shared()
      .getJWTByCode(phone, otp)
      .then((response: any) => {
        console.log("RESPONSE:", response);
        if (response?.jwt) {
          AuthApiService.shared().saveTokens(response.jwt);
          setAuthorized(true);
          navigate("/tickets");
        } else {
          throw new Error("Auth.server_error");
        }
      })
      .catch((err) => {
        console.error("[AuthScreeт] onConfirmOTP failed:", err);
        setAttempt((value) => value + 1);
        setLoading(false);
        api.error({
          key: NOTIFICATION_KEY,
          message: t("Error"),
          description: t([err.message, "Auth.server_error"]),
          placement: "topRight",
        });
      });
  }, [phone, otp, api, setAuthorized, navigate, t]);

  const onBack = useCallback(() => {
    setStage("phone");
    setOtp("");
    setAttempt(0);
    stopTimer();
  }, [stopTimer]);

  useEffect(() => {
    if (attempt > 3) {
      onBack();
    }
  }, [attempt]); // eslint-disable-line

  useEffect(() => {
    console.log("OTP_NEW:", otp.length, OTP_LENGTH);
    if (otp?.length === OTP_LENGTH) {
      onConfirmOTP();
    }
  }, [otp, onConfirmOTP]);

  const errMessage = useMemo(() => {
    if (!error) {
      return null;
    }

    return <Typography.Paragraph>{t(error)}</Typography.Paragraph>;
  }, [t, error]);

  return (
    <div className='AuthScreen'>
      <div className='AuthScreen-logoContainer'>
        <img src={logoImg} className='AuthScreen-logo' alt='logo' />
      </div>
      <div className='AuthScreen-section'>
        {stage === "phone" ? (
          <Typography.Title className='AuthScreen-title-phone-number'>
            {t("Authorization.provide_mobile_number")}
          </Typography.Title>
        ) : (
          <Typography.Title className='AuthScreen-title-otp-code'>
            {t("Authorization.AuthenticationCode")}
          </Typography.Title>
        )}
      </div>
      {stage === "phone" ? (
        <>
          <div className='AuthScreen-phone'>
            <PhoneNumber
              name='phone'
              value={phone}
              defaultCountry='ch'
              onChange={onHandlePhone}
            />
            {errMessage}
          </div>
          <Checkbox
            checked={acceptTerms}
            onChange={setAcceptTerms}
            color={APP_COLORS.light.default_text_color}
          >
            <Trans
              i18nKey='Authorization.termsRow'
              components={{
                termsLink: <a href='/terms_and_condition' aria-label='Terms' />,
                privacyPolicyLink: (
                  <a href='/privacy_policy' aria-label='Privacy' />
                ),
                communityLink: (
                  <a href='/community_rules' aria-label='Community' />
                ),
              }}
              style={{
                color: "red",
                fontWeight: isSafari || isMobileSafari ? 599 : "bold",
              }}
            />
          </Checkbox>
        </>
      ) : (
        <>
          <OTPField
            id='otp'
            value={otp}
            onChange={setOtp}
            numInputs={OTP_LENGTH}
          />
          {errMessage}
          <div className='AuthScreen-timerWrap'>
            {counter > 0 ? (
              <div className='AuthScreen-timer'>
                {t("Authorization.timerText", { counter: counter })}
              </div>
            ) : (
              <Button
                block
                disabled={loading}
                style={{
                  color: APP_COLORS.light.primary_text_color,
                  backgroundColor: APP_COLORS.light.primary,
                }}
                onClick={onRequestOTP}
              >
                {t("Authorization.Resend_code")}
              </Button>
            )}
          </div>
        </>
      )}
      <Button
        block
        disabled={
          loading ||
          !acceptTerms ||
          (stage === "phone"
            ? !isValidPhone && acceptTerms
            : !otp || otp.length < OTP_LENGTH)
        }
        style={{
          color: APP_COLORS.light.default_text_color,
        }}
        onClick={stage === "phone" ? onRequestOTP : onConfirmOTP}
      >
        {stage === "phone"
          ? t("Authorization.verify")
          : t("Authorization.confirmCode")}
      </Button>
      {stage === "otp" ? (
        <Button
          block
          type='primary'
          onClick={onBack}
          className='AuthScreen-back-button'
          style={{
            color: APP_COLORS.light.primary_text_color,
            backgroundColor: APP_COLORS.light.primary,
          }}
        >
          {t("Authorization.back")}
        </Button>
      ) : null}
      {contextHolder}
    </div>
  );
}

export default AuthScreen;
