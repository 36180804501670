import { useCallback, useMemo } from "react";
import "./Past.css";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "redux/ReduxState";
import moment from "moment";
import { Input } from "antd";
import { useTranslation } from "react-i18next";
import HistoryList from "../HistoryList";
import DBHistoryList from "../DBHistoryList";
import { setDate } from "redux/features/history";

function Past({ ts }: { ts: number }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const stateDate = useSelector((state: ReduxState) => state.history.date);

  const isHistoryDate = useMemo(() => {
    if (!stateDate) {
      return null;
    }

    return moment(stateDate).isSameOrBefore(
      moment().add(-3, "month").startOf("day")
    );
  }, [stateDate]);

  const onSelectDate = useCallback((event) => {
    dispatch(setDate(event.target.value));
  }, []);

  const today = useMemo(() => {
    return moment(ts - 1).format("YYYY-MM-DD");
  }, [ts]);

  const selectedTS = useMemo(() => {
    if (!stateDate) {
      return null;
    }

    return moment(stateDate).valueOf();
  }, [stateDate]);

  return (
    <div className="Past">
      <div className="Past-container">
        <div className="Past-header">
          <Input
            style={{ width: "100%" }}
            type="date"
            className="dateInput"
            max={today}
            value={stateDate || undefined}
            onChange={onSelectDate}
            // onBlur={() => setFieldTouched("flightDate", true)}
            placeholder={t("AddingFlight.enterFlightDate")}
          />
        </div>
        {!selectedTS || isHistoryDate ? (
          <HistoryList ts={selectedTS} />
        ) : (
          <DBHistoryList ts={selectedTS} />
        )}
      </div>
    </div>
  );
}

export default Past;
