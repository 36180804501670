import { FC, memo, useCallback } from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { ParsedCountry } from "react-international-phone/build/types";

interface PhoneNumberProps {
  name: string;
  value: string;
  defaultCountry: string;
  onChange: (name: string, value: string, country: string) => void;
}
const PhoneNumber: FC<PhoneNumberProps> = memo(
  ({ name, value, defaultCountry, onChange }) => {
    const handleChange = useCallback(
      (phone: string, meta: { country: ParsedCountry }) => {
        onChange(name, phone, meta.country.iso2 as string);
      },
      [name, onChange]
    );

    return (
      <div>
        <PhoneInput
          defaultCountry={defaultCountry}
          value={value}
          onChange={handleChange}
          inputStyle={{ width: "100%" }}
          autoFocus={false}

          // onFocus={(e) => e.target.select()}
        />
      </div>
    );
  }
);

export default PhoneNumber;
