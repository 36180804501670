import { useCallback } from "react";

import { WidgetProps } from "@rjsf/utils";
import Dictionary from "./Dictionary";
import { fieldNameToLabel } from "utils/string";
import { Input, Typography } from "antd";

export default function TextWidget(props: WidgetProps) {
  const {
    id,
    // name,
    value,
    required,
    // disabled,
    // readonly,
    onBlur,
    onChange,
    onFocus,
    label,
    placeholder,
    options,
  } = props;
  const handleBlur = useCallback(() => onBlur(id, value), [id, onBlur, value]);
  const handleFocus = useCallback(
    () => onFocus(id, value),
    [id, onFocus, value]
  );

  return options.hidden ? null : !options?.fieldType ? (
    <div>
      <Typography.Text style={{}}>
        {fieldNameToLabel(label)} {required && "*"}
      </Typography.Text>
      <Input
        id={id}
        autoCapitalize="characters"
        style={{}}
        value={value}
        placeholder={placeholder || fieldNameToLabel(label)}
        onChange={(value) =>
          onChange(
            value.target.value === "" ? undefined : (value.target.value as any)
          )
        }
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </div>
  ) : (
    <Dictionary type={options.fieldType as string} {...props} />
  );
}
