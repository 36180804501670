import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ShareStatus } from "../../../types/flights";
import { HistoryItem, SharedStatus } from "../../../types/history";
import TicketItem from "components/TicketItem";
import { APP_COLORS } from "utils/colors";
import { Modal } from "antd";

export interface HistoryTicketItemContainerProps {
  item: HistoryItem;

  onCall: () => void;
}

const HistoryTicketItemContainer = ({
  item,
  onCall,
}: HistoryTicketItemContainerProps) => {
  const { t } = useTranslation();

  const shareStatus: ShareStatus | null = useMemo(() => {
    switch (item.shared) {
      case SharedStatus.shared_by:
        return ShareStatus.shared_by;
      case SharedStatus.shared_to:
        return ShareStatus.shared_to;
      default:
        return null;
    }
  }, [item]);

  const onClickShare = useCallback(() => {
    Modal.error({
      title: t("Error"),
      content: t("FLIGHT_TICKET_SHARE_NOT_ALLOWED_BY_DATE"),
      okText: t("ok"),
      okButtonProps: {
        size: "small",
        style: { color: APP_COLORS.light.primary },
      },
    });
  }, [t]);

  const passenger = useMemo(() => {
    return {
      firstName: item?.owner_first_name,
      lastName: item?.owner_last_name,
      dob: null,
      documentNumber: item?.owner_document_number,
    };
  }, [item]);

  return (
    <TicketItem
      ticket={item}
      ticket_id={item.flight_ticket_id}
      session_status={item.status}
      session_handler={item.handler}
      short_id={item.short_id}
      session_id={null}
      doc_links={item}
      isUnsynced={false}
      passenger={passenger}
      onStartValidation={() => {}}
      canValidate={false}
      viewer={false}
      loading={false}
      sharing={false}
      onShare={onClickShare}
      onUnshare={onClickShare}
      shareStatus={shareStatus}
      onCall={onCall}
    />
  );
};

export default HistoryTicketItemContainer;
