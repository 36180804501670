import { useCallback, useEffect } from "react";
import OtpInput from "react-otp-input";
import "./OTPField.css";
import { APP_COLORS } from "utils/colors";

export interface OTPFieldProps {
  className?: string;
  id: string;
  value: string;
  onChange: (value: string) => void;
  isDisabled?: boolean;
  isInputNum?: boolean;
  numInputs: number;
}

function OTPField({
  className,
  id,
  value,
  onChange,
  isDisabled,
  isInputNum = true,
  numInputs,
}: OTPFieldProps) {
  const renderInput = useCallback(
    (inputProps: any, index: number) => {
      return (
        <input
          id={`${id}_${index}`}
          key={`input_${index}`}
          {...inputProps}
          disabled={isDisabled}
          style={{
            border: "1px solid black",
            color: APP_COLORS.light.primary,
          }}
        />
      );
    },
    [isDisabled, id]
  );

  const renderSeparator = useCallback(() => {
    return <span> </span>;
  }, []);

  useEffect(() => {
    if (isDisabled) {
      const lastInput = document.getElementById(`${id}_${value.length - 1}`);
      lastInput && lastInput.blur();
    }
  }, [id, isDisabled, value]);

  return (
    <div className={`OTPField ${className || ""}`} data-matomo-mask>
      <OtpInput
        value={value}
        shouldAutoFocus
        onChange={onChange}
        numInputs={numInputs}
        renderInput={renderInput}
        renderSeparator={renderSeparator}
        containerStyle='OTPField-inputs'
        inputStyle='OTPField-input'
        inputType={isInputNum ? "number" : "text"}
      />
    </div>
  );
}

export default OTPField;
