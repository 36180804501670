import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import { HistoryItem } from "../types/history";
import { getTicketPNR } from "./tickets";

export function getGrouppedHistoryTickets(
  list: HistoryItem[],
  order: "desc" | "asc"
): { date: string; data: HistoryItem[][] }[] {
  const grouppedByDate = groupBy(list, (ticket) => {
    return ticket.flight_date;
  });

  const result: { date: string; data: HistoryItem[][] }[] = [];

  Object.keys(grouppedByDate).forEach((date: string) => {
    const ticketsByDate = grouppedByDate[date];

    const groupped: Record<string, HistoryItem[]> = {};
    ticketsByDate.forEach((ticket: HistoryItem) => {
      const pnr = getTicketPNR(ticket);
      const key =
        `${ticket.flight_number}_${ticket.flight_origin}_${ticket.flight_destination}_${ticket.airline}_${pnr}`.toLowerCase();
      groupped[key] = (groupped[key] || []).concat(ticket);
    });

    result.push({
      date,
      data: Object.values(groupped).sort(
        (ticketsA: HistoryItem[], ticketsB: HistoryItem[]) => {
          const ticketA: HistoryItem = ticketsA[0] as HistoryItem;
          const ticketB: HistoryItem = ticketsB[0] as HistoryItem;
          return (
            ((ticketB.created_at && new Date(ticketB.created_at).getTime()) ||
              0) -
            ((ticketA.created_at && new Date(ticketA.created_at).getTime()) ||
              0)
          );
        }
      ),
    });
  });

  return orderBy(result, "date", [order]);
}
