import { DocumentMeta } from "./Document";

export interface UserData {
  nationality: string | null;
  nationality_alpha_2?: string | null;
  birth_place: string | null;
  given_name: string | null;
  surname: string | null;
  fullname: string | null;
  sex: string | null;
  dob: string | null;
  issued_at: string | null;
  document_number: string | null;
  document_class_code: string | null;
  valid?: boolean;
}

export enum ResultStatus {
  OK,
  WRONG_PARAMS,
  REQUEST_ERROR,
  VALIDATION_ERROR,
  DOC_TYPE_NOT_VALID,
  NOT_RECOGNIZED,
  OTHER_ERROR,
}

export interface FormProcessorResponse {
  status: ResultStatus;
  error?: string;
  data: any | null;
  documentType: string | null;
  recognizer: string | null;
  fields: {
    empty: string[];
    parsed: string[];
    invalid: string[];
  };
  doc_data: {
    date_of_expiry: string | null;
    passenger: UserData | null;
    meta: DocumentMeta | null;
  };
}
