import { useCallback } from "react";
import { WidgetProps } from "@rjsf/utils";
import { Button, Typography, Checkbox } from "antd";
import "./style.css";

export default function CheckboxWidget({
  id,
  label,
  disabled,
  readonly,
  value,
  onChange,
}: WidgetProps) {
  console.log("CheckboxWidget", { id, label, disabled, readonly, value });
  const onToggle = useCallback(() => onChange(!value), [onChange, value]);
  const isMobile =
    /iPhone|Android/i.test(navigator.userAgent) &&
    !/iPad|Tablet/i.test(navigator.userAgent);
  return (
    <Button onClick={onToggle} disabled={disabled || readonly}>
      <div className="field">
        <Typography.Text
          style={{
            fontSize: isMobile ? undefined : 16,
          }}
          className="fieldText"
        >
          {label}
        </Typography.Text>
        <Checkbox checked={value} onChange={onToggle} />
      </div>
    </Button>
  );
}
