import { useMemo } from "react";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import PDFViewer from "../PDFViewer";
import { getIsPDF } from "utils/utils";

import "./FileViewer.css";
import { APP_COLORS } from "utils/colors";

export interface FileViewerProps {
  file: Blob | string;
  size?: "small" | "medium" | "large";
  onClick?: () => void;
  onRemove?: () => void;
  canRemove?: boolean;
}

export default function FileViewer({
  file,
  size = "small",
  onRemove,
  onClick,
  canRemove = true,
}: FileViewerProps) {
  const { t } = useTranslation();
  const isPDF = useMemo(() => {
    return getIsPDF(file);
  }, [file]);

  const pdfSize = useMemo(() => {
    if (size === "small") {
      return {
        width: 100,
        height: 150,
      };
    }

    const width = Math.max(100, window.innerHeight * 0.35);
    return {
      width: width,
      height: width * 1.5,
    };
  }, [size]);

  return (
    <div className={cn(`FileViewer ${size}`)}>
      {canRemove && (
        <Button
          className="FileViewer-remove"
          shape="circle"
          icon={<DeleteOutlined style={{ color: APP_COLORS.light.primary }} />}
          onClick={onRemove}
        />
      )}
      {isPDF ? (
        <PDFViewer
          file={file}
          height={pdfSize.height}
          width={pdfSize.width}
          onError={() => {}}
          onClick={onClick}
        />
      ) : (
        <img
          src={typeof file === "string" ? file : URL.createObjectURL(file)}
          alt=""
          onClick={onClick}
        />
      )}
      <div onClick={onClick} className="FileViewer-preview">
        <span>
          <EyeOutlined /> {t("Preview")}
        </span>
      </div>
    </div>
  );
}
