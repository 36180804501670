import axios, { AxiosError, AxiosResponse } from "axios";
import { api_url } from "config/services";
import config from "config/index";
import Storage from "core/Storage/Storage";
import AuthApiService from "./AuthApi";
import { appEvent } from "./customEvent";

class RESTApi {
  refreshing: boolean = false;
  refreshPromise: Promise<any> | null = null;
  instance = axios.create({
    baseURL: api_url,
    headers: {
      "Content-Type": "application/json",
    },
  });

  constructor() {
    this.instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response?.status === 401) {
          const origRequest = error.config;
          if (origRequest.url.includes(config.REFRESH_TOKEN_EP)) {
            return Promise.reject(error);
          }

          const token = await this.getRefreshedToken();
          origRequest.headers["Authorization"] = `Bearer ${token}`;
          return this.instance?.(origRequest);
        }

        return Promise.reject(error);
      }
    );
  }

  async addBearerTokenToHeader() {
    if (this.refreshPromise && this.refreshing) {
      return await this.refreshPromise;
    }
    return AuthApiService.shared()
      .getUserToken()
      .then((token) => {
        if (this.instance?.defaults && token) {
          this.instance.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${token}`;
        } else {
          throw new Error("token not found");
        }
      });
  }

  removeBearerToken() {
    if (this.instance?.defaults) {
      delete this.instance.defaults.headers.common["Authorization"];
    }
  }

  getIsLogoutError(error) {
    return error.response?.status === 401;
  }

  parseError(error) {
    if (error.response) {
      const { data, statusText, status }: AxiosResponse = error.response;
      console.error(
        `[RESTApi] request failed with status : ${statusText}`,
        data
      );
      throw new Error(data?.message, { cause: { status } });
    } else {
      console.error("[RESTApi] request failed:", error);
      throw new Error(error?.message);
    }
  }

  parseResponse(response: AxiosResponse, callback?: (value: any) => any) {
    const { status, data, statusText } = response;
    if (status < 300) {
      callback && callback(data);
      return data;
    } else {
      console.log(
        `[${response.config?.method || "parseResponse"}] request failed:`,
        statusText
      );
      throw new Error(
        `Request failed with status ${response.config?.method}: ` + status
      );
    }
  }

  get = async (
    key: string | string[],
    params: any = {},
    fn: (data: any) => void = () => {},
    fail: (error: AxiosError) => void = () => {},
    noRefresh = false
  ) => {
    try {
      const route = typeof key === "string" ? key : key.join("");
      await this.addBearerTokenToHeader();
      console.log(
        "[get] request",
        route,
        JSON.stringify(params, null, 4),
        this.instance?.defaults.headers
      );
      const response: AxiosResponse = await this.instance?.get(route, params);
      return this.parseResponse(response, fn);
    } catch (error: any) {
      this.parseError(error);
    }
  };

  post = async (
    route: string,
    body: any,
    fn: (data: any) => void = () => {},
    fail: (error: AxiosError) => void = () => {},
    noRefresh = false
  ) => {
    try {
      await this.addBearerTokenToHeader();
      console.log(
        "[post] request",
        route,
        JSON.stringify(body, null, 4),
        this.instance?.defaults.headers
      );
      const response: AxiosResponse = await this.instance?.post(route, body);
      console.log(
        "[post] result",
        response.data,
        response.status,
        response.statusText
      );
      return this.parseResponse(response, fn);
    } catch (error) {
      this.parseError(error);
    }
  };

  put = async (
    route: string,
    body: any,
    fn: (data: any) => void = () => {},
    fail: (error: AxiosError) => void = () => {},
    noRefresh = false
  ) => {
    try {
      await this.addBearerTokenToHeader();
      console.log(
        "[put] request",
        route,
        JSON.stringify(body, null, 4),
        this.instance?.defaults.headers
      );
      const response: AxiosResponse = await this.instance?.put(route, body);
      return this.parseResponse(response, fn);
    } catch (error: any) {
      this.parseError(error);
    }
  };

  del = async (
    route: string,
    body: any,
    fn: (data: any) => void = () => {},
    fail: (error: AxiosError) => void = () => {},
    noRefresh = false
  ) => {
    try {
      await this.addBearerTokenToHeader();
      console.log(
        "[delete] request",
        route,
        JSON.stringify(body, null, 4),
        this.instance?.defaults.headers
      );
      const response: AxiosResponse = await this.instance?.delete(route, body);
      return this.parseResponse(response, fn);
    } catch (error: any) {
      await this.parseError(error);
    }
  };

  async obtainNewAccessToken() {
    try {
      appEvent("refresh", { isActive: true });
      this.refreshing = true;
      const token = Storage.shared().get(AuthApiService.REFRESH_TOKEN_KEY);
      console.log("[getRefreshedToken] tkn:", !!token);
      if (token) {
        const refreshURL = `${config.REFRESH_TOKEN_EP}/${token}`;
        this.removeBearerToken();
        console.log(
          "[getRefreshedToken] request:",
          refreshURL,
          this.instance?.defaults.headers
        );
        const { data }: AxiosResponse = await this.instance?.get(
          refreshURL,
          {}
        );
        Storage.shared().set(AuthApiService.TOKEN_KEY, data.accessToken);
        Storage.shared().set(
          AuthApiService.REFRESH_TOKEN_KEY,
          data.refreshToken
        );
        console.log("[getRefreshedToken] result:", data);
        appEvent("refresh", { isActive: false });
        this.refreshing = false;
        return data.accessToken;
      } else {
        throw new Error("no-refresh-token");
      }
    } catch (err) {
      console.error("[getRefreshedToken] failed:", err);
      appEvent("refresh", { isActive: false });
      this.refreshing = false;
      if (this.getIsLogoutError(err)) {
        console.error("[getRefreshedToken] expired refresh token:", err);
        appEvent("onAuthorized", { isAuth: false });
        appEvent("auth", { isAuth: false, logouted: true });
      }

      throw err;
    }
  }

  async getRefreshedToken() {
    if (this.refreshPromise && this.refreshing) {
      return await this.refreshPromise;
    } else {
      this.refreshPromise = this.obtainNewAccessToken();
      return await this.refreshPromise;
    }
  }
}

const restApi = new RESTApi();
export default restApi;
