import { useCallback, useState } from "react";
import {
  isMobile,
  isIOS,
  isAndroid,
  isSafari,
  isTablet,
} from "react-device-detect";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import config from "config";
import appStoreIcon from "../../assets/images/app-store.svg";
import googlePlayIcon from "../../assets/images/google-play.svg";
import browserIcon from "../../assets/images/browser.svg";
import "./StorePopover.css";
import { APP_COLORS } from "utils/colors";

function StorePopover() {
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(
    isMobile &&
      (isIOS || isAndroid) &&
      sessionStorage.getItem("selected_app") !== "browser"
  );
  console.log("APP", isTablet, isMobile);
  const onClose = useCallback(() => {
    setShow(false);
  }, []);

  const onContentClick = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const onBrowser = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    sessionStorage.setItem("selected_app", "browser");
    setShow(false);
  }, []);

  const onStore = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    let url = "";

    switch (true) {
      case isAndroid:
        url = config.COMPLIANCEPASS_PLAY_STORE;
        break;
      case isIOS:
        url = config.COMPLIANCEPASS_APP_STORE;
        break;
      case isSafari:
        url = config.COMPLIANCEPASS_APP_STORE;
        break;
      default:
        url = config.COMPLIANCEPASS_APP_STORE;
        break;
    }
    url && window.open(url, "_blank");
  }, []);

  if (!show) {
    return null;
  }

  return (
    <div className='StorePopover' onClick={onClose}>
      <div className='StorePopover-content'>
        <div className='StorePopover-header'>
          {t("curtainTitle", { appname: "CompPass" })}
        </div>
        <div className='StorePopover-list' onClick={onContentClick}>
          <div className='StorePopover-row'>
            <div className='StorePopover-iconContainer'>
              <img
                alt='appStoreIcon'
                className='StorePopover-icon'
                src={isIOS ? appStoreIcon : googlePlayIcon}
              />
              {t("Store.app", { appname: "CompPass" })}
            </div>
            <Button
              shape='round'
              type='primary'
              onClick={onStore}
              style={{
                backgroundColor: APP_COLORS.light.primary,
                color: APP_COLORS.light.primary_text_color,
              }}
            >
              {t("open")}
            </Button>
          </div>
          <div className='StorePopover-row'>
            <div className='StorePopover-iconContainer'>
              <img
                className='StorePopover-icon'
                src={browserIcon}
                alt='StorePopover'
              />
              {t("browser")}
            </div>
            <Button
              shape='round'
              type='default'
              onClick={onBrowser}
              style={{
                color: APP_COLORS.light.primary,
                borderColor: APP_COLORS.light.primary,
              }}
            >
              {t("continue")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StorePopover;
