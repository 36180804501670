import { useCallback, useMemo } from "react";
import groupBy from "lodash/groupBy";
import { database } from "wmelon/database";
import { Q } from "@nozbe/watermelondb";
import { getHASH, SESSION_STATUSES } from "../utils/session";
import Session from "../wmelon/models/Sessions";
import FlightTicket from "../wmelon/models/FlightTicket";
import { TicketStatus, ShareStatus } from "../types/flights";
import { getQueryValue } from "../utils/db";
import { ValidationTicket } from "../redux/features/validation";
import FlightTicketShare from "../wmelon/models/FlightTicketShare";
import {
  getShareStatus,
  getCombinationsPercentageOfMatch,
  getIsVirtual,
} from "utils/tickets";
import { calcPercentageOfMatch } from "../utils/string";
import config from "../config";
import { getArray } from "utils/utils";

function getIsValidTicket(
  ticket: FlightTicket,
  firstName: string | null,
  lastName: string,
  docNumber: string | null
) {
  const isVirtual = getIsVirtual(ticket);

  if (isVirtual) {
    console.log(
      `[useSession].getIsValidTicket check ticket: isV-${isVirtual};`
    );
    return true;
  }

  const docNumberHASH = docNumber ? getHASH(docNumber) : null;

  const isValidDocNumber =
    (!ticket.passenger_document_hash && !ticket.passenger_document) ||
    ticket.passenger_document_hash === docNumberHASH ||
    ticket.passenger_document_hash === docNumber ||
    ticket.passenger_document === docNumber;

  if (!isValidDocNumber) {
    console.log(
      `[useSession].getIsValidTicket check ticket: invalid doc number: ${docNumber}, tktDN-${ticket.passenger_document_hash}`
    );
    return false;
  }

  const isValidFullnameHASH =
    ticket.passenger_firstname_hash === getHASH(firstName || "") &&
    ticket.passenger_lastname_hash === getHASH(lastName);

  if (isValidFullnameHASH) {
    console.log(`[useSession].getIsValidTicket check ticket: valid hash:`);
    return true;
  }

  const docFullName = `${firstName || ""} ${lastName || ""}`
    .trim()
    .toLowerCase();
  const fullName =
    ticket.passenger_firstname || ticket.passenger_lastname
      ? `${ticket.passenger_firstname || ""} ${ticket.passenger_lastname || ""}`
          .trim()
          .toLowerCase()
      : ticket.passenger_firstname_hash || ticket.passenger_lastname_hash
      ? `${ticket.passenger_firstname_hash || ""} ${
          ticket.passenger_lastname_hash || ""
        }`
          .trim()
          .toLowerCase()
      : "";

  if (fullName) {
    if (calcPercentageOfMatch(fullName, docFullName) >= 0.8) {
      console.log(
        `[useSession].getIsValidTicket check ticket: valid fullname perc:`,
        fullName,
        docFullName
      );
      return true;
    }

    return getCombinationsPercentageOfMatch(docFullName, fullName);
  }

  return true;
}

export interface UseSessionResponse {
  getIsAvailableBooking: (
    ticketId: string,
    validationTickets: ValidationTicket[],
    firstName: string | null,
    lastName: string,
    docNumber: string | null
  ) => Promise<{ status: TicketStatus; ticket?: FlightTicket }>;
  getIsBlockedTicket: (
    sessionId: string | null,
    ticketId: string
  ) => Promise<boolean>;
  removeSession: (sessionId: string) => Promise<void>;
}

export function useSession(): UseSessionResponse {
  const getIsBlockedBySession = useCallback((sessions: Session[]): boolean => {
    return (
      sessions.length >= 9 ||
      sessions.some((session: Session) => {
        return (
          [
            SESSION_STATUSES.COMPLETED,
            SESSION_STATUSES.WAIT_FOR_TICKET,
            SESSION_STATUSES.WAIT_FOR_DOC_VALIDATION,
            SESSION_STATUSES.WAIT_FOR_CHECK_VALIDATION,
          ].indexOf(session.status) > -1
        );
      })
    );
  }, []);

  const getTicketByIds = useCallback(
    async (
      ticketIds: string[],
      firstName: string | null,
      lastName: string,
      docNumber: string | null
    ): Promise<{ status: TicketStatus; ticket?: FlightTicket }> => {
      console.log(
        `[useSession].getTicketByIds fn-"${firstName}";ln-"${lastName}";doc-"${docNumber}"`
      );
      const tickets: FlightTicket[] = await database
        .get<FlightTicket>(FlightTicket.table)
        .query(Q.where("id", Q.oneOf(ticketIds)))
        .fetch()
        .catch((err) => {
          console.error(
            "[useSession].getTicketByOwner failed to get by ids",
            err
          );
          return [];
        });

      const validTickets: FlightTicket[] = tickets.filter(
        (ticket: FlightTicket) => {
          return getIsValidTicket(ticket, firstName, lastName, docNumber);
        }
      );
      console.log(
        "VALID_TICKETS:",
        validTickets.map((it) => it.id)
      );
      const sessions = await database
        .get<Session>(Session.table)
        .query(
          Q.where("flight_ticket_id", Q.oneOf(validTickets.map((it) => it.id))),
          Q.sortBy("short_id", Q.desc)
        )
        .fetch()
        .then((list: Session[]) => {
          return groupBy(list ?? [], (s) => s.flight_ticket_id);
        });

      const ticket = validTickets.find(
        (ticket: FlightTicket) =>
          !sessions[ticket.id] || getIsBlockedBySession(sessions[ticket.id])
      );

      return {
        ticket: ticket,
        status: ticket ? TicketStatus.accepted : TicketStatus.denied,
      };
    },
    [getIsBlockedBySession]
  );

  const getTicketById = useCallback(
    async (
      ticketId: string,
      blockList: string[],
      firstName: string | null,
      lastName: string,
      docNumber: string | null
    ) => {
      console.log(
        `[useSession].getTicketById fn-"${firstName}";ln-"${lastName}";doc-"${docNumber}"`
      );
      const ticket: FlightTicket | null = await database
        .get<FlightTicket>(FlightTicket.table)
        .find(ticketId)
        .catch((err) => {
          console.error("[useSession].getTicketById failed to get by id", err);
          return null;
        });

      if (ticket) {
        if (getIsValidTicket(ticket, firstName, lastName, docNumber)) {
          return {
            status: TicketStatus.accepted,
            ticket: ticket,
          };
        }
      }

      const identifiers: string[] = getArray<string>(ticket?.identifiers);

      let identifiersQuery: any[] = [];

      identifiers.forEach((identifier: string) => {
        identifiersQuery.push(
          Q.where(
            "identifiers",
            Q.like(`%"${Q.sanitizeLikeString(identifier)}"%`)
          )
        );
      });

      let tickets: FlightTicket[] = (await database
        .get<FlightTicket>(FlightTicket.table)
        .query(
          Q.or(...identifiersQuery),
          Q.where(
            "flight_date",
            getQueryValue(
              ticket?.flight_date
                ? new Date(ticket?.flight_date).getTime()
                : null
            )
          ),
          Q.where("flight_number", getQueryValue(ticket?.flight_number)),
          Q.where("airline", getQueryValue(ticket?.airline)),
          Q.where("flight_origin", getQueryValue(ticket?.flight_origin)),
          Q.where(
            "flight_destination",
            getQueryValue(ticket?.flight_destination)
          )
        )
        .fetch()) as FlightTicket[];

      tickets = tickets.filter(
        (it) =>
          blockList.indexOf(it.id) === -1 &&
          getIsValidTicket(it, firstName, lastName, docNumber)
      );

      console.log(
        "[useSession].getTicketById tickets:",
        tickets.map((it) => it.id)
      );
      if (!tickets.length) {
        return {
          status: TicketStatus.denied,
        };
      }

      return {
        status: TicketStatus.accepted,
        ticket: tickets[0],
      };
    },
    []
  );

  const getIsAvailableBooking = useCallback(
    (
      ticketId: string,
      validationTickets: ValidationTicket[],
      firstName: string | null,
      lastName: string,
      docNumber: string | null
    ): Promise<{ status: TicketStatus; ticket?: FlightTicket }> => {
      console.log(
        "[useSession].getIsAvailableBooking start:",
        ticketId,
        validationTickets
      );
      return getTicketById(
        ticketId,
        validationTickets
          .filter(
            (it) =>
              !!it.status &&
              it.status !== config.SESSION_STATUS_IDS.WRONG_ETICKET
          )
          .map((it) => it.id),
        firstName,
        lastName,
        docNumber
      ).then((resp: { status: TicketStatus; ticket?: FlightTicket }) => {
        console.log(
          "[useSession].getIsAvailableBooking:",
          resp.status,
          resp.ticket?.id,
          validationTickets.length
        );
        if (
          resp.status !== TicketStatus.accepted &&
          validationTickets.length > 1
        ) {
          return getTicketByIds(
            validationTickets
              .filter(
                (it) =>
                  !it.status ||
                  it.status === config.SESSION_STATUS_IDS.WRONG_ETICKET
              )
              .map((it) => it.id),
            firstName,
            lastName,
            docNumber
          );
        } else {
          return resp;
        }
      });
    },
    [getTicketById, getTicketByIds]
  );

  const getSessionsByTicket = useCallback((ticketId: string) => {
    return database
      .get<Session>(Session.table)
      .query(Q.where("flight_ticket_id", ticketId))
      .fetch();
  }, []);

  const getIsBlockedByShare = useCallback((ticketId: string) => {
    return database
      .get<FlightTicketShare>(FlightTicketShare.table)
      .query(Q.where("flight_ticket_id", ticketId))
      .fetch()
      .then((shares: FlightTicketShare[]) => {
        return getShareStatus(shares) === ShareStatus.shared_by;
      });
  }, []);

  const getIsBlockedTicket = useCallback(
    (sessionId: string | null, ticketId: string): Promise<boolean> => {
      return getIsBlockedByShare(ticketId)
        .then((isBlocked: boolean) => {
          if (isBlocked) {
            return true;
          }

          return getSessionsByTicket(ticketId).then((sessions: Session[]) =>
            getIsBlockedBySession(sessions.filter((it) => it.id !== sessionId))
          );
        })
        .catch((err: Error) => {
          console.error("[useETicketSessions] failed to load sessions:", err);
          return err.message !== "not_found";
        });
    },
    [getIsBlockedBySession, getSessionsByTicket, getIsBlockedByShare]
  );

  const removeSession = useCallback((id: string) => {
    return database.write(() => {
      return database
        .get<Session>(Session.table)
        .find(id)
        .then((session) => {
          return session.destroyPermanently();
        });
    });
  }, []);

  return useMemo(
    () => ({
      getIsAvailableBooking,
      getIsBlockedTicket,
      removeSession,
    }),
    [getIsAvailableBooking, getIsBlockedTicket, removeSession]
  );
}
