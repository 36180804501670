import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import "./style.css";
import FileViewer from "components/FileViewer";
import Gallery from "components/Gallery";
import { ReduxState } from "redux/ReduxState";
import {
  ReduxDocumentFile,
  ReduxDocumentFileClone,
} from "redux/features/documents";
import { getFullFilePath } from "utils/file";

function DocumentGallery({
  documentId,
  containerStyle,
}: {
  documentId: string;
  containerStyle: any;
}) {
  const document = useSelector((state: ReduxState) =>
    state.documents.items.find((it) => it.id === documentId)
  );

  const [showGallery, setShowGallery] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<number>(0);

  const handleSelectFile = useCallback((index: number) => {
    setSelectedFile(index);
  }, []);

  const hanldeClose = useCallback(() => {
    setShowGallery(false);
    setSelectedFile(0);
  }, []);

  const images: (string | Blob)[] = useMemo(() => {
    if (!document) {
      return [];
    }

    return document.files.map(
      (it: ReduxDocumentFile | ReduxDocumentFileClone): string | Blob => {
        if ("link" in it) {
          return getFullFilePath(it.link);
        }
        return it.data;
      }
    );
  }, [document]);

  if (!images.length) {
    return null;
  }

  return (
    <div className="DocumentGallery">
      {images.map((image, index: number) => (
        <FileViewer
          key={index}
          file={image}
          size="medium"
          canRemove={false}
          onClick={() => {
            setShowGallery(true);
            handleSelectFile(index);
          }}
        />
      ))}

      <Gallery
        visible={showGallery}
        files={images}
        onClose={hanldeClose}
        selectedFile={selectedFile}
      />
    </div>
  );
}

export default DocumentGallery;
