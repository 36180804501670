import { useCallback, useContext, useEffect, useMemo } from "react";
import User from "wmelon/models/User";
import { Q } from "@nozbe/watermelondb";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import debounce from "lodash/debounce";
import { database } from "wmelon/database";
import { Col, Row, notification } from "antd";
import { useTranslation } from "react-i18next";
import UserPicture from "./UserPicture";
import UserInfo from "./UserInfo";
import Other from "./Other";
// import Tag from "wmelon/models/Tag";
import Buttons from "./Buttons";
import ModalConfirm from "./ModalConfirm";
import AuthorizedContext from "contexts/AuthorizedContext";
import { UserSchema } from "./Shema";
import Storage from "core/Storage/Storage";
import { useToggle } from "hooks/useToggle";
import { resetDB } from "utils/db";
import AuthApiService from "utils/AuthApi";
import "./style.css";
import { syncBack } from "wmelon/sync";
import { withObservables } from "@nozbe/watermelondb/react";
import { ReduxState } from "redux/ReduxState";
import { Observable } from "@nozbe/watermelondb/utils/rx";

interface IProps {
  userId: string | null;
  users: User[];
  onGetUserId: () => void;
  onClickTags: () => void;
}
const NOTIFICATION_KEY = "PROFILE_NOTIFICATION";
const UserForm = ({ userId, onGetUserId, users, onClickTags }: IProps) => {
  const { t } = useTranslation();
  const [isToggled, toggle] = useToggle(false);

  const { setAuthorized } = useContext(AuthorizedContext);
  const isSyncing = useSelector((state: ReduxState) => state.app.syncing);
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const user = users[0];

  const initialValues = useMemo(() => {
    if (!user) {
      return {
        avatar: null,
        name: "",
        surname: "",
        dob: "",
        mobile: "",
      };
    }
    return {
      avatar: user.avatar,
      name: user.name,
      surname: user.surname,
      dob: user.dob,
      mobile: userId,
    };
  }, [user, userId]);
  const callSync = useCallback(
    debounce(() => {
      syncBack(AuthApiService.shared().getSessionId()).catch((err) =>
        console.error("[UserForm] failed to sync:", err)
      );
    }, 10000),
    []
  );
  const onUpdateUser = useCallback(
    async (values: any) => {
      console.log("[UserForm] update user:", !!user, values);
      if (!user) {
        return Promise.resolve();
      }

      return database
        .write(() => {
          return user.update(() => {
            user.avatar = values.avatar;
            user.name = values.name;
            user.surname = values.surname;
            user.dob = values.dob;
          });
        })
        .then(async () => {
          await callSync();
        })
        .catch((err) => {
          console.error("[UserForm] failed to save user:", err);
          api.info({
            key: NOTIFICATION_KEY,
            message: t("Info"),
            description: t("Profile.failedToSave"),
            placement: "topRight",
          });
        });
    },
    [user, t, api, callSync]
  );

  useEffect(() => {
    if (isSyncing) {
      callSync.cancel();
    }
  }, [isSyncing]); // eslint-disable-line

  const {
    values,
    setFieldValue,
    touched,
    setFieldTouched,
    errors,
    setSubmitting,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: UserSchema,
    onSubmit: onUpdateUser,
  });

  const callAutoSubmit = useCallback(
    debounce((values) => {
      setSubmitting(true);
      onUpdateUser({ ...values }).then(() => setSubmitting(false));
    }, 150),
    [onUpdateUser]
  );

  useEffect(() => {
    if (!Object.keys(errors).length) {
      callAutoSubmit(values);
    }
  }, [values, callAutoSubmit, errors]);

  const onShowAlert = useCallback(() => {
    api.info({
      key: NOTIFICATION_KEY,
      message: t("Profile.userInfo"),
      placement: "topRight",
    });
    onGetUserId();
    console.error(
      `[UserForm] failed to change form: id-"${userId}";user-`,
      user
    );
  }, [t, userId, user, onGetUserId, api]);

  const onLogout = useCallback(async () => {
    try {
      await resetDB();
      Storage.shared().clear([
        AuthApiService.TOKEN_KEY,
        AuthApiService.REFRESH_TOKEN_KEY,
      ]);
      setAuthorized(false);
      navigate("/auth");
    } catch (error) {
      console.log("[UserForm] logout", error);
    }
  }, [setAuthorized, navigate]);

  return (
    <div className="UserForm" onClick={() => (!user ? onShowAlert() : null)}>
      <Row justify={"space-between"}>
        <Col xs={24} md={12} xl={12}>
          <div className="userPicture">
            <UserPicture onChange={setFieldValue} value={values.avatar} />
          </div>
        </Col>
        <Col xs={24} md={12} xl={12}>
          <UserInfo
            values={values}
            errors={errors}
            touched={touched}
            onChange={setFieldValue}
            onBlur={setFieldTouched}
            disabled={false}
          />
          <Other click={onClickTags} />
          <Buttons onDelete={() => {}} onLogout={toggle} />
        </Col>
      </Row>
      <ModalConfirm isToggled={isToggled} toggle={toggle} onLogout={onLogout} />
      {contextHolder}
    </div>
  );
};

const enhance = withObservables<
  Omit<IProps, "users">,
  { users: Observable<User[]> }
>(["userId"], ({ userId }: { userId: string | null }) => {
  userId = userId || null;
  return {
    users: database
      .get<User>(User.table)
      .query(Q.where("id", userId), Q.take(1))
      .observe(),
  };
});

const EnhancedUserForm = enhance(UserForm);
export default EnhancedUserForm;
