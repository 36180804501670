import { isSafari, isIOS } from "react-device-detect";

export function getBase64HASH(data: string): string {
  const binary_string = window.atob(data);
  const len = binary_string.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i += 1) {
    bytes[i] = binary_string.charCodeAt(i);
  }

  // return sha1(WordArray.create(Array.from(bytes))).toString(Hex)
  return btoa(String.fromCharCode.apply(null, bytes as any));
}

export function setFocus(id: string) {
  const input = document.getElementById(id);
  input && input.focus();

  if (input) {
    if (isSafari && isIOS) {
      const tempElem = document.createElement("input");
      tempElem.style.position = "absolute";
      tempElem.style.top = input.offsetTop + 7 + "px";
      tempElem.style.left = input.offsetLeft + "px";
      tempElem.style.height = "0";
      tempElem.style.opacity = "0";
      document.body.appendChild(tempElem);
      tempElem.focus();

      setTimeout(function () {
        input.focus();
        input.click();
        document.body.removeChild(tempElem);
      }, 300);
    } else {
    }
    input.focus();
  }
}

export function getParsedUrl(url: string): {
  provider: string | null;
  pnr: string | null;
  share_token: string | null;
} {
  const parts = url.split("/").filter(Boolean);

  if (!parts.length) {
    throw new Error("INVALID_URL_DESC");
  }

  if (parts.includes("share")) {
    const shareToken = parts.pop();

    if (!shareToken) {
      throw new Error("INVALID_SHARE_URL_DESC");
    }

    return {
      provider: null,
      pnr: null,
      share_token: shareToken || null,
    };
  } else if (parts.includes("pnr")) {
    const path = parts.pop();

    if (!path) {
      throw new Error("INVALID_URL_DESC");
    }

    const [provider, pnr] = path.split("_").filter(Boolean);

    if (!provider || !pnr) {
      throw new Error("INVALID_URL_DESC");
    }

    return {
      provider,
      pnr,
      share_token: null,
    };
  } else {
    throw new Error("INVALID_URL_DESC");
  }
}

export function getIsPDF(file: Blob | string) {
  if (!file) {
    return false;
  }

  if (file && file instanceof Blob) {
    return /application\/pdf/.test(file.type);
  }

  if (typeof file === "string") {
    return file.startsWith("data:application/pdf") || file.endsWith(".pdf");
  }

  return false;
}

export function getArray<T>(value: string | T[] | undefined): T[] {
  if (!value) {
    return [];
  }

  if (Array.isArray(value)) {
    return value;
  }

  try {
    return JSON.parse(value);
  } catch (err) {
    return [];
  }
}
