import { IStorage } from './IStorage';

export default class Storage implements IStorage {
  static shared = () => new Storage();

  get(key: string) {
    try {
      const value = window.localStorage ? window.localStorage.getItem(key) : null;
      return value ? JSON.parse(value) : null
    } catch (error) {
      console.error(`[Storage] Error reading item with key "${key}":`, error)
      return null
    }
  }

  set(key: string, value: any) {
    try {
      window.localStorage?.setItem(key, JSON.stringify(value));
      console.log(`Item with key "${key}" created successfully.`)
    } catch (error) {
      console.error(`[Storage] Error creating item with key "${key}":`, error)
    }
  }

  delete(key: string) {
    try {
      window.localStorage?.removeItem(key);
      console.log(`Item with key "${key}" deleted successfully.`)
    } catch (error) {
      console.error(`[Storage] Error deleting item with key "${key}":`, error)
    }
  }
  clear(keys: string[]) {
    try {
      (keys || []).forEach((key) => {
        window.localStorage?.removeItem(key)
      })
    } catch (error) {
      console.error(`[Storage] Error clearing storage:`, error)
    }

  }
}