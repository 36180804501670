import { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { RegistryWidgetsType, RegistryFieldsType, UiSchema } from "@rjsf/utils";
import Form from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import DateWidget from "./controls/DateWidget";
import TextWidget from "./controls/TextWidget";
import CheckboxWidget from "./controls/CheckboxWidget";
import SelectWidget from "./controls/SelectWidget";
import BooleanField from "./controls/BooleanField";
import TitleField from "./controls/TitleField";
import { getIsVisibleField, getOptionsByUiScheme } from "utils/jsonform";
import { fieldNameToLabel } from "utils/string";

const widgets: RegistryWidgetsType = {
  DateWidget,
  TextWidget,
  CheckboxWidget,
  SelectWidget,
};

const fields: RegistryFieldsType = {
  BooleanField,
  TitleField,
};

interface FormRendererProps {
  jsonschema: any;
  uischema: any;
  value: any;
  readonlyFields: string[];
  onChange: (value: {
    edit: boolean;
    errorSchema: any;
    errors: any[];
    formData: any;
    idSchema: any;
    schema: any;
    uiSchema: any;
  }) => void;
  onSubmit: () => void;
  isQuestions?: boolean;
}

function FormRenderer({
  jsonschema,
  uischema,
  value,
  readonlyFields,
  onChange,
  onSubmit,
  isQuestions,
}: FormRendererProps) {
  const { t } = useTranslation();

  const formRef = useRef<Form>();

  const uiSchema: UiSchema = useMemo(() => {
    let ui = {};
    const fields = Object?.keys(jsonschema?.properties);
    const readOnly: any = readonlyFields?.map((it) =>
      it.split(".").pop()
    ) as any;

    if (fields.length) {
      fields.forEach((field) => {
        const options = getOptionsByUiScheme(uischema, field, readOnly);
        const isVisibleField = getIsVisibleField(uischema, field);
        ui = {
          ...ui,
          [field]: {
            "ui:label": false,
            ...{ "ui:options": { ...options, hidden: !isVisibleField } },
          },
        };
      });
    }

    return ui;
  }, [jsonschema, uischema, readonlyFields]);

  const jsonSchema = useMemo(() => {
    if (isQuestions) {
      let schema = jsonschema;
      const fields = Object?.keys(schema?.properties);
      if (!!fields.length) {
        fields.forEach((field) => {
          schema.properties[field] = {
            ...schema.properties[field],
            default: false,
          };
        });
      }
      return schema;
    } else {
      return jsonschema;
    }
  }, [isQuestions, jsonschema]);

  const transformErrors = useCallback(
    (errors) => {
      return errors.map((error) => {
        if (error.name === "required") {
          error.message = t("Validator.required", {
            name: fieldNameToLabel(error.property),
          });
        }
        return error;
      });
    },
    [t]
  );

  const customValidate = useCallback(
    (formData, errors, uiSchema) => {
      const fields = Object.keys(formData);
      fields.forEach((field: string) => {
        if (
          field.toLowerCase().includes("birth") &&
          !uiSchema[field]["ui:options"].hidden
        ) {
          if (
            formData[field] &&
            moment(formData[field]).isSameOrAfter(moment())
          ) {
            errors[field].addError(
              t("Validator.invalidDate", { name: fieldNameToLabel(field) })
            );
          }
        }

        if (
          field.toLowerCase().includes("expiry") &&
          !uiSchema[field]["ui:options"].hidden
        ) {
          if (
            formData[field] &&
            moment(formData[field]).isSameOrBefore(moment())
          ) {
            errors[field].addError(
              t("Validator.invalidDate", { name: fieldNameToLabel(field) })
            );
          }
        }
      });
      return errors;
    },
    [t]
  );

  const setRef = useCallback((ref) => {
    formRef.current = ref;
  }, []);

  useEffect(() => {
    isQuestions && formRef.current && formRef.current.reset();
  }, []);

  return (
    <Form
      ref={setRef}
      id="form"
      schema={jsonSchema}
      uiSchema={uiSchema}
      validator={validator}
      widgets={widgets}
      fields={fields}
      formData={value}
      onChange={(e: any) => {
        onChange(e);
      }}
      showErrorList={false}
      onSubmit={onSubmit}
      customValidate={customValidate}
      children={true}
      liveValidate={!isQuestions}
      transformErrors={transformErrors}
    />
  );
}

export default FormRenderer;
