import { useEffect, useCallback } from "react";
import { useAppDispatch } from "redux/store";
import { setRefreshing } from "redux/features/app";

export interface IProps {
  onLogout: () => void;
  isAuthorized: boolean;
}

const RefreshProvider = () => {
  const dispatch = useAppDispatch();

  const onHandleRefreshEvent = useCallback(
    (event: any) => {
      dispatch(setRefreshing(event?.detail?.data?.isActive));
    },
    [dispatch]
  );

  useEffect(() => {
    if (document) {
      document.addEventListener("refresh", onHandleRefreshEvent);

      return () => {
        document.removeEventListener("refresh", onHandleRefreshEvent);
      };
    }
  }, [onHandleRefreshEvent]);

  return null;
};

export default RefreshProvider;
