import { Model } from '@nozbe/watermelondb'
import { field } from '@nozbe/watermelondb/decorators'

export default class SchemaVaccines extends Model {
  static table = 'schemaVaccines'

  @field('key') key!: string
  @field('display') display!: string
  @field('lang') lang!: string
  @field('active') active!: string
  @field('system') system!: string
  @field('version') version!: string
  @field('field') field!: string
}
