import  { useCallback, useEffect, useRef } from "react";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import { Progress } from "antd";
import uploadCenter from "../../assets/animation/upload_center.json";

import "./AnimatedPreloader.css";

export interface AnimatedPreloaderProps {
  percent: number;
  isSuccess?: boolean;
  showUpload: boolean;
}

export default function AnimatedPreloader({
  percent,
  isSuccess,
  showUpload,
}: AnimatedPreloaderProps) {
  const lottieCenterRef = useRef<LottieRefCurrentProps | null>(null);

  const isFinish = percent >= 100;

  const playFinishAnimation = useCallback(() => {
    if (isSuccess) {
      lottieCenterRef.current?.playSegments([60, 100], true);
      setTimeout(() => {
        lottieCenterRef && lottieCenterRef?.current?.pause();
      }, 600);
    } else {
      lottieCenterRef.current?.playSegments([180, 220], true);
      setTimeout(() => {
        lottieCenterRef && lottieCenterRef?.current?.pause();
      }, 600);
    }
  }, [isSuccess]);

  const playArrowAnimation = useCallback(() => {
    setTimeout(() => {
      lottieCenterRef && lottieCenterRef?.current?.play();
    }, 600);
    lottieCenterRef.current?.playSegments([0, 60], true);
  }, []);

  useEffect(() => {
    if (isFinish) {
      playFinishAnimation();
    } else {
      playArrowAnimation();
    }
  }, [isFinish, playArrowAnimation, playFinishAnimation]);

  return (
    <div className='AnimatedPreloader'>
      <div style={{ position: "relative" }}>
        <Progress
          className='AnimatedPreloader-progress'
          size={200}
          status='active'
          type='circle'
          percent={showUpload ? percent : 0}
          format={() => (
            <div className='AnimatedPreloader-animation-container'>
              <Lottie
                className='AnimatedPreloader-animation'
                lottieRef={lottieCenterRef}
                animationData={uploadCenter}
                autoplay={false}
              />
            </div>
          )}
        />
      </div>
    </div>
  );
}
