const time_period = 3000; //ms

const PV_API = pageVisibilityAPICheck();
console.log("PV_API:", PV_API);
function pageVisibilityAPICheck() {
  let pageVisibilityAPI: string | boolean = false;
  let visibilityChangeHandler: string | null = null;
  if ("hidden" in document) {
    pageVisibilityAPI = "hidden";
    visibilityChangeHandler = "visibilitychange";
  } else {
    const prefixes = ["webkit", "moz", "ms", "o"];

    for (let i = 0; i < prefixes.length; i++) {
      if (prefixes[i] + "Hidden" in document) {
        pageVisibilityAPI = prefixes[i] + "Hidden";
        visibilityChangeHandler = prefixes[i] + "visibilitychange";
      }
    }
  }

  if (typeof pageVisibilityAPI !== "string") {
    return { hidden: null, handler: null };
  }

  return { hidden: pageVisibilityAPI, handler: visibilityChangeHandler };
}

class TabsManager {
  intervalId: NodeJS.Timer;
  currentWindowId = Date.now().toString();

  constructor() {
    globalThis.tabsManager = tabsManager;
    this.checkTabState();
    this.intervalId = setInterval(() => {
      this.checkCurrentWindowStatus();
    }, time_period);

    this.checkCurrentWindowStatus();
    this.addToMainWindowsList(this.currentWindowId);
    localStorage.active_window = this.currentWindowId;

    if (PV_API && typeof PV_API.handler === "string") {
      document.addEventListener(
        PV_API.handler,
        () => {
          if (typeof PV_API.hidden === "string" && document[PV_API.hidden]) {
            this.removeFromActiveWindows(this.currentWindowId);
          } else {
            this.checkCurrentWindowStatus();
          }
        },
        false
      );
    }

    this.addToMainWindowsList(this.currentWindowId);
    localStorage.active_window = this.currentWindowId;

    window.addEventListener("beforeunload", () => {
      this.removeFromMainWindowsList(this.currentWindowId);
    });
  }

  checkCurrentWindowStatus() {
    this.checkTabState();

    if (PV_API) {
      let activeStatus: string = "Inactive";
      var windows_list = this.getMainWindowsList();

      var active_windows_list = this.getActiveWindowsList();

      if (windows_list.indexOf(localStorage.active_window) < 0) {
        localStorage.active_window = windows_list[windows_list.length - 1];
      }

      if (PV_API.hidden && !document[PV_API.hidden]) {
        localStorage.active_window = this.currentWindowId;
      }

      if (localStorage.active_window === this.currentWindowId) {
        activeStatus = "Active";
      }

      if (activeStatus === "Active") {
        active_windows_list = this.addToActiveWindows(this.currentWindowId);
      } else {
        active_windows_list = this.removeFromActiveWindows(
          this.currentWindowId
        );
      }

      console.log("[checkCurrentWindowStatus]", active_windows_list);
    } else {
      console.log("PageVisibility API is not supported :(");
      //our INACTIVE pages, will remain INACTIVE forever, you need to make some action in this case!
    }

    localStorage.last_update = Date.now();
  }

  checkTabState() {
    if (localStorage.last_update) {
      if (parseInt(localStorage.last_update) + time_period * 2 < Date.now()) {
        console.log("RELOAD_PAGE:");
        localStorage.removeItem("main_windows");
        localStorage.removeItem("actived_windows");
        localStorage.removeItem("active_window");
        localStorage.removeItem("last_update");
        // window.location.reload();
        // globalThis.location.reload();
      }
    }
  }

  addToMainWindowsList(windowId: string) {
    let result = this.getMainWindowsList();
    var index = result.indexOf(windowId);

    if (index < 0) {
      //this windows is not in the list currently
      result.push(windowId);
    }

    localStorage.main_windows = result.join(",");

    return result;
  }

  getMainWindowsList() {
    let result: string[] = [];
    if (localStorage.main_windows) {
      result = localStorage.main_windows.split(",");
    }

    return result;
  }

  removeFromMainWindowsList(windowId: string) {
    let result: string[] = [];
    if (localStorage.main_windows) {
      result = localStorage.main_windows.split(",");
    }

    let index = result.indexOf(windowId);
    if (index > -1) {
      result.splice(index, 1);
    }

    localStorage.main_windows = result.join(",");

    this.removeFromActiveWindows(windowId);

    return result;
  }

  getActiveWindowsList() {
    let result: string[] = [];
    if (localStorage.actived_windows) {
      result = localStorage.actived_windows.split(",");
    }

    return result;
  }

  removeFromActiveWindows(windowId: string) {
    let result = this.getActiveWindowsList();

    let index = result.indexOf(windowId);
    if (index > -1) {
      result.splice(index, 1);
    }

    localStorage.actived_windows = result.join(",");

    return result;
  }

  addToActiveWindows(windowId: string) {
    let result = this.getActiveWindowsList();

    var index = result.indexOf(windowId);

    if (index < 0) {
      //this windows is not in active list currently
      result.push(windowId);
    }

    localStorage.actived_windows = result.join(",");

    return result;
  }
}

let tabsManager;

export default function getTabsManager() {
  if (!tabsManager) {
    tabsManager = new TabsManager();
  }
  globalThis.tabsManager = tabsManager;
  return tabsManager;
}
