import {
  synchronize,
  SyncPullResult,
  SyncTableChangeSet,
} from "@nozbe/watermelondb/sync";
import { getLastPulledAt } from "@nozbe/watermelondb/sync/impl";
import uniqBy from "lodash/uniqBy";
import omit from "lodash/omit";
import { database } from "./database";
import restApi from "utils/RESTApi";
import config from "config";
import { generalAPI } from "services/base/api";
import { Q, DirtyRaw } from "@nozbe/watermelondb";
import { PushData, PullData } from "types/db";
import Document from "wmelon/models/Document";
import { appEvent } from "utils/customEvent";
import { uniq } from "lodash";
import Session from "./models/Sessions";

const MAX_RESYNC_ATTEMPTS = 4;

function mapSyncedDocument(doc: DirtyRaw) {
  return {
    ...doc,
    files: doc.files ? JSON.stringify(doc.files) : "[]",
  };
}

function mapSyncedForm(item: DirtyRaw) {
  return {
    ...item,
    id: item.id.toString(),
  };
}

function mapSyncedFlightTicket(ticket: DirtyRaw) {
  return {
    ...ticket,
    identifiers:
      typeof ticket.identifiers === "string"
        ? ticket.identifiers
        : JSON.stringify(ticket.identifiers),
    flight_date: new Date(ticket?.flight_date).getTime(),
  };
}

function mapDocument(it: DirtyRaw) {
  const resp: DirtyRaw = {
    ...it,
    ...(typeof it.files === "string" ? { files: JSON.parse(it.files) } : {}),
  };

  ["verified_manual", "verified_auto", "auto_verification_result"].forEach(
    (field: string) => {
      delete resp[field];
    }
  );
  return database
    .get<Session>(Session.table)
    .query(Q.where("id", it.session_id))
    .then((sessions) => {
      resp["flight_ticket_id"] = sessions[0]?.flight_ticket_id || "";
      console.log(
        "[mapDocument]",
        sessions.length,
        sessions[0]?.flight_ticket_id,
        it.session_id
      );
      return resp;
    });
}

function mapNotificationUpdate(it: DirtyRaw) {
  return {
    id: it.id,
    _status: it._status,
    _changed: it._changed,
    read_at: it.read_at,
  };
}

function mapNotification(it: DirtyRaw) {
  return {
    id: it.id,
    _status: it._status,
    _changed: it._changed,
  };
}

function mapSessionCreate(it: DirtyRaw) {
  let transit = [];

  if (typeof it.transit === "string") {
    transit = JSON.parse(it.transit);
  }

  const resp: DirtyRaw = { ...it, transit, assign_to: null };
  delete resp.created_at;
  delete resp.handler;
  delete resp.provider;
  return resp;
}

function mapSessionUpdate(it: DirtyRaw) {
  return {
    id: it.id,
    _status: it._status,
    _changed: it._changed,
    status: it.status,
    owner_first_name: it.owner_first_name,
    owner_last_name: it.owner_last_name,
    owner_dob: it.owner_dob,
  };
}

async function tusSyncBack(documentIds: string[]) {
  let unloadedDocuments = await database
    .get<Document>(Document.table)
    .query(Q.on("files", Q.where("data", Q.notEq(null))))
    .fetchIds();

  unloadedDocuments = unloadedDocuments.sort((a: string, b: string) => {
    return (
      Math.max(documentIds.indexOf(a), Number.MAX_SAFE_INTEGER) -
      Math.max(documentIds.indexOf(b), Number.MAX_SAFE_INTEGER)
    );
  });

  let ids: string[] = uniq(Array.from(unloadedDocuments));

  const handleProgress = (
    documentId: string,
    page: number,
    bytesUploaded: number,
    bytesTotal: number
  ) => {
    appEvent("uploadState", {
      documentId: documentId,
      page: page,
      bytesUploaded: bytesUploaded,
      bytesTotal: bytesTotal,
    });
  };

  console.log(
    "[tusSyncBack] new ids:",
    ids,
    ";unloadedDocuments-",
    unloadedDocuments
  );
  if (ids.length > 0) {
    const documents: Document[] = (await database
      .get<Document>(Document.table)
      .query(Q.where("id", Q.oneOf(ids)))
      .fetch()) as Document[];

    try {
      console.log("tusSyncBack documents -------------------", documents);
      await Promise.all(
        documents.map((doc) => generalAPI.uploadDocument(doc, handleProgress))
      );
    } catch (err) {
      console.error("[tusSyncBack] Err", err);
    }
  }
}

function getWithoutDuplicates(table: string, created: DirtyRaw[]) {
  if (!created.length || !database.get(table)) {
    return Promise.resolve(uniqBy(created, "id"));
  }

  const ids = created.map((it) => it.id);
  return database
    .get(table)
    .query(Q.where("id", Q.oneOf(ids)))
    .fetchIds()
    .then((dbIDS) => {
      if (!dbIDS.length) {
        return uniqBy(created, "id");
      }

      return uniqBy(created, "id").filter((it) => !dbIDS.includes(it.id));
    });
}

async function mapPullChanges(
  lastPulledAt: number | undefined,
  changes: PullData,
  timestamp: number
) {
  console.log(
    "[syncBack] pull:docs-cr",
    (changes.documents?.created || [])
      .map((it) => `${it.id};s-${it.session_id};t-${it.type}`)
      .join("|")
  );
  console.log(
    "[syncBack] pull:docs-up",
    (changes.documents?.updated || [])
      .map((it) => `${it.id};s-${it.session_id};t-${it.type}`)
      .join("|")
  );
  console.log(
    "[syncBack] pull:session-cr",
    (changes.sessions?.created || [])
      .map(
        (it) =>
          `${it.id};sid-${it.short_id};t-${it.flight_ticket_id};s-${it.status}`
      )
      .join("|")
  );
  console.log(
    "[syncBack] pull:session-up",
    (changes.sessions?.updated || [])
      .map(
        (it) =>
          `${it.id};sid-${it.short_id};t-${it.flight_ticket_id};s-${it.status}`
      )
      .join("|")
  );
  const result: SyncPullResult = {
    changes: {
      ...omit(changes, ["pnrFlights"]),
      forms: {
        ...changes.forms,
        created: uniqBy(changes.forms.created, "id").map(mapSyncedForm),
        updated: uniqBy(changes.forms.updated, "id").map(mapSyncedForm),
      },
      ...(changes.documents
        ? {
            documents: {
              ...changes.documents,
              created: changes.documents.created.map(mapSyncedDocument),
              updated: changes.documents.updated.map(mapSyncedDocument),
            },
          }
        : {}),
      ...(changes.flightTickets
        ? {
            flightTickets: {
              ...changes.flightTickets,
              created: changes.flightTickets.created.map(mapSyncedFlightTicket),
              updated: changes.flightTickets.updated.map(mapSyncedFlightTicket),
            },
          }
        : {}),
    },
    timestamp,
  };

  if (!lastPulledAt) {
    for (let table in result.changes) {
      result.changes[table].created = uniqBy(
        result.changes[table].created,
        "id"
      );
    }
    return result;
  }

  for (let table in result.changes) {
    (result.changes as unknown as PullData)[table as keyof PullData].created =
      (await getWithoutDuplicates(
        table,
        (result.changes as unknown as PullData)[table as keyof PullData].created
      )) as SyncTableChangeSet["created"];
  }

  return result;
}

async function mapPushChanges(changes: PushData, device_id: string | null) {
  const documentChanges = changes.documents;
  const sessionChanges = changes.sessions;
  const notificationChanges = changes.notifications;
  const result = {
    device_id,
    changes: {
      notifications: {
        ...notificationChanges,
        created: notificationChanges.created.map(mapNotification),
        updated: notificationChanges.updated.map(mapNotificationUpdate),
      },
      tagBinds: changes.tagBinds,
      tags: changes.tags,
      users: changes.users,
      documents: {
        ...documentChanges,
        created: await Promise.all(documentChanges.created.map(mapDocument)),
        updated: await Promise.all(documentChanges.updated.map(mapDocument)),
      },
      sessions: {
        ...sessionChanges,
        created: sessionChanges.created.map(mapSessionCreate),
        updated: sessionChanges.updated.map(mapSessionUpdate),
      },
    },
  };
  console.log("[sync] push:", JSON.stringify(result));
  return result;
}

let isSyncing = false;
let failedSyncCount = 0;

async function syncBack(deviceId: string, resync: boolean = true) {
  if (isSyncing) {
    console.log("[syncBack] is already syncing");
    return;
  }

  if (
    globalThis.tabsManager &&
    globalThis.tabsManager.currentWindowId !== localStorage.active_window
  ) {
    console.log(
      `[syncBack] not main window: current-"${globalThis.tabsManager.currentWindowId}"; active-`,
      localStorage.active_window
    );
    return;
  }
  console.log(
    `[syncBack] current-"${globalThis.tabsManager?.currentWindowId}"; active-`,
    localStorage.active_window
  );

  appEvent("syncing", { active: true, failed: false });

  let ts = await getLastPulledAt(database);

  const syncProgress = (stage) => {
    appEvent("syncStage", stage);
  };

  isSyncing = true;
  let documentIds: string[] = [];

  try {
    syncProgress("start");
    await synchronize({
      database,

      pullChanges: async ({ lastPulledAt, schemaVersion, migration }) => {
        appEvent("syncing", { active: true, lastPulledAt });
        syncProgress("pull_1");
        try {
          console.log("PULL:", lastPulledAt);
          const response = await restApi.get(`${config.SYNC_PULL_EP}`, {
            params: {
              last_pulled_at: JSON.stringify(lastPulledAt),
              schema_version: schemaVersion,
              migration: JSON.stringify(migration),
              ...(deviceId ? { device_id: deviceId } : null),
            },
          });
          console.log("lastPulledAt", JSON.stringify(lastPulledAt));

          const { changes, timestamp } = response;
          // console.log('[synckBack] Response', JSON.stringify(response, null, 4))
          return mapPullChanges(lastPulledAt, changes, timestamp);
        } catch (error: any) {
          console.error("[syncBack] pullChanges#1 failed:", error);
          throw new Error(error.message, {
            cause: { status: error.cause.status },
          });
        }
      },
      pushChanges: async ({ changes, lastPulledAt }) => {
        try {
          syncProgress("push_1");
          await restApi.post(
            `${config.SYNC_PUSH_EP}?last_pulled_at=${lastPulledAt}`,
            await mapPushChanges(changes as unknown as PushData, deviceId)
          );
          documentIds = (changes as any).documents.created.map((d) => d.id);
        } catch (error: any) {
          console.error(
            "syncBack pushChanges -------------------",
            error,
            error?.response?.data
          );
          throw new Error(error.message, {
            cause: { status: error.cause.status },
          });
        }
      },
      migrationsEnabledAtVersion: 3,
    });

    try {
      await synchronize({
        database,

        pullChanges: async ({ lastPulledAt, schemaVersion, migration }) => {
          try {
            syncProgress("pull_2");
            const response = await restApi.get(`${config.SYNC_PULL_EP}`, {
              params: {
                last_pulled_at: JSON.stringify(lastPulledAt),
                schema_version: schemaVersion,
                migration: JSON.stringify(migration),
                ...(deviceId ? { device_id: deviceId } : null),
              },
            });

            console.log("lastPulledAt", JSON.stringify(lastPulledAt));

            const { changes, timestamp } = response;
            // console.log('Response', JSON.stringify(response, null, 4))

            return mapPullChanges(lastPulledAt, changes, timestamp);
          } catch (error: any) {
            console.error("syncBack pullChanges -------------------", error);
            // throw new Error(error?.response?.data?.message);
            throw new Error(error.message, {
              cause: { status: error.cause.status },
            });
          }
        },
        pushChanges: async ({ changes, lastPulledAt }) => {
          try {
            syncProgress("push_2");
            await restApi.post(
              `${config.SYNC_PUSH_EP}?last_pulled_at=${lastPulledAt}`,
              await mapPushChanges(changes as unknown as PushData, deviceId)
            );
            ts = lastPulledAt;
            documentIds = [
              ...documentIds,
              ...(changes as any).documents.created.map((d) => d.id),
            ];
          } catch (error: any) {
            console.error(
              "syncBack pushChanges -------------------",
              error,
              error?.response?.data
            );
            // throw new Error(error?.response?.data?.message);
            throw new Error(error.message, {
              cause: { status: error.cause.status },
            });
          }
        },
        migrationsEnabledAtVersion: 3,
      });
    } catch (err) {
      syncProgress("push_2");
      console.error("[syncBack] second sync failed:", err);
    }

    syncProgress("upload");
    await tusSyncBack(uniq(documentIds));

    isSyncing = false;
    appEvent("syncing", { active: false, lastPulledAt: ts });
    appEvent("lastPulledAt", { lastPulledAt: ts });
    appEvent("resyncing", { isResyncing: false, error: false });
  } catch (err: any) {
    isSyncing = false;

    if (err?.cause?.status === 500 && resync) {
      if (failedSyncCount <= MAX_RESYNC_ATTEMPTS) {
        appEvent("resyncing", { isResyncing: true, error: false });
        failedSyncCount = failedSyncCount + 1;
      } else {
        failedSyncCount = 0;
        appEvent("resyncing", { isResyncing: false, error: true });
        appEvent("syncing", { active: false, lastPulledAt: ts });
      }
    } else {
      failedSyncCount = 0;
      appEvent("resyncing", { isResyncing: false });
      appEvent("syncing", { active: false, lastPulledAt: ts });
      appEvent("lastPulledAt", { lastPulledAt: ts });
    }

    throw new Error(err.message);
  }
}

export { syncBack, tusSyncBack, isSyncing };
