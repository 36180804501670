import { Col, Row, Layout, Typography } from "antd";
import config from "config";
import { useMemo } from "react";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { FormProcessorResponse } from "core/FormProcessor/model/FormProcessorResponse";
import FormRenderer from "components/Form/FormRenderer";
import DocumentGallery from "components/DocumentGallery/DocumentGallery";
import DocumentViewer from "components/DocumentViewer/DocumentViewer";
import FileUploader from "components/FileUploader";
import interPass from "assets/images/passport.svg";
import nationalPass from "assets/images/ID.svg";
import formReq from "assets/images/form_req.svg";
import visaImg from "assets/images/visa_req.svg";
import airplane from "assets/images/airplane.png";
import photoImg from "assets/images/photo_blue.svg";
import Loader from "components/Loader";
import { APP_COLORS } from "utils/colors";
import "./ValidatorView.css";

const ValidatorView = ({
  loadingState,
  title,
  documentId,
  jsonschema,
  uischema,
  fields,
  isPassportStep,
  category,
  formData,
  onChangeForm,
  onSubmit,
  onClickValidate,
  onContinue,
  onAddFile,
  onRemoveFile,
  onSkip,
  onCancelSession,
  images,
}: {
  loadingState: string | null;
  title: string | null;
  documentId: string | null;
  jsonschema: any;
  uischema: any;
  fields: string[];
  isPassportStep: boolean;
  category: number | null;
  formData: FormProcessorResponse;
  onChangeForm: (response: { formData: any }) => void;
  onSubmit: () => void;
  onClickValidate: () => Promise<void>;
  onContinue: () => void;
  onAddFile: (file: Blob) => void;
  onRemoveFile: (index: number) => void;
  onSkip?: () => void;
  onCancelSession: () => void;
  images: Blob[];
}) => {
  const { t } = useTranslation();
  const loading = !!loadingState;

  const jsonSchemaWithOutFile = useMemo(() => {
    if (!jsonschema) {
      return;
    }

    const newSchema = jsonschema;
    delete newSchema.properties?.documentFile;

    return newSchema;
  }, [jsonschema]);

  const onlyDocumentFields = useMemo(() => {
    return fields.length === 1 && fields.includes("documentFile");
  }, [fields]);

  const isPhotoStep = category === Number(config.DOCUMENT_CATEGORIES.PHOTO);
  const isQuestionsStep =
    category === Number(config.DOCUMENT_CATEGORIES.QUESTION);

  const icons = useMemo(() => {
    switch (category?.toString()) {
      case config.DOCUMENT_CATEGORIES.ID:
        return [interPass, nationalPass];
      case config.DOCUMENT_CATEGORIES.VISA:
        return [visaImg];
      case config.DOCUMENT_CATEGORIES.FORM:
        return [formReq];
      case config.DOCUMENT_CATEGORIES.QUESTION:
        return [airplane];
      case config.DOCUMENT_CATEGORIES.PHOTO:
        return [photoImg];
      default:
        return [];
    }
  }, [category]);

  return (
    <>
      <Loader loading={loading} />
      <Layout style={{ margin: 10, borderRadius: 10 }}>
        <Layout.Header
          style={{
            borderRadius: 10,
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            padding: 10,
            height: "auto",
          }}
        >
          <Typography className="Validator-header">{title}</Typography>
        </Layout.Header>
        <Layout.Content
          style={{
            borderRadius: 10,
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            margin: 10,
            height: "100%",
            overflowY: "auto",
          }}
        >
          {documentId ? (
            <>
              <DocumentGallery
                documentId={documentId}
                containerStyle={{
                  margin: 30,
                }}
              />
              <DocumentViewer documentId={documentId} />
            </>
          ) : jsonschema &&
            !isPassportStep &&
            !onlyDocumentFields &&
            !isPhotoStep ? (
            <FormRenderer
              jsonschema={jsonSchemaWithOutFile}
              uischema={uischema}
              value={formData?.data}
              readonlyFields={formData.fields.parsed}
              onChange={onChangeForm}
              onSubmit={onSubmit}
              isQuestions={isQuestionsStep}
            />
          ) : (
            <>
              <FileUploader
                uploadFiles={images}
                onRemove={onRemoveFile}
                onAddFile={onAddFile}
                icons={icons}
              />
            </>
          )}
        </Layout.Content>

        {/* {loadingState ? <Spin fullscreen /> : null} */}

        <Layout.Footer className="Validator-footer">
          <Row gutter={[16, 8]} justify="center">
            {documentId ? (
              <Col xs={24} md={8} xl={3}>
                <Button
                  style={{
                    width: "100%",
                    color: APP_COLORS.light.primary_text_color,
                    backgroundColor: APP_COLORS.light.primary,
                  }}
                  type="primary"
                  size="large"
                  disabled={loading}
                  onClick={onClickValidate}
                >
                  {t("Validator.continue")}
                </Button>
              </Col>
            ) : (
              <Col xs={24} md={8} xl={3}>
                <Button
                  style={{
                    width: "100%",
                    color: APP_COLORS.light.primary_text_color,
                    backgroundColor: APP_COLORS.light.primary,
                  }}
                  type="primary"
                  disabled={loading}
                  size="large"
                  onClick={onContinue}
                >
                  {t("Validator.continue")}
                </Button>
              </Col>
            )}
            {onSkip ? (
              <Col xs={24} md={8} xl={3}>
                <Button
                  style={{
                    width: "100%",
                    color: APP_COLORS.light.primary_text_color,
                    backgroundColor: APP_COLORS.light.primary,
                  }}
                  type="primary"
                  disabled={loading}
                  size="large"
                  onClick={onSkip}
                >
                  {t("Validator.skip")}
                </Button>
              </Col>
            ) : null}
            <Col xs={24} md={8} xl={3}>
              <Button
                style={{
                  width: "100%",
                  color: APP_COLORS.light.primary,
                  backgroundColor: APP_COLORS.light.default_btn,
                  borderColor: APP_COLORS.light.primary,
                }}
                type="primary"
                disabled={loading}
                size="large"
                onClick={onCancelSession}
              >
                {t("Cancel")}
              </Button>
            </Col>
          </Row>
        </Layout.Footer>
      </Layout>
    </>
  );
};

export default ValidatorView;
