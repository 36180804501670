export enum FieldId {
  PassportDocumentNumber = 27,
  DateOfIssue = 4,
  DateOfExpiry = 3,
  IssuingState = 1,
}

export interface EVISAResponse {
  type: string | null;
  fields: {
    id: FieldId;
    name: string;
    value: string;
  }[];
  errors?: {
    code: number;
    message: string;
  }[];
}
