import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Observable } from "@nozbe/watermelondb/utils/rx";
import { withObservables } from "@nozbe/watermelondb/react";
import { database } from "wmelon/database";
import { Q } from "@nozbe/watermelondb";
import Session from "../../../wmelon/models/Sessions";
import FlightTicket from "../../../wmelon/models/FlightTicket";
import FlightTicketShare from "../../../wmelon/models/FlightTicketShare";
import { getQueryValue } from "utils/db";
import { getShareStatus, mapTicketOwnerName } from "../../../utils/tickets";
import { ShareStatus } from "types/flights";
import { ReduxState } from "../../../redux/ReduxState";
import { SESSION_STATUSES } from "utils/session";
import TicketItem from "components/TicketItem";
import { Modal } from "antd";
import { APP_COLORS } from "utils/colors";

export interface DBTicketItemContainerProps {
  ticket: FlightTicket;
  onEditTag: (ticketId: string) => void;

  onStartValidation: (ticket: FlightTicket) => Promise<void>;
  onShare: (ticket: FlightTicket) => Promise<void>;
  onUnshare: (share: FlightTicketShare) => Promise<void>;

  viewer: boolean;

  sessions: Session[];
  shares: FlightTicketShare[];

  onCall: () => void;
}

const DBTicketItemContainer = ({
  ticket,
  sessions,
  shares,

  onEditTag,

  onStartValidation,
  onShare,
  onUnshare,

  viewer,

  onCall,
}: DBTicketItemContainerProps) => {
  const { t } = useTranslation();
  const isSyncing = useSelector((state: ReduxState) => state.app.syncing);
  const [sharing, setSharing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const session = viewer ? null : sessions[0];

  const shareStatus: ShareStatus | null = useMemo(() => {
    return getShareStatus(shares);
  }, [shares]);

  const handleStartValidation = useCallback(() => {
    if (sessions.length < 9) {
      setLoading(true);
      onStartValidation(ticket).finally(() => setLoading(false));
    } else {
      Modal.confirm({
        title: t("Ticket_and_Info.maximumAttempts"),
        content: t("Ticket_and_Info.callSupport"),
        onOk: () => onCall(),
        okText: t("ok"),
        cancelText: t("Cancel"),
      });
    }
  }, [onStartValidation, ticket, sessions.length, t, onCall]);

  const canValidateFlight =
    moment().isSameOrBefore(moment(ticket.flight_date), "day") &&
    !isSyncing &&
    !sharing &&
    shareStatus !== ShareStatus.shared_by;

  const onClickShare = useCallback(() => {
    if (ticket && moment().isAfter(moment(ticket.flight_date), "day")) {
      Modal.error({
        title: t("Error"),
        content: t("FLIGHT_TICKET_SHARE_NOT_ALLOWED_BY_DATE"),
        okText: t("ok"),
        okButtonProps: {
          size: "small",
          style: { color: APP_COLORS.light.primary },
        },
      });
      return;
    }

    if (sessions.length) {
      Modal.error({
        title: t("Error"),
        content: t("FLIGHT_TICKET_SHARE_NOT_ALLOWED"),
        okText: t("ok"),
        okButtonProps: { size: "small" },
      });
      return;
    }

    setSharing(true);
    return onShare(ticket).then(() => setSharing(false));
  }, [sessions, onShare, ticket, t]);

  const onClickUnshare = useCallback(() => {
    if (sessions.length) {
      Modal.error({
        title: t("Error"),
        content: t("FLIGHT_TICKET_ALREADY_BINDED"),
        okText: t("ok"),
      });
      return;
    }

    onUnshare(shares[0]);
  }, [onUnshare, shares, sessions, t]);

  const passenger = useMemo(() => {
    if (
      session &&
      [
        SESSION_STATUSES.COMPLETED,
        SESSION_STATUSES.WAIT_FOR_CHECK_VALIDATION,
        SESSION_STATUSES.WAIT_FOR_DOC_VALIDATION,
        SESSION_STATUSES.AUTO_REJECTED,
        SESSION_STATUSES.MANUAL_REJECTED,
        SESSION_STATUSES.CANCELLED,
        SESSION_STATUSES.MANUAL_INACTIVE,
        SESSION_STATUSES.WAIT_FOR_TICKET,
      ].indexOf(session.status) !== -1
    ) {
      return {
        firstName: session.owner_first_name || null,
        lastName: session.owner_last_name || null,
        dob: session.owner_dob || null,
        documentNumber: session.owner_document_number || null,
      };
    }

    return {
      firstName: mapTicketOwnerName(ticket?.passenger_firstname),
      lastName: mapTicketOwnerName(ticket?.passenger_lastname),
      dob: null,
      documentNumber: null,
    };
  }, [session, ticket]);

  return (
    <TicketItem
      ticket={ticket}
      ticket_id={ticket.id}
      session_status={session?.status || null}
      session_handler={session?.handler || null}
      short_id={session?.short_id || null}
      session_id={session?.id || null}
      doc_links={null}
      isUnsynced={!!session && session._raw._status !== "synced"}
      passenger={passenger}
      onStartValidation={handleStartValidation}
      canValidate={canValidateFlight}
      onEditTag={onEditTag}
      viewer={viewer}
      loading={loading}
      sharing={sharing}
      onShare={onClickShare}
      onUnshare={onClickUnshare}
      shareStatus={shareStatus}
      onCall={onCall}
    />
  );
};

const enhance = withObservables<
  Omit<DBTicketItemContainerProps, "sessions" | "shares">,
  {
    sessions: Observable<Session[]>;
    shares: Observable<FlightTicketShare[]>;
  }
>(["ticket"], ({ ticket }: { ticket: FlightTicket }) => ({
  sessions: database
    .get<Session>(Session.table)
    .query(
      Q.where("flight_ticket_id", getQueryValue(ticket.id)),
      Q.sortBy("short_id", Q.desc)
    )
    .observeWithColumns(["status", "handler"]),
  shares: database
    .get<FlightTicketShare>(FlightTicketShare.table)
    .query(Q.where("flight_ticket_id", ticket.id))
    .observe(),
}));
const EnhancedDBTicketItemContainer = enhance(DBTicketItemContainer);
export default EnhancedDBTicketItemContainer;
