import config from "../../../config";
import { ErrorTexts } from "../ErrorTexts";
import DateTimeUtils from "../DateTimeUtils";
import { JsonForm, JsonFormJson, JsonFormJsonSchema } from "../model/JsonForm";
import {
  ResultStatus,
  FormProcessorResponse,
  UserData,
} from "../model/FormProcessorResponse";
import { FormProcessorParseProps } from "../model/FormProcessorParseProps";
import Recognizer from "./Recognizer";
import { EVISAResponse, FieldId } from "../model/EVISA";

export default class EVISARecognizer extends Recognizer {
  static getGUID() {
    return "vtevisa";
  }

  static performValue(
    structureType: string,
    value: any,
    values: Record<string, any>,
    field: string,
    parsedFields: string[],
    currentPath: string,
    format: string
  ) {
    let isParsed = false;
    if (structureType.toLowerCase() === "number") {
      values[`${field}`] = parseInt(value, 10);
      isParsed = true;
    } else {
      const parsed = DateTimeUtils.toStandard(
        value,
        format,
        config.DOCUMENT_READER_DATE_FORMAT
      );
      if (parsed !== null) {
        values[`${field}`] = parsed;
        isParsed = true;
      }
    }
    isParsed && parsedFields.push(currentPath + "." + field);
    return isParsed;
  }

  workWithJsonForm(
    currentPath: string,
    jsonSchema: JsonFormJsonSchema,
    values: Record<string, any>,
    parsed: EVISAResponse,
    parsedFields: string[],
    notValidFields: string[]
  ) {
    const propertiesNode = jsonSchema.properties;
    const manualFields = jsonSchema.manual || [];

    Object.keys(propertiesNode).forEach((structureKey) => {
      const structure = propertiesNode[structureKey];

      if (structure.type.toLowerCase() === "object") {
        if (values[structureKey] === null) values[structureKey] = {};
        this.workWithJsonForm(
          currentPath + "." + structureKey,
          structure,
          values[structureKey],
          parsed,
          parsedFields,
          notValidFields
        );
      } else {
        const structureType = structure.type || "string";
        const fieldIdStr = structure.recognizerFieldId || null;
        const fieldId = parseInt(fieldIdStr, 10);
        const recognizer = structure.recognizer || "";

        const isManual = manualFields.includes(structureKey);

        //if in data no values for field
        if (values[structureKey] != null) {
          if (
            !isManual &&
            parsed &&
            EVISARecognizer.getGUID().toLowerCase() === recognizer.toLowerCase()
          ) {
            //if found not manual field with data, then refresh data
            const fieldValue = this.getField(parsed, fieldId);
            if (fieldValue) {
              EVISARecognizer.performValue(
                structureType,
                fieldValue,
                values,
                structureKey,
                parsedFields,
                currentPath,
                structure.format
              );
            }
          }
        } else if (
          !isManual &&
          parsed &&
          EVISARecognizer.getGUID().toLowerCase() === recognizer.toLowerCase()
        ) {
          const fieldValue = this.getField(parsed, fieldId);

          if (fieldValue) {
            EVISARecognizer.performValue(
              structureType,
              fieldValue,
              values,
              structureKey,
              parsedFields,
              currentPath,
              structure.format
            );
          }
        }
      }
    });
  }

  getField(data: EVISAResponse, field: FieldId) {
    return data.fields.find((it) => it.id === field)?.value;
  }

  process(
    props: FormProcessorParseProps,
    form: JsonForm,
    allowedDocumentTypes: string[],
    shouldRecognizeDocument: boolean,
    quickMode: boolean
  ): FormProcessorResponse {
    let parsed: EVISAResponse;

    if (props.parsed) {
      parsed = props.parsed as EVISAResponse;
    } else {
      return {
        status: ResultStatus.NOT_RECOGNIZED,
        error: undefined,
        data: null,
        recognizer: null,
        fields: {
          empty: [],
          invalid: [],
          parsed: [],
        },
        documentType: null,
        doc_data: {
          date_of_expiry: null,
          passenger: null,
          meta: null,
        },
      };
    }

    let passenger: UserData | null = null;
    let documentType: string | null = null;

    if (parsed) {
      if (parsed.errors && parsed.errors.length) {
        return {
          status: ResultStatus.VALIDATION_ERROR,
          error: parsed.errors[0].message,
          data: null,
          recognizer: null,
          fields: {
            empty: [],
            invalid: [],
            parsed: [],
          },
          documentType: parsed.type,
          doc_data: {
            date_of_expiry: this.getField(parsed, FieldId.DateOfExpiry) || null,
            passenger,
            meta: null,
          },
        };
      }

      documentType =
        allowedDocumentTypes.find((dt) => `${dt}` === `${parsed.type}`) || null;
      if (!documentType && shouldRecognizeDocument) {
        return {
          status: ResultStatus.DOC_TYPE_NOT_VALID,
          error: ErrorTexts.DOCUMENT_TYPE_NOT_ALLOWED,
          data: null,
          recognizer: null,
          fields: {
            empty: [],
            invalid: [],
            parsed: [],
          },
          documentType: parsed.type || null,
          doc_data: {
            date_of_expiry: this.getField(parsed, FieldId.DateOfExpiry) || null,
            passenger,
            meta: null,
          },
        };
      }
    }

    const values = props.formData || {};
    const parsedFields: string[] = [];
    const notValidFields: string[] = [];
    const jsonSchema: JsonFormJsonSchema = (
      JSON.parse(form.json) as JsonFormJson
    ).schema;

    this.workWithJsonForm(
      "",
      jsonSchema,
      values,
      parsed,
      parsedFields,
      notValidFields
    );

    this.fixFieldsName(parsedFields);

    let recognizer: string | null = null;

    if (documentType || (!shouldRecognizeDocument && parsed)) {
      recognizer = form.recognizer;
    }

    return {
      status: ResultStatus.OK,
      error: undefined,
      data: values,
      recognizer,
      fields: {
        empty: this.getEmptyRequiredFields(jsonSchema, values, props.images),
        invalid: notValidFields,
        parsed: parsedFields,
      },
      documentType,
      doc_data: {
        date_of_expiry: this.getField(parsed, FieldId.DateOfExpiry) || null,
        passenger: null,
        meta: null,
      },
    };
  }
}
