import { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { ReduxDocument } from "redux/features/documents";
import { useTranslation } from "react-i18next";
import { database } from "wmelon/database";
import Form from "wmelon/models/Form";
import { fieldNameToLabel } from "utils/string";
import { getDocumentData, getLabelByUiScheme } from "utils/jsonform";

import "./style.css";
function DocumentViewer({ item }: { item: ReduxDocument | null }) {
  const { t } = useTranslation();
  const [form, setForm] = useState<Form | null>(null);
  useEffect(() => {
    if (item?.form_id) {
      const formObservable = database
        .get<Form>(Form.table)
        .findAndObserve(item.form_id.toString());
      const subscription = formObservable.subscribe(setForm);

      // Clean up subscription on unmount
      return () => subscription.unsubscribe();
    }
  }, [item]);

  const { jsonschema, uischema } = useMemo(() => {
    if (!form) {
      return { jsonschema: null, uischema: null };
    }

    try {
      const { schema, uischema } = JSON.parse(form.json);
      delete schema.properties?.documentFile;
      return { jsonschema: schema, uischema };
    } catch (err) {
      console.error("[DocumentViewer] failed to parse form:", err);
      return { jsonschema: null, uischema: null };
    }
  }, [form]);

  const data = useMemo(() => {
    if (!item) {
      return {};
    }

    return jsonschema
      ? getDocumentData(item.data, jsonschema)
      : item.data || {};
  }, [item, jsonschema]);

  const mapValue = useCallback(
    (value) => {
      if (typeof value === "boolean") {
        return value ? t("Validator.yes") : t("Validator.no");
      } else {
        return value;
      }
    },
    [t]
  );

  const renderField = useCallback(
    (name: string, value: any) => {
      if (typeof value !== "string") {
        if (value === undefined) {
          return;
        } else if (Array.isArray(value)) {
        } else if (value && typeof value === "object") {
          return Object.keys(value).map((key: string) =>
            renderField(key, value[key])
          );
        }
      }
      return (
        <div key={name} className="DocumentViewer-row">
          <span className="DocumentViewer-label">
            {(uischema && getLabelByUiScheme(uischema, name)) ||
              fieldNameToLabel(name)}
          </span>
          <span className="DocumentViewer-value">{mapValue(value)}</span>
        </div>
      );
    },
    [uischema, mapValue]
  );

  return (
    <div className="DocumentViewer-container">
      {Object.keys(data).map((key: string) => renderField(key, data[key]))}
    </div>
  );
}

const mapState = (state: any, props: { documentId: string }) => ({
  item: state.documents.items.find((it) => it.id === props.documentId) || null,
});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

const EnhancedDocumentViewer = connector(DocumentViewer as any);
export default EnhancedDocumentViewer;
