import { useCallback } from "react";
import { FieldProps } from "@rjsf/utils";
import { Typography, Checkbox } from "antd";
import "./style.css";
export default function BooleanField({
  name,
  formData,
  onChange,
  required,
  disabled,
  schema,
}: FieldProps) {
  const isMobile =
    /iPhone|Android/i.test(navigator.userAgent) &&
    !/iPad|Tablet/i.test(navigator.userAgent);
  const onToggle = useCallback(() => onChange(!formData), [onChange, formData]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Typography.Text
        className="fieldText"
        style={{
          fontSize: isMobile ? undefined : 16,
          cursor: "pointer",
          transition: "color 0.3s",
        }}
        onClick={() => onToggle()}
      >
        {schema.description}
      </Typography.Text>
      <Checkbox checked={formData} onChange={onToggle} disabled={disabled} />
    </div>
  );
}
