import { useCallback } from "react";
import "./style.css";
import SupportCard from "./components/SupportCard";
import { Col, Row } from "antd";
import LineCard from "./components/LineCard";
import config from "config";
import { useTranslation } from "react-i18next";
import ModalCallCenter from "components/ModalCallCenter";
import { useAppDispatch } from "redux/store";
import { setManualState } from "redux/features/app";
import Manual from "components/Manual";
import { useToggle } from "hooks/useToggle";

function Support() {
  const [value, toggle] = useToggle(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const onShowManual = useCallback(() => {
    dispatch(setManualState(true));
  }, [dispatch]);

  return (
    <div className='Support-container'>
      <div className='Support'>
        <Row gutter={16} justify='space-between'>
          <Col xs={24} md={10}>
            <SupportCard
              onCall={toggle}
              onInformation={() =>
                window.open(config.SUPPORT.INFORMATION, "_blank")
              }
            />
          </Col>
          <Col xs={24} md={14}>
            <LineCard title={t("Support.Manual")} onPress={onShowManual} />
            <LineCard
              title={t("Support.Termsofservice")}
              onPress={() => {
                window.open(config.SUPPORT.TERMS_OF_SERVICE, "_blank");
              }}
            />
            <LineCard
              title={t("Support.PrivacyPolicy")}
              onPress={() => {
                window.open(config.SUPPORT.PRIVACY_POLICY, "_blank");
              }}
            />
          </Col>
        </Row>
        <ModalCallCenter
          text={""}
          withOutShortId
          visible={value}
          onClose={() => toggle()}
        />
        <Manual />
      </div>
    </div>
  );
}

export default Support;
