import { App, Input, Modal } from "antd";
import { useFlights } from "hooks/useFlights";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Flight } from "types/flights";
import Airline from "wmelon/models/Airline";
import Airport from "wmelon/models/Airport";
import Country from "wmelon/models/Country";
import { getAirportsByCodes } from "wmelon/middleware/airport";
import { uniq } from "lodash";
import { getAirlineByCodes } from "wmelon/middleware/airline";
import { getCountriesByCodes } from "wmelon/middleware/country";
import FlightSelect from "components/FlightSelect/FlightSelect";
import Select from "components/Select";
import "./style.css";
import { APP_COLORS } from "utils/colors";
interface IProps {
  formik: any | [];
}

const ManualPNR = ({ formik }: IProps) => {
  const { t } = useTranslation();
  const { loadFlights } = useFlights();
  const [flights, setFlights] = useState<Flight[]>([]) as any;
  const [loading, setLoading] = useState<boolean>(false);
  const { values, setFieldValue, errors, setFieldTouched, touched } = formik;
  const onSelect = useCallback(
    (flight) => {
      setFieldValue("flight", flight);
      setFieldTouched("flight", false);

      if (flight.schedule?.length === 1) {
        setFieldValue("time", flight.schedule[0]);
      } else {
        values.time &&
          !flight.schedule.includes(values.time) &&
          setFieldValue("time", null);
      }
    },
    [setFieldValue, setFieldTouched, values.time]
  );

  const onChangeDate = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFieldValue("flightDate", event.target.value);
      setFieldTouched("flightDate", false);
      setFieldValue("flight", null);
      setFieldValue("time", null);
      setLoading(true);
      loadFlights(event.target.value)
        .then(
          async (
            flights: {
              carrier: string;
              flight: string;
              origin: string;
              destination: string;
              schedule: string[];
            }[]
          ) => {
            if (!flights.length) {
              setLoading(false);
              setFlights([]);
              Modal.info({
                title: t("AddingFlight.flightsNotFound"),
                okText: t("ok"),
                okButtonProps: {
                  style: {
                    color: APP_COLORS.light.primary_text_color,
                    backgroundColor: APP_COLORS.light.primary,
                  },
                },
              });
              return;
            }
            const airports: Record<string, Airport> = {};
            const countries: Record<string, Country> = {};
            const airlines: Record<string, Airline> = {};

            let airportCodes: string[] = [];
            let countryCodes: string[] = [];
            let airlineCodes: string[] = [];

            flights.forEach((it) => {
              airportCodes.push(it.origin, it.destination);
              it.carrier && airlineCodes.push(it.carrier);
            });
            airportCodes = uniq(airportCodes);
            airlineCodes = uniq(airlineCodes);

            await getAirportsByCodes(airportCodes).then(
              (list: Airport[] | any) => {
                list.forEach((it: Airport | any) => {
                  airports[it.iata.toUpperCase()] = it;
                  it.countryCode && countryCodes.push(it.countryCode);
                });
              }
            );

            await getAirlineByCodes(airlineCodes).then(
              (list: Airline[] | any) => {
                list.forEach((it: Airline) => {
                  airlines[it.icao.toUpperCase()] = it;
                });
              }
            );

            countryCodes = uniq(countryCodes);
            await getCountriesByCodes(countryCodes).then((list: Country[]) => {
              list.forEach((it: Country) => {
                countries[it.alpha2] = it;
              });
            });

            const getFlightPlace = (iata: string) => {
              const airport = airports[iata];

              if (!airport) {
                return {
                  iata: iata,
                };
              }

              const country = countries[airport.countryCode];

              return {
                name: airport.name,
                iata: airport.iata,
                icao: airport.icao,
                country: country?.name,
                countryCode: airport.countryCode,
              };
            };
            const result = flights.map((it) => {
              return {
                id: it.flight,
                airline: {
                  name: airlines[it.carrier]?.name,
                  iata: airlines[it.carrier]?.iata,
                  icao: airlines[it.carrier]?.icao || it.carrier,
                },
                origin: getFlightPlace(it.origin),
                destination: getFlightPlace(it.destination),
                schedule: it.schedule,
              };
            });
            setFlights(result);
            if (result.length === 1 && !values.flight) {
              onSelect(result[0]);
            }
          }
        )
        .catch((err) => {
          console.log("[Manual] failed to get flights:", err);
          setFlights([]);
        })
        .finally(() => setLoading(false));
    },
    [loadFlights, setFieldValue, setFieldTouched, t, onSelect, values.flight]
  );

  const timeOptions = useMemo(() => {
    if (!values.flight?.schedule) {
      return [];
    }

    return values.flight.schedule.map((time: string) => ({
      value: time,
      label: time.split(";").join(" - "),
    }));
  }, [values.flight]);

  const minDate = useMemo(() => {
    return new Date().toISOString().split("T")[0];
  }, []);

  const maxDate = useMemo(() => {
    return new Date(new Date().setFullYear(new Date().getFullYear() + 1))
      .toISOString()
      .split("T")[0];
  }, []);

  return (
    <App>
      <div>
        <div
          style={{
            marginBottom: touched.flightDate && !!errors?.flightDate ? 0 : 10,
          }}
          className="dateInputContainer"
        >
          <Input
            style={{ width: "100%" }}
            type="date"
            min={minDate}
            className="dateInput"
            max={maxDate}
            value={values.flightDate}
            onChange={onChangeDate}
            onBlur={() => setFieldTouched("flightDate", true)}
            placeholder={t("AddingFlight.date")}
          />
          {touched.flightDate && !!errors.flightDate ? (
            <span style={{ color: APP_COLORS.light.error }}>
              {errors.flightDate}
            </span>
          ) : null}
        </div>
        <div
          style={{ marginBottom: touched.flight && !!errors.flight ? 0 : 10 }}
        >
          <FlightSelect
            inputPlaceholder={t("AddingFlight.flightNumber")}
            flights={flights}
            disabled={loading || !values.flightDate || !flights.length}
            value={values.flight}
            onSelect={onSelect}
          />
          {touched.flight && errors.flight ? (
            <span
              style={{
                color: APP_COLORS.light.error,
                marginBottom: 10,
              }}
            >
              {errors.flight}
            </span>
          ) : null}
        </div>
        <Select
          inputPlaceholder={t("AddingFlight.flightTime")}
          options={timeOptions}
          disabled={loading || !values.flightDate || !values.flight}
          value={values.time}
          onSelect={(value) => setFieldValue("time", value)}
        />
        {touched.time && errors.time ? (
          <span style={{ color: APP_COLORS.light.error }}>{errors.time}</span>
        ) : null}
      </div>
    </App>
  );
};

export default ManualPNR;
