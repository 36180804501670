import { useCallback, useEffect, useRef } from "react";
import { Modal } from "antd";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "redux/store";
import { setFailedSync } from "redux/features/app";
import { syncBack } from "../wmelon/sync";
import { useSelector } from "react-redux";
import AuthApiService from "utils/AuthApi";
import { APP_COLORS } from "utils/colors";
import { ReduxState } from "redux/ReduxState";

function useDBSync(force?: boolean, hideAlert?: boolean): () => Promise<void> {
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const failedCount = useSelector((state: ReduxState) => state.app.failedSync);

  const failedCountRef = useRef<number>(failedCount);
  useEffect(() => {
    failedCountRef.current = failedCount;
  }, [failedCount]);

  console.log("FAILED:", failedCount, failedCountRef.current);

  const getCanSync = useCallback(() => {
    return (
      force || ["/final/success", "/final/failed"].indexOf(location.pathname)
    );
  }, [force, location]);

  const sync = useCallback(() => {
    if (!getCanSync()) {
      throw new Error("In validation process");
    }

    return syncBack(AuthApiService.shared().getSessionId())
      .then((response: any) => {
        dispatch(setFailedSync(0));
        return response;
      })
      .catch((error) => {
        console.log(
          "[useDBSync] failed:",
          failedCountRef.current,
          error.cause?.status,
          error
        );
        if (error.cause?.status === 500) {
          if (failedCountRef.current < 4) {
            dispatch(setFailedSync(failedCountRef.current + 1));
            return new Promise((res, rej) => {
              setTimeout(() => {
                sync().then(res).catch(rej);
              }, failedCountRef.current * 1000);
            });
          } else {
            !hideAlert &&
              Modal.error({
                title: t("RegulaScreens.failedToSyncDatabase"),
                content: t("RegulaScreens.pleaseTryAgainLater"),
                okText: t("ok"),
                okButtonProps: {
                  style: {
                    backgroundColor: APP_COLORS.light.primary,
                    color: APP_COLORS.light.default_text_color,
                  },
                },
              });
            dispatch(setFailedSync(0));
            throw error;
          }
        } else {
          throw error;
        }
      });
  }, [dispatch, getCanSync, hideAlert, t]);

  return sync;
}

export default useDBSync;
