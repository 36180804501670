import IncompleteIncomingDataError from "./exceptions.js";
import cerberValidate from "./cerber-validator.js";

const VERSION = "0.0.6";

function isEmpty(data) {
  try {
    if (data == null || data == undefined) return true;
    if (Array.isArray(data) || typeof data === "string") return !data.length;
    if (typeof data == "object") return Object.keys(data).length === 0;
  } catch (e) {
    console.log("IsEmty error: ", e);
    return true;
  }
}

function prepareDocsFiledsParams(scheme) {
  let docFieldsParams = {};
  let virtualDocs = new Set(); // virtual docs for no resolvers

  //docs
  for (const docInfo of scheme["full_data_documents"]) {
    let fileds = {};
    let recognizers = new Set();
    for (const field of docInfo["fields_full_data"]) {
      fileds[field["name"].toLowerCase()] = {
        name: field["description"],
        type: field["type"],
      };
      recognizers.add(field["description"].split(".")[0]);
    }
    docFieldsParams[docInfo["type"]] = {
      fields: fileds,
      recognizers: recognizers,
      groups: new Set(),
      is_doc: true,
    };
    if ("virtual" in docInfo && docInfo["virtual"]) {
      virtualDocs.add(docInfo["type"]);
    }
  }

  //groups
  for (const groupInfo of scheme["full_data_group_documents"]) {
    let fileds = {};
    let recognizers = new Set();
    for (const field of groupInfo["doc_fields"]) {
      fileds[field["name"].toLowerCase()] = {
        name: field["description"],
        type: field["type"],
      };
      recognizers.add(field["description"].split(".")[0]);
    }
    for (const docType of groupInfo["doc_types"]) {
      if (docType in docFieldsParams) {
        docFieldsParams[docType]["fields"] = Object.assign(
          docFieldsParams[docType]["fields"],
          fileds
        );
        docFieldsParams[docType]["recognizers"] = new Set(
          docFieldsParams[docType]["recognizers"],
          recognizers
        );
        docFieldsParams[docType]["groups"].add(groupInfo["name"]);
      } else {
        docFieldsParams[docType] = {
          fields: fileds,
          recognizers: recognizers,
          groups: new Set().add(groupInfo["name"]),
          is_doc: false,
        };
      }
    }
    if ("virtual" in groupInfo && groupInfo["virtual"]) {
      virtualDocs.add(groupInfo["id"]);
    }
  }
  return [docFieldsParams, virtualDocs];
}

function prepareDocs(documents, scheme, session_info) {
  // add session_info in docs data
  documents.push({
    id: "-1",
    version: "-1",
    type: "session_info",
    recognizer: "!custom for disable check doc verify status",
    verified_auto: 1,
    verified_manual: 1,
    _internal_doc: true,
    data: session_info,
  });

  const [docsFiledsParams, virtualDocs] = prepareDocsFiledsParams(scheme);

  // add empty validate doc if exist in scheme
  if (
    docsFiledsParams.hasOwnProperty("validate_doc") &&
    !isEmpty(docsFiledsParams.validate_doc)
  ) {
    console.log("Added validate doc");
    let vd = {};
    for (let fn of Object.keys(docsFiledsParams.validate_doc.fields)) {
      vd[fn] = null;
    }
    documents.push({
      id: "-1",
      version: "-1",
      type: "validate_doc",
      recognizer: "!custom for disable check doc verify status",
      verified_auto: 1,
      verified_manual: 1,
      _internal_doc: true,
      data: vd,
    });
  }

  let preparedDocs = [];
  for (const doc of documents) {
    if (!docsFiledsParams.hasOwnProperty(doc["type"])) {
      continue;
    }
    let docParams = docsFiledsParams[doc["type"]];
    //set autoverifi status
    if (docParams["recognizers"].has(doc["recognizer"].toLowerCase())) {
      doc["verified_auto"] = 1;
    }
    doc["_scheme_fields"] = docParams["fields"];
    //add doc like doc
    if (docParams["is_doc"]) {
      preparedDocs.push({ ...doc });
    }
    //add doc like group
    if (docParams["groups"].size > 0) {
      doc["_group_names"] = docParams["groups"];
      preparedDocs.push(doc);
    }
  }

  return [preparedDocs, virtualDocs];
}

function devideDocsByVerifyStatus(docs) {
  let verified_docs = [];
  let unverified_docs = [];
  for (const doc of docs) {
    if (
      ("verified_manual" in doc && doc["verified_manual"] == 1) ||
      ("verified_auto" in doc && doc["verified_auto"] == 1)
    ) {
      verified_docs.push(doc);
    } else {
      unverified_docs.push(doc);
    }
  }
  return [verified_docs, unverified_docs];
}

function generateDataSets(index, data) {
  let res = [];
  for (const i of data[index]) {
    if (index + 1 <= data.length - 1) {
      let next = generateDataSets(index + 1, data);
      for (const j of next) {
        res.push([...[i], ...j]);
      }
    } else {
      res.push([i]);
    }
  }
  return res;
}

function generateValidateDataSets(docs) {
  let tmpValidateData = {};
  for (const doc of docs) {
    if (doc.hasOwnProperty("_group_names")) {
      if (!tmpValidateData.hasOwnProperty("[G]" + doc["type"])) {
        tmpValidateData["[G]" + doc["type"]] = [];
      }
      tmpValidateData["[G]" + doc["type"]].push(doc);
      continue;
    }
    if (!tmpValidateData.hasOwnProperty(doc["type"])) {
      tmpValidateData[doc["type"]] = [];
    }
    tmpValidateData[doc["type"]].push(doc);
  }
  let validateDataLists = [];
  for (const [docType, docArr] of Object.entries(tmpValidateData)) {
    validateDataLists.push(docArr);
  }
  return generateDataSets(0, validateDataLists);
}

function getDocumentFieldNameAndTypeByName(field_name, document) {
  if (
    "_scheme_fields" in document &&
    !isEmpty(document["_scheme_fields"]) &&
    field_name in document["_scheme_fields"]
  ) {
    return [
      document["_scheme_fields"][field_name]["type"],
      document["_scheme_fields"][field_name]["name"],
    ];
  } else {
    console.log(
      `\tField name not found in form, field name: ${field_name}, doc id: ${document["id"]}, version: ${document["version"]}, type: ${document["type"]}`
    );
    return [-1, field_name];
  }
}

function generateDocumetsObject(document, group) {
  let documetsObject = {};
  try {
    let docTypeTitle =
      (!isEmpty(group) ? "[G]" + group : document["type"]) + ".";

    if (!isEmpty(document["data"]) && document["data"] != "{}") {
      let data =
        typeof document["data"] == "object"
          ? document["data"]
          : JSON.parse(document["data"]);

      for (const [fieldKey, fieldValue] of Object.entries(data)) {
        const [type, fieldName] = getDocumentFieldNameAndTypeByName(
          fieldKey.toLowerCase(),
          document
        );
        // //need check date types
        // if (type == 8){
        //     documetsObject[docTypeTitle + field_name] = (datetime.strptime(data[field], "%Y-%m-%d"));
        // }
        // else if (type == 2){
        //     documetsObject[docTypeTitle + field_name] = (datetime.strptime(data[field], "%Y-%m-%dT%H:%M:%SZ"));
        // }
        // else{
        //     documetsObject[docTypeTitle + field_name] = data[field];
        // }
        documetsObject[docTypeTitle + fieldName] = fieldValue;
      }
    }
    if (!isEmpty(document["provider"])) {
      documetsObject[docTypeTitle + "provider"] = document["provider"];
    }

    if (!isEmpty(document["verified_auto"])) {
      documetsObject[docTypeTitle + "verified_auto"] =
        document["verified_auto"];
    }

    if (!isEmpty(document["verified_manual"])) {
      documetsObject[docTypeTitle + "verified_manual"] =
        document["verified_manual"];
    }

    if (!isEmpty(document["created_at"])) {
      documetsObject[docTypeTitle + "created_at"] = document["created_at"];
    }

    if (!isEmpty(document["updated_at"])) {
      documetsObject[docTypeTitle + "updated_at"] = document["updated_at"];
    }

    documetsObject[docTypeTitle + "submitted"] = true;
  } catch (e) {
    console.log(
      `Parse document: ${document["id"]} from documents data error: ${e}`
    );
  }
  return documetsObject;
}

async function getValidateDocData(
  doc,
  validateData,
  preparedScheme,
  getValidateDocDataCallback,
  validateDocuments
) {
  let tmpStep = null;
  for (let step of Object.keys(preparedScheme)) {
    if (
      JSON.stringify(preparedScheme[step]).includes(
        "validate_doc.custom.validator"
      )
    ) {
      tmpStep = preparedScheme[step];
      break;
    }
  }
  let requestData = { docType: "validate_doc", data: "" };
  let dataField = {};
  let usedDocNames = new Set();
  for (let fn of Object.keys(tmpStep.schema0.native_schema)) {
    let rule = tmpStep.schema0.native_schema[fn];

    if (
      fn == "validate_doc.custom.validator" &&
      !isEmpty(rule.allowed) &&
      !isEmpty(rule.allowed[0])
    ) {
      requestData["validator"] = rule.allowed[0];
      continue;
    }

    if (fn.includes(".submitted")) {
      continue;
    }

    let key = fn.substring(fn.indexOf(".") + 1);
    if (isEmpty(validateData[fn])) {
      dataField[key] = "";
    } else {
      dataField[key] = validateData[fn];
    }

    usedDocNames.add(fn.substring(0, fn.indexOf(".")));
  }
  usedDocNames = Array.from(usedDocNames);

  // dataField["regula.2"] = "123" // for test
  requestData.data = JSON.stringify(dataField);
  console.log("Validate doc request:", requestData);
  console.log("Used docs for validate doc request:", usedDocNames);

  // save used validators in used docs
  let docsWithUsedValidators = [];
  for (let doc of validateDocuments) {
    usedDocNames.forEach((usedDoc) => {
      // check by group name
      if (usedDoc.includes("[G]")) {
        if (
          doc.hasOwnProperty("_group_names") &&
          doc._group_names.has(usedDoc.substring(3, usedDoc.length))
        )
          docsWithUsedValidators.push(doc);
      }
      // check by doc type
      else {
        if (usedDoc == doc.id) docsWithUsedValidators.push(doc);
      }
    });
  }

  let response = await getValidateDocDataCallback(requestData);

  try {
    console.log("Validate doc response code:", response.status_code);
    if (response.status_code != 200) throw new Error("Response code != 200");

    let respJson = response.data;
    console.log("Validate doc response data:", JSON.stringify(respJson));

    if (respJson.ocs.meta.status != "ok") throw new Error("Ocs status != ok");

    // prepare doc fields info
    let preparedSchemeFields = {};
    for (let sf of Object.keys(doc["_scheme_fields"])) {
      let fieldData = doc._scheme_fields[sf];
      preparedSchemeFields[fieldData["name"]] = {
        type: fieldData["type"],
        name: sf,
      };
    }
    // reset validate doc data
    doc.data = {};

    // fill fields
    let newValidateDocData = {};

    if (!isEmpty(respJson.ocs.data.result)) {
      newValidateDocData["validate_doc.submitted"] = true;
    }

    let preparedSchemeFieldsKeys = Object.keys(preparedSchemeFields);
    for (let fn of Object.keys(respJson.ocs.data)) {
      let value = respJson.ocs.data[fn];

      newValidateDocData[`validate_doc.${fn}`] =
        typeof value == "boolean" ? (value ? "1" : "0") : value;

      // fill validate doc data
      if (preparedSchemeFieldsKeys.includes(fn)) {
        doc.data[preparedSchemeFields[fn].name] = value;
      }
    }
    console.log("Validate doc fields: %s", newValidateDocData);

    // fill _used_valdators in used docs
    docsWithUsedValidators.forEach((doc) => {
      doc._used_valdators = {
        id: 0,
        valid: respJson.ocs.data.result,
        result: JSON.stringify(respJson),
      };
    });

    return newValidateDocData;
  } catch (error) {
    console.log("Parse response validate doc error:", error);
    // fill _used_valdators in used docs
    docsWithUsedValidators.forEach((doc) => {
      doc._used_valdators = { id: 0, result: "" };
    });
    return {};
  }
}

async function generateValidateData(
  validateDocuments,
  flightDate,
  preparedScheme,
  getValidateDocDataCallback
) {
  let validateData = { flight_date: flightDate };
  for (const doc of validateDocuments) {
    // skip empty validate_doc
    if (doc.type == "validate_doc" && doc._internal_doc) {
      continue;
    }

    if (doc.hasOwnProperty("_group_names") && doc["_group_names"].size > 0) {
      for (const group of doc["_group_names"]) {
        validateData = Object.assign(
          validateData,
          generateDocumetsObject(doc, group)
        );
      }
    } else {
      validateData = Object.assign(
        validateData,
        generateDocumetsObject(doc, null)
      );
    }
  }

  // fill validate doc data
  for (let doc of validateDocuments) {
    if (doc.type == "validate_doc" && doc._internal_doc) {
      validateData = Object.assign(
        validateData,
        await getValidateDocData(
          doc,
          validateData,
          preparedScheme,
          getValidateDocDataCallback,
          validateDocuments
        )
      );
      break;
    }
  }

  return validateData;
}

function parseErrors(lastStep) {
  let submittedErrors = {};
  let errors = {};
  for (const [errKey, errValue] of Object.entries(lastStep["errors"])) {
    if (errKey.includes(".submitted")) {
      submittedErrors[errKey.split(".submitted")[0]] = [
        "Document is not submitted",
      ];
    } else {
      errors[errKey] = errValue;
    }
  }
  return [errors, submittedErrors];
}

function getAllErrorDocTypes(validateResults) {
  let allErrorDocTypes = [];
  for (const step of validateResults) {
    for (const [errKey, errValue] of Object.entries(step["errors"])) {
      allErrorDocTypes.push(errKey.split(".")[0]);
    }
  }

  return allErrorDocTypes;
}

function docInArrayById(docs, docId) {
  for (let item of docs) {
    if (item["id"] == docId) {
      return true;
    }
  }
  return false;
}

function delDuplicateDocsFromArray(docs) {
  let unicData = [];

  for (let item of docs) {
    if (!docInArrayById(unicData, item["id"])) {
      unicData.push(item);
    }
  }
  return delInternalFieldsAndDocsFromArray(unicData);
}

function checkDocIsRejected(doc, errors) {
  if (isEmpty(doc) || isEmpty(errors)) {
    return false;
  }
  if (errors.has(doc["type"])) {
    return true;
  }
  if (doc.hasOwnProperty("_group_names")) {
    for (let group of doc["_group_names"]) {
      if (errors.has("[G]" + group)) {
        return true;
      }
    }
  }
  return false;
}

function prepareDocsForUpdateAutoVerifyStatus(docs, allErrorsDocTypes) {
  let docWithValidators = {};
  for (let doc of docs) {
    if (doc.hasOwnProperty("_used_valdators")) {
      let docUsedValidators = [];

      if (doc.hasOwnProperty("auto_verification_result")) {
        let tmpAvr = JSON.parse(doc.auto_verification_result);
        if (!isEmpty(tmpAvr) && tmpAvr.hasOwnProperty("used_valdators")) {
          tmpAvr.used_valdators.forEach((v) => {
            if (v.id != 0) docUsedValidators.push(v);
          });
        }
      }
      docUsedValidators.push(doc._used_valdators);
      docWithValidators[doc.id] = docUsedValidators;
    }
  }

  let uniqueDocs = delDuplicateDocsFromArray(docs);
  for (let doc of uniqueDocs) {
    // for online validate docs
    if (docWithValidators.hasOwnProperty(doc.id)) {
      doc["auto_verification_result"] = JSON.stringify({
        form:
          doc["verified_auto"] == 1
            ? "Form data from scheme docs and groups data."
            : "Form not found in scheme.",
        rejected: checkDocIsRejected(doc, allErrorsDocTypes),
        used_valdators: docWithValidators[doc.id],
      });
    }
    // for local validate docs
    else {
      doc["auto_verification_result"] = JSON.stringify({
        form:
          doc["verified_auto"] == 1
            ? "Form data from scheme docs and groups data."
            : "Form not found in scheme.",
        rejected: checkDocIsRejected(doc, allErrorsDocTypes),
      });
    }
  }

  return uniqueDocs;
}

function fillAutoIgnoredData(autoIgnoreStep, scheme) {
  let ignoredDocs = [];
  let autoIgnoredDocument = { step_id: autoIgnoreStep };

  for (let step of scheme["cerber_schema"]) {
    if (step["id"] == autoIgnoreStep) {
      if (step["documentOrGroup"]["type"] == "group") {
        autoIgnoredDocument["id"] = "[G]" + step["documentOrGroup"]["value"];
        ignoredDocs.push("[G]" + step["documentOrGroup"]["value"]);
        break;
      }

      if (step["documentOrGroup"]["type"] == "document") {
        for (let d of scheme["full_data_documents"]) {
          if (d["id"] == step["documentOrGroup"]["value"]) {
            autoIgnoredDocument["id"] = d["type"];
            ignoredDocs.push(d["type"]);
            break;
          }
        }
      }
      break;
    }
  }
  return { ignored_docs: ignoredDocs, auto_ignore_doc: autoIgnoredDocument };
}

function prepareScheme(scheme, autoIgnoreStep) {
  if (isEmpty(scheme || isEmpty(scheme.cerber_schema))) {
    return null;
  }
  let preparedScheme = {};

  for (const step of scheme.cerber_schema) {
    preparedScheme[step.id] = step;

    if (step["id"] == autoIgnoreStep) {
      preparedScheme[step.id]["autoIgnoredData"] = fillAutoIgnoredData(
        step["id"],
        scheme
      );
    }
  }
  return preparedScheme;
}

function checkDocExist(errors) {
  for (const key in errors) {
    if (errors[key].indexOf("required field") > -1) {
      return false;
    }
  }
  return true;
}

function stepInIgnoredDocs(step, ignoredDocs) {
  if (isEmpty(ignoredDocs) || isEmpty(step["schema0"]["native_schema"]))
    return false;
  for (const [fieldName, fieldValue] of Object.entries(
    step["schema0"]["native_schema"]
  )) {
    if (ignoredDocs.includes(fieldName.split(".")[0])) return true;
  }
  return false;
}

function validateScheme(
  stepId,
  preparedScheme,
  preparedDocs,
  ignoredDocs,
  autoIgnoreStep
) {
  // 1) came a step
  // 2) step goes under ignored_documents -> go to else
  // 3) step false ->
  // 3.1) needForm = false -> go to else
  // 3.2) needForm = true ->
  // 3.2.1) there is a document -> go to else
  // 3.2.2) no document ->
  // 3.2.2.1) step id matches auto_ignore_step -> add the document/step group to ignored_documents and go to else
  // 3.2.2.2) the step ID does not match -> return the scheme resolver

  if (stepId == "-1") return [[], []];

  let results = [];
  let newIgnoredDocs = [];
  let step = preparedScheme[stepId];

  // skip validate step if step contains ignored docs
  if (stepInIgnoredDocs(step, ignoredDocs)) {
    results.push({
      step: step["id"],
      result: false,
      errors: {},
      resolvers: {},
      then: step["then"],
      else: step["else"],
      step_ignored_by_ignoredDocs: true,
    });
    // results = results.concat(validateScheme(step.else, preparedScheme, preparedDocs, ignoredDocs, autoIgnoreStep));

    let [tmpResults, tmpIgnoreDocs] = validateScheme(
      step.else,
      preparedScheme,
      preparedDocs,
      ignoredDocs,
      autoIgnoreStep
    );
    results = results.concat(tmpResults);
    newIgnoredDocs = newIgnoredDocs.concat(tmpIgnoreDocs);

    return [results, newIgnoredDocs];
  }

  let errors = cerberValidate(step["schema0"]["native_schema"], preparedDocs); // fix for more schemes schema1...
  let result = isEmpty(errors);

  if (result) {
    // step true
    results.push({
      step: step["id"],
      result: result,
      errors: {},
      resolvers: {},
      then: step["then"],
      else: step["else"],
    });
    if (step["then"] == "-1") return [results, newIgnoredDocs];
    // results = results.concat(validateScheme(step.then, preparedScheme, preparedDocs, ignoredDocs, autoIgnoreStep));

    let [tmpResults, tmpIgnoreDocs] = validateScheme(
      step.then,
      preparedScheme,
      preparedDocs,
      ignoredDocs,
      autoIgnoreStep
    );
    results = results.concat(tmpResults);
    newIgnoredDocs = newIgnoredDocs.concat(tmpIgnoreDocs);
  } else {
    // step false
    results.push({
      step: step["id"],
      result: result,
      errors: errors,
      resolvers: step["schema0"]["resolvers"],
      then: step["then"],
      else: step["else"],
    });

    // if (step["else"] == "-1") return [results, newIgnoredDocs];

    if (!step["needForm"]) {
      // results = results.concat(validateScheme(step.else, preparedScheme, preparedDocs, ignoredDocs, autoIgnoreStep));

      let [tmpResults, tmpIgnoreDocs] = validateScheme(
        step.else,
        preparedScheme,
        preparedDocs,
        ignoredDocs,
        autoIgnoreStep
      );
      results = results.concat(tmpResults);
      newIgnoredDocs = newIgnoredDocs.concat(tmpIgnoreDocs);
    } else {
      if (checkDocExist(errors)) {
        // results = results.concat(validateScheme(step.else, preparedScheme, preparedDocs, ignoredDocs, autoIgnoreStep));

        let [tmpResults, tmpIgnoreDocs] = validateScheme(
          step.else,
          preparedScheme,
          preparedDocs,
          ignoredDocs,
          autoIgnoreStep
        );
        results = results.concat(tmpResults);
        newIgnoredDocs = newIgnoredDocs.concat(tmpIgnoreDocs);
      } else {
        if (step["id"] == autoIgnoreStep) {
          // fill ignoreDocs from auto ignore step
          if (
            !isEmpty(preparedScheme[step["id"]]["autoIgnoredData"]) &&
            !isEmpty(
              preparedScheme[step["id"]]["autoIgnoredData"]["ignored_docs"]
            )
          ) {
            newIgnoredDocs = newIgnoredDocs.concat(
              preparedScheme[step["id"]]["autoIgnoredData"]["ignored_docs"]
            );
          }

          // results = results.concat(validateScheme(step.else, preparedScheme, preparedDocs, ignoredDocs, autoIgnoreStep));
          let [tmpResults, tmpIgnoreDocs] = validateScheme(
            step.else,
            preparedScheme,
            preparedDocs,
            ignoredDocs,
            autoIgnoreStep
          );
          results = results.concat(tmpResults);
          newIgnoredDocs = newIgnoredDocs.concat(tmpIgnoreDocs);
        } else {
          results[results.length - 1]["schemeResolver"] = {
            step_id: step.id,
            schem_names: ["schema0"],
          }; // fix for more schemes schema1...
          console.log(
            "Stop validate: NeedForm true and Document no exist and Step != autoIgnoreStep, show resolver.",
            step["id"]
          );
          return [results, newIgnoredDocs];
        }
      }
    }
  }

  return [results, newIgnoredDocs];
}

function checkStepWithVirtualDocs(errors, virtualDocs) {
  if (isEmpty(errors) || isEmpty(virtualDocs)) return false;
  for (const [fieldName, fieldValue] of Object.entries(errors)) {
    if (virtualDocs.has(fieldName.split(".")[0])) return true;
  }
  return false;
}

function fillDocDataFromScheme(step, preparedScheme, preparedDocs) {
  let docsData = {};
  let schemeFields = preparedScheme[step]["schema0"]["native_schema"];

  for (const fieldName of Object.keys(schemeFields)) {
    let fieldValue = schemeFields[fieldName];

    if (fieldValue.hasOwnProperty("submitted")) {
      docsData[fieldName] = true;
      continue;
    }

    if (fieldValue.hasOwnProperty("allowed")) {
      docsData[fieldName] = fieldValue["allowed"][0];
      continue;
    }

    if (fieldValue.hasOwnProperty("valid_date")) {
      let validDateRule = fieldValue["valid_date"];
      let scale = validDateRule["scale"];
      let direction = validDateRule["direction"];
      let params_value = validDateRule["value"];
      let comparedDate =
        typeof preparedDocs[validDateRule["compared_date"]] === "string"
          ? new Date(Date.parse(preparedDocs[validDateRule["compared_date"]]))
          : preparedDocs[validDateRule["compared_date"]];

      let sign = validDateRule["sign"];
      let newDate;
      if (sign == "<") {
        params_value = params_value - 1;
        newDate = new Date(
          scale == "year"
            ? comparedDate.getFullYear() + params_value * direction
            : comparedDate.getFullYear(),
          comparedDate.getMonth(),
          scale == "day"
            ? comparedDate.getDate() + params_value * direction
            : comparedDate.getDate()
        );
      }

      if (sign == ">") {
        params_value = params_value + 1;
        newDate = new Date(
          scale == "year"
            ? comparedDate.getFullYear() + params_value * direction
            : comparedDate.getFullYear(),
          comparedDate.getMonth(),
          scale == "day"
            ? comparedDate.getDate() + params_value * direction
            : comparedDate.getDate()
        );
      }
      docsData[fieldName] = newDate.toISOString().split("T")[0];
      continue;
    }

    if (fieldValue.hasOwnProperty("should_match")) {
      let shouldMatchField =
        fieldValue["should_match"][fieldValue["should_match"].length - 1];

      if (preparedDocs.hasOwnProperty(shouldMatchField)) {
        docsData[fieldName] = preparedDocs[shouldMatchField];
      } else {
        docsData[fieldName] = "empty data";
        docsData[shouldMatchField] = "empty data";
      }
      continue;
    }

    if (
      fieldValue.hasOwnProperty("valid_before") ||
      fieldValue.hasOwnProperty("valid_after")
    ) {
      docsData[fieldName] = fieldValue["compared_date"];
      continue;
    }

    if (fieldValue.hasOwnProperty("strcontains")) {
      docsData[fieldName] = fieldValue["strcontains"];
      continue;
    }

    if (fieldValue.hasOwnProperty("starts_with")) {
      docsData[fieldName] = fieldValue["starts_with"];
      continue;
    }

    if (fieldValue.hasOwnProperty("ends_with")) {
      docsData[fieldName] = fieldValue["ends_with"];
      continue;
    }

    if (fieldValue.hasOwnProperty("max_ne")) {
      docsData[fieldName] = fieldValue["allowed"] - 1;
      continue;
    }

    if (fieldValue.hasOwnProperty("min_ne")) {
      docsData[fieldName] = fieldValue["allowed"] + 1;
      continue;
    }

    if (fieldValue.hasOwnProperty("allowed")) {
      docsData[fieldName] = fieldValue["allowed"][0];
      continue;
    }

    if (fieldValue.hasOwnProperty("contains")) {
      docsData[fieldName] = fieldValue["contains"];
      continue;
    }
  }
  return docsData;
}

function generateSchemeResolverVariants(
  preparedDocs,
  preparedScheme,
  virtualDocs
) {
  console.log("RUN Generate scheme resolver variants");
  let docs = new Set();
  let errorStep;
  let validateResults, newIgnoredDocs;
  let previousErrorStep = null;
  let tmpPreparedDocs = { ...preparedDocs };
  let recursionCount = 0;
  try {
    for (let attemp = 0; attemp < 5; attemp++) {
      if (recursionCount++ >= 1000) {
        console.log("\tGenerate Scheme Resolver Variants recursion limit");
        break;
      }

      [validateResults, newIgnoredDocs] = validateScheme(
        "start",
        preparedScheme,
        tmpPreparedDocs,
        [],
        ""
      );
      errorStep = validateResults[validateResults.length - 1];

      if (checkStepWithVirtualDocs(errorStep["errors"], virtualDocs)) {
        // console.log("Found step: \"else=1 and virtual doc\":", errorStep["step"], ", select previous step");

        if (validateResults.length - 2 > 0) {
          errorStep = validateResults[validateResults.length - 2];
        } else {
          console.log(
            "Virteal doc in first step, impossible to use the previous step. Return null."
          );
        }
      }

      // console.log("\n\nRun validate data:", tmpPreparedDocs, "ERROR STEP:", errorStep);
      if (errorStep["result"]) return Array.from(docs);

      for (const [fieldName, fieldValue] of Object.entries(
        errorStep["errors"]
      )) {
        // if (fieldName == "Validator") continue;
        docs.add(fieldName.split(".")[0]);
        tmpPreparedDocs = Object.assign(
          tmpPreparedDocs,
          fillDocDataFromScheme(
            errorStep["step"],
            preparedScheme,
            tmpPreparedDocs
          )
        );
      }

      if (previousErrorStep != errorStep["step"]) {
        previousErrorStep = errorStep["step"];
        attemp -= 1;
      }
    }
  } catch (error) {
    console.log("RUN Generate scheme resolver variants error:", error);
  }

  return Array.from(docs);
}

function getPathFromValidateResults(validateResults) {
  let path = [];
  validateResults.forEach((step) => {
    path.push(step["step"]);
  });
  return path;
}

async function runValidateDocumentSets(
  scheme,
  validateDatas,
  ignoredDocs,
  requestData,
  virtualDocs,
  getValidateDocDataCallback
) {
  // ignoredDocs - filter errors and resolvers,
  let validateResults = []; // virtualDocs - filter no docs for check doc submitted in step
  let allErrorDocTypes = [];
  let preparedScheme = prepareScheme(scheme, requestData["auto_ignore_step"]);

  for (const docs of validateDatas) {
    let preparedDocs = await generateValidateData(
      docs,
      requestData["session_info"]["flight_date"],
      preparedScheme,
      getValidateDocDataCallback
    );
    let [tmpValidateResults, validate_variant_ignored_docs] = validateScheme(
      "start",
      preparedScheme,
      preparedDocs,
      ignoredDocs,
      requestData["auto_ignore_step"]
    );
    let lastStep = tmpValidateResults[tmpValidateResults.length - 1];
    let validateResult = lastStep["result"] && lastStep["then"] == "-1";

    let [errors, submittedErrors] = parseErrors(lastStep);
    allErrorDocTypes = allErrorDocTypes.concat(
      getAllErrorDocTypes(tmpValidateResults)
    );

    let documents = [];
    for (let doc of docs) {
      if (doc.hasOwnProperty("_internal_doc")) {
        continue;
      }

      documents.push({
        id: doc["id"],
        version: doc["version"],
        type: doc["type"],
        verified_auto: doc["verified_auto"],
        verified_manual: doc["verified_manual"],
        category:
          doc.hasOwnProperty("category") && doc["category"]
            ? doc["category"]
            : null,
        _group_names:
          doc.hasOwnProperty("_group_names") && !isEmpty(doc["_group_names"])
            ? doc["_group_names"]
            : null,
      });
    }

    validateResults.push({
      validate_result: validateResult,
      errors: errors,
      submitted_errors: submittedErrors,
      documents: documents,
      resolvers:
        !validateResult && !isEmpty(lastStep["resolvers"])
          ? lastStep["resolvers"]
          : {},
      scheme_resolver:
        !validateResult && !isEmpty(lastStep["schemeResolver"])
          ? lastStep["schemeResolver"]
          : {},
      resolver_variants:
        !lastStep["result"] && lastStep["else"] == "-1"
          ? [
              generateSchemeResolverVariants(
                preparedDocs,
                preparedScheme,
                virtualDocs
              ),
            ]
          : [],
      // "resolver_variants": generateSchemeResolverVariants(preparedDocs, preparedScheme, virtualDocs),
      auto_ignore_data: requestData["auto_ignore_step"]
        ? preparedScheme[requestData["auto_ignore_step"]]["autoIgnoredData"]
        : {},
      selected_path: getPathFromValidateResults(tmpValidateResults),
      validate_variant_ignored_docs: validate_variant_ignored_docs,
    });
  }

  return [validateResults, allErrorDocTypes];
}

function delInternalFieldsAndDocsFromArray(data) {
  if (isEmpty(data)) {
    return null;
  }

  let tmpResult = [];
  for (const item of data) {
    // del intrenal docs like session_info
    if (item.hasOwnProperty("_internal_doc") && item["_internal_doc"]) {
      continue;
    }

    let tmpObject = {};
    for (const [fieldKey, fieldValue] of Object.entries(item)) {
      if (fieldKey.charAt(0) != "_") {
        tmpObject[fieldKey] = fieldValue;
      }
    }
    tmpResult.push(tmpObject);
  }
  return tmpResult;
}

function parseValidateResults(validateResults, requestData, verifiedDocsOnly) {
  let result = {};
  let validDocs = [];
  let allValidDocs = [];

  for (const validateResult of validateResults) {
    if (validateResult["validate_result"]) {
      if (isEmpty(result)) {
        result = validateResult;
      } else {
        for (const doc of validateResult["documents"]) {
          if (!validDocs.includes(doc) && !result["documents"].includes(doc)) {
            validDocs.push(doc);
          }
        }
      }
    }

    //check all valid docs
    let errorDocTypes = [];
    for (const [tmpErrKey, tmpErrValue] of Object.entries(
      validateResult["errors"]
    )) {
      // узнать ошибки по шагу или по пути !!!
      try {
        errorDocTypes.push(tmpErrKey.split(".")[0]);
      } catch (e) {
        console.log(
          `\t Check all valid docs, parse errors, field: ${
            (tmpErrKey, tmpErrValue)
          }, ${e}`
        );
      }
    }

    for (const tmpDoc of validateResult["documents"]) {
      try {
        if (
          tmpDoc.hasOwnProperty("_group_names") &&
          !isEmpty(tmpDoc["_group_names"])
        ) {
          for (const tmpGroupName of tmpDoc["_group_names"]) {
            if (errorDocTypes.includes("[G]" + tmpGroupName)) {
              console.log(
                `\tDocument like Group: ${
                  "[G]" + tmpGroupName
                } found in errors, ignore for all valid docs...`
              );
              throw new Error("Document like Group found in errors");
            }
          }
        } else {
          if (errorDocTypes.includes(tmpDoc["type"])) {
            console.log(
              `\tDocument: ${tmpDoc["type"]} found in errors, ignore for all valid docs...`
            );
            throw new Error("Document found in errors");
          }
        }

        allValidDocs.push(tmpDoc);
      } catch (e) {
        console.log(`\tCheck all valid docs: ${e}`);
      }
    }
  }

  if (isEmpty(result)) {
    result = validateResults[0];
  }

  let ignoredDocuments = [
    ...requestData["ignored_documents"],
    ...result["validate_variant_ignored_docs"],
  ];
  let autoIgnoredDocument = {};

  if (!isEmpty(result["auto_ignore_data"])) {
    autoIgnoredDocument = result["auto_ignore_data"]["auto_ignore_doc"];
  }

  return {
    session_id: requestData["session_id"],
    session_info: requestData["session_info"],
    result: result["validate_result"],
    used_documents: delDuplicateDocsFromArray(result["documents"]),
    valid_documents: delDuplicateDocsFromArray(validDocs),
    all_valid_documents: delDuplicateDocsFromArray(allValidDocs),
    resolvers: result["resolvers"],
    scheme_resolver: result["scheme_resolver"],
    submitted_errors: result["submitted_errors"],
    errors: result["errors"],
    verified_docs_only: verifiedDocsOnly,
    scheme_resolver_variants: result["resolver_variants"],
    ignored_documents: ignoredDocuments,
    auto_ignored_document: autoIgnoredDocument,
    auto_ignore_step: !isEmpty(requestData["auto_ignore_step"])
      ? requestData["auto_ignore_step"]
      : null,
    selected_path: result["selected_path"],
  };
}

async function runValidate(
  docs,
  scheme,
  requestData,
  virtualDocs,
  verifiedStatus,
  getValidateDocDataCallback
) {
  let validateDataSets = generateValidateDataSets(docs);
  console.log(`Count validate variants: ${validateDataSets.length}`);
  let ignoredDocs = isEmpty(requestData["ignored_documents"])
    ? []
    : requestData["ignored_documents"];
  const [validateResults, allErrorDocTypes] = await runValidateDocumentSets(
    scheme,
    validateDataSets,
    ignoredDocs,
    requestData,
    virtualDocs,
    getValidateDocDataCallback
  );
  return [
    parseValidateResults(validateResults, requestData, verifiedStatus),
    allErrorDocTypes,
  ];
}

export default async function validate(
  documents,
  scheme,
  requestData,
  getValidateDocDataCallback
) {
  console.log("Validator version:", VERSION);

  if (isEmpty(documents))
    throw IncompleteIncomingDataError("No documents data");
  if (isEmpty(scheme)) throw new IncompleteIncomingDataError("No scheme data");
  if (isEmpty(requestData))
    throw new IncompleteIncomingDataError("No request data");
  if (isEmpty(requestData["session_info"]))
    throw new IncompleteIncomingDataError("No session_info in request data");
  // if (isEmpty(requestData["ignored_documents"])) throw new IncompleteIncomingDataError("No ignored_documents in request data");

  if (isEmpty(scheme["cerber_schema"]))
    throw new IncompleteIncomingDataError("No cerber_schema in scheme");
  if (isEmpty(scheme["full_data_documents"]))
    throw new IncompleteIncomingDataError("No full_data_documents in scheme");
  if (isEmpty(scheme["full_data_group_documents"]))
    throw new IncompleteIncomingDataError(
      "No full_data_group_documents in scheme"
    );

  console.log("Used scheme:", scheme["id"], "version:", scheme["rev"]);

  // console.log("Input docs:", JSON.stringify(documents));
  // console.log("RequestData:", JSON.stringify(requestData));

  // del internal fields in incoming docs
  documents.forEach((doc) => {
    if (doc.hasOwnProperty("_scheme_fields")) delete doc["_scheme_fields"];
    if (doc.hasOwnProperty("_group_names")) delete doc["_group_names"];
  });

  const [preparedDocs, virtualDocs] = prepareDocs(
    [...documents],
    { ...scheme },
    { ...requestData["session_info"] }
  );
  console.log(
    `Count prepared docs: ${preparedDocs.length}, Virtual docs: ${virtualDocs.size}`
  );

  const [manualAndAutoVerifiedDocs, unverifiedDocs] =
    devideDocsByVerifyStatus(preparedDocs);
  console.log(
    `Count manual or auto verified docs: ${manualAndAutoVerifiedDocs.length}, manual unverified docs: ${unverifiedDocs.length}`
  );

  // console.log("Preapred docs:", JSON.stringify(preparedDocs, null, 4));

  let validateResult = null;
  let tmpAllErrorDocTypes = null;
  let allErrorDocTypes = [];

  if (!isEmpty(manualAndAutoVerifiedDocs)) {
    console.log("Run validate verified docs");
    [validateResult, tmpAllErrorDocTypes] = await runValidate(
      manualAndAutoVerifiedDocs,
      scheme,
      requestData,
      virtualDocs,
      true,
      getValidateDocDataCallback
    );
    allErrorDocTypes = allErrorDocTypes.concat(tmpAllErrorDocTypes);
  }

  if (!validateResult["result"] && !isEmpty(unverifiedDocs)) {
    console.log("Run validate all docs");
    [validateResult, tmpAllErrorDocTypes] = await runValidate(
      preparedDocs,
      scheme,
      requestData,
      virtualDocs,
      false,
      getValidateDocDataCallback
    );
    allErrorDocTypes = allErrorDocTypes.concat(tmpAllErrorDocTypes);
  }

  // let outputDocs = prepareDocsForUpdateAutoVerifyStatus(preparedDocs, new Set(allErrorDocTypes));
  // console.log("Output docs:", JSON.stringify(outputDocs));
  // console.log("Output validate result:", JSON.stringify(validateResult));

  return [
    validateResult,
    prepareDocsForUpdateAutoVerifyStatus(
      preparedDocs,
      new Set(allErrorDocTypes)
    ),
  ];
  // return [validateResult, outputDocs];
}

// module.exports = validate;
