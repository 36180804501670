import { CSSProperties } from "react";
import { useNavigate } from "react-router-dom";
import Bag from "wmelon/models/Bag";
import Tag from "wmelon/models/Tag";
import { Card } from "antd";
import { database } from "wmelon/database";
import { withObservables } from "@nozbe/watermelondb/react";
import { Q } from "@nozbe/watermelondb";
import { Observable } from "@nozbe/watermelondb/utils/rx";

const { Meta } = Card;
interface Props {
  bag?: Bag;
  tags?: Tag[];
}

function BagsCard({ bag, tags }: Props) {
  console.log(bag, "helo");
  const navigate = useNavigate();

  // const goToBag = useCallback(() => {
  //   navigate(`/bags/${bag?.id}`);
  // }, []);
  return (
    <div style={BagCardStyle}>
      <Card
        key={bag?.id}
        onClick={() => {
          navigate(`/bags/${bag?.id}`);
        }}
        hoverable
        style={{
          width: 250,
          marginRight: 10,
          marginLeft: 10,
        }}
        cover={
          <img
            alt="example"
            src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
          />
        }
      >
        <Meta
          title={bag?.name}
          description={bag?.tag_id}
          style={{ textAlign: "center" }}
        />
        {tags?.map((tag) => (
          <Meta
            key={tag.id}
            description={tag.name}
            style={{ ...Tagcontainers, backgroundColor: tag.color }}
          />
        ))}
      </Card>
    </div>
  );
}

const enhance = withObservables<{ bag: Bag }, { tags: Observable<Tag[]> }>(
  ["bag"],
  ({ bag }) => ({
    tags: database
      .get<Tag>(Tag.table)
      .query(Q.on("tagBinds", "object_id", bag.id), Q.take(1))
      .observeWithColumns(["name", "color"]),
  })
);
const BagsCardEnhance = enhance(BagsCard as any);

export default BagsCardEnhance;

const BagCardStyle: CSSProperties = {
  marginLeft: 20,
};
const Tagcontainers: CSSProperties = {
  textAlign: "center",
  padding: "5px 10px 5px 10px",
  borderRadius: "15px",
  marginTop: 10,
};
