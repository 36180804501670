import { useCallback, useEffect, useState } from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { isMobile } from "react-device-detect";
import { CloseOutlined } from "@ant-design/icons";
import { APP_COLORS } from "utils/colors";

export type TQrReaderProps = {
  handleScan: (data: string) => void;
  onClose: () => void;
};

const videoStyles = {
  height: "100%",
  width: "100%",
  backgroundColor: APP_COLORS.light.primary_text_color,
  borderRadius: "16px",
};

const Scanner = ({ handleScan, onClose }: TQrReaderProps) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    if (!isMobile) {
      if (navigator.permissions) {
        //@ts-ignore
        navigator?.permissions
          ?.query({ name: "camera" } as any)
          .then((permission) => {
            if (permission.state === "prompt") {
              setError(t("AddingFlight.camera_access_error"));
            }
            permission.onchange = () => {
              if (permission.state === "granted") {
                setError(null);
              }
            };
          })
          .catch((err) => {
            console.log("[Scanner] getPermissions err: ", err);
          });
      }
    }
  }, [t]);

  const hanldeClose = useCallback(() => {
    onClose();
    setError(null);
  }, [onClose]);

  const onShowErrorModal = useCallback(() => {
    return modal.error({
      title: t("AddingFlight.camera"),
      content: error,
      okButtonProps: { size: "small",style:{
        color:APP_COLORS.light.default_btn,
        backgroundColor:APP_COLORS.light.primary,

      } },
      okText: t("ok"),
      onOk: () => {
        setError(null);
      },
      
    });
  }, [error, modal, t]);

  const handleError = useCallback(
    (error) => {
      if (error) {
        console.log("[Scanner] camera:", error);
        switch (true) {
          case String(error).includes("Requested device not found"):
            setError(t("AddingFlight.camera_not_found"));
            break;
          case String(error).includes("Permission denied"):
          case String(error).includes(
            "The request is not allowed by the user agent or the platform in the current context."
          ):
          case String(error).includes(
            "The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission."
          ):
            setError(t("AddingFlight.camera_permission_denied"));
            break;
          case String(error).includes(
            'MediaDevices API has no support for your browser. You can fix this by running "npm i webrtc-adapter"'
          ):
            setError(t("AddingFlight.failed_to_show_video"));
            break;
          default:
            break;
        }
      }
    },
    [t]
  );

  useEffect(() => {
    error && onShowErrorModal();
  }, [error, onShowErrorModal]);

  return (
    <div style={{ position: "relative" }}>
      <p
        style={{
          position: "absolute",
          right: "15px",
          top: "15px",
          zIndex: 999,
          margin: 0,
        }}
      >
        <CloseOutlined
          style={{ color: APP_COLORS.light.primary }}
          onClick={hanldeClose}
        />
      </p>
      <QrScanner
        containerStyle={videoStyles}
        onDecode={handleScan}
        onError={(err) => handleError(err)}
        scanDelay={500}
      />
      {contextHolder}
    </div>
  );
};

export default Scanner;
