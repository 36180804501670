import { useCallback } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Image, Upload } from "antd";
import type { UploadChangeParam } from "antd/es/upload";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { beforeUploadProfile, getBase64Profile } from "utils/file";
import "./style.css";
import { useTranslation } from "react-i18next";
interface Props {
  value?: any;
  onChange: (name: string, value: any) => void;
}
const UserPicture = ({ value, onChange }: Props) => {
  const { t } = useTranslation();

  const handleChange: UploadProps["onChange"] = useCallback(
    (event: UploadChangeParam<UploadFile>) => {
      console.log("FILE:", event);
      getBase64Profile(event.file as RcFile, (url) => {
        onChange("avatar", url);
      });
    },
    [onChange]
  );
  const uploadButton = (
    <div className="uploadButton">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PlusOutlined />
      </div>
      <div style={{ marginLeft: 5 }}>{t("Profile.upload")}</div>
    </div>
  );

  return (
    <div className="avatarContainer">
      <Upload
        name="avatar"
        showUploadList={false}
        customRequest={() => {}}
        maxCount={1}
        beforeUpload={beforeUploadProfile}
        onChange={handleChange}
      >
        {value ? (
          <>
            <Image
              preview={false}
              src={value}
              style={{ objectFit: "cover" }}
              alt="avatar"
              className="avatar"
            />
          </>
        ) : (
          <div className="noAvatar">{uploadButton}</div>
        )}
      </Upload>
    </div>
  );
};

export default UserPicture;
