import { useCallback, useEffect, useMemo, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import cn from "classnames";
import Pagination from "components/Pagination";

import "./PDFViewer.css";
import { APP_COLORS } from "utils/colors";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export interface PDFViewerProps {
  file: any;
  height?: number | undefined;
  width?: number | undefined;
  hidePagination?: boolean;
  paginationWidth?: number | undefined;
  onClick?: () => void;
  onError: () => void;
}

function PDFViewer({
  file,
  height,
  width,
  paginationWidth,
  onError,
  onClick,
  hidePagination,
}: PDFViewerProps) {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    file && setLoading(true);
  }, [file]);

  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(0);
    setLoading(false);
  }, []);

  const onChangePage = useCallback((idx: number) => {
    setPageNumber(idx);
  }, []);

  const paginationIsVisible = useMemo(() => {
    return numPages > 1;
  }, [numPages]);

  return (
    <div className={cn("PDFViewer", { hidePagination })}>
      {loading ? (
        <Spin
          indicator={
            <LoadingOutlined
              style={{ fontSize: 24, color: APP_COLORS.light.background }}
              spin
            />
          }
        />
      ) : null}
      <Document
        className="PDFViewer-pdf"
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={onError}
        loading={""}
        onClick={onClick}
      >
        <Page pageNumber={pageNumber + 1} height={height} width={width} />
      </Document>
      {paginationIsVisible ? (
        <Pagination
          width={paginationWidth}
          index={pageNumber}
          count={numPages}
          onChangePage={onChangePage}
        />
      ) : null}
    </div>
  );
}

export default PDFViewer;
