import { Q } from "@nozbe/watermelondb";
import { database } from "wmelon/database";
import Airline from "wmelon/models/Airline";

async function getAirlineByCodes(codes: string[]): Promise<Airline[]> {
  return database
    .get<Airline>(Airline.table)
    .query(Q.where("icao", Q.oneOf(codes.map((code) => code.toUpperCase()))))
    .fetch()
    .catch((err) => {
      console.error("[getAirlineByCodes] failed to get:", err);
      return [];
    });
}

export { getAirlineByCodes };
