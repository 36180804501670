import { useMemo } from "react";
import groupBy from "lodash/groupBy";
import FlightTicket from "../wmelon/models/FlightTicket";
import FlightTicketBinds from "../wmelon/models/FlightTicketBinds";
import FlightTicketShare from "../wmelon/models/FlightTicketShare";
import Session from "../wmelon/models/Sessions";
import { getSortedTickets } from "utils/flights";
import { getShareStatus } from "utils/tickets";
import { ShareStatus } from "types/flights";
import { getSessionStatus } from "utils/session";
import { SESSION_STATUSES } from "../utils/session";

export interface UseUnvalidatedTicketsResponse {
  statuses: Record<
    string,
    { status: string | null; count: number; sessionStatus: string | null }
  >;
  shareStatuses: Record<string, ShareStatus | null>;
  unvalidated: FlightTicket[];
}

export function useUnvalidatedTickets(
  tickets: FlightTicket[],
  flightTicketBinds: FlightTicketBinds[],
  shares: FlightTicketShare[],
  sessions: Session[]
): UseUnvalidatedTicketsResponse {
  const statuses: Record<
    string,
    { status: string | null; sessionStatus: string | null; count: number }
  > = useMemo(() => {
    if (!sessions?.length) {
      return {};
    }

    const groupped = groupBy(sessions ?? [], (s) => s.flight_ticket_id);
    return Object.keys(groupped).reduce(
      (
        acc: Record<
          string,
          { status: string | null; count: number; sessionStatus: string | null }
        >,
        ticketId
      ) => {
        const sessionStatus = getSessionStatus(groupped[ticketId][0]);
        return {
          ...acc,
          [ticketId]: {
            status: sessionStatus.state || null,
            sessionStatus: groupped[ticketId][0]?.status,
            count: groupped[ticketId].length,
          },
        };
      },
      {} as Record<
        string,
        { status: string | null; count: number; sessionStatus: string | null }
      >
    );
  }, [sessions]);

  const shareStatuses: Record<string, ShareStatus | null> = useMemo(() => {
    if (!shares?.length) {
      return {};
    }

    const groupped = groupBy(shares ?? [], (s) => s.flight_ticket_id);
    return Object.keys(groupped).reduce(
      (acc: Record<string, ShareStatus | null>, ticketId: string) => {
        return {
          ...acc,
          [ticketId]: getShareStatus(groupped[ticketId]),
        };
      },
      {}
    );
  }, [shares]);

  const unvalidated = useMemo(() => {
    return getSortedTickets(tickets, flightTicketBinds, "desc").filter(
      (ticket: FlightTicket) => {
        if (shareStatuses[ticket.id] === ShareStatus.shared_by) {
          return false;
        }

        if (statuses[ticket.id]) {
          return (
            statuses[ticket.id].count < 9 &&
            [
              SESSION_STATUSES.COMPLETED,
              SESSION_STATUSES.WAIT_FOR_CHECK_VALIDATION,
              SESSION_STATUSES.WAIT_FOR_DOC_VALIDATION,
              SESSION_STATUSES.WAIT_FOR_TICKET,
            ].indexOf(statuses[ticket.id].sessionStatus || "") === -1
          );
        } else {
          return true;
        }
      }
    );
  }, [tickets, shareStatuses, statuses, flightTicketBinds]);

  return useMemo(
    () => ({
      statuses,
      shareStatuses,
      unvalidated,
    }),
    [statuses, shareStatuses, unvalidated]
  );
}
