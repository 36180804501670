import { useCallback, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Q } from "@nozbe/watermelondb";
import { withObservables } from "@nozbe/watermelondb/react";
import uniqBy from "lodash/uniqBy";
import { database } from "wmelon/database";
import FlightTicket from "../../../wmelon/models/FlightTicket";
import FlightTicketBinds from "../../../wmelon/models/FlightTicketBinds";
import FlightTicketShare from "../../../wmelon/models/FlightTicketShare";
import Session from "../../../wmelon/models/Sessions";

import { useReduxSession } from "../../../hooks/useReduxSession";
import { useTags } from "../../../hooks/useTags";
import { useDocuments } from "../../../hooks/useDocuments";
import { useUnvalidatedTickets } from "../../../hooks/useUnvalidatedTickets";
import { useFlights } from "../../../hooks/useFlights";
import { getSortedTickets } from "utils/flights";
import { getShareLink } from "utils/flights";
import { refreshDB } from "utils/db";
import { ReduxState } from "../../../redux/ReduxState";
import { getQueryDateValue, getQueryValue } from "../../../utils/db";
import { Observable } from "@nozbe/watermelondb/utils/rx";
import TicketsList from "./TicketsList";
import ModalCallCenter from "components/ModalCallCenter";
import DBTicketItemContainer from "./DBTicketItemContainer";
import Tags from "components/Tags";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import { APP_COLORS } from "utils/colors";
import ShareInfo from "components/TicketItem/components/ShareInfo";

export interface IProps {
  tickets: FlightTicket[];
  flightTicketBinds: FlightTicketBinds[];
  shares: FlightTicketShare[];
  sessions: Session[];
  params: {
    flight_number: string | null;
    date: string | null;
    pnr: string | null;
    airline: string | null;
    flight_origin: string | null;
    flight_destination: string | null;
  };
}

/*temp const*/
function DBTicketsList({
  tickets,
  flightTicketBinds,
  shares,
  sessions,
  params,
}: IProps) {
  const isSyncing = useSelector((state: ReduxState) => state.app.syncing);
  const { t } = useTranslation();
  const { startValidation, clearSession } = useReduxSession();
  const { clearDocuments } = useDocuments();
  const navigate = useNavigate();

  const { getBindTag, createBindTags, updateBindTags } = useTags();
  const { shareTicket, unshareTicket } = useFlights();

  const [showCallDialog, setShowCallDialog] = useState(false);
  const [tagTicketId, setTagTicketId] = useState<string | null>(null);
  const [modal, contextHolder] = Modal.useModal();

  const { unvalidated } = useUnvalidatedTickets(
    tickets,
    flightTicketBinds,
    shares,
    sessions
  );

  // useEffect(() => {
  //   if (!tickets.length && !isSyncing) {
  //     console.log("[DBTicketsList] no tickets:");
  //     navigate("/tickets");
  //   }
  // }, [tickets, isSyncing]); // eslint-disable-line
  console.log("DBTICKETS:", tickets.length, isSyncing, params);

  const onStartValidation = useCallback(
    async (ticket) => {
      let list = uniqBy([ticket, ...unvalidated], "id");
      console.log("START_VALIDATION:", ticket);
      Promise.all([
        clearSession(true).catch((err) =>
          console.log("[DBTicketsList] failed to clear session state:", err)
        ),
        clearDocuments().catch((err) =>
          console.log("[DBTicketsList] failed to clear documetns state:", err)
        ),
      ])
        .then(() => startValidation(list))
        .then(() => {
          navigate("/validator");
        })
        .catch((err) => {
          console.log("[DBTicketsList] failed to init session:", err);
          if (err.message === "SCHEMA_NOT_FOUND") {
            navigate("/tickets");
            Modal.confirm({
              title: t("Ticket_and_Info.schemeNotFound"),
              content: t("restrictionsWillBeUpdated"),
              okText: t("ok"),
              cancelText: t("Cancel"),
            });
          } else if (["AIRPORT_NOT_FOUND"].includes(err.message)) {
            refreshDB();
            Modal.info({
              content: t(err.message) + t("restrictionsWillBeUpdated"),
              okText: t("ok"),
            });
          } else {
            Modal.info({
              title: t("Ticket_and_Info.failedToStartValidation"),
              okText: t("ok"),
            });
          }
        });
    },
    [unvalidated, clearSession, startValidation, navigate, t, clearDocuments]
  );

  const onShareTicket = useCallback(
    (ticket: FlightTicket) => {
      return shareTicket(ticket.id)
        .then((responce) => {
          modal.info({
            title: t("Ticket_and_Info.share"),
            content: <ShareInfo link={getShareLink(responce.token)} />,
            icon: null,
            closable: true,
            className: "TicketItem-share",
            footer: null,
            okButtonProps: { size: "small", shape: "round" },
            okText: t("ok"),
          });
        })
        .catch((err) => {
          modal.error({
            title: t("Error"),
            content: t([err.message, "Ticket_and_Info.failedToShare"]),
            okText: t("ok"),
            okButtonProps: {
              size: "small",
              style: {
                color: APP_COLORS.light.primary_text_color,
                backgroundColor: APP_COLORS.light.primary,
              },
            },
          });
          console.log(
            "[DBTicketsList] share",
            t([err.message, "Ticket_and_Info.failedToShare"])
          );
        });
    },
    [shareTicket, t, modal]
  );

  const onUnshareTicket = useCallback(
    (share: FlightTicketShare) => {
      return new Promise((res: (value: void) => void) => {
        modal.confirm({
          title: t("Ticket_and_Info.unshare"),
          content: <span>{t("Ticket_and_Info.doYouWantToUnshare")}</span>,
          cancelButtonProps: {
            size: "small",
            shape: "round",
            style: {
              color: APP_COLORS.light.primary,
              borderColor: APP_COLORS.light.primary,
            },
          },
          okButtonProps: {
            size: "small",
            shape: "round",
            style: {
              color: APP_COLORS.light.primary_text_color,
              backgroundColor: APP_COLORS.light.primary,
            },
          },
          okText: t("ok"),
          cancelText: t("Cancel"),
          onOk: () => {
            unshareTicket(share)
              .catch((err) => {
                modal.error({
                  title: t("Error"),
                  content: t([err.message, "Ticket_and_Info.failedToUnshare"]),
                  okText: t("ok"),
                });
                console.log("[TicketInfoBlock] failed to unshare:", err);
              })
              .finally(() => res());
          },
          onCancel: () => res(),
        });
      });
    },
    [modal, t, unshareTicket]
  );

  const onSelectTag = useCallback(
    async (id: string) => {
      if (!tagTicketId) {
        return;
      }
      console.log("[DBTicketsList] update tag: id", id);
      const bind = await getBindTag(tagTicketId);
      console.log("bind", bind);
      if (bind.length > 0) {
        await updateBindTags(bind?.[0]?._raw?.id, id).catch((err) =>
          console.error("[DBTicketsList] failed to update bind:", err)
        );
      } else {
        await createBindTags({
          tag_id: id,
          bind_object: "pnrFlight",
          object_id: tagTicketId,
        }).catch((err) =>
          console.error("[DBTicketsList] failed to create bind:", err)
        );
      }
      // hasBind()
      setTagTicketId(null);
    },
    [tagTicketId, createBindTags, getBindTag, updateBindTags]
  );
  console.log("[DBTicketsList]", tagTicketId);
  const onCloseTags = useCallback(() => {
    setTagTicketId(null);
  }, []);

  const list = useMemo(() => {
    return getSortedTickets(tickets, flightTicketBinds, "desc");
  }, [tickets, flightTicketBinds]);

  const renderTicket = useCallback(
    (item: FlightTicket) => {
      return (
        <DBTicketItemContainer
          ticket={item}
          onEditTag={setTagTicketId}
          onStartValidation={onStartValidation}
          onShare={onShareTicket}
          onUnshare={onUnshareTicket}
          viewer={false}
          onCall={() => setShowCallDialog(true)}
        />
      );
    },
    [onStartValidation, onShareTicket, onUnshareTicket]
  );

  return (
    <>
      <TicketsList data={list} renderItem={renderTicket} />
      <ModalCallCenter
        text={""}
        withOutShortId
        visible={showCallDialog}
        onClose={() => setShowCallDialog(false)}
      />
      <Tags
        visible={!!tagTicketId}
        onClose={onCloseTags}
        onSelectTag={onSelectTag}
        canEdit={false}
      />
      {contextHolder}
    </>
  );
}

const enhance = withObservables<
  Omit<IProps, "tickets" | "flightTicketBinds" | "shares" | "sessions">,
  { tickets: Observable<FlightTicket[]> }
>(["params"], ({ params }) => {
  const {
    flight_number,
    date,
    pnr,
    airline,
    flight_origin,
    flight_destination,
  } = params;
  return {
    tickets: database
      .get<FlightTicket>(FlightTicket.table)
      .query(
        Q.where("flight_date", getQueryValue(date ? parseInt(date, 10) : null)),
        Q.where("flight_number", getQueryValue(flight_number)),
        Q.where(
          "identifiers",
          Q.like(`%:${Q.sanitizeLikeString(pnr || "")}"%`)
        ),
        Q.where("airline", getQueryValue(airline)),
        Q.where("flight_origin", getQueryValue(flight_origin)),
        Q.where("flight_destination", getQueryValue(flight_destination))
      )
      .observe(),
  };
});

const enhance2 = withObservables<
  Omit<IProps, "flightTicketBinds" | "shares" | "sessions">,
  {
    flightTicketBinds: Observable<FlightTicketBinds[]>;
    shares: Observable<FlightTicketShare[]>;
    sessions: Observable<Session[]>;
  }
>(["tickets"], ({ tickets }: { tickets: FlightTicket[] }) => {
  const ticketIds = tickets.map((it) => it.id);
  return {
    flightTicketBinds: database
      .get<FlightTicketBinds>(FlightTicketBinds.table)
      .query(Q.where("ticket_id", Q.oneOf(ticketIds)))
      .observe(),
    shares: database
      .get<FlightTicketShare>(FlightTicketShare.table)
      .query(Q.where("flight_ticket_id", Q.oneOf(ticketIds)))
      .observe(),
    sessions: database
      .get<Session>(Session.table)
      .query(
        Q.where("flight", getQueryValue(tickets[0]?.flight_number)),
        Q.where(
          "flight_date",
          getQueryDateValue(tickets[0]?.flight_date, "YYYY-MM-DD")
        ),
        Q.where("flight_ticket_id", Q.oneOf(ticketIds)),
        Q.sortBy("short_id", Q.desc)
      )
      .observeWithColumns(["status", "handler"]),
  };
});

const EnhancedDBTicketsList = enhance(enhance2(DBTicketsList));
export default EnhancedDBTicketsList;
