import get from "lodash/get";
import { CheckResult, RfidLocation } from "../constants";
import config from "../../../config";
import { ErrorTexts } from "../ErrorTexts";
import RegulaParser from "../RegulaParser";
import DateTimeUtils from "../DateTimeUtils";
import { calcPercentageOfMatch } from "utils/string";
import { JsonForm, JsonFormJson, JsonFormJsonSchema } from "../model/JsonForm";
import {
  ResultStatus,
  FormProcessorResponse,
  UserData,
} from "../model/FormProcessorResponse";
import { Document, DocumentField } from "../model/Document";
import { FormProcessorParseProps } from "../model/FormProcessorParseProps";
import { getFieldPath } from "utils/jsonform";
import Recognizer from "./Recognizer";
import {
  LCID,
  ProcessResponse,
  TextFieldType,
} from "@regulaforensics/document-reader-webclient";

const OWNER_EXCEPTION_FIELDS = [
  TextFieldType.DATE_OF_BIRTH,
  TextFieldType.SURNAME,
  TextFieldType.GIVEN_NAMES,
  TextFieldType.SURNAME_AND_GIVEN_NAMES,
];

export default class RegulaRecognizer extends Recognizer {
  static getGUID() {
    return "Regula";
  }

  getUniqByType(fields: DocumentField[]): DocumentField[] {
    const res: DocumentField[] = [];

    fields.forEach((it) => {
      if (res.every((item: DocumentField) => item.type !== it.type)) {
        res.push(it);
      }
    });

    return res;
  }

  static performValue(
    structureType: string,
    value: any,
    values: Record<string, any>,
    field: string,
    parsedFields: string[],
    currentPath: string,
    format: string
  ) {
    let isParsed = false;
    if (structureType.toLowerCase() === "number") {
      values[`${field}`] = parseInt(value, 10);
      isParsed = true;
    } else {
      const parsed = DateTimeUtils.toStandard(
        value,
        format,
        config.DOCUMENT_READER_DATE_FORMAT
      );
      if (parsed !== null) {
        values[`${field}`] = parsed;
        isParsed = true;
      }
    }
    isParsed && parsedFields.push(currentPath + "." + field);
    return isParsed;
  }

  workWithJsonForm(
    currentPath: string,
    jsonSchema: JsonFormJsonSchema,
    values: Record<string, any>,
    document: Document | null,
    parsedFields: string[],
    notValidFields: string[]
  ) {
    const propertiesNode = jsonSchema.properties;
    const manualFields = jsonSchema.manual || [];

    Object.keys(propertiesNode).forEach((structureKey) => {
      const structure = propertiesNode[structureKey];

      if (structure.type.toLowerCase() === "object") {
        if (values[structureKey] === null) values[structureKey] = {};
        this.workWithJsonForm(
          currentPath + "." + structureKey,
          structure,
          values[structureKey],
          document,
          parsedFields,
          notValidFields
        );
      } else if (
        structure?.recognizer === "custom" &&
        [
          config.JSONFORMS.MRZ_FIELD,
          config.JSONFORMS.RFID_FIELD,
          config.JSONFORMS.ICAO_CODE_FIELD,
          config.JSONFORMS.DOCUMENT_NAME_FIELD,
          config.JSONFORMS.REGULA_ID,
        ].indexOf(structure?.recognizerFieldId) > -1
      ) {
        switch (structure.recognizerFieldId) {
          case config.JSONFORMS.MRZ_FIELD:
            values[structureKey] =
              typeof document?.mrzStatus === "number" &&
              [CheckResult.OK, CheckResult.ERROR].indexOf(document.mrzStatus) >
                -1
                ? "1"
                : "0";
            parsedFields.push(currentPath + "." + structureKey);
            break;
          case config.JSONFORMS.RFID_FIELD:
            values[structureKey] =
              typeof document?.chipStatus === "number" &&
              [RfidLocation.BACK_PAGE, RfidLocation.MAIN_PAGE].indexOf(
                document.chipStatus
              ) > -1
                ? "1"
                : "0";
            parsedFields.push(currentPath + "." + structureKey);
            break;
          case config.JSONFORMS.ICAO_CODE_FIELD:
            values[structureKey] = document?.ICAOCode;
            parsedFields.push(currentPath + "." + structureKey);
            break;
          case config.JSONFORMS.DOCUMENT_NAME_FIELD:
            values[structureKey] = document?.documentName;
            parsedFields.push(currentPath + "." + structureKey);
            break;
          case config.JSONFORMS.REGULA_ID:
            values[structureKey] = document?.documentId;
            parsedFields.push(currentPath + "." + structureKey);
            break;
          default:
            break;
        }
      } else if (
        structure?.recognizer === "custom" &&
        config.JSONFORMS.FULLNAME_FIELD === structure?.recognizerFieldId
      ) {
        let fullName: DocumentField | null = null;
        let surname: DocumentField | null = null;
        let givenname: DocumentField | null = null;

        (document?.fields || []).forEach((field: DocumentField) => {
          if (field.lcid === LCID.LATIN) {
            const type = `${field.type}`.toLowerCase();
            if (type === TextFieldType.SURNAME_AND_GIVEN_NAMES.toString()) {
              fullName = field;
            } else if (type === TextFieldType.SURNAME.toString()) {
              surname = field;
            } else if (type === TextFieldType.GIVEN_NAMES.toString()) {
              givenname = field;
            }
          }
        });

        if (fullName) {
          values[structureKey] = (fullName as DocumentField).value;
          parsedFields.push(currentPath + "." + structureKey);
        } else if (surname || givenname) {
          values[structureKey] = `${
            (surname as DocumentField | null)?.value || ""
          } ${(givenname as DocumentField | null)?.value || ""}`.trim();
          parsedFields.push(currentPath + "." + structureKey);
        } else {
          const [surnamePath] = getFieldPath(
            jsonSchema,
            RegulaRecognizer.getGUID(),
            TextFieldType.SURNAME.toString(),
            ""
          );
          const [givenNamePath] = getFieldPath(
            jsonSchema,
            RegulaRecognizer.getGUID(),
            TextFieldType.GIVEN_NAMES.toString(),
            ""
          );
          if (surnamePath || givenNamePath) {
            values[structureKey] = `${get(values, surnamePath) || ""} ${
              get(values, givenNamePath) || ""
            }`.trim();
          }
        }
      } else {
        const structureType = structure.type || "string";
        const recognizerFieldId = structure.recognizerFieldId || null;
        const recognizer = structure.recognizer || "";

        const isManual = manualFields.includes(structureKey);

        //if in data no values for field
        if (values[structureKey] != null) {
          if (
            !isManual &&
            document &&
            RegulaRecognizer.getGUID().toLowerCase() ===
              recognizer.toLowerCase()
          ) {
            //if found not manual field with data, then refresh data
            const field = document.fields.find(
              (f) =>
                `${f.type}`.toLowerCase() ===
                `${recognizerFieldId}`.toLowerCase()
            );
            if (field) {
              RegulaRecognizer.performValue(
                structureType,
                field.value,
                values,
                structureKey,
                parsedFields,
                currentPath,
                structure.format
              );
              !field.status && field.name && notValidFields.push(field.name);
            }
          }
        } else if (
          !isManual &&
          document &&
          RegulaRecognizer.getGUID().toLowerCase() === recognizer.toLowerCase()
        ) {
          if (
            [
              TextFieldType.GIVEN_NAMES.toString(),
              TextFieldType.SURNAME.toString(),
            ].includes(`${recognizerFieldId}`.toLowerCase())
          ) {
            const givenName = document.fields.find(
              (f) =>
                `${f.type}`.toLowerCase() ===
                `${TextFieldType.GIVEN_NAMES}`.toLowerCase()
            );
            const surname = document.fields.find(
              (f) =>
                `${f.type}`.toLowerCase() ===
                `${TextFieldType.SURNAME}`.toLowerCase()
            );

            if (!surname || !givenName) {
              const fullName = document.fields.find(
                (f) =>
                  `${f.type}`.toLowerCase() ===
                  `${TextFieldType.SURNAME_AND_GIVEN_NAMES}`.toLowerCase()
              );

              if (
                `${recognizerFieldId}`.toLowerCase() ===
                TextFieldType.GIVEN_NAMES.toString()
              ) {
                if (fullName) {
                  RegulaRecognizer.performValue(
                    structureType,
                    "",
                    values,
                    structureKey,
                    parsedFields,
                    currentPath,
                    structure.format
                  );

                  return;
                }
              } else if (
                fullName &&
                `${recognizerFieldId}`.toLowerCase() ===
                  TextFieldType.SURNAME.toString()
              ) {
                RegulaRecognizer.performValue(
                  structureType,
                  fullName.value,
                  values,
                  structureKey,
                  parsedFields,
                  currentPath,
                  structure.format
                );

                if (!fullName.status && fullName.name)
                  notValidFields.push(fullName.name);
                return;
              }
            }
          }

          const field = document.fields.find(
            (f) =>
              `${f.type}`.toLowerCase() === `${recognizerFieldId}`.toLowerCase()
          );

          if (field) {
            RegulaRecognizer.performValue(
              structureType,
              field.value,
              values,
              structureKey,
              parsedFields,
              currentPath,
              structure.format
            );
            !field.status && field.name && notValidFields.push(field.name);
          }
        }
      }
    });
  }

  getFieldValue(parsed: Document, type) {
    return parsed.fields.find((it) => it.type === type)?.value;
  }

  getPassengerData(parsed: Document): UserData {
    let given_name =
      this.getFieldValue(parsed, TextFieldType.GIVEN_NAMES) || null;
    let surname = this.getFieldValue(parsed, TextFieldType.SURNAME) || null;

    if (!given_name && !surname) {
      surname =
        this.getFieldValue(parsed, TextFieldType.SURNAME_AND_GIVEN_NAMES) ||
        null;
      surname && (given_name = "");
    }

    return {
      nationality:
        this.getFieldValue(parsed, TextFieldType.NATIONALITY_CODE) || null,
      nationality_alpha_2: null,
      birth_place:
        this.getFieldValue(parsed, TextFieldType.PLACE_OF_BIRTH) || null,
      given_name: given_name,
      surname: surname,
      fullname:
        this.getFieldValue(parsed, TextFieldType.SURNAME_AND_GIVEN_NAMES) ||
        null,
      sex: this.getFieldValue(parsed, TextFieldType.SEX) || null,
      dob: this.getFieldValue(parsed, TextFieldType.DATE_OF_BIRTH) || null,
      issued_at:
        this.getFieldValue(parsed, TextFieldType.ISSUING_STATE_CODE) || null,
      document_number:
        this.getFieldValue(parsed, TextFieldType.DOCUMENT_NUMBER) || null,
      document_class_code:
        this.getFieldValue(parsed, TextFieldType.DOCUMENT_CLASS_CODE) || null,
    };
  }

  process(
    props: FormProcessorParseProps,
    form: JsonForm,
    allowedDocumentTypes: string[],
    shouldRecognizeDocument: boolean,
    quickMode: boolean
  ): FormProcessorResponse {
    let parsedDocument: Document | null = null;

    try {
      if (props.parsed) {
        parsedDocument = RegulaParser.parse(
          props.parsed as ProcessResponse,
          shouldRecognizeDocument,
          quickMode
        );
      }
    } catch (e: any) {
      console.log("[RegulaRecognizer] parse failed:", e.message);
      return {
        status:
          e.message === ErrorTexts.NOT_RECOGNIZED
            ? ResultStatus.NOT_RECOGNIZED
            : ResultStatus.OTHER_ERROR,
        error: e.message || null,
        data: null,
        recognizer: null,
        fields: {
          empty: [],
          invalid: [],
          parsed: [],
        },
        documentType: null,
        doc_data: {
          date_of_expiry: null,
          passenger: null,
          meta: null,
        },
      };
    }

    let passenger: UserData | null = null;
    let documentType: string | null = null;

    if (parsedDocument) {
      passenger = this.getPassengerData(parsedDocument);
      if (parsedDocument.mrzStatus === CheckResult.ERROR) {
        return {
          status: ResultStatus.VALIDATION_ERROR,
          error: ErrorTexts.MRZ_NOT_VALID,
          data: null,
          recognizer: null,
          fields: {
            empty: [],
            invalid: [],
            parsed: [],
          },
          documentType: null,
          doc_data: {
            date_of_expiry: null,
            passenger,
            meta: null,
          },
        };
      }

      documentType =
        allowedDocumentTypes.find(
          (dt) => `${dt}` === `${parsedDocument?.docType}`
        ) || null;
      if (!documentType && shouldRecognizeDocument) {
        return {
          status: ResultStatus.DOC_TYPE_NOT_VALID,
          error: ErrorTexts.DOCUMENT_TYPE_NOT_ALLOWED,
          data: null,
          recognizer: null,
          fields: {
            empty: [],
            invalid: [],
            parsed: [],
          },
          documentType: parsedDocument.docType?.toString() || null,
          doc_data: {
            date_of_expiry: parsedDocument.date_of_expiry,
            passenger,
            meta: parsedDocument.meta,
          },
        };
      }

      const invalidFields = this.getUniqByType(parsedDocument.fields).filter(
        (field) => {
          if (OWNER_EXCEPTION_FIELDS.indexOf(field.type) > -1) {
            if (field.status === CheckResult.OK) {
              return false;
            } else if (field.valueList.length > 1) {
              return (
                calcPercentageOfMatch(field.valueList[0], field.valueList[1]) <=
                0.8
              );
            } else {
              return field.status === CheckResult.ERROR;
            }
          }
          return false;
        }
      );

      if (invalidFields.length) {
        return {
          status: ResultStatus.OTHER_ERROR,
          error: ErrorTexts.DOCUMENT_IS_NOT_VALID,
          data: null,
          recognizer: null,
          fields: {
            empty: [],
            invalid: invalidFields
              .map((it) => it.name)
              .filter((it) => !!it) as string[],
            parsed: [],
          },
          documentType: documentType,
          doc_data: {
            date_of_expiry: null,
            passenger: null,
            meta: parsedDocument.meta,
          },
        };
      }
    }

    const values = props.formData || {};
    const parsedFields: string[] = [];
    const notValidFields: string[] = [];
    const jsonSchema: JsonFormJsonSchema = (
      JSON.parse(form.json) as JsonFormJson
    ).schema;

    this.workWithJsonForm(
      "",
      jsonSchema,
      values,
      parsedDocument,
      parsedFields,
      notValidFields
    );

    this.fixFieldsName(parsedFields);

    let recognizer: string | null = null;

    if (documentType || (!shouldRecognizeDocument && parsedDocument)) {
      recognizer = form.recognizer;
    }

    if (passenger) {
      passenger.valid = true;
    }

    return {
      status: ResultStatus.OK,
      error: parsedDocument?.morePagesAvailable
        ? ErrorTexts.MORE_PAGES_AVAILABLE
        : undefined,
      data: values,
      recognizer,
      fields: {
        empty: this.getEmptyRequiredFields(jsonSchema, values, props.images),
        invalid: notValidFields,
        parsed: parsedFields,
      },
      documentType,
      doc_data: {
        date_of_expiry: parsedDocument?.date_of_expiry || null,
        passenger,
        meta: parsedDocument?.meta || (!parsedDocument && props.meta) || null,
      },
    };
  }
}
