import { Model } from "@nozbe/watermelondb";
import { field, relation } from "@nozbe/watermelondb/decorators";
import Document from "wmelon/models/Document";

export default class File extends Model {
  static table = "files";

  @field("hash") hash!: string;
  @field("data") data!: string;
  @field("position") position!: number;
  @relation("documents", "document_id") document!: Document;
}
