export const OUTPUT_TAG_COUNT = 3;
export const MAX_LENGTH_TAG_NAME = 22;
export const MAX_LENGTH_TAG_TICKET = 10;

export const BIND_ERROR_CODES = {
  FLIGHT_TICKET_NOT_FOUND: "FLIGHT_TICKET_NOT_FOUND",
};

export const TIME_LABELS = {
  validating: "[TS]: validating",
  check_ticket: "[TS]: check_ticket",
  set_session_handler: "[TS]: set_session_handler",
  set_session_owner: "[TS]: set_session_owner",
  form_processing: "[TS]: form_processing",
  doc_creating: "[TS]: doc_creating",
  doc_updating: "[TS]: doc_updating",
  doc_uploading: "[TS]: doc_uploading",
  create_session: "[TS]: create_session",
  search_schemes: "[TS]: search_schemes",
  load_scheme: "[TS]: load_scheme",
  check_flight: "[TS]: check_flight",
  matomo_search_event: "[TS]: matomo_search_event",
  map_files: "[TS]: map_files",
  regula_request: "[TS]: regula_request",
  eudcc_request: "[TS]: eudcc_request",
};

export const IDS = {
  container: "container",
  content: "content",
  header: "header",
  title: "app_title",
  error: "app_error",
  modal: "app_modal",
  buttons: "app_buttons",
  footer: "app_footer",
  progressbar: "app_progressbar",
  drag_panel: "app_dragpanel",
  app_profile_button: "app_profile_button",

  photo_button: "photo_button",
  doc_reader_button: "doc_reader_button",
  qr_reader_button: "qr_reader_button",
  form_center_btn: "form_center_btn",
  upload_button: "upload_button",
  header_buttons: "header_buttons",
  header_button_left: "header_button_left",
  header_button_right: "header_button_right",

  form_fields: "form_fields",
};

export const PAGES = {
  tickets: "/tickets",
  tickets_upcoming: "/tickets/upcoming",
  tickets_flightNumber_date: "/tickets/:flight_number/:date",
  tickets_flightNumber_date_ticket_id:
    "/tickets/:flight_number/:date/:flight_ticket_id",
  bags: "/bags",
  add_bag: "/bags/add_bag",
  profile: "/profile",
  notifications: "/notifications",
  notifications_unread: "/notifications/unread",
  add_flight: "/add_flight",
  support: "/support",
  validator: "/validator",
  final_attention: "/final/attention",
  final_unrecognized: "/final/unrecognized",
  final_failed: "/final/failed",
  final_success: "/final/success",
};

export const UNRECOGNIZED_REASONS = {
  snapshot: "snapshot",
  rejected_by_recognizer: "rejected_by_recognizer",
  wrong_type: "wrong_type",
  owner_not_recognized: "owner_not_recognized",
};

export const NOTIFICATION_KEYS = {
  PROFILE_NOTIFICATION: "PROFILE_NOTIFICATION",
  FAILEDTOMARKASREAD: "FAILEDTOMARKASREAD",
  FAILEDTOMARKALLREAD: "FAILEDTOMARKALLREAD",
  TICKETNOTFOUND: "TICKETNOTFOUND",
  FAILEDTODELETE: "FAILEDTODELETE",
  ADD_BAG_UPLOAD_IMAGE: "ADD_BAG_UPLOAD_IMAGE",
  PHONE_CALL: "PHONE_CALL",
};
