import { Model } from "@nozbe/watermelondb";
import { field, date, json } from "@nozbe/watermelondb/decorators";

export default class Notification extends Model {
  static table = "notifications";

  @date("created_at") createdAt!: Date;
  @date("updated_at") updatedAt!: Date;
  @date("deleted_at") deletedAt?: Date | null;
  @field("device_id") deviceId?: string;
  @field("user_id") userId!: string;
  @field("session_id") sessionId?: string;
  @json("payload", (s) => s) payload?: { id: string };
  @field("flight_ticket_id") flightTicketId!: string;
  @field("action") action!:
    | "accepted"
    | "rejected"
    | "redirected_to_agent"
    | "share_revoked"
    | "additional_document_request";
  @date("read_at") readAt?: Date;
}
