import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import FlightTicket from "wmelon/models/FlightTicket";

import "./TicketsList.css";
import { APP_COLORS } from "utils/colors";
import { HistoryItem } from "types/history";

export interface TicketsListProps {
  data: (FlightTicket | HistoryItem)[];
  renderItem: (item: any) => ReactElement;
}

function TicketsList({ data, renderItem }: TicketsListProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();

  return (
    <>
      <div className="TicketsList">
        <div className="TicketsList-header">
          <Button
            type="text"
            icon={
              <LeftOutlined
                style={{
                  color: APP_COLORS.light.default_text_color,
                  fontSize: 20,
                }}
              />
            }
            onClick={() => {
              state?.notification ||
              state?.from === "final" ||
              state?.from === "add_flight"
                ? state.notification && state.notification_read
                  ? navigate("/tickets/past")
                  : navigate("/tickets/upcoming")
                : navigate(-1);
            }}
          />
          <span style={{ color: APP_COLORS.light.default_text_color }}>
            {t("Ticket_and_Info.TICKET")}
          </span>
        </div>
        <div className="TicketsList-content">
          <div className="TicketsList-container">{data.map(renderItem)}</div>
        </div>
      </div>
    </>
  );
}

export default TicketsList;
