import { useCallback, useMemo } from "react";
import { database } from "wmelon/database";
import { Q } from "@nozbe/watermelondb";
import Tag from "wmelon/models/Tag";
import TagBind from "wmelon/models/TagBind";

export function useTags() {
  const getBindTag = useCallback((id: string | null) => {
    return database
      .get<TagBind>(TagBind.table)
      .query(Q.where("object_id", id))
      .fetch();
  }, []);

  const createBindTags = useCallback(
    (data: {
      tag_id: string;
      bind_object: "pnrFlight" | "bag";
      object_id: string | null;
    }) => {
      return database.write(() => {
        return database
          .get<TagBind>(TagBind.table)
          .create((bind) => {
            Object.assign(bind, {
              ...data,
            });
            console.log("[useTags] create tag", bind);
          })
          .then((bind: TagBind) => bind);
      });
    },
    []
  );
  const updateBindTags = useCallback((id: string, tag_id: string) => {
    return database.write(() => {
      return database
        .get<TagBind>(TagBind.table)
        .find(id)
        .then((bind) => {
          return bind.update(() => {
            bind.tag_id = tag_id;
          });
        });
    });
  }, []);

  const updateTag = useCallback((id: string, name: string, color: string) => {
    return database.write(() => {
      return database
        .get<Tag>(Tag.table)
        .find(id)
        .then((tag) => {
          return tag.update(() => {
            tag.name = name;
            tag.color = color;
          });
        });
    });
  }, []);

  const createTag = useCallback((data: { name: string; color: string }) => {
    return database.write(() => {
      return database
        .get<Tag>(Tag.table)
        .create((tag) => {
          Object.assign(tag, {
            ...data,
          });
          console.log("[useTags] create tag", tag);
        })
        .then((tag: Tag) => tag);
    });
  }, []);

  const removeTag = useCallback((tag_id: string) => {
    return database.write(() => {
      return database
        .get<Tag>(Tag.table)
        .find(tag_id)
        .then((tag: Tag) => {
          return tag.markAsDeleted();
        });
    });
  }, []);

  return useMemo(
    () => ({
      createTag,
      removeTag,
      createBindTags,
      updateBindTags,
      getBindTag,
      updateTag,
    }),
    [
      createTag,
      removeTag,
      createBindTags,
      updateBindTags,
      getBindTag,
      updateTag,
    ]
  );
}
