import { useEffect, useRef } from "react";
import { Modal, Carousel } from "antd";
import PDFViewer from "components/PDFViewer";
import { CarouselRef } from "antd/es/carousel";
import { getIsPDF } from "utils/utils";
import "./Gallery.css";

export interface GalleryProps {
  visible: boolean;
  files: Blob[];
  onClose: () => void;
  selectedFile: number;
}

export default function Gallery({ visible, files, onClose, selectedFile }) {
  const refCarousel = useRef<CarouselRef | null>(null);

  useEffect(() => {
    const elem = refCarousel.current;
    if (visible && elem) {
      const timeout = setTimeout(() => {
        elem.goTo(selectedFile);
      }, 30);
      return () => clearTimeout(timeout);
    }
  }, [selectedFile, visible]);
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      wrapClassName="Gallery"
      centered
      footer={null}
    >
      <Carousel ref={refCarousel} slickGoTo={selectedFile}>
        {files.map((file: Blob, index: number) =>
          getIsPDF(file) ? (
            <div key={index}>
              <PDFViewer file={file} onError={() => {}} />
            </div>
          ) : (
            <div key={index}>
              <img
                className="Gallery-carousel-image"
                src={
                  typeof file === "string" ? file : URL.createObjectURL(file)
                }
                alt=""
              />
            </div>
          )
        )}
      </Carousel>
    </Modal>
  );
}
