import { useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import AuthApiService from "utils/AuthApi";
import restApi from "../utils/RESTApi";
import { ReduxState } from "redux/ReduxState";
export interface IProps {
  onLogout: () => void;
  isAuthorized: boolean;
}

const AuthProvider = ({ isAuthorized, onLogout }: IProps) => {
  const isRefreshing = useSelector((state: ReduxState) => state.app.refreshing);

  const intervalRef = useRef<ReturnType<typeof setTimeout>>();

  const onHandleLogoutEvent = useCallback(
    (event: any) => {
      const { isAuth, logouted } = event?.detail?.data;

      if (!isAuth && logouted) {
        onLogout();
      }
    },
    [onLogout]
  );

  useEffect(() => {
    if (document) {
      document.addEventListener("auth", onHandleLogoutEvent);

      return () => {
        document.removeEventListener("auth", onHandleLogoutEvent);
      };
    }
  }, [onHandleLogoutEvent]);

  useEffect(() => {
    if (isRefreshing || !isAuthorized) {
      return;
    }

    const checkToken = () => {
      const needRefresh = AuthApiService.shared().getIsExpiredToken();
      console.log("[AuthProvider] refresh:", needRefresh);
      if (needRefresh) {
        restApi.getRefreshedToken().catch((err) => {
          console.error("[AuthProvider] failed to refresh token:", err);
        });
      } else {
        intervalRef.current = setTimeout(() => checkToken(), 60 * 1000);
      }
    };

    checkToken();

    return () => {
      clearTimeout(intervalRef.current);
    };
  }, [isRefreshing, isAuthorized]);

  return null;
};

export default AuthProvider;
