import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ReduxDocumentFile {
  filename: string;
  data: string;
  hash: string;
}

export interface ReduxDocumentFileClone {
  link: string;
  hash: string;
  position: number;
}

export interface ReduxDocument {
  id: string;
  category: number | null;
  form_id: number | null;
  form_version: number | null;
  type: string;
  provider: string | null;
  recognizer: string | null;
  session_id?: string;
  data: any;
  autoVerificationResult: string | null;
  files: ReduxDocumentFile[] | ReduxDocumentFileClone[];
  verifiedManual: boolean;
  verifiedAuto: boolean;
  createdAt: number | null;
  cloned?: boolean;
}

export interface DocumentsState {
  items: ReduxDocument[];
  startScan: boolean;
}

export const initialState: DocumentsState = {
  items: [],
  startScan: false,
};

const documentSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    addDocument: (
      state: DocumentsState,
      action: PayloadAction<ReduxDocument>
    ) => {
      state.items = [...state.items, action.payload];
    },
    updateDocument: (
      state: DocumentsState,
      action: PayloadAction<Partial<ReduxDocument>>
    ) => {
      console.log("[documents] update document");
      state.items = state.items.map((it) => {
        if (it.id === action.payload.id) {
          return {
            ...it,
            ...action.payload,
          };
        }

        return it;
      });
    },
    clearState: (state: DocumentsState) => {
      Object.keys(initialState).forEach((field: string) => {
        state[field] = initialState[field];
      });
    },
    startScanAction: (
      state: DocumentsState,
      action: PayloadAction<boolean>
    ) => {
      state.startScan = action.payload;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});

export const { addDocument, updateDocument, clearState, startScanAction } =
  documentSlice.actions;

export default documentSlice.reducer;
