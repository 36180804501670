import { useRef, useCallback, useState } from "react";
import { Q } from "@nozbe/watermelondb";
import Notification from "wmelon/models/Notification";
import { database } from "wmelon/database";
import { VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { markAsRead } from "wmelon/middleware/notification";
import { useTranslation } from "react-i18next";
import NotificationCard from "components/NotificationCard";
import { Empty } from "antd";
import "../styles.css";
import Typography from "antd/es/typography/Typography";
import { withObservables } from "@nozbe/watermelondb/react";
import { APP_COLORS } from "utils/colors";
import { Observable } from "@nozbe/watermelondb/utils/rx";
import { useFlights } from "hooks/useFlights";
import { useReduxSession } from "hooks/useReduxSession";
import useDBSync from "hooks/useDBSync";
import Preloader from "components/Preloader/Preloader";
export interface UnreadProps {
  notifications: Notification[];
  onPressAction: (
    actionKey: "read" | "allRead" | "delete",
    notification: Notification
  ) => void;
}

const UnRead = ({ notifications, onPressAction }: UnreadProps) => {
  const { t } = useTranslation();

  const sync = useDBSync();

  const listRef = useRef(null);
  const notificationSize = useRef({});

  const { navigateToTicketById } = useFlights();
  const { initSessionByDocumentRequestId } = useReduxSession();

  const [loading, setLoading] = useState(false);

  const setNotificationSize = useCallback((index, size) => {
    notificationSize.current = { ...notificationSize.current, [index]: size };
    //@ts-ignore
    listRef?.current?.resetAfterIndex(index);
  }, []);

  const getSize = useCallback((index) => {
    return notificationSize.current[index] || 192;
  }, []);

  const goToTicket = useCallback(
    async (notification: Notification) => {
      if (
        notification.action === "additional_document_request" &&
        notification.payload &&
        notification.sessionId
      ) {
        markAsRead(notification.id)
          .then(() =>
            sync().catch((err) =>
              console.error("[Unread] failed to sync:", err)
            )
          )
          .catch((err) =>
            console.error("[Unread] failed to mark as read:", err)
          );
        setLoading(true);
        return initSessionByDocumentRequestId(
          notification.payload.id,
          notification.sessionId
        ).finally(() => setLoading(false));
      } else {
        if (!notification.flightTicketId) return;

        console.log("[Unread] go to ticket:", notification);
        markAsRead(notification.id)
          .then(() =>
            sync().catch((err) =>
              console.error("[Unread] failed to sync:", err)
            )
          )
          .catch((err) =>
            console.error("[Unread] failed to mark as read:", err)
          );

        setLoading(true);
        return navigateToTicketById(notification.flightTicketId, true).finally(
          () => setLoading(false)
        );
      }
    },
    [sync, initSessionByDocumentRequestId, navigateToTicketById]
  );

  const renderNotificationCard = useCallback(
    ({ index, style }) => {
      const notification = notifications[index];
      return (
        <div
          style={{
            ...style,
            top: `${parseFloat(style.top)}px`,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <NotificationCard
            isRead={true}
            key={`notification_unread_${index}`}
            notification={notification}
            goToTicket={goToTicket}
            onPressDropMenu={(action) => {
              onPressAction(action, notification);
            }}
            index={index}
            onSetSize={setNotificationSize}
          />
        </div>
      );
    },
    [goToTicket, notifications, onPressAction, setNotificationSize]
  );

  return (
    <>
      {notifications?.length > 0 ? (
        <AutoSizer>
          {({ height, width }) => (
            <VariableSizeList
              ref={listRef}
              height={height}
              width={width}
              itemCount={notifications.length}
              itemSize={getSize}
            >
              {renderNotificationCard}
            </VariableSizeList>
          )}
        </AutoSizer>
      ) : (
        <Empty
          description={
            <Typography
              style={{
                color: APP_COLORS.light.primary_text_color,
              }}
            >
              {t("EmptyMessages.notification")}
            </Typography>
          }
        />
      )}
      {loading ? <Preloader /> : null}
    </>
  );
};
const enhance = withObservables<
  Omit<UnreadProps, "notifications">,
  { notifications: Observable<Notification[]> }
>([], () => {
  return {
    notifications: database
      .get<Notification>(Notification.table)
      .query(Q.where("read_at", Q.eq(null)), Q.sortBy("created_at", Q.desc))
      .observe(),
  };
});
const EnhancedUnread = enhance(UnRead as any);
export default EnhancedUnread;
