import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";
import { FloatButton } from "antd";
import type { TabsProps } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Upcoming from "./components/Upcoming";
import Past from "./components/Past";
import Tabs from "components/Tabs";
import useDateTS from "hooks/useDateTS";

import "./Flights.css";

const TABS_KEY = {
  upcoming_key: "upcoming",
  past_key: "past",
};

export default function Flights() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { source } = useParams();
  const ts = useDateTS();

  const tabs: TabsProps["items"] = useMemo(() => {
    return [
      {
        key: TABS_KEY.upcoming_key,
        label: t("TabViewsTitle.Upcoming"),
        children: <Upcoming ts={ts} />,
      },
      {
        key: TABS_KEY.past_key,
        label: t("TabViewsTitle.Past"),
        children: <Past ts={ts} />,
      },
    ];
  }, [t, ts]);

  return (
    <div className='Flights'>
      <Tabs
        onChange={(path) => navigate(`/tickets/${path}`)}
        defaultActiveKey={source}
        tabs={tabs}
      />
      {isMobile ? null : (
        <FloatButton
          onClick={() => {
            navigate("/add_flight");
          }}
          icon={<PlusOutlined />}
          type='default'
          style={{ right: "3%", bottom: "10%" }}
        />
      )}
    </div>
  );
}
