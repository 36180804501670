import { useCallback, useContext, useEffect, useState, useMemo } from "react";
import { Avatar, Badge, Flex, Layout, theme, Typography, Dropdown } from "antd";
import { MenuFoldOutlined } from "@ant-design/icons";
import UserAvatar from "./UserAvatar";
import AuthApiService from "utils/AuthApi";
import { useNavigate } from "react-router-dom";
import AuthorizedContext from "contexts/AuthorizedContext";
import { Q } from "@nozbe/watermelondb";
import { BellOutlined } from "@ant-design/icons";
import {
  isIOS,
  isAndroid,
  isSafari,
  isChrome,
  isMacOs,
  isMobile,
} from "react-device-detect";
import config from "config";
import type { MenuProps } from "antd";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import logo from "../../assets/images/logo.svg";
import appStoreIcon from "../../assets/images/app_store_white.svg";
import googleStoreIcon from "../../assets/images/google_play_white.svg";

import "./Header.css";
import { withObservables } from "@nozbe/watermelondb/react";
import { database } from "wmelon/database";
import { APP_COLORS } from "utils/colors";
import Notification from "wmelon/models/Notification";

export interface HeaderProps {
  slogan?: string;
  unreadNotificationsCount?: any;
}

function Header({ slogan, unreadNotificationsCount }: HeaderProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { authorized } = useContext(AuthorizedContext);
  const { token } = theme.useToken();
  const [userId, setUserId] = useState<any>(null);
  const isResponsive = useMediaQuery({ maxWidth: 600 });

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const userId = await AuthApiService.shared().getUserId();
        setUserId(userId);
      } catch (err) {
        setUserId(null);
      }
    };
    fetchUserId();
  }, [setUserId, authorized]);

  const onGoProfile = useCallback(() => {
    if (authorized) {
      navigate("/profile");
    }
  }, [authorized, navigate]);

  const items: MenuProps["items"] = useMemo(
    () => [
      {
        label: (
          <div onClick={() => navigate("/notifications")}>
            <Badge
              count={unreadNotificationsCount || 0}
              style={{ marginRight: 10 }}
              overflowCount={999}
            >
              <Avatar
                size="large"
                style={{
                  marginRight: 10,
                  background: APP_COLORS.light.background,
                }}
              >
                <BellOutlined allowFullScreen />
              </Avatar>
            </Badge>
            <Typography.Text>{t("HeaderTitle.Notification")}</Typography.Text>
          </div>
        ),
        key: "0",
      },
      {
        label: (
          <div onClick={onGoProfile}>
            <UserAvatar userId={userId} onPress={onGoProfile} />
            <Typography.Text
              style={{
                marginLeft: 10,
              }}
            >
              {t("HeaderTitle.Profile")}
            </Typography.Text>
          </div>
        ),
        key: "1",
      },

      {
        label: (
          <div
            onClick={() => {
              let url = "";
              switch (true) {
                case isAndroid:
                  url = config.COMPLIANCEPASS_PLAY_STORE;
                  break;
                case isIOS:
                  url = config.COMPLIANCEPASS_APP_STORE;
                  break;
                case isSafari:
                  url = config.COMPLIANCEPASS_APP_STORE;
                  break;
                case isChrome:
                  url = config.COMPLIANCEPASS_APP_STORE;
                  break;
                case isMacOs:
                  url = config.COMPLIANCEPASS_APP_STORE;
                  break;
                default:
                  url = config.COMPLIANCEPASS_APP_STORE;
                  break;
              }
              window.open(url, "_blank");
            }}
          >
            <Avatar
              size="large"
              style={{
                border: "none",
                padding: 8,
                background: APP_COLORS.light.background,
              }}
              src={isIOS ? appStoreIcon : googleStoreIcon}
            />
            <Typography.Text style={{ marginLeft: 10 }}>
              {t("HeaderTitle.GoToApp")}
            </Typography.Text>
          </div>
        ),
        key: "3",
      },
    ],
    [navigate, onGoProfile, t, unreadNotificationsCount, userId]
  );

  return (
    <Layout.Header
      style={{
        background: token.colorPrimary,
        width: "100%",
      }}
      className="Header"
    >
      <Flex align="center" justify="space-between" flex={1}>
        <Typography.Title
          className="Header-title"
          style={{ color: APP_COLORS.light.primary, margin: 0 }}
        >
          {isMobile && <img className="Header-logo" src={logo} alt="" />}
          {slogan}
        </Typography.Title>
        {isResponsive && authorized ? (
          <div>
            <Dropdown
              trigger={["click"]}
              arrow
              menu={{ items }}
              placement="bottomLeft"
            >
              <MenuFoldOutlined
                style={{ color: APP_COLORS.light.primary, fontSize: 24 }}
              />
            </Dropdown>
          </div>
        ) : (
          <div>
            {isMobile && (
              <Avatar
                className="Header-button"
                size="large"
                style={{
                  border: "none",
                  marginRight: 10,
                  padding: 8,
                  background: APP_COLORS.light.background,
                }}
                onClick={() => {
                  let url = "";
                  switch (true) {
                    case isAndroid:
                      url = config.COMPLIANCEPASS_PLAY_STORE;
                      break;
                    case isIOS:
                      url = config.COMPLIANCEPASS_APP_STORE;
                      break;
                    case isSafari:
                      url = config.COMPLIANCEPASS_APP_STORE;
                      break;
                    case isChrome:
                      url = config.COMPLIANCEPASS_APP_STORE;
                      break;
                    case isMacOs:
                      url = config.COMPLIANCEPASS_APP_STORE;
                      break;
                    default:
                      url = config.COMPLIANCEPASS_APP_STORE;
                      break;
                  }
                  window.open(url, "_blank");
                }}
                src={isIOS ? appStoreIcon : googleStoreIcon}
              />
            )}
            {authorized && (
              <Badge
                count={unreadNotificationsCount || 0}
                style={{ marginRight: 20 }}
                overflowCount={99}
              >
                <Avatar
                  size="large"
                  onClick={() => navigate("/notifications")}
                  style={{ marginRight: 10 }}
                  className="Header-button"
                >
                  <BellOutlined allowFullScreen />
                </Avatar>
              </Badge>
            )}

            {authorized && (
              <UserAvatar
                cssClass="Header-button"
                userId={userId}
                onPress={onGoProfile}
              />
            )}
          </div>
        )}
      </Flex>
    </Layout.Header>
  );
}

const enhance = withObservables([], () => {
  return {
    unreadNotificationsCount: database
      .get<Notification>(Notification.table)
      .query(Q.where("read_at", Q.eq(null) || null))
      .observeCount(),
  };
});
const EnhancedHeader = enhance(Header);
export default EnhancedHeader;
