import getEndpoints from "./endpoints";

const defHost =
  process.env.REACT_APP_HTTP_SERVER || "https://fasttrack-pax-backend.vitim.io";

const config = {
  HTTP_SERVER: defHost,
  LINK_URL: process.env.REACT_APP_LINK_URL || defHost,
  UPLOAD_HOST: process.env.REACT_APP_UPLOAD_HOST || `${defHost}/api/files`,
  STORAGE_HOST: process.env.REACT_APP_STORAGE_HOST || `${defHost}/get/files`,
  APP_TYPE: process.env.REACT_APP_TYPE || "web",
  TEST_JWT_TOKEN: process.env.REACT_APP_TEST_JWT_TOKEN,
  JSONFORMS: {
    DOCUMENT_FIELD:
      process.env.REACT_APP_JSONFORMS_DOCUMENT_FIELD || "documentFile",
    TITLE: process.env.REACT_APP_JSONFORMS_TITLE || "\\$title$\\",
    ANSWER_FIELD: process.env.REACT_APP_JSONFORMS_ANSWER_FIELD || "answer",
    DOC_HELPER_FIELD:
      process.env.REACT_APP_JSONFORMS_DOC_HELPER_FIELD || "#doc_helper",
    STEP_HELPER_FIELD:
      process.env.REACT_APP_JSONFORMS_STEP_HELPER_FIELD || "#step_helper",
    TRAVEL_DOCUMENT_FORM:
      process.env.REACT_APP_JSONFORMS_TRAVEL_DOCUMENT_FORM || "",
    MRZ_FIELD: process.env.REACT_APP_JSONFORMS_MRZ_FIELD || "227",
    RFID_FIELD: process.env.REACT_APP_JSONFORMS_RFID_FIELD || "228",
    ICAO_CODE_FIELD: process.env.REACT_APP_JSONFORMS_ICAO_CODE_FIELD || "244",
    DOCUMENT_NAME_FIELD:
      process.env.REACT_APP_JSONFORMS_DOCUMENT_NAME_FIELD || "255",
    FULLNAME_FIELD: process.env.REACT_APP_JSONFORMS_FULLNAME_FIELD || "259",
    REGULA_ID: process.env.REACT_APP_JSONFORMS_REGULA_FIELD || "246",
    ACCEPT_ID: process.env.REACT_APP_JSONFORMS_ACCEPT_FIELD || "260",
    DEFAULT_SCHEME_ERROR:
      process.env.REACT_APP_JSONFORMS_DEFAULT_SCHEME_ERROR || "Manual validate",
    TRAVEL_DOCUMENT_EXPIRED_ERROR:
      process.env.REACT_APP_JSONFORMS_TRAVEL_DOCUMENT_EXPIRED_ERROR ||
      '["00 Expired Passport (Bona Fide)","Expired Passport/ID", "36 Passport not valid long enough (validity) (Bona Fide)"]',
  },
  SUPER_GROUP_ID: process.env.REACT_APP_SUPER_GROUP_ID || "start",
  RECOGNIZERS: {
    REGULA: process.env.REACT_APP_RECOGNIZERS_REGULA || "regula",
    EUDCC: process.env.REACT_APP_RECOGNIZERS_EUDCC || "eudcc",
    CUSTOM: process.env.REACT_APP_RECOGNIZERS_CUSTOM || "custom",
    MANUAL: process.env.REACT_APP_RECOGNIZERS_MANUAL || "manual",
    VTEVISA: process.env.REACT_APP_RECOGNIZERS_VTEVISA || "vtevisa",
  },
  SESSION_STATUS_IDS: {
    REJECTED_MANUALLY:
      process.env.REACT_APP_SESSIONS_REJECTED_MANUALLY || "rejected_manually",
    ALLOWED_MANUALLY:
      process.env.REACT_APP_SESSIONS_ALLOWED_MANUALLY || "allowed_manually",
    COMPLETED_BY_TICKET:
      process.env.REACT_APP_SESSIONS_COMPLETED_BY_TICKET ||
      "completed_by_ticket",
    REJECTED_BY_RECOGNIZER:
      process.env.REACT_APP_SESSIONS_REJECTED_BY_RECOGNIZER ||
      "rejected_by_recognizer",
    CANCELLED_MANUALLY:
      process.env.REACT_APP_SESSIONS_CANCELLED_MANUALLY || "cancelled_manually",
    REJECTED_BY_ETICKET:
      process.env.REACT_APP_SESSIONS_REJECTED_BY_ETICKET ||
      "rejected_by_eticket",
    AUTOREJECTED_BY_VALIDATOR:
      process.env.REACT_APP_SESSIONS_AUTOREJECTED_BY_VALIDATOR ||
      "autorejected_by_validator",
    PASSED_BY_VALIDATOR:
      process.env.REACT_APP_SESSIONS_PASSED_BY_VALIDATOR ||
      "passed_by_validator",
    PASSED_BY_LOCAL_VALIDATOR:
      process.env.REACT_APP_SESSIONS_PASSED_BY_LOCAL_VALIDATOR ||
      "passed_by_local_validator",
    EXPIRED_DOCUMENT:
      process.env.REACT_APP_SESSIONS_EXPIRED_DOCUMENT || "expired_document",
    WRONG_ETICKET:
      process.env.REACT_APP_SESSIONS_WRONG_ETICKET ||
      "wrong_eticket_passport_owner",
  },
  SESSION_STATUSES: {
    WAIT_FOR_OWNER: "wait_for_owner",
    VALIDATION: "validation",
    WAIT_FOR_PAID: "wait_for_paid",
    WAIT_FOR_DOC_VALIDATION: "wait_for_doc_validation",
    WAIT_FOR_TICKET: "wait_for_ticket",
    COMPLETED: "completed",
    AUTO_REJECTED: "auto_rejected",
    MANUAL_REJECTED: "manual_rejected",
    CANCELLED: "cancelled",
    WAIT_FOR_CHECK_VALIDATION: "wait_for_check_validation",
    MANUAL_INACTIVE: "manual_inactive",
  },
  CERTIFICATE_FIELDS: {
    GIVEN_NAME: process.env.REACT_APP_CERTIFICATE_FIELDS_GIVEN_NAME || "nam.gn",
    SURNAME: process.env.REACT_APP_CERTIFICATE_FIELDS_SURNAME || "nam.fn",
    DATE_OF_BIRTH:
      process.env.REACT_APP_CERTIFICATE_FIELDS_DATE_OF_BIRTH || "dob",
  },
  REGULA_SHIFT_EXPIRED_DATE:
    (typeof process.env.REACT_APP_REGULA_SHIFT_EXPIRED_DATE === "string"
      ? parseInt(process.env.REACT_APP_REGULA_SHIFT_EXPIRED_DATE, 10)
      : process.env.REACT_APP_REGULA_SHIFT_EXPIRED_DATE) || 360,
  DOCUMENT_READER_DATE_FORMAT:
    process.env.REACT_APP_DOCUMENT_READER_DATE_FORMAT || "yyyy-MM-dd",
  DEFAULT_SCHEME_COUNTRY_CODE:
    process.env.REACT_APP_DEFAULT_SCHEME_COUNTRY_CODE || "UT",
  SUPPORT: {
    INFORMATION:
      process.env.REACT_APP_SUPPORT_INFORMATION ||
      "https://www.validationcheckport.com",
    TERMS_OF_SERVICE:
      process.env.REACT_APP_SUPPORT_TERMS_OF_SERVICE ||
      "https://www.validationcheckport.com/termsconditions",
    PRIVACY_POLICY:
      process.env.REACT_APP_SUPPORT_PRIVACY_POLICY ||
      "https://www.validationcheckport.com/datenschutzprivacy",
  },
  SHOW_ALERT_ENV_AND_SEND_LOG: process.env.REACT_APP_SHOW_CONFIG || null,
  SYNC_INTERVAL:
    (typeof process.env.REACT_APP_SYNC_INTERVAL === "string"
      ? parseInt(process.env.REACT_APP_SYNC_INTERVAL, 10)
      : process.env.REACT_APP_SYNC_INTERVAL) || 60000,
  RESET_ID:
    process.env.REACT_APP_RESET_ID || "da3e4806-12b3-49aa-bbb1-340f03057d2e",
  CALL_CENTER_PHONE:
    process.env.REACT_APP_CALL_CENTER_PHONE || "+41 900 734 736",
  SHOW_BAGGAGE_TAB: process.env.REACT_APP_SHOW_BAGGAGE_TAB === "true",
  PHOTO_MAX_SIZE: process.env.REACT_APP_PHOTO_MAX_SIZE || 2000,
  FILE_MAX_SIZE:
    (typeof process.env.REACT_APP_FILE_MAX_SIZE === "string"
      ? parseInt(process.env.REACT_APP_FILE_MAX_SIZE, 10)
      : process.env.REACT_APP_FILE_MAX_SIZE) || 10240,
  COMPLIANCEPASS_APP_STORE:
    process.env.REACT_APP_COMPLIANCEPASS_APP_STORE ||
    "https://apps.apple.com/ru/app/compliancepass/id6463605986?l=en-GB",
  COMPLIANCEPASS_PLAY_STORE:
    process.env.REACT_APP_COMPLIANCEPASS_PLAY_STORE ||
    "https://play.google.com/store/apps/details?id=io.blueicareus.dti",

  DOCUMENT_CATEGORIES: {
    ID: process.env.REACT_APP_DOCUMENT_CATEGORIES_ID || "1",
    VISA: process.env.REACT_APP_DOCUMENT_CATEGORIES_VISA || "2",
    HEALTH: process.env.REACT_APP_DOCUMENT_CATEGORIES_HEALTH || "3",
    FORM: process.env.REACT_APP_DOCUMENT_CATEGORIES_FORM || "4",
    QUESTION: process.env.REACT_APP_DOCUMENT_CATEGORIES_QUESTION || "5",
    ADDITIONAL_DOCUMENT:
      process.env.REACT_APP_DOCUMENT_CATEGORIES_ADDITIONAL_DOCUMENT || "18",
    PHOTO: process.env.REACT_APP_DOCUMENT_CATEGORIES_PHOTO || "22",
  },
};

console.log(
  "config:::",
  process.env.REACT_APP_HTTP_SERVER,
  config.HTTP_SERVER,
  process.env.REACT_APP_JSONFORMS_TITLE
);
export default {
  ...config,
  ...getEndpoints(config),
};
