import { Col, Input, Typography } from "antd";

import { useTranslation } from "react-i18next";
import moment from "moment";

import "./style.css";
interface UserInfoProps {
  values: {
    avatar: any;
    name: any;
    surname: any;
    dob: any;
    mobile: any;
  };
  errors: any;
  touched: any;
  onChange: (name: string, value: any) => void;
  onBlur: any;
  disabled: boolean;
}
const { Text } = Typography;
const UserInfo = ({
  values,
  errors,
  touched,
  onChange,
  onBlur,
  disabled,
}: UserInfoProps) => {
  const { t } = useTranslation();
  return (
    <div className='userInfoContainer'>
      <Col xs={24} sm={24} md={24} xl={24} style={{ marginBottom: "1rem" }}>
        <Text>
          {t("Profile.name")}
          <span style={{ color: "red" }}>*</span>
        </Text>
        <Input
          size='large'
          value={values.name}
          onChange={(e) => onChange("name", e.target.value)}
          onBlur={onBlur}
          disabled={disabled}
          placeholder={t("Profile.name")}
          className='inputContainer'
        />
        {errors.name && touched.name && (
          <div style={{ color: "red" }}>{errors.name}</div>
        )}
      </Col>
      <Col xs={24} sm={24} md={24} xl={24} style={{ marginBottom: "1rem" }}>
        <Text>
          {t("Profile.Surname")}
          <span style={{ color: "red" }}>*</span>
        </Text>
        <Input
          size='large'
          value={values.surname}
          onChange={(e) => onChange("surname", e.target.value)}
          onBlur={onBlur}
          disabled={disabled}
          placeholder={t("Profile.Surname")}
          className='inputContainer'
        />
        {errors.surname && touched.surname && (
          <div style={{ color: "red" }}>{errors.surname}</div>
        )}
      </Col>
      <Col xs={24} sm={24} md={24} xl={24} style={{ marginBottom: "1rem" }}>
        <Text>
          {t("Profile.Birthday")}
          <span style={{ color: "red" }}>*</span>
        </Text>
        <br />
        <Input
          size='large'
          type='date'
          value={values.dob ? moment.utc(values.dob).format("YYYY-MM-DD") : ""}
          onChange={(evt) => onChange("dob", evt.target.value)}
          className='inputContainer'
          max={moment.utc().format("YYYY-MM-DD")}
        />
        {errors.dob && touched.dob && (
          <div style={{ color: "red" }}>{errors.dob}</div>
        )}
      </Col>
      <Col xs={24} sm={24} md={24} xl={24} style={{ marginBottom: "1rem" }}>
        <Text>
          {t("Profile.MobileNo")}
          <span style={{ color: "red" }}>*</span>
        </Text>
        <br />
        <Input
          size='large'
          disabled
          value={values.mobile}
          onChange={(e) => onChange("mobile", e.target.value)}
          className='inputContainer'
        />
        {errors.dob && touched.dob && (
          <div style={{ color: "red" }}>{errors.dob}</div>
        )}
      </Col>
    </div>
  );
};

export default UserInfo;
