import { useCallback } from "react";
import { WidgetProps } from "@rjsf/utils";
import { fieldNameToLabel } from "utils/string";
import { getData } from "utils/jsonform";
import { Input, Typography } from "antd";
import moment from "moment";

import "./style.css";

export default function DateWidget({
  id,
  name,
  value,
  required,
  disabled,
  readonly,
  onBlur,
  onChange,
  onFocus,
  label,
  placeholder,
  options,
}: WidgetProps) {
  const format = options.dateFormat ?? "YYYY-MM-DD";
  const saveFormat = options.dateSaveFormat ?? "YYYY-MM-DD";

  const formatedDate = useCallback(
    (date: any) => {
      onChange && onChange(getData(date, saveFormat as any, format as any));
    },
    [format, onChange, saveFormat]
  );

  const disabledField = (options?.readOnly || readonly || disabled) as boolean;
  return options.hidden ? null : (
    <div>
      <div className="field">
        <Typography style={{}} className="label">
          {fieldNameToLabel(label)} {required && "*"}
        </Typography>
        {id === "root_DateOfBirth" ? (
          <Input
            type="date"
            id={id}
            name={name}
            value={value ? moment.utc(value).format("YYYY-MM-DD") : ""}
            placeholder={placeholder || fieldNameToLabel(label)}
            disabled={disabledField}
            onChange={(e: any) =>
              formatedDate(e.target.value === "" ? undefined : e.target.value)
            }
            max={moment.utc().format("YYYY-MM-DD")}
            className="dateInput"
          />
        ) : (
          <Input
            type="date"
            id={id}
            name={name}
            className="dateInput"
            value={value ? moment.utc(value).format("YYYY-MM-DD") : ""}
            placeholder={placeholder || fieldNameToLabel(label)}
            disabled={disabledField}
            onChange={(e: any) =>
              formatedDate(e.target.value === "" ? undefined : e.target.value)
            }
            min={new Date().toISOString().split("T")[0]}
          />
        )}
      </div>
    </div>
  );
}
