import { useCallback, useMemo } from "react";
import { withObservables } from "@nozbe/watermelondb/react";
import { database } from "wmelon/database";
import { Q } from "@nozbe/watermelondb";
import { getGrouppedTickets, getSortedTickets } from "utils/tickets";
import FlightTicket from "wmelon/models/FlightTicket";

import { useFlights } from "hooks/useFlights";
import moment from "moment";
import FlightTicketBinds from "wmelon/models/FlightTicketBinds";
import { Observable } from "@nozbe/watermelondb/utils/rx";
import DBPNRItem from "components/DBPNRItem";
import PNRList from "./PNRList";

export interface DBHistoryListProps {
  ts: number;
  tickets: FlightTicket[];
  flightTicketBinds: FlightTicketBinds[];
}

function DBHistoryList({ tickets, flightTicketBinds }) {
  const { navigateToTicketById } = useFlights();

  const sections = useMemo(() => {
    return getGrouppedTickets(
      getSortedTickets(tickets, flightTicketBinds, "desc"),
      "desc"
    );
  }, [tickets, flightTicketBinds]);

  const onSelect = useCallback(
    (tickets: FlightTicket[]) => {
      return navigateToTicketById(tickets[0].id, true);
    },
    [navigateToTicketById]
  );

  const renderPnr = useCallback(
    (pnr: FlightTicket[], idx: number) => (
      <DBPNRItem isPast key={idx} tickets={pnr} onSelect={onSelect} />
    ),
    [onSelect]
  );

  return <PNRList sections={sections} renderItem={renderPnr} />;
}

const enhance = withObservables<
  Omit<DBHistoryListProps, "tickets" | "flightTicketBinds">,
  {
    tickets: Observable<FlightTicket[]>;
    flightTicketBinds: Observable<FlightTicketBinds[]>;
  }
>(["ts"], ({ ts }) => {
  const start = moment(ts).startOf("day").valueOf();
  const end = moment(ts).add(1, "day").startOf("day").valueOf();
  return {
    tickets: database
      .get<FlightTicket>(FlightTicket.table)
      .query(
        Q.where("flight_date", Q.gte(start)),
        Q.where("flight_date", Q.lt(end))
      )
      .observe(),
    flightTicketBinds: database
      .get<FlightTicketBinds>(FlightTicketBinds.table)
      .query(
        Q.on(
          FlightTicket.table,
          Q.and(
            Q.where("flight_date", Q.gte(start)),
            Q.where("flight_date", Q.lt(end))
          )
        )
      )
      .observe(),
  };
});

const EnhancedDBHistoryList = enhance(DBHistoryList);
export default EnhancedDBHistoryList;
