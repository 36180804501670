import { useCallback, useEffect, useRef, useState } from "react";
import { setManualState } from "redux/features/app";
import video from "assets/video/video_manual.mp4";
import { useSelector } from "react-redux";
import { useAppDispatch } from "redux/store";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import ReactPlayer from "react-player";
import { ReduxState } from "redux/ReduxState";

const Manual = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const showManual = useSelector((state: ReduxState) => state.app.showManual);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const playerRef = useRef<ReactPlayer>(null);

  const [loading, setLoading] = useState<boolean>(true);

  const onEnd = useCallback(() => {
    setIsPlaying(false);
    dispatch(setManualState(false));
  }, [dispatch]);

  const onLoadStart = useCallback(() => setLoading(true), []);

  const onReadyForDisplay = useCallback(() => setLoading(false), []);
  const onError = useCallback(() => {
    const onDismiss = () => onEnd();
    Modal.error({
      title: t("ManualScreen.failedToLoad"),
      onOk: onDismiss,
      onCancel: onDismiss,
      okText: t("ok"),
      cancelText: t("Cancel"),
    });
  }, [onEnd, t]);
  useEffect(() => {
    setIsPlaying(showManual);
  }, [showManual]);
  return (
    <Modal open={showManual} onCancel={onEnd} footer={null} centered>
      {showManual && (
        <ReactPlayer
          ref={playerRef}
          url={video}
          playing={isPlaying && loading}
          onEnded={() => onEnd()}
          onStart={onLoadStart}
          width="100%"
          onReady={onReadyForDisplay}
          onError={onError}
        />
      )}
    </Modal>
  );
};

export default Manual;
