export default class DateTimeUtils {
    static toStandard(str: string, format: string, inputFormat?: string) {
        try {
            if (['date', 'date-time', 'time'].indexOf(format) === -1) {
                return str;
            }

            if (inputFormat && !(new RegExp(`^${inputFormat.replace(/\w/g,'\\d')}$`)).test(str)) {
                console.log(`[DateTimeUtils] failed to parse date: val-${str}, input format-${inputFormat} to ${format}`)
                return null;
            }

            switch (format) {
                case 'date': {
                    return new Date(str).toISOString().substr(0,10)
                }
                case 'date-time': {
                    return new Date(str).toISOString().substr(0,19)
                }
                case 'time': {
                    return new Date(str).toISOString().substr(11,8)
                }
                default:
                    return str
            }
        } catch (err) {
            console.log('[DateTimeUtils] failed to parse date:', err);
            return null;
        }
    }
}
