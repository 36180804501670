export function isConstructor<T>(
    value: { (): T } | { new(): T },
): value is { new(): T } {
    if (value.prototype === undefined) {
        return false;
    }

    try {
        const proxy = new Proxy(value, { construct: () => ({}) }) as { new(): T };
        // eslint-disable-next-line no-new
        new proxy();
        return true;
    } catch (err) {
        return false;
    }
}


export default function singleton<T>(fabric: { (): T } | { new(): T }) {
    let instance: T | null = null;
    const lazy = () => {
        if (!instance) {
            instance = isConstructor(fabric)
                ? Reflect.construct(fabric, [])
                : fabric();
        }
        return instance;
    };
    const destroy = () => {
        instance = null;
    };

    return Object.assign(lazy, { destroy });
}
