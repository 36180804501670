import { useCallback } from "react";
import { ProcessResponse } from "@regulaforensics/document-reader-webclient";
import config from "config";
import { TIME_LABELS } from "../constants/contants";
import AuthApiService from "utils/AuthApi";
import Storage from "core/Storage/Storage";

export function useRegula(): (
  quickMode: boolean,
  files: Blob[]
) => Promise<ProcessResponse | null> {
  const parse = useCallback((quickMode: boolean, files: Blob[]) => {
    return AuthApiService.shared()
      .getUserToken()
      .then((token: string) => {
        return new Promise(
          (resolve: (value: ProcessResponse) => void, reject) => {
            const formData = new FormData();
            const request = new XMLHttpRequest();

            files.forEach((file: Blob) => {
              formData.append("files", file, `file.${file.type.split("/")[1]}`);
            });
            formData.append("mode", quickMode ? "quick" : "full");

            request.open("POST", config.REGULA_EP);
            request.withCredentials = false;

            token &&
              request.setRequestHeader(
                "Authorization",
                `Bearer ${Storage.shared().get(AuthApiService.TOKEN_KEY)}`
              );

            request.onload = () => {
              console.timeEnd(TIME_LABELS.regula_request);
              try {
                if (request.status === 200 || request.status === 201) {
                  console.log("[useRegula] response:", request.response);
                  resolve(JSON.parse(request.response) as ProcessResponse);
                } else if (request.status === 401) {
                  reject(new Error("wrong_token"));
                } else if (
                  request.status === 404 ||
                  request.status === 405 ||
                  request.status >= 500
                ) {
                  reject(new Error("service_is_temporarily_unavailable"));
                } else {
                  const data = JSON.parse(request.response);
                  reject(data);
                }
              } catch (err) {
                console.log("[useRegula] onload parse failed:", err);
                reject(err);
              }
            };

            request.onerror = (e) => {
              console.timeEnd(TIME_LABELS.regula_request);
              console.log("[useRegula] parse failed:", e);
              reject(new Error("service_is_temporarily_unavailable"));
            };
            console.time(TIME_LABELS.regula_request);
            request.send(formData);
          }
        );
      });
  }, []);

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return parse;
}
