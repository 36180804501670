import { useState, useEffect, useRef } from "react";

const useDateTS = (interval?: number) => {
  const [ts, setTS] = useState<number>(new Date().setHours(0, 0, 0, 0));
  const intervalRef = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    const checkTime = () => {
      setTS(new Date().setHours(0, 0, 0, 0));
    };
    intervalRef.current = setInterval(checkTime, interval || 2 * 60 * 1000);
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [interval]);

  return ts;
};

export default useDateTS;
