import { useCallback } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import Button from "components/Button";

import "./Pagination.css";

export interface PaginationProps {
  id?: string;
  width?: number | undefined;
  index: number;
  count: number;
  onChangePage: (index: number) => void;
}

function Pagination({
  id,
  width,
  index,
  count,
  onChangePage,
}: PaginationProps) {
  const onPrev = useCallback(() => {
    onChangePage(index - 1);
  }, [index, onChangePage]);

  const onNext = useCallback(() => {
    onChangePage(index + 1);
  }, [index, onChangePage]);

  return (
    <div
      id={id}
      className='Pagination'
      style={width ? { minWidth: width } : undefined}
    >
      <Button
        shape='circle'
        icon={<LeftOutlined />}
        disabled={index === 0 || index === -1}
        onClick={onPrev}
      />
      <Button
        shape='circle'
        icon={<RightOutlined />}
        disabled={index >= count - 1}
        onClick={onNext}
      />
    </div>
  );
}

export default Pagination;
