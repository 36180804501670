import { useCallback, useEffect } from "react";
import { Q } from "@nozbe/watermelondb";
import { connect } from "react-redux";
import { Modal, Button, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FinalPage from "./components/FinalPage";
import { useSessionInitializer } from "hooks/useSessionInitializer";
import { useReduxSession } from "hooks/useReduxSession";
import { useTimer } from "hooks/useTimer";
import { getQueryValue } from "utils/db";
import { SessionState } from "redux/features/session";
import Session from "wmelon/models/Sessions";
import FlightTicket from "wmelon/models/FlightTicket";
import { ValidationTicket } from "redux/features/validation";
import warningIcon from "../../assets/images/attention.svg";
import { withObservables } from "@nozbe/watermelondb/react";
import { database } from "wmelon/database";
import { APP_COLORS } from "utils/colors";
import { Observable } from "@nozbe/watermelondb/utils/rx";

export interface IProps {
  validationTickets: ValidationTicket[];
  sessionState: SessionState;
  sessions: Session[];
  unsynced: Session[];
  tickets: FlightTicket[];
}

const FinalAttention = ({
  validationTickets,
  sessionState,
  sessions,
  tickets,
  unsynced,
}: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [counter, startTimer] = useTimer("syncLimit");

  const {
    nextTicketId,
    cancelSession,
    restartSession,
    loadingState,
    initializing,
    percent,
    sessionId,
  } = useSessionInitializer(sessions, unsynced, startTimer);
  const { runByTicketId } = useReduxSession();

  useEffect(() => {
    if (sessionState && !sessionState.flight_ticket_id) {
      navigate("/tickets/upcoming");
    }
  }, [sessionState, navigate]);

  const onCancelSession = useCallback(() => {
    return cancelSession(true, true);
  }, [cancelSession]);

  const onNextPassenger = useCallback(() => {
    nextTicketId &&
      cancelSession(false, false)
        .then(() => runByTicketId(nextTicketId))
        .then(() => navigate("/preparation"))
        .catch((err) => {
          Modal.error({
            title: t("RegulaScreens.failedToInitSession"),
            okText: t("ok"),
            onOk: () => cancelSession(true, true),
          });
        });
  }, [cancelSession, nextTicketId, runByTicketId, navigate, t]);

  const loading = !!loadingState;

  return (
    <FinalPage
      icon={warningIcon}
      title={`${t("FinalAttention.title")}!`}
      description={t("FinalAttention.description")}
      initializationState={percent}
      initializing={initializing}
      sessionId={sessionId}
      buttons={
        <>
          <Button
            disabled={loading || counter > 0}
            type="primary"
            onClick={restartSession}
            style={{
              color: APP_COLORS.light.primary_text_color,
              backgroundColor: APP_COLORS.light.primary,
            }}
          >
            {counter ? t("canRepeatIn", { count: counter }) : t("tryAgain")}
          </Button>
          {nextTicketId ? (
            <Button
              type="primary"
              disabled={loading || counter > 0}
              onClick={onNextPassenger}
              style={{
                color: APP_COLORS.light.primary_text_color,
                backgroundColor: APP_COLORS.light.primary,
              }}
              icon={
                loading ? (
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 24 }} spin />
                    }
                  />
                ) : null
              }
            >
              {counter
                ? t("canRepeatIn", { count: counter })
                : t("RegulaScreens.nextPassenger")}
            </Button>
          ) : null}
        </>
      }
      ticketId={sessionState.flight_ticket_id}
      tickets={tickets}
      validationTickets={validationTickets}
      loading={loading}
      onCancel={onCancelSession}
    />
  );
};

const mapState = (state: any) => ({
  sessionState: state.session,
  validationTickets: state.validation.tickets,
});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

const enhance: any = withObservables<
  Omit<IProps, "sessions" | "unsynced" | "tickets">,
  {
    sessions: Observable<Session[]>;
    unsynced: Observable<Session[]>;
    tickets: Observable<FlightTicket[]>;
  }
>(
  ["sessionState", "validationTickets"],
  ({
    sessionState,
    validationTickets,
  }: {
    sessionState: SessionState;
    validationTickets: ValidationTicket[];
  }) => ({
    sessions: database
      .get<Session>(Session.table)
      .query(
        Q.where(
          "flight_ticket_id",
          getQueryValue(sessionState?.flight_ticket_id)
        ),
        Q.where("_status", "synced")
      )
      .observe(),
    unsynced: database
      .get<Session>(Session.table)
      .query(
        Q.where(
          "flight_ticket_id",
          getQueryValue(sessionState?.flight_ticket_id)
        ),
        Q.where("_status", Q.notEq("synced"))
      )
      .observe(),
    tickets: database
      .get<FlightTicket>(FlightTicket.table)
      .query(Q.where("id", Q.oneOf(validationTickets.map((it) => it.id))))
      .observe(),
  })
);

const EnhancedFinalAttention = connector(enhance(FinalAttention));
export default EnhancedFinalAttention;
