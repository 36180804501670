import { FieldProps } from "@rjsf/utils";
import { Typography } from "antd";

export default function TitleField({ id, label }: FieldProps) {
  return (
    <div
      style={{
        marginBottom: 5,
      }}
    >
      <Typography id={id}>{label}</Typography>
    </div>
  );
}
