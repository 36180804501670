import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Menu } from "antd";
import { useTranslation } from "react-i18next";

import "./Sidebar.css";
import { APP_COLORS } from "utils/colors";

export interface SidebarProps {
  logo?: string;
}

export default function Sidebar({ logo }: SidebarProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Layout.Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      className='Sidebar'
      style={{
        backgroundColor: APP_COLORS.light.buttonGradient[2],
      }}
    >
      {logo && (
        <img
          src={logo}
          alt=''
          className={`Sidebar-logo ${collapsed ? "collapsed" : ""}`}
          onClick={() => navigate("/tickets")}
        />
      )}
      <Menu
        theme='dark'
        onClick={() => {}}
        style={{
          marginTop: 20,
          backgroundColor: APP_COLORS.light.buttonGradient[2],
          
        }}
        
        mode='vertical'
        defaultSelectedKeys={["flights"]}
        items={[
          {
            key: "flights",
            label: t("TabViewsTitle.Home"),
            onClick: () => {
              navigate("/tickets");
            },
          },
          // {
          //   key: "bags",
          //   label: t("TabViewsTitle.Bags"),
          //   onClick: () => {
          //     navigate("/bags");
          //   },
          // },
          {
            key: "support",
            label: t("Support.Support"),
            onClick: () => {
              navigate("/support");
            },
          },
        ]}
      />
    </Layout.Sider>
  );
}
