import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";
import "./TicketNumber.css";

export interface IProps {
  ticketNumber: string | null;
  shortId?: string | null;
}

const TicketNumber = ({ ticketNumber, shortId }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className="TicketNumber">
      {!shortId && ticketNumber ? (
        <>
          <span>{t("Ticket_and_Info.TicketNumber")}:</span>
          <span className="TicketNumber-number">{ticketNumber}</span>
        </>
      ) : (
        <>
          <span>{t("SessionCodeCopyToClipboard.title")}</span>
          <Typography.Text copyable className="TicketNumber-number">
            {shortId}
          </Typography.Text>
        </>
      )}
    </div>
  );
};

export default TicketNumber;
