import { Q } from "@nozbe/watermelondb";
import { database } from "wmelon/database";
import Country from "wmelon/models/Country";

async function getCountryByCode(code: string): Promise<Country[]> {
  return database
    .get<Country>(Country.table)
    .query(Q.where("alpha2", code.toLowerCase()))
    .fetch()
    .catch((err) => {
      console.error("[getCountryByCode] failed to get:", err);
      return [];
    });
}

async function getCountriesByCodes(codes: string[]): Promise<Country[]> {
  return database
    .get<Country>(Country.table)
    .query(Q.where("alpha2", Q.oneOf(codes.map((code) => code.toUpperCase()))))
    .fetch()
    .catch((err) => {
      console.error("[getCountryByCode] failed to get:", err);
      return [];
    });
}

export { getCountryByCode, getCountriesByCodes };
