import { useCallback } from "react";
import FlightTicket from "wmelon/models/FlightTicket";
import { HistoryItem } from "types/history";
import "./PNRList.css";
import { Empty } from "antd";

export interface PNRListProps {
  sections: { date: string; data: (FlightTicket | HistoryItem)[][] };
  renderItem: (item: (FlightTicket | HistoryItem)[]) => Element;
}

function PNRList({ sections, renderItem }) {
  const renderPnr = useCallback(
    (item: any, index: number) => {
      return (
        <div className="PNRList-pnrs-container" key={index}>
          {/* <div className="PNRList-pnrs-date">
            {getLocaleDate(item.date, "DD MMMM, YYYY")}
          </div> */}
          <div className="PNRList-pnrs">{item.data?.map(renderItem)}</div>
        </div>
      );
    },
    [renderItem]
  );

  return (
    <div className="PNRList">
      <div className="PNRList-container">
        {sections.length ? sections.map(renderPnr) : <Empty />}
      </div>
    </div>
  );
}

export default PNRList;
