import { useCallback } from "react";
import { Col, Row } from "antd";
import BagsCard from "components/BagsCard";
import Bag from "wmelon/models/Bag";
import { PlusOutlined } from "@ant-design/icons";
import { FloatButton } from "antd";
import "./Bags.css";
import { useNavigate } from "react-router-dom";
import { withObservables } from "@nozbe/watermelondb/react";
import { database } from "wmelon/database";

interface BagProps {
  bags: Bag[];
}

function Bags({ bags }: BagProps) {
  const navigate = useNavigate();

  const renderBag = useCallback(
    (bag) => (
      <Col key={bag.id} span={6}>
        <BagsCard bag={bag} />
      </Col>
    ),
    []
  );

  return (
    <div className="Bags scrollable">
      <Row
        gutter={[16, 26]}
        align={"middle"}
        justify="space-between"
        style={{ marginBottom: 10, marginLeft: 10, marginRight: 20 }}
      >
        {bags?.map(renderBag)}
      </Row>
      <FloatButton
        onClick={() => {
          navigate("/bags/add_bag");
        }}
        icon={<PlusOutlined />}
        type="default"
        style={{ right: "3%", bottom: "10%" }}
      />
    </div>
  );
}

const enhance = withObservables([], () => ({
  bags: database
    .get<Bag>(Bag.table)
    .query()
    .observeWithColumns(["files", "name", "tag_id"]),
}));
const BagsScreenEnhance = enhance(Bags as any);

export default BagsScreenEnhance;
