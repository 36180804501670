import { createSlice } from "@reduxjs/toolkit";

export interface LoaderState {
  loader: boolean;
}

export const initialState: LoaderState = {
  loader: false,
};

const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    setLoaderAction: (state, action) => {
      state.loader = action.payload;
    },
  },
  extraReducers: {},
});

export const { setLoaderAction } = loaderSlice.actions;

export default loaderSlice.reducer;
