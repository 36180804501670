export interface FlightPlace {
  name?: string;
  iata: string | null;
  icao?: string;
  country?: string | null;
  countryCode?: string | null;
}

export interface Flight {
  id: string;
  airline: {
    name: string;
    iata?: string;
    icao?: string;
  };
  origin: FlightPlace;
  destination: FlightPlace;
  schedule: string[];
}

export enum ShareStatus {
  shared_by,
  shared_to,
}

export enum TicketStatus {
  accepted,
  denied,
}

export interface FlightSearchitem {
  carrier: string;
  flight: string;
  origin: string;
  destination: string;
  schedule: string[];
}
