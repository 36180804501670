import { useCallback, useMemo } from "react";
import { Q } from "@nozbe/watermelondb";
import { Empty, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { database } from "wmelon/database";
import { withObservables } from "@nozbe/watermelondb/react";
import PnrAccordion from "components/PnrAccordion";
import FlightTicket from "wmelon/models/FlightTicket";
import FlightTicketBinds from "wmelon/models/FlightTicketBinds";
import { useReduxSession } from "hooks/useReduxSession";
import { useDocuments } from "hooks/useDocuments";
import { useFlights } from "hooks/useFlights";
import { getGrouppedTickets, getSortedTickets } from "utils/tickets";
import { refreshDB } from "utils/db";
import { Observable } from "@nozbe/watermelondb/utils/rx";
import "./Upcoming.css";

export interface UpcomingProps {
  ts: number;
  tickets: FlightTicket[];
  flightTicketBinds: FlightTicketBinds[];
}

function Upcoming({ tickets, flightTicketBinds }: UpcomingProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { startValidation, clearSession } = useReduxSession();
  const { clearDocuments } = useDocuments();
  const { navigateToTicketById } = useFlights();

  const list = useMemo(() => {
    return getGrouppedTickets(
      getSortedTickets(tickets, flightTicketBinds, "desc"),
      "asc"
    );
  }, [tickets, flightTicketBinds]);

  const onSelect = useCallback(
    (tickets: FlightTicket[], validate: boolean) => {
      if (validate) {
        return Promise.all([
          clearSession(true).catch((err) =>
            console.log("[PnrAccordion] failed to clear session state:", err)
          ),
          clearDocuments().catch((err) =>
            console.log("[PnrAccordion] failed to clear documents state:", err)
          ),
        ])
          .then(() => startValidation(tickets))
          .then(() => {
            navigate("/preparation");
          })
          .catch((err) => {
            console.log("[PnrAccordion] failed to init session:", err);
            if (err.message === "SCHEMA_NOT_FOUND") {
              navigate("/tickets/upcoming");
              Modal.error({
                title: t("Ticket_and_Info.schemeNotFound"),
                content: t("restrictionsWillBeUpdated"),
                okText: t("ok"),
              });
            } else if (["AIRPORT_NOT_FOUND"].includes(err.message)) {
              refreshDB();
              Modal.error({
                title: t(err.message),
                content: t("restrictionsWillBeUpdated"),
              });
            } else {
              Modal.error({
                title: t("Ticket_and_Info.failedToStartValidation"),
                okText: t("ok"),
              });
            }
          });
      } else {
        navigateToTicketById(tickets[0].id, true);
        return Promise.resolve();
      }
    },
    [
      clearDocuments,
      clearSession,
      navigate,
      navigateToTicketById,
      startValidation,
      t,
    ]
  );

  const renderPNR = useCallback(
    (item: { date: string; data: FlightTicket[][] }, index: number) => {
      return (
        <PnrAccordion
          key={`${index}_${item.date}`}
          title={item.date}
          data={item.data}
          onSelect={onSelect}
        />
      );
    },
    [onSelect]
  );

  return (
    <div className="Upcoming">
      <div className="Upcoming-container">
        {list.length ? list.map(renderPNR) : <Empty />}
      </div>
    </div>
  );
}

const enhance = withObservables<
  Omit<UpcomingProps, "tickets" | "flightTicketBinds">,
  {
    tickets: Observable<FlightTicket[]>;
    flightTicketBinds: Observable<FlightTicketBinds[]>;
  }
>(["ts"], ({ ts }) => {
  return {
    tickets: database
      .get<FlightTicket>(FlightTicket.table)
      .query(Q.where("flight_date", Q.gte(ts)))
      .observe(),
    flightTicketBinds: database
      .get<FlightTicketBinds>(FlightTicketBinds.table)
      .query(Q.on("flightTickets", Q.where("flight_date", Q.gte(ts))))
      .observe(),
  };
});

const UnComingScreenEnhance = enhance(Upcoming);
export default UnComingScreenEnhance;
