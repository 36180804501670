import { useEffect, useRef, useState, useCallback } from 'react'
import Storage from '../core/Storage/Storage';

export function useTimer (name: string = `DEFAULT_TIMER_KEY_${new Date().getTime()}`): [number, (duration: number, onEnd?: () => void) => void, () => void] {
  const [counter, setCounter] = useState(0);
  const timer = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    Storage.shared().set(name, `${new Date().getTime()}`);
    return () => {
      timer.current && clearInterval(timer.current);
    }
  }, []); // eslint-disable-line

  const startTimer = useCallback((duration: number = 60, onEnd = () => {}) => {
    const storage = Storage.shared();
    storage.set(name, `${new Date().getTime()}`);
    timer.current && clearInterval(timer.current);
    setCounter(duration);
    timer.current = setInterval(async () => {
      const startTime = storage.get(name);
      const now = new Date().getTime();
      const _remaining = duration * 1000 - (now - parseInt(startTime));
      if (_remaining > 0) {
        setCounter(Math.round(_remaining / 1000));
      } else {
        setCounter(0);
        storage.delete(name);
        clearInterval(timer.current);
        onEnd();
      }
    }, 1000);
  }, [name]);

  const stopTimer = useCallback(() => {
    timer.current && clearInterval(timer.current);
  }, []);

  return [counter, startTimer, stopTimer];
}
