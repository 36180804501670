import { useMemo, useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import debounce from "lodash/debounce";
import orderBy from "lodash/orderBy";
import { getGrouppedHistoryTickets } from "utils/history";
import { useFlights } from "hooks/useFlights";
import { ReduxState } from "redux/ReduxState";
import { useHistory } from "hooks/useHistory";
import { HistoryItem } from "types/history";
import PNRList from "./PNRList";
import HistoryPNRItem from "components/HistoryPNRItem";
import Loader from "components/Loader";

function HistoryList({ ts }: { ts: number | null }) {
  const { loadList } = useHistory();

  const tickets = useSelector((state: ReduxState) => state.history.items);

  const { navigateToTickets } = useFlights();
  const [loading, setLoading] = useState(false);

  const sections = useMemo(() => {
    return getGrouppedHistoryTickets(
      orderBy(tickets, "binded_at", "desc"),
      "desc"
    );
  }, [tickets]);
  console.log("SECTIONS:", sections, tickets);
  const onSelect = useCallback(
    async (tickets: HistoryItem[]) => {
      navigateToTickets(tickets[0], true);
    },
    [navigateToTickets]
  );

  const loadData = useCallback(
    debounce((ts: number | null) => {
      setLoading(true);
      loadList(ts ? moment(ts).format("YYYY-MM-DD") : null)
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }, 500),
    [loadList]
  );

  useEffect(() => {
    if (!loading) {
      loadData(ts);
    }
  }, [ts]); // eslint-disable-line

  const renderPNR = useCallback(
    (item: HistoryItem[]) => {
      return <HistoryPNRItem tickets={item} onSelect={onSelect} />;
    },
    [onSelect]
  );

  if (loading) {
    return <Loader loading={loading} />;
  }

  return <PNRList sections={sections} renderItem={renderPNR} />;
}

export default HistoryList;
