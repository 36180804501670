import { Model } from '@nozbe/watermelondb'
import { field } from '@nozbe/watermelondb/decorators'

export default class PnrPassenger extends Model {
  // static create(arg0: (passenger: any) => void) {
  //   throw new Error('Method not implemented.')
  // }
  static table = 'profile'

  @field('avatar') avatar!: string
  @field('name') name!: string
  @field('surname') surname!: string
  @field('dob') birthday!: string
  @field('email') email!: string
  @field('created_at') createdAt!: number
  @field('updated_at') updatedAt!: number
  @field('deleted_at') deletedAt!: boolean

  @field('mobile') mobile!: string
  @field('passport') passport!: string
  @field('passport_exp') passportExp!: number

  @field('mobile_id') mobileId!: boolean
  @field('face_id') faceId!: boolean
  @field('email_sms') emailSms!: boolean

  @field('trustedDevice') trusted_device!: string
}
