import {
  Result,
  CheckResult,
  ProcessResponse,
  StatusResult,
  ChosenDocumentTypeResult,
  TextResult,
  TextFieldType,
  ImageQualityResult,
} from "@regulaforensics/document-reader-webclient";
import { Document, DocumentField } from "./model/Document";
import { ErrorTexts } from "./ErrorTexts";

export default class RegulaParser {
  static StatusResult = "Status";
  static TextResult = "Text";
  static ChosenDocumentTypeResult = "OneCandidate";

  static getListItem(regulaResult: ProcessResponse, type: number) {
    return regulaResult.ContainerList.List.find((l) => l.result_type === type);
  }

  static getListItems(regulaResult: ProcessResponse, type: number) {
    return regulaResult.ContainerList.List.filter(
      (l) => l.result_type === type
    );
  }

  static getOverallStatus(regulaResult: ProcessResponse) {
    const status = RegulaParser.getListItem(
      regulaResult,
      Result.STATUS
    ) as StatusResult;
    return status?.Status?.detailsOptical?.overallStatus;
  }

  static getIsRecognized(regulaResult: ProcessResponse) {
    const status = RegulaParser.getListItem(
      regulaResult,
      Result.STATUS
    ) as StatusResult;
    return status?.Status?.detailsOptical?.docType === CheckResult.OK;
  }

  static getMrzStatus(regulaResult: ProcessResponse) {
    const status = RegulaParser.getListItem(
      regulaResult,
      Result.STATUS
    ) as StatusResult;
    return status?.Status?.detailsOptical?.mrz;
  }

  static getChipStatus(regulaResult: ProcessResponse) {
    return regulaResult.ChipPage;
  }

  static getType(regulaResult: ProcessResponse) {
    const item = RegulaParser.getListItem(
      regulaResult,
      Result.DOCUMENT_TYPE
    ) as ChosenDocumentTypeResult;
    return item?.OneCandidate?.FDSIDList?.dType;
  }

  static getDocumentClassCode(regulaResult: ProcessResponse) {
    const item = RegulaParser.getListItem(
      regulaResult,
      Result.TEXT
    ) as TextResult;
    return (
      (item?.Text?.fieldList || []).find(
        (it) => it.fieldType === TextFieldType.DOCUMENT_CLASS_CODE
      )?.value || null
    );
  }

  static getDateOfExpiry(regulaResult: ProcessResponse) {
    const item = RegulaParser.getListItem(
      regulaResult,
      Result.TEXT
    ) as TextResult;
    return (
      (item?.Text?.fieldList || []).find(
        (it) => it.fieldType === TextFieldType.DATE_OF_EXPIRY
      )?.value || null
    );
  }

  static getTextFieldList(regulaResult: ProcessResponse) {
    const item = RegulaParser.getListItem(
      regulaResult,
      Result.TEXT
    ) as TextResult;
    return item?.Text?.fieldList || [];
  }

  static getICAOCode(regulaResult: ProcessResponse) {
    const item = RegulaParser.getListItem(
      regulaResult,
      Result.DOCUMENT_TYPE
    ) as ChosenDocumentTypeResult;
    return item?.OneCandidate?.FDSIDList?.ICAOCode || null;
  }

  static getDocumentData(regulaResult: ProcessResponse): {
    icao: string | null;
    documentId: string | null;
    documentName: string | null;
  } {
    const item = RegulaParser.getListItem(
      regulaResult,
      Result.DOCUMENT_TYPE
    ) as ChosenDocumentTypeResult;

    return {
      icao: item?.OneCandidate?.FDSIDList?.ICAOCode || null,
      documentId: item?.OneCandidate?.ID
        ? item.OneCandidate.ID.toString()
        : null,
      documentName: item?.OneCandidate?.DocumentName || null,
    };
  }

  static getDocumentName(regulaResult: ProcessResponse) {
    const item = RegulaParser.getListItem(
      regulaResult,
      Result.DOCUMENT_TYPE
    ) as ChosenDocumentTypeResult;
    return item?.OneCandidate?.DocumentName || null;
  }

  static getImageQuality(regulaResult: ProcessResponse) {
    const items = RegulaParser.getListItems(
      regulaResult,
      Result.IMAGE_QUALITY
    ) as ImageQualityResult[];

    return items.map((item: ImageQualityResult) => ({
      result: item.ImageQualityCheckList.result,
      list: item.ImageQualityCheckList.List.map((it) => ({
        type: it.type,
        status: it.result,
        mean: it.mean,
        probability: it.probability,
        std_dev: it.std_dev,
      })),
    }));
  }

  static parse(
    regulaResult: ProcessResponse,
    shouldRecognizeDocument: boolean,
    quickMode: boolean
  ): Document {
    if (
      shouldRecognizeDocument &&
      (quickMode
        ? !RegulaParser.getDocumentClassCode(regulaResult)
        : !RegulaParser.getIsRecognized(regulaResult))
    )
      throw Error(ErrorTexts.NOT_RECOGNIZED);
    // if (!RegulaParser.getOverallStatus(regulaResult)) throw Error(ErrorTexts.DOCUMENT_IS_NOT_VALID)
    // if (regulaResult.morePagesAvailable) throw Error(ErrorTexts.MORE_PAGES_AVAILABLE)

    let fields: DocumentField[] = [];
    RegulaParser.getTextFieldList(regulaResult).forEach((textField: any) =>
      fields.push({
        type: textField.fieldType,
        name: textField.fieldName,
        value: textField.value,
        lcid: textField.lcid,
        status: textField.status,
        valueList: (textField.valueList || []).map((it: any) => it.value),
      })
    );

    const { icao, documentId, documentName } =
      RegulaParser.getDocumentData(regulaResult);
    console.log("[REGULA]", fields);
    return {
      mrzStatus: RegulaParser.getMrzStatus(regulaResult),
      chipStatus: RegulaParser.getChipStatus(regulaResult) || null,
      docType:
        RegulaParser.getType(regulaResult) ||
        (quickMode ? RegulaParser.getDocumentClassCode(regulaResult) : null),
      fields: fields.sort((a: any, b: any) => a.lcid - b.lcid),
      morePagesAvailable: regulaResult.morePagesAvailable || null,
      ICAOCode: icao,
      documentId: documentId,
      documentName: documentName,
      date_of_expiry: RegulaParser.getDateOfExpiry(regulaResult),
      meta: {
        image_quality: RegulaParser.getImageQuality(regulaResult),
      },
    };
  }
}
