import { useCallback, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import cn from "classnames";
import DBPNRItem from "components/DBPNRItem";
import { getLocaleDate } from "utils/date";
import FlightTicket from "wmelon/models/FlightTicket";
import "./PnrAccordion.css";
import { APP_COLORS } from "utils/colors";
export interface PnrAccordionProps {
  title: string;
  data: FlightTicket[][];
  onSelect: (tickets: FlightTicket[], validate: boolean) => Promise<void>;
}

export default function PnrAccordion({
  title,
  data,
  onSelect,
}: PnrAccordionProps) {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  const onToogle = useCallback(() => setIsVisible(!isVisible), [isVisible]);

  return (
    <div className="PnrAccordion">
      <div className="PnrAccordion-header" onClick={onToogle}>
        <span className="PnrAccordion-header-title">
          {getLocaleDate(title, "DD MMMM YYYY")}
        </span>
        <DownOutlined
          rotate={isVisible ? 180 : 0}
          style={{ color: APP_COLORS.light.primary }}
        />
      </div>
      <div className="PnrAccordion-content">
        <div className={cn("PnrAccordion-container", { visible: isVisible })}>
          {isVisible ? (
            data.map((item: FlightTicket[], index: number) => (
              <DBPNRItem
                isPast={false}
                key={index}
                tickets={item}
                onSelect={onSelect}
              />
            ))
          ) : (
            <div className="PnrAccordion-compact">
              <DBPNRItem
                isPast={false}
                compact
                tickets={data[0]}
                onSelect={onSelect}
              />
              <div className="PnrAccordion-first-subcontainer" />
              <div className="PnrAccordion-second-subcontainer" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
