import { Model } from '@nozbe/watermelondb'
import { field } from '@nozbe/watermelondb/decorators'

export default class TagBind extends Model {
  static table = 'tagBinds'

  @field('tag_id') tag_id!: string
  @field('bind_object') bind_object!: 'pnrFlight' | 'bag'
  @field('object_id') object_id!: string
}
