import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Q } from "@nozbe/watermelondb";
import FlightTicket from "wmelon/models/FlightTicket";
import Session from "wmelon/models/Sessions";
import { getQueryValue } from "utils/db";
import { getSessionStatus } from "utils/session";
import CopyToClipboardSessionCode, {
  CopyToClipboardSessionCodeType,
} from "components/CopyToClipboardSessionCode";
import warningIcon from "../../../../assets/images/warning.svg";
import stampIcon from "../../../../assets/images/smallstamp.svg";
import errorStampIcon from "../../../../assets/images/error_stamp.svg";
import config from "config";

import "./TicketResult.css";
import { withObservables } from "@nozbe/watermelondb/react";
import { database } from "wmelon/database";
import { Observable } from "@nozbe/watermelondb/utils/rx";

const STATUS_MESSAGE = {
  [config.SESSION_STATUS_IDS.REJECTED_BY_RECOGNIZER]:
    "Validator.documentIsNotRecognized",
  [config.SESSION_STATUS_IDS.EXPIRED_DOCUMENT]: "Validator.documentIsExpired",
  [config.SESSION_STATUS_IDS.WRONG_ETICKET]: "Validator.ticketOwnerIsDifferent",
};

interface TicketResultProps {
  sessionId: string | null;
  ticket: FlightTicket;
  statusId: string;
  fullwidth: boolean;
  sessions: Session[];
}

function TicketResult({ ticket, statusId, sessions }: TicketResultProps) {
  const { t } = useTranslation();

  const session = [
    config.SESSION_STATUS_IDS.REJECTED_BY_RECOGNIZER,
    config.SESSION_STATUS_IDS.EXPIRED_DOCUMENT,
    config.SESSION_STATUS_IDS.WRONG_ETICKET,
  ].includes(statusId)
    ? null
    : sessions[0];

  const shortId = session?.short_id || "SHORT_ID";

  const fullName = session
    ? `${session.owner_first_name || ""} ${
        session.owner_last_name || ""
      }`.trim()
    : "";

  const status = useMemo(() => {
    if (!session) {
      return null;
    }

    if (session._raw._status !== "synced") {
      return null;
    }

    return getSessionStatus(session).state;
  }, [session]);

  const statusImage = useMemo(() => {
    const statuses = {
      waitIcon: warningIcon,
      wait: stampIcon,
      success: stampIcon,
      rejected: errorStampIcon,
    };
    return status ? statuses[status] : statuses.waitIcon;
  }, [status]);

  if (!session) {
    return (
      <div className="TicketResult">
        <div className="TicketResult-content">
          <span className="TicketResult-number">{ticket.ticket_number}</span>
          <span className="TicketResult-description">
            {STATUS_MESSAGE[statusId]
              ? t(STATUS_MESSAGE[statusId])
              : t("RegulaScreens.validationNotDone")}
          </span>
        </div>
        <div className="TicketResult-icon">
          <img src={statusImage} alt="" />
        </div>
      </div>
    );
  }

  return (
    <div className="TicketResult">
      <div className="TicketResult-content">
        <span className="TicketResult-number">
          <CopyToClipboardSessionCode
            text={shortId ?? t("na")}
            styleType={CopyToClipboardSessionCodeType.validation}
          />
        </span>
        <span className="TicketResult-description">{fullName}</span>
      </div>
      <div className="TicketResult-icon">
        <img src={statusImage} alt="" />
      </div>
    </div>
  );
}
const enhance = withObservables<
  Omit<TicketResultProps, "sessions">,
  { sessions: Observable<Session[]> }
>(["sessionId"], ({ sessionId }: { sessionId: string | null }) => ({
  sessions: database
    .get<Session>(Session.table)
    .query(Q.where("id", getQueryValue(sessionId)))
    .observeWithColumns(["status", "handler"]),
}));

const EnhancedTicketResult = enhance(TicketResult as any) as any;
export default EnhancedTicketResult;
