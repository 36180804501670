import { useMemo } from "react";
import { useParams } from "react-router-dom";
import DBTicketsList from "./components/DBTicketsList";
import HistoryTicketsList from "./components/HistoryTicketsList";

function Tickets() {
  const {
    flight_number,
    date,
    pnr,
    airline,
    flight_origin,
    flight_destination,
    source,
  } = useParams();

  const params = useMemo(
    () => ({
      flight_number: flight_number || null,
      date: date || null,
      pnr: pnr || null,
      airline: airline || null,
      flight_origin: flight_origin || null,
      flight_destination: flight_destination || null,
    }),
    [flight_number, date, pnr, airline, flight_origin, flight_destination]
  );
  console.log("SOURCE:", source);
  if (source === "db") {
    return <DBTicketsList params={params} />;
  }

  return <HistoryTicketsList params={params} />;
}

export default Tickets;
