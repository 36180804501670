import { useEffect, useRef, useState } from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import FileUploader from "../FileUploader";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { APP_COLORS } from "utils/colors";
interface ModalProps {
  visible: boolean;
  uploadFiles: (Blob | File)[];
  onCancel: () => void;
  onAccept: (files: (Blob | File)[]) => void;
  onRemove: (index: number) => void;
  onAddFile: (file: Blob) => void;
  disabled?: boolean;
}

const FileModal = ({
  visible,
  uploadFiles,
  onCancel,
  onAccept,
  onRemove,
  onAddFile,
  disabled = false,
}: ModalProps & { onAccept: (files: (Blob | File)[]) => void }) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState([] as any);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setFiles([...uploadFiles]);
    console.log("Uploading files", uploadFiles);
  }, [uploadFiles]);

  useEffect(() => {
    if (!visible && inputRef.current) {
      inputRef.current.value = "";
    }
  }, [visible]);

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      okButtonProps={{
        size: "small",
        disabled: files.length === 0 || disabled,
        style: {
          color: files.length === 0 || disabled ? "" : APP_COLORS.light.primary,
        },
      }}
      cancelButtonProps={{
        size: "small",
        style: {
          color: APP_COLORS.light.primary,
          // backgroundColor: APP_COLORS.light.primary,
        },
      }}
      onOk={() => {
        onAccept(files);
        console.log(" files loaded successfully", files);
      }}
      okText={t("accept")}
      closable={false}
    >
      <FileUploader
        uploadFiles={uploadFiles}
        onAddFile={onAddFile}
        onRemove={onRemove}
      />
    </Modal>
  );
};

export default FileModal;
