import { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoaderAction } from "redux/features/loader";
import { useFlights } from "hooks/useFlights";
import { ReduxState } from "redux/ReduxState";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import { BIND_ERROR_CODES } from "constants/contants";
import useDBSync from "hooks/useDBSync";

export default function LinkingProvider() {
  const { t } = useTranslation();
  const { checkPNR, bindTicketByShareToken } = useFlights();
  const isSyncing = useSelector((state: ReduxState) => state.app.syncing);
  const dispatch = useDispatch();
  const sync = useDBSync(true);

  const [linkingMsg, setLinkingMsg] = useState<{
    pnr: string | null;
    provider: string | null;
  } | null>(null);

  const isSyncingRef = useRef(isSyncing);
  isSyncingRef.current = isSyncing;

  useEffect(() => {
    if (!isSyncing && linkingMsg && linkingMsg.pnr) {
      checkPNR(linkingMsg.pnr, linkingMsg.provider as any, true)
        .then(() => {
          dispatch(setLoaderAction(false));
        })
        .catch((err) => {
          console.log("[LinkingProvider] checkPNR", err);
        });
      setLinkingMsg(null);
    }
  }, [isSyncing, linkingMsg, dispatch, checkPNR]);

  const handleBindEvent = useCallback(
    async (event: any) => {
      const {
        pnr,
        provider,
        share_token,
      }: {
        pnr: string | null;
        provider: string | null;
        share_token: string | null;
      } = event?.detail?.data;
      try {
        if (share_token) {
          await bindTicketByShareToken(share_token);
          dispatch(setLoaderAction(false));
        } else if (pnr) {
          console.log("[LinkingProvider] start binding:", pnr, provider);
          console.log("[LinkingProvider].linking sync check pnr:", Date.now());
          await checkPNR(pnr, provider, true)
            .then(() => {
              dispatch(setLoaderAction(false));
            })
            .catch((err) => {
              if (err.message === BIND_ERROR_CODES.FLIGHT_TICKET_NOT_FOUND) {
                console.log(
                  "[LinkingProvider] set to check:",
                  err,
                  isSyncingRef.current
                );
                if (!isSyncingRef.current) {
                  sync()
                    .then(() => setLinkingMsg({ pnr, provider }))
                    .catch((err) => {
                      dispatch(setLoaderAction(false));
                      console.error(
                        "[LinkingProvider].linking: failed to sync:",
                        err.message
                      );
                    });
                } else {
                  setLinkingMsg({ pnr, provider });
                }
              } else {
                Modal.error({
                  title: t("Error") as string,
                  content: t([err.message, "error"]),
                  okText: t("ok"),
                });
                throw err;
              }
            });
        } else {
          dispatch(setLoaderAction(false));
        }
      } catch (err) {
        console.error("Linking error: ", err);
        dispatch(setLoaderAction(false));
      }
    },
    [bindTicketByShareToken, checkPNR, dispatch, sync, t]
  );

  useEffect(() => {
    if (document) {
      document.addEventListener("linking", handleBindEvent);
      return () => document.removeEventListener("linking", handleBindEvent);
    }
  }, [handleBindEvent]);

  return null;
}
