import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { InboxOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import FileViewer from "../FileViewer";

import "./FileUploader.css";
import Gallery from "components/Gallery";
import { APP_COLORS } from "utils/colors";

const ACCEPT_FILES = "application/pdf, image/*";

interface FileUploaderProps {
  uploadFiles: (Blob | File)[];
  onRemove: (index: number) => void;
  onAddFile: (file: Blob) => void;
  disabled?: boolean;
  icons?: string[];
}

export default function FileUploader({
  uploadFiles,
  onAddFile,
  onRemove,
  icons,
}: FileUploaderProps) {
  const { t } = useTranslation();
  const [showGallery, setShowGallery] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<number>(0);
  const modalRef = useRef(false);

  const handleSelectFile = useCallback((index: number) => {
    setSelectedFile(index);
  }, []);

  const handleClose = useCallback(() => {
    setShowGallery(false);
    setSelectedFile(0);
  }, []);

  const renderIcon = useCallback((icon, idx: number) => {
    return <img key={`icon_${idx}`} src={icon} alt="" />;
  }, []);
  return (
    <div className="FileUploader">
      <Upload.Dragger
        id="uploader"
        accept={ACCEPT_FILES}
        multiple
        maxCount={5}
        beforeUpload={(file, FileList) => {
          if (uploadFiles.length + FileList.length > 5) {
            if (!modalRef.current) {
              modalRef.current = true;
              Modal.warning({
                title: t("Validator.youCanUploadMaxImages", { max: 5 }),
                okText: t("ok"),
                onOk: () => {
                  modalRef.current = false;
                },
                onCancel: () => {
                  modalRef.current = false;
                },
              });
            }
            return Upload.LIST_IGNORE;
          }
          onAddFile(file);
          return false;
        }}
        className="FileUploader-dnd"
      >
        <p className="FileUploader-icon">
          {icons && icons.length ? (
            icons.map(renderIcon)
          ) : (
            <InboxOutlined
              style={{
                fontSize: 48,
                color: APP_COLORS.light.primary,
              }}
            />
          )}
        </p>
        <p className="FileUploader-description">
          {t("Validator.dndDescription")}
        </p>
      </Upload.Dragger>
      <div className="FileUploader-preview">
        {uploadFiles.map((file: any, index: any) => (
          <FileViewer
            key={index}
            file={file}
            size="small"
            onRemove={() => onRemove(index)}
            onClick={() => {
              setShowGallery(true);
              handleSelectFile(index);
            }}
          />
        ))}
      </div>
      <Gallery
        visible={showGallery}
        files={uploadFiles}
        onClose={handleClose}
        selectedFile={selectedFile}
      />
    </div>
  );
}
