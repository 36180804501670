import {Model} from '@nozbe/watermelondb'
import {field} from '@nozbe/watermelondb/decorators'

export default class Airport extends Model {
  static table = 'airports'

  @field('icao') icao!: string
  @field('iata') iata?: string
  @field('name') name!: string
  @field('countryCode') countryCode!: string
}
