import { WidgetProps, enumOptionsValueForIndex } from "@rjsf/utils";

import { fieldNameToLabel } from "utils/string";
import { Select, Typography } from "antd";
import { APP_COLORS } from "utils/colors";

function SelectWidget({
  id,
  name,
  value,
  required,
  disabled,
  readonly,
  onBlur,
  onChange,
  onFocus,
  label,
  placeholder,
  options,
  selectOptions,
  enumOptions,
  enumDisabled,
  emptyValue: optEmptyValue,
}: WidgetProps) {
  return options.hidden ? null : (
    <div>
      <Typography.Text style={{}}>
        {fieldNameToLabel(label)} {required && "*"}
      </Typography.Text>
      <Select
        dropdownStyle={{
          backgroundColor: APP_COLORS.light.black,
        }}
        id={id}
        options={enumOptions}
        disabled={disabled}
        onSelect={(selectedItem, _) => {
          onChange(
            enumOptionsValueForIndex(
              selectedItem.value,
              enumOptions,
              optEmptyValue
            )
          );
        }}
      />
    </div>
  );
}

export default SelectWidget;
