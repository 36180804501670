import { Button, Card, Col, Typography } from "antd";
import { useTranslation } from "react-i18next";
import config from "config";
import useDeviceType from "hooks/useDeviceType";
import { isSafari, isMobileSafari } from "react-device-detect";
import { APP_COLORS } from "utils/colors";

interface IProps {
  onCall: () => void;
  onInformation: () => void;
}

function SupportCard({ onCall, onInformation }: IProps) {
  const isMobileTablet = useDeviceType();
  const { t } = useTranslation();
  return (
    <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
      <Card
        style={{ marginBottom: 20 }}
        headStyle={{
          border: "none",
          fontWeight: isMobileSafari || isSafari ? "599" : "600",
          marginBottom: -10,
        }}
        bodyStyle={{ padding: "0px 24px 24px 24px" }}
        title={t("Support.Call")}
        bordered={true}
      >
        {isMobileTablet ? (
          <a href={`tel:${config.CALL_CENTER_PHONE}`} style={{ fontSize: 18 }}>
            {config.CALL_CENTER_PHONE}
          </a>
        ) : (
          <Typography style={{ fontSize: 18 }}>
            {config.CALL_CENTER_PHONE}
          </Typography>
        )}
        <Typography style={{ marginTop: 10 }}>
          {t("Support.callfree")}
        </Typography>
        <Typography style={{ marginTop: 10 }}>
          {t("Support.workingHours")}
        </Typography>
        <Typography>{t("Support.swissTime")}</Typography>
        <Button
          onClick={onCall}
          style={{
            marginTop: 20,
            width: "100%",
            color: APP_COLORS.light.primary_text_color,
            backgroundColor: APP_COLORS.light.primary,
          }}
          type='primary'
        >
          {t("Support.calltous")}
        </Button>
      </Card>
      <Card
        style={{ marginBottom: 20 }}
        headStyle={{
          border: "none",
          fontWeight: isMobileSafari || isSafari ? "599" : "600",
          marginBottom: -10,
        }}
        bodyStyle={{ padding: "0px 24px 24px 24px" }}
        title={t("Support.calltous")}
        bordered={true}
      >
        <Typography>{t("Support.Information")}</Typography>
        <Button
          onClick={onInformation}
          style={{
            marginTop: 20,
            width: "100%",
            color: APP_COLORS.light.primary_text_color,
            backgroundColor: APP_COLORS.light.primary,
          }}
          type='primary'
        >
          {t("Support.ShowTermsofservice")}
        </Button>
      </Card>
    </Col>
  );
}

export default SupportCard;
