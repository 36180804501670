import { Q } from "@nozbe/watermelondb";
import { withObservables } from "@nozbe/watermelondb/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { getTicketDates } from "utils/tickets";
import { database } from "wmelon/database";
import Airport from "wmelon/models/Airport";
import airlineIcon from "assets/images/airline.svg";
import airlineTransitIcon from "assets/images/airline_transit.svg";
import "./FlightDetails.css";

interface TicketData {
  flight_origin: string;
  flight_destination: string;
  flight_date: string;
  flight_departure_time?: string | null;
  flight_arrival_time?: string | null;
  flight_number: string;
}

export interface FlightDetailsProps {
  ticket: TicketData | null;
  airports: Airport[];
  className?: string;
}

function FlightDetails({ ticket, airports, className }: FlightDetailsProps) {
  const { t } = useTranslation();
  const ticketsInfo = React.useMemo(() => {
    if (!ticket) {
      return {};
    }

    const dates = getTicketDates(ticket);

    const origin = airports.find(
      (it) => it.iata === ticket.flight_origin.toUpperCase()
    );
    const destination = airports.find(
      (it) => it.iata === ticket.flight_destination.toUpperCase()
    );

    let destination_code = destination?.iata || ticket.flight_destination;
    let destination_city = destination?.name;
    let transit: string | null = null;

    if (ticket.flight_number && ticket.flight_number.endsWith("T")) {
      if (ticket.flight_destination === "DDE") {
        // destination_city && (destination_city = `Doha-${destination_city}`);
        destination_code = "DEL";
        transit = "DOH";
      } else if (ticket.flight_destination === "NBF") {
        // destination_city && (destination_city = `Doha-${destination_city}`);
        destination_code = "NBO";
        transit = "DOH";
      }
    }
    return {
      origin_city: origin?.name || null,
      origin_code: origin?.iata || ticket.flight_origin,
      destination_city: destination_city || null,
      destination_code: destination_code,
      transit,
      dates,
    };
  }, [ticket, airports]);

  return (
    <div className={`FlightDetails ${className}`}>
      <div className="FlightDetails-header">
        <span>{ticketsInfo.origin_city || t("na")}</span>
        <span>{ticketsInfo.origin_code || t("na")}</span>
      </div>
      <div className="FlightDetails-content">
        <span className="FlightDetails-aircode">
          {ticket?.flight_origin ?? t("na")}
        </span>
        <img
          src={ticketsInfo.transit ? airlineTransitIcon : airlineIcon}
          alt=""
        />
        <span className="FlightDetails-aircode">
          {ticket?.flight_destination ?? t("na")}
        </span>
      </div>
      <div className="FlightDetails-footer">
        <span className="FlightDetails-time">
          {ticketsInfo.dates?.departure_at || t("na")}
        </span>
        <span className="FlightDetails-time">
          {ticketsInfo.dates?.arrival_at || t("na")}
        </span>
      </div>
    </div>
  );
}

const enhance = withObservables(
  ["ticket"],
  ({ ticket }: { ticket: TicketData | null }) => {
    const codes = ticket
      ? [
          ticket.flight_origin.toUpperCase(),
          ticket.flight_destination.toUpperCase(),
        ]
      : [];

    return {
      airports: database
        .get<Airport>(Airport.table)
        .query(Q.where("iata", Q.oneOf(codes)))
        .observeWithColumns(["icao", "iata", "name", "countryCode"]),
    };
  }
);

const EnhancedFlightDetails = enhance(FlightDetails);
export default EnhancedFlightDetails;
