import { useCallback, useEffect, useState } from "react";
import { Button, Layout } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Tags from "components/Tags";

import { useToggle } from "hooks/useToggle";
import AuthApiService from "utils/AuthApi";

import "./Profile.css";
import UserForm from "./components/UserForm";
import { APP_COLORS } from "utils/colors";

function Profile() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userId, setUserId] = useState<any>(null);
  const [showTags, toggle, setToggle] = useToggle(false);
  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const userId = await AuthApiService.shared().getUserId();
        await setUserId(userId);
      } catch (err) {
        setUserId(null);
      }
    };
    fetchUserId();
  }, []);

  const onGetUserId = useCallback(async () => {
    try {
      setUserId(AuthApiService.shared().getUserId());
    } catch (err) {
      setUserId(null);
    }
  }, []);

  const onCloseTags = useCallback(() => setToggle(false), [setToggle]);

  return (
    <div className="Profile">
      <div className="Profile-header">
        <Button
          type="text"
          icon={
            <LeftOutlined
              style={{
                color: APP_COLORS.light.primary,
                fontSize: 20,
              }}
            />
          }
          onClick={() => {
            navigate("/tickets");
          }}
        />
        <span>{t("HeaderTitle.Profile")}</span>
      </div>
      <Layout className="Profile-container">
        <UserForm
          userId={userId}
          onGetUserId={onGetUserId}
          onClickTags={toggle}
        />
        <Tags visible={showTags} onClose={onCloseTags} canEdit />
      </Layout>
    </div>
  );
}

export default Profile;
