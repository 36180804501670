import { Model } from '@nozbe/watermelondb'
import { date, field, json, children } from '@nozbe/watermelondb/decorators'
import { Associations } from "@nozbe/watermelondb/Model";
import FlightTicketBinds from './FlightTicketBinds';


export default class FlightTicket extends Model {
  static table = 'flightTickets'

  static associations: Associations = {
    flightTicketBinds: { type: 'has_many', foreignKey: 'ticket_id' },
    flightTicketShares: { type: 'has_many', foreignKey: 'flight_ticket_id' },
  }

  @json('identifiers', s => s) identifiers!: string

  @field('ticket_number') ticket_number!: string
  @field('airline') airline!: string

  @field('flight_number') flight_number!: string
  @date('flight_date') flight_date!: string
  @field('flight_departure_time') flight_departure_time?: string
  @field('flight_arrival_time') flight_arrival_time?: string

  @field('flight_origin') flight_origin!: string
  @field('flight_destination') flight_destination!: string

  @field('passenger_document_hash') passenger_document_hash?: string
  @field('passenger_firstname_hash') passenger_firstname_hash?: string
  @field('passenger_lastname_hash') passenger_lastname_hash?: string

  @field('passenger_document') passenger_document?: string
  @field('passenger_firstname') passenger_firstname?: string
  @field('passenger_lastname') passenger_lastname?: string

  @children('flightTicketBinds') flightTicketBinds!: FlightTicketBinds[];
}
