import { Button, Modal, Typography, notification } from "antd";
import config from "config";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { NOTIFICATION_KEYS } from "constants/contants";
import { APP_COLORS } from "utils/colors";

type Props = {
  text: string;
  onClose: () => void;
  visible: boolean;
  withOutShortId?: boolean;
  onNextPassenger?: () => void;
};

const CALL_CENTER = config.CALL_CENTER_PHONE.replace(/\s/g, "");
function ModalCallCenter({
  text,
  onClose,
  visible,
  withOutShortId,
  onNextPassenger,
}: Props) {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();

  const [showIcao, setShowIcao] = useState(false);

  const { t } = useTranslation();
  const callNumber = useCallback(async () => {
    let phoneNumber = `tel:${CALL_CENTER}`;
    const isMobile =
      /iPhone|Android/i.test(navigator.userAgent) &&
      !/iPad|Tablet/i.test(navigator.userAgent);
    if (isMobile) {
      window.location.href = phoneNumber;
    } else {
      api.info({
        key: NOTIFICATION_KEYS.PHONE_CALL,
        message: t("callCenter.phoneCallIsNot", {
          CALL_CENTER: CALL_CENTER,
        }),
        placement: "topRight",
      });
      navigator.clipboard.writeText(CALL_CENTER);

      onNextPassenger
        ? onNextPassenger()
        : setTimeout(() => {
            navigate("/tickets");
          }, 5000);
    }
  }, [t, api, navigate, onNextPassenger]);
  const textToIcao = useCallback((text: string) => {
    var count = 0;
    var icao = "";
    while (count < text?.length) {
      switch (text[count]) {
        case "A":
        case "a":
          icao += " Alpha";
          break;
        case "B":
        case "b":
          icao += " Bravo";
          break;
        case "C":
        case "c":
          icao += " Charli";
          break;
        case "D":
        case "d":
          icao += " Delta";
          break;
        case "E":
        case "e":
          icao += " Echo";
          break;
        case "F":
        case "f":
          icao += " Foxtrot";
          break;
        case "G":
        case "g":
          icao += " Golf";
          break;
        case "H":
        case "h":
          icao += " Hotel";
          break;
        case "I":
        case "i":
          icao += " India";
          break;
        case "J":
        case "j":
          icao += " Juliett";
          break;
        case "K":
        case "k":
          icao += " Kilo";
          break;
        case "L":
        case "l":
          icao += " Lima";
          break;
        case "M":
        case "m":
          icao += " Mike";
          break;
        case "N":
        case "n":
          icao += " November";
          break;
        case "O":
        case "o":
          icao += " Oscar";
          break;
        case "P":
        case "p":
          icao += " PaPa";
          break;
        case "Q":
        case "q":
          icao += " Quebec";
          break;
        case "R":
        case "r":
          icao += " Romeo";
          break;
        case "S":
        case "s":
          icao += " Sierra";
          break;
        case "T":
        case "t":
          icao += " Tango";
          break;
        case "U":
        case "u":
          icao += " Uniform";
          break;
        case "V":
        case "v":
          icao += " Victor";
          break;
        case "W":
        case "w":
          icao += " Whiskey";
          break;
        case "X":
        case "x":
          icao += " X-Ray";
          break;
        case "Y":
        case "y":
          icao += " Yankee";
          break;
        case "Z":
        case "z":
          icao += " Zulu";
          break;
        case "-":
          break;
        default:
          icao += ` ${text[count]}`;
          break;
      }

      count += 1;

      if (count % 3 === 0) {
        icao += "\n";
      }
    }

    return icao;
  }, []);

  return (
    <Modal
      open={visible}
      width={500}
      onCancel={onClose}
      centered
      onOk={callNumber}
      okText={t("Support.Call")}
      cancelText={t("Cancel")}
      okButtonProps={{
        size: "small",
        style: {
          color: APP_COLORS.light.primary_text_color,
          backgroundColor: APP_COLORS.light.primary,
        },
      }}
      cancelButtonProps={{ size: "small",style:{
        color: APP_COLORS.light.default_btn_text_color,
      } }}
    >
      <div
        style={{
          marginTop: 30,
        }}
      >
        {withOutShortId ? (
          <div>
            <Typography>{t("FinalFailed.requestingSupport")}</Typography>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
              {t("SessionCodeCopyToClipboard.title")}
            </Typography>
            {!showIcao && (
              <div>
                <Button onClick={() => setShowIcao(true)}>
                  <div className='headerSessionContainer'>
                    <DownOutlined style={{ marginRight: 15 }} />
                    <Typography style={{ fontWeight: "bold", fontSize: 16 }}>
                      {text}
                    </Typography>
                    <DownOutlined style={{ marginLeft: 15 }} />
                  </div>
                </Button>
              </div>
            )}
            {showIcao && (
              <div>
                <Button
                  style={{ minHeight: "75px", height: "100%" }}
                  onClick={() => setShowIcao(false)}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <UpOutlined style={{ marginRight: 15 }} />
                    <div>
                      <div>
                        <Typography
                          style={{ fontWeight: "bold", fontSize: 16 }}
                        >
                          {text}
                        </Typography>
                      </div>
                      <div className='icaoExpandView'>
                        <Typography>{textToIcao(text)}</Typography>
                      </div>
                    </div>
                    <UpOutlined style={{ marginLeft: 15 }} />
                  </div>
                </Button>
              </div>
            )}
            <div>
              <Typography>{t("FinalFailed.requestingSupport")}</Typography>
            </div>
          </div>
        )}
      </div>

      {contextHolder}
    </Modal>
  );
}

export default ModalCallCenter;
