import { Button, Checkbox, Flex, Modal } from "antd";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { APP_COLORS } from "utils/colors";

interface LogoutModalProps {
  isToggled: boolean;
  toggle: () => void;
  onLogout: () => void;
}
const ModalConfirm: FC<LogoutModalProps> = ({
  isToggled,
  toggle,
  onLogout,
}) => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);

  return (
    <Modal
      open={isToggled}
      onCancel={toggle}
      centered
      footer={null}
      title={t("Profile.doYouWantToLogout")}
    >
      <Checkbox   onChange={(e) => setIsChecked(e.target.checked)}>
        {t("Profile.logoutConfirmText")}
      </Checkbox>
      <div
        style={{
          right: "20px",
        }}
      >
        <Flex justify='space-between' align='middle'>
          <div></div>
          <div>
            <Button
              type='primary'
              size='small'
              onClick={toggle}
              style={{ marginRight: "10px",
                color: APP_COLORS.light.primary,
               }}
            >
              {t("Profile.cancel")}
            </Button>
            <Button
              type='primary'
              size='small'
              onClick={onLogout}
              disabled={!isChecked}
              style={{
                backgroundColor: APP_COLORS.light.primary,
                color: APP_COLORS.light.primary_text_color,
              }}
            >
              {t("Profile.logout")}
            </Button>
          </div>
        </Flex>
      </div>
    </Modal>
  );
};

export default ModalConfirm;
