import { Model } from '@nozbe/watermelondb'
import { field } from '@nozbe/watermelondb/decorators'


export default class Damage extends Model {
  static table = 'damageBag'
  @field('bag_id') bag_id!: string
  @field('files') photos!: string
  @field('name') name!: string
  @field('tag_id') tag_id!: string
  @field('contact') contact!:string
  @field('comment') comment!:string
}
