import { JsonForm, JsonFormJson, JsonFormJsonSchema } from "../model/JsonForm";
import {
  FormProcessorResponse,
  ResultStatus,
} from "../model/FormProcessorResponse";
import { FormProcessorParseProps } from "../model/FormProcessorParseProps";
import Recognizer from "./Recognizer";

export default class DefaultCustomRecognizer extends Recognizer {
  static getGUID(): string {
    return "Custom";
  }

  process(
    props: FormProcessorParseProps,
    jsonForm: JsonForm
  ): FormProcessorResponse {
    const values = props.formData || {};
    const jsonSchema = (JSON.parse(jsonForm.json) as JsonFormJson)
      .schema as JsonFormJsonSchema;

    return {
      status: ResultStatus.OK,
      data: values,
      documentType: null,
      recognizer: null,
      fields: {
        empty: this.getEmptyRequiredFields(jsonSchema, values, props.images),
        invalid: [],
        parsed: [],
      },
      doc_data: {
        date_of_expiry: null,
        passenger: null,
        meta: null,
      },
    };
  }
}
