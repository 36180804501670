import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { QRCode, Button } from "antd";
import { isIOS, isMacOs, isTablet } from "react-device-detect";
import { DownloadOutlined } from "@ant-design/icons";
import walletIcon from "../../../../assets/images/wallet.svg";
import config from "config";
import { APP_COLORS } from "utils/colors";
import "./TicketFooter.css";

export interface TicketFooterProps {
  qr: string | null;
  ticketLink: string | null;
  pkpassLink: string | null;
}

function TicketFooter({ qr, ticketLink, pkpassLink }: TicketFooterProps) {
  const { t } = useTranslation();

  const openURL = useCallback(() => {
    window.open(
      `${config.HTTP_SERVER}/api/v1/document/${ticketLink}`,
      "_blank"
    );
  }, [ticketLink]);

  const openWallet = useCallback(() => {
    pkpassLink && window.open(pkpassLink);
  }, [pkpassLink]);

  if (!ticketLink) {
    return null;
  }

  return (
    <div className="TicketFooter">
      <div className="TicketFooter-Qr">
        {qr && <QRCode key={qr} type="svg" value={qr} size={152} />}
      </div>
      <div className="TicketFooter-Buttons">
        {pkpassLink && ((isIOS && !isTablet) || isMacOs) ? (
          <Button className="TicketFooter-Wallet" onClick={openWallet}>
            <div className="TicketFooter-Wallet-container">
              <img src={walletIcon} alt="" />
              <div className="TicketFooter-Wallet-text">
                <span>{t("Ticket_and_Info.addTo")}</span>
                <span>{t("Ticket_and_Info.appleWallet")}</span>
              </div>
            </div>
          </Button>
        ) : null}
        {ticketLink ? (
          <Button
            icon={
              <DownloadOutlined style={{ color: APP_COLORS.light.primary }} />
            }
            style={{
              borderColor: APP_COLORS.light.primary,
              color: APP_COLORS.light.primary,
            }}
            type="default"
            size="small"
            onClick={openURL}
          >
            {t("Ticket_and_Info.downloadpdf")}
          </Button>
        ) : null}
      </div>
    </div>
  );
}

export default TicketFooter;
