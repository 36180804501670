import { Model } from "@nozbe/watermelondb";
import { field, json, relation } from "@nozbe/watermelondb/decorators";
import { Associations } from "@nozbe/watermelondb/Model";
import Session from "./Sessions";

export default class Document extends Model {
  static table = "documents";

  static associations: Associations = {
    files: { type: "has_many", foreignKey: "document_id" },
  };

  @field("category") category!: number;
  @field("form_id") formId?: number;
  @field("form_version") formVersion?: number;
  @field("type") type!: string;
  @field("provider") provider!: string;
  @field("recognizer") recognizer?: string;
  @json("data", (s) => s) data!: Record<string, any>;
  @field("auto_verification_result") auto_verification_result?: string;
  @json("files", (s) => s) files?: {
    link: string;
    hash: string;
    position: number;
  }[];
  @field("verified_manual") verified_manual?: boolean;
  @field("verified_auto") verified_auto?: boolean;
  @field("created_at") created_at?: number;
  @relation("sessions", "session_id") session!: Session;
}
