import { useMemo, useCallback, useEffect } from "react";
import {
  getFieldPath,
  getFormFields,
  replaceFieldTypes,
} from "../utils/jsonform";
import config from "config";
import get from "lodash/get";
import { ReduxDocument } from "redux/features/documents";
import Form from "wmelon/models/Form";
import { ValidationResult } from "types/validator";
import { SchemeDocument, SchemeDocumentsGroup } from "types/scheme";
import { TextFieldType } from "@regulaforensics/document-reader-webclient";

function getHasField(
  schema: any,
  fieldId: string,
  recognizer: string
): boolean {
  if (schema.type === "object") {
    return Object.keys(schema.properties).some((field: string) => {
      return getHasField(schema.properties[field], fieldId, recognizer);
    });
  }

  return (
    schema.recognizerFieldId === fieldId &&
    (schema.recognizer || "").toLowerCase() === recognizer
  );
}

function sanitizeJSON(data) {
  if (typeof data === "string") {
    return data.replace(/[<>&'"]/g, (c: string) => {
      switch (c) {
        case "<":
          return "&lt;";
        case ">":
          return "&gt;";
        case "&":
          return "&amp;";
        case "'":
          return "&apos;";
        case '"':
          return "&quot;";
        default:
          return c;
      }
    });
  } else if (typeof data === "object") {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        data[key] = sanitizeJSON(data[key]);
      }
    }
  }
  return data;
}

function sanitizeFormData(raw: string) {
  try {
    const parsedData = JSON.parse(raw);

    if (
      typeof parsedData.schema !== "object" ||
      typeof parsedData.uischema !== "object"
    ) {
      console.log("[sanitizeFormData] invalid json:");
      return { schema: null, uischema: null };
    }

    return {
      schema: sanitizeJSON(parsedData.schema),
      uischema: sanitizeJSON(parsedData.uischema),
    };
  } catch (err) {
    console.error("[sanitizeFormData] failed to parse:", err);
    return { schema: null, uischema: null };
  }
}

export interface UseFormValuesResponse {
  formId: number | null;
  form: any | null;
  jsonschema: any | null;
  uischema: any | null;
  title: string | null;
  formRecognizer: string | null;
  hint: string | null;

  item: SchemeDocumentsGroup | SchemeDocument | null;
  skipId: string | null;

  fields: string[];
  isManualForm: boolean;
  getAutoFields: () => Record<string, any>;

  shouldRecognizeDocument: boolean;
}

const useFormValues = (
  scheme,
  documents: ReduxDocument[],
  forms: Form[],
  validationResult: ValidationResult | null,
  stepId: string | null
): UseFormValuesResponse => {
  const step = useMemo(() => {
    if (!scheme || !stepId) {
      return null;
    }

    return scheme.cerber_schema.find((it) => it.id === stepId) || null;
  }, [scheme, stepId]);

  const {
    item,
    skipId,
  }: {
    item: SchemeDocumentsGroup | SchemeDocument | null;
    skipId: string | null;
  } = useMemo(() => {
    if (!step || !scheme) {
      return {
        item: null,
        skipId: null,
      };
    }

    if (step.documentOrGroup.type === "group") {
      return {
        item: scheme.full_data_group_documents.find(
          (it) => it.id === step.documentOrGroup.value
        ) as SchemeDocumentsGroup | null,
        skipId: `[G]${step.documentOrGroup.value}`,
      };
    } else {
      const item: SchemeDocument = scheme.full_data_documents.find(
        (it) => it.id === step.documentOrGroup.value
      );
      return {
        item: item,
        skipId: item.type || null,
      };
    }
  }, [scheme, step]);

  const form = useMemo(() => {
    if (!item) {
      return null;
    }

    const isGroup = !!("doc_fields" in item) && item.doc_fields;

    return (forms || []).find((it) =>
      isGroup
        ? it.groupId === item.id
        : (it.documentId || "").toString() === item.id.toString()
    );
  }, [forms, item]);

  const { jsonschema, uischema, title, formRecognizer, formId } =
    useMemo(() => {
      if (!item || !form) {
        return {
          jsonschema: null,
          uischema: null,
          title: null,
          formRecognizer: null,
          formId: null,
        };
      }

      const { schema, uischema } = sanitizeFormData(form.json);
      const isGroup = !!("doc_fields" in item) && item.doc_fields;
      return {
        jsonschema: replaceFieldTypes(schema, [
          {
            field: config.JSONFORMS.MRZ_FIELD,
            recognizer: config.RECOGNIZERS.CUSTOM,
            type: "string",
          },
          {
            field: config.JSONFORMS.RFID_FIELD,
            recognizer: config.RECOGNIZERS.CUSTOM,
            type: "string",
          },
          {
            field: config.JSONFORMS.REGULA_ID,
            recognizer: config.RECOGNIZERS.CUSTOM,
            type: "string",
          },
        ]),
        uischema: uischema,
        title: (isGroup ? item.label : item.name) || form.title,
        formRecognizer: form.recognizer.toLowerCase(),
        formId: parseInt(form.id, 10),
      };
    }, [form, item]);
  useEffect(() => {
    jsonschema && console.log("JSON_SCHEMA:", jsonschema);
  }, [jsonschema]);
  const hint = useMemo(() => {
    if (!step?.hint) {
      return null;
    }

    if (!scheme || !validationResult) {
      return step.hint;
    }

    const result = step.hint.replace(/%.*%/, (match) => {
      if (!match) {
        return match;
      }

      const row = match.slice(1, -1);
      let [id, , fieldId] = row.split(".");
      let isGroup = false;

      if (id.startsWith("[G]")) {
        id = id.replace("[G]", "");
        isGroup = true;
      }

      let item = isGroup
        ? scheme?.full_data_group_documents.find((it) => it.id === id)
        : scheme?.full_data_documents.find((it) => it.id.toString() === id);

      if (!item) {
        return "-";
      }

      const doc = (validationResult.all_valid_documents || []).find((it) => {
        return isGroup
          ? item?.doc_types.indexOf(it.type) > -1
          : item?.type === it.type;
      });

      if (doc) {
        const fullDoc = (documents || []).find((it) => it.id === doc.id);

        if (fullDoc) {
          const form = forms[id];

          if (!form) {
            return "-";
          }

          try {
            const { schema: jsonschema } = JSON.parse(form.json);
            const path = getFieldPath(jsonschema, form.recognizer, fieldId, "");
            return path ? get(fullDoc.data, path) : "-";
          } catch (err) {
            console.error("[useFormValues] failed to parse jsonform:", err);
          }
        }
      }

      return "-";
    });

    return result;
  }, [step, scheme, documents, validationResult, forms]);

  const fields: string[] = useMemo(() => {
    if (!jsonschema) {
      return [];
    }

    return getFormFields(jsonschema, [], "");
  }, [jsonschema]);

  const isManualForm = useMemo(() => {
    if (!fields.length) {
      return false;
    }

    if (formRecognizer === config.RECOGNIZERS.CUSTOM) {
      return fields.indexOf(config.JSONFORMS.DOCUMENT_FIELD) === -1;
    } else {
      return false;
    }
  }, [fields, formRecognizer]);

  const getAutoFields = useCallback(() => {
    if (!jsonschema || !form) {
      return {};
    }

    const path = getFieldPath(
      jsonschema,
      form.recognizer,
      config.JSONFORMS.ACCEPT_ID,
      ""
    );

    return path[0]
      ? {
          [path[0]]: "1",
        }
      : {};
  }, [jsonschema, form]);

  const shouldRecognizeDocument = useMemo(() => {
    if (!jsonschema) {
      return true;
    }

    return !getHasField(
      jsonschema,
      TextFieldType.OTHER.toString(),
      config.RECOGNIZERS.REGULA
    );
  }, [jsonschema]);

  return useMemo(
    () => ({
      formId,
      form,
      jsonschema,
      uischema,
      title,
      formRecognizer,
      hint,

      item,
      skipId,

      fields,
      isManualForm,
      getAutoFields,

      shouldRecognizeDocument,
    }),
    [
      formId,
      form,
      jsonschema,
      uischema,
      title,
      formRecognizer,
      hint,

      item,
      skipId,

      fields,
      isManualForm,
      getAutoFields,

      shouldRecognizeDocument,
    ]
  );
};

export default useFormValues;
