import { useCallback } from "react";
import config from "config";
import AuthApiService from "utils/AuthApi";
import Storage from "core/Storage/Storage";
import { EVISAResponse } from "core/FormProcessor/model/EVISA";

export function useVTEVISA(): (files: Blob[]) => Promise<EVISAResponse | null> {
  const parse = useCallback((files: Blob[]) => {
    return AuthApiService.shared()
      .getUserToken()
      .then((token: string) => {
        return new Promise(
          (resolve: (value: EVISAResponse) => void, reject) => {
            const formData = new FormData();
            const request = new XMLHttpRequest();

            files.forEach((file: Blob) => {
              formData.append("files", file, `file.${file.type.split("/")[1]}`);
            });

            request.open("POST", config.VTEVISA_EP);
            request.withCredentials = false;

            token &&
              request.setRequestHeader(
                "Authorization",
                `Bearer ${Storage.shared().get(AuthApiService.TOKEN_KEY)}`
              );

            request.onload = () => {
              try {
                if (request.status === 200 || request.status === 201) {
                  console.log("[useVTEVISA] response:", request.response);
                  resolve(JSON.parse(request.response));
                } else if (request.status === 401) {
                  reject(new Error("wrong_token"));
                } else if (
                  request.status === 404 ||
                  request.status === 405 ||
                  request.status >= 500
                ) {
                  reject(new Error("service_is_temporarily_unavailable"));
                } else {
                  const data = JSON.parse(request.response);
                  reject(data);
                }
              } catch (err) {
                console.log("[useVTEVISA] onload parse failed:", err);
                reject(err);
              }
            };

            request.onerror = (e) => {
              console.log("[useVTEVISA] parse failed:", e);
              reject(new Error("service_is_temporarily_unavailable"));
            };
            request.send(formData);
          }
        );
      });
  }, []);

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return parse;
}
