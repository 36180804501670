import { useCallback, useEffect, useMemo } from "react";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "redux/store";
import { useLocation } from "react-router-dom";
import {
  setSyncing,
  setLastPulledAt,
  setResyncing,
  setUploadState,
  setSyncStage,
  clearUploadState,
} from "redux/features/app";
import { useSelector } from "react-redux";
import { syncBack } from "wmelon/sync";
import AuthApiService from "utils/AuthApi";
import { debounce } from "lodash";
import { ReduxState } from "redux/ReduxState";

const INTERVAL = 5 * 60 * 1000;

const SyncProvider = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const lastPull = useSelector((state: ReduxState) => state.app.lastPulledAt);

  const canSync = useMemo(() => {
    return (
      !location.pathname.includes("/validator") &&
      !location.pathname.includes("/final")
    );
  }, [location]);
  const setSyncingByDebounce = useCallback(
    debounce((value: boolean) => {
      console.log("[SyncProvider] syncing: set:", value);
      dispatch(setSyncing(value));
    }, 500),
    [dispatch]
  );

  const onHandleSyncEvent = useCallback(
    (event: any) => {
      setSyncingByDebounce.cancel();
      if (event?.detail?.data?.failed) {
        setSyncingByDebounce(event?.detail?.data?.active);
      } else {
        dispatch(setSyncing(event?.detail?.data?.active));
      }
      !event?.detail?.data?.active && dispatch(clearUploadState());
    },
    [dispatch, setSyncingByDebounce]
  );

  const onHandleResyncEvent = useCallback(
    (event: any) => {
      const { isResyncing, error } = event?.detail?.data;
      if (!canSync) {
        return;
      }

      if (isResyncing) {
        dispatch(setResyncing(isResyncing));
        setTimeout(() => {
          syncBack(AuthApiService.shared().getSessionId()).catch(() => {});
        }, 3000);
      } else {
        dispatch(setResyncing(false));
        if (error) {
          message.open({
            type: "error",
            content: t("FAILED_SYNC"),
          });
        }
      }
    },
    [dispatch, t, canSync]
  );

  const onHandlePulledAtEvent = useCallback(
    (event: any) => {
      dispatch(setLastPulledAt(event?.detail?.data?.lastPulledAt));
    },
    [dispatch]
  );

  const onHandleSyncStage = useCallback(
    (event: any) => {
      dispatch(setSyncStage(event?.detail?.data));
    },
    [dispatch]
  );

  const onHandleUploadState = useCallback(
    (event: any) => {
      dispatch(setUploadState(event?.detail?.data));
    },
    [dispatch]
  );

  useEffect(() => {
    if (document) {
      document.addEventListener("syncing", onHandleSyncEvent);

      return () => {
        document.removeEventListener("syncing", onHandleSyncEvent);
      };
    }
  }, [onHandleSyncEvent]);

  useEffect(() => {
    if (document) {
      document.addEventListener("resyncing", onHandleResyncEvent);

      return () => {
        document.removeEventListener("resyncing", onHandleResyncEvent);
      };
    }
  }, [onHandleResyncEvent]);

  useEffect(() => {
    if (document) {
      document.addEventListener("lastPulledAt", onHandlePulledAtEvent);

      return () => {
        document.removeEventListener("lastPulledAt", onHandlePulledAtEvent);
      };
    }
  }, [onHandlePulledAtEvent]);

  useEffect(() => {
    if (document) {
      document.addEventListener("syncStage", onHandleSyncStage);

      return () => {
        document.removeEventListener("syncStage", onHandleSyncStage);
      };
    }
  }, [onHandleSyncStage]);

  useEffect(() => {
    if (document) {
      document.addEventListener("uploadState", onHandleUploadState);

      return () => {
        document.removeEventListener("uploadState", onHandleUploadState);
      };
    }
  }, [onHandleUploadState]);

  useEffect(() => {
    if (!canSync) {
      return;
    }

    const interval = setInterval(() => {
      if (lastPull && lastPull + INTERVAL <= Date.now()) {
        console.log("[SyncProvider] repeated sync");
        syncBack(AuthApiService.shared().getSessionId()).catch((err) => {
          console.error("[SyncProvider] failed to sync:", err);
        });
      }
    }, INTERVAL);
    return () => clearInterval(interval);
  }, [lastPull, location, canSync]);

  return null;
};

export default SyncProvider;
