import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { useSelector } from "react-redux";
import { Q } from "@nozbe/watermelondb";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import Airline from "wmelon/models/Airline";
import Tag from "wmelon/models/Tag";
import PassengerInfo from "./components/PassengerInfo";
import Button from "components/Button";
import { getQueryValue } from "utils/db";
import { getIsVirtual, getPNRS } from "utils/tickets";
import {
  SESSION_STATUSES,
  getSessionStatus,
  getSessionStatusIcon,
} from "utils/session";
import { APP_COLORS, getTextColor } from "utils/colors";
import { ShareStatus } from "types/flights";
import { MAX_LENGTH_TAG_TICKET } from "constants/contants";
import successIcon from "../../assets/images/success.svg";
import shareIcon from "../../assets/images/share.svg";
import shareOrangeIcon from "../../assets/images/share_orange.svg";

import "./TicketItem.css";
import { withObservables } from "@nozbe/watermelondb/react";
import { database } from "wmelon/database";
import FlightDetails from "components/FlightDetails";
import { ReduxState } from "redux/ReduxState";
import { Observable } from "@nozbe/watermelondb/utils/rx";
import TicketNumber from "./components/TicketNumber";
import TicketFooter from "./components/TicketFooter";
import TicketFooterContainer from "./components/TicketFooterContainer";

interface InnerTicketItem {
  flight_origin: string;
  flight_destination: string;
  flight_date: string;
  flight_departure_time?: string | null;
  flight_arrival_time?: string | null;
  identifiers: string | string[];
  airline: string;
  flight_number: string;
  ticket_number: string;
}

export interface TicketItemProps {
  airlines: Airline[];

  ticket: InnerTicketItem;
  ticket_id: string;

  session_status: string | null;
  session_handler: string | null;
  short_id: string | null;
  session_id: string | null;
  doc_links: {
    qr: string | null;
    document: string | null;
    pkpass_document: string | null;
  } | null;
  isUnsynced: boolean;
  passenger: {
    firstName: string | null;
    lastName: string | null;
    dob: string | null;
    documentNumber: string | null;
  };

  onStartValidation: () => void;
  canValidate: boolean;

  tags: Tag[];
  onEditTag?: (ticketId: string) => void;

  viewer: boolean;
  loading: boolean;
  sharing: boolean;

  onShare: () => void;
  onUnshare: () => void;
  shareStatus: ShareStatus | null;

  onCall: () => void;
}

function TicketItem({
  airlines,

  ticket,
  ticket_id,

  session_status,
  session_handler,
  short_id,
  session_id,
  doc_links,
  isUnsynced,
  passenger,

  onStartValidation,
  canValidate,

  tags,
  onEditTag,

  viewer,
  loading,
  sharing,

  onShare,
  onUnshare,
  shareStatus,

  onCall,
}: TicketItemProps) {
  const { t } = useTranslation();
  const isSyncing = useSelector((state: ReduxState) => state.app.syncing);
  const [showAirImageImage, setShowAirImageImage] = useState(true);

  const tag = tags[0];
  const airline = airlines[0];

  const isShared = shareStatus !== null;

  const flightStatus = useMemo(() => {
    if (session_status && session_handler) {
      return getSessionStatus({
        status: session_status,
        handler: session_handler,
      });
    }

    return null;
  }, [session_status, session_handler]);

  const statusText = useMemo(() => {
    return session_status && t(session_status);
  }, [session_status, t]);

  const airImage = useMemo(() => {
    if (airline?.icon) {
      return `data:image/jpg;base64,${airline?.icon}`;
    }

    return `https://www.flightaware.com/images/airline_logos/90p/${
      ticket.airline ?? null
    }.png`;
  }, [ticket.airline, airline?.icon]);

  const statusImage = useMemo(() => {
    return flightStatus?.state
      ? getSessionStatusIcon(flightStatus.state)
      : null;
  }, [flightStatus?.state]);

  const tagRender = useCallback(() => {
    if (tag?.id) {
      return (
        <Button
          type="primary"
          ghost
          size="small"
          shape="round"
          onClick={onEditTag ? () => onEditTag(ticket_id) : undefined}
          style={{
            backgroundColor: tag.color,
            color: getTextColor(tag.color),
          }}
        >
          {tag.name.substring(0, MAX_LENGTH_TAG_TICKET)}
        </Button>
      );
    }

    if (!onEditTag) {
      return <div />;
    }

    return (
      <Button
        type="primary"
        ghost
        shape="round"
        size="small"
        style={{
          color: APP_COLORS.light.primary,
          borderColor: APP_COLORS.light.primary,
        }}
        onClick={() => onEditTag(ticket_id)}
      >
        {t("Tags.chooseTag")}
      </Button>
    );
  }, [tag, t, ticket_id, onEditTag]);

  const handleAirImageImageError = useCallback(() => {
    setShowAirImageImage(false);
  }, []);

  const ticketNumber = useMemo(() => {
    if (!ticket) {
      return null;
    }

    const isVirtual = getIsVirtual(ticket);
    return isVirtual ? t("virtual") : ticket.ticket_number;
  }, [ticket, t]);
  return (
    <>
      <div
        className={cn("TicketItem", {
          sync: isUnsynced,
        })}
      >
        {viewer ? null : (
          <div className="TicketItem-buttons">
            {tagRender()}
            {shareStatus === ShareStatus.shared_to ? (
              <div className="TicketItem-shared">
                <img src={shareOrangeIcon} alt="" />
                <span>{t("Ticket_and_Info.shared")}</span>
              </div>
            ) : (
              <Button
                className="TicketItem-share-btn"
                type="primary"
                size="small"
                ghost
                shape="round"
                style={{
                  color: APP_COLORS.light.primary,
                  borderColor: APP_COLORS.light.primary,
                }}
                icon={
                  sharing ? (
                    <Spin indicator={<LoadingOutlined />} spinning />
                  ) : (
                    <img src={shareIcon} alt="" />
                  )
                }
                onClick={isShared ? onUnshare : onShare}
              >
                {t(
                  isShared ? "Ticket_and_Info.unshare" : "Ticket_and_Info.share"
                )}
              </Button>
            )}
          </div>
        )}
        <div className="TicketItem-content">
          <div className="TicketItem-row">
            <div className="TicketItem-airline">
              {airline?.name && showAirImageImage ? (
                <img src={airImage} alt="" onError={handleAirImageImageError} />
              ) : null}
              <span className="TicketItem-text">{airline?.name}</span>
            </div>
            <div className="TicketItem-flight">
              <span className="TicketItem-text">{ticket.flight_number}</span>
              {
                (!short_id ||
                  ![
                    SESSION_STATUSES.WAIT_FOR_DOC_VALIDATION,
                    SESSION_STATUSES.WAIT_FOR_CHECK_VALIDATION,
                  ].includes(session_status || "")) &&
                !!statusImage
                  ? statusImage && <img src={statusImage} alt="" />
                  : null //TODO add spiner, if syncing
              }
            </div>
          </div>
          <div className="TicketItem-row">
            <span className="TicketItem-text">
              {t("Ticket_and_Info.BookingNumber")}
            </span>
            <span className="TicketItem-text">
              {getPNRS(ticket) || t("na")}
            </span>
          </div>
          <FlightDetails ticket={ticket} />
          <TicketNumber shortId={short_id} ticketNumber={ticketNumber} />
          <PassengerInfo passenger={passenger} />
          {session_status === SESSION_STATUSES.COMPLETED && (
            <>
              {doc_links ? (
                <TicketFooter
                  qr={doc_links.qr || null}
                  ticketLink={doc_links.document || null}
                  pkpassLink={doc_links.pkpass_document || null}
                />
              ) : (
                <TicketFooterContainer sessionId={session_id} />
              )}
              <div className="TicketItem-comleted">
                <img src={successIcon} alt="" />
                <span>{statusText}</span>
              </div>
            </>
          )}

          {session_status &&
            short_id &&
            [
              SESSION_STATUSES.WAIT_FOR_DOC_VALIDATION,
              SESSION_STATUSES.WAIT_FOR_CHECK_VALIDATION,
              SESSION_STATUSES.WAIT_FOR_TICKET,
            ].includes(session_status) && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    // marginBottom: "2%",
                  }}
                >
                  <Button
                    ghost
                    onClick={onCall}
                    style={{
                      color: APP_COLORS.light.primary_text_color,
                      backgroundColor: APP_COLORS.light.primary,
                    }}
                  >
                    {t("FinalFailed.callCenter")}
                  </Button>
                </div>
              </>
            )}

          {session_status &&
            short_id &&
            [
              SESSION_STATUSES.AUTO_REJECTED,
              SESSION_STATUSES.MANUAL_REJECTED,
              SESSION_STATUSES.MANUAL_INACTIVE,
              SESSION_STATUSES.CANCELLED,
            ].indexOf(session_status) !== -1 && (
              <>
                <div className="TicketItem-buttons">
                  <Button
                    type="primary"
                    size="large"
                    style={{
                      marginBottom: 5,
                      height: 56,
                      color: APP_COLORS.light.primary_text_color,
                      backgroundColor: APP_COLORS.light.primary,
                    }}
                    disabled={!canValidate || loading || sharing || isSyncing}
                    loading={loading || sharing || isSyncing}
                    onClick={onStartValidation}
                  >
                    {t("Ticket_and_Info.startvalidation")}
                  </Button>
                  <Button
                    type="primary"
                    ghost
                    size="large"
                    style={{
                      height: 56,
                      color: APP_COLORS.light.primary,
                      borderColor: APP_COLORS.light.primary,
                    }}
                    onClick={onCall}
                  >
                    {t("FinalFailed.callCenter")}
                  </Button>
                </div>
              </>
            )}

          {(!session_status ||
            [
              SESSION_STATUSES.COMPLETED,
              SESSION_STATUSES.WAIT_FOR_CHECK_VALIDATION,
              SESSION_STATUSES.WAIT_FOR_DOC_VALIDATION,
              SESSION_STATUSES.AUTO_REJECTED,
              SESSION_STATUSES.MANUAL_REJECTED,
              SESSION_STATUSES.CANCELLED,
              SESSION_STATUSES.MANUAL_INACTIVE,
              SESSION_STATUSES.WAIT_FOR_TICKET,
            ].indexOf(session_status) === -1) && (
            <>
              <div className="TicketItem-buttons">
                {viewer ? null : (
                  <Button
                    type="primary"
                    size="large"
                    disabled={!canValidate || loading || sharing || isSyncing}
                    loading={loading || sharing || isSyncing}
                    style={{
                      height: 56,
                      color: APP_COLORS.light.primary_text_color,
                      backgroundColor: APP_COLORS.light.primary,
                    }}
                    onClick={onStartValidation}
                  >
                    {t("Ticket_and_Info.startvalidation")}
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
const enhance = withObservables<
  Omit<TicketItemProps, "airlines" | "tags">,
  { airlines: Observable<Airline[]>; tags: Observable<Tag[]> }
>(
  ["ticket"],
  ({ ticket, ticket_id }: { ticket: InnerTicketItem; ticket_id: string }) => ({
    airlines: database
      .get<Airline>(Airline.table)
      .query(Q.where("icao", getQueryValue(ticket.airline)))
      .observe(),

    tags: database
      .get<Tag>(Tag.table)
      .query(Q.on("tagBinds", "object_id", ticket_id), Q.take(1))
      .observeWithColumns(["name", "color"]),
  })
);

const EnhancedTicketItem = enhance(TicketItem);
export default EnhancedTicketItem;
