import { useEffect, useMemo, useRef } from "react";
import { WidgetProps } from "@rjsf/utils";
import { fieldNameToLabel } from "utils/string";
import { Select, Typography } from "antd";
import { withObservables } from "@nozbe/watermelondb/react";
import { database } from "wmelon/database";

const FIELD_TYPE = {
  country: "Country",
  vaccination: "Vaccination",
};

function Dictionary({
  id,
  name,
  value,
  required,
  disabled,
  readonly,
  onBlur,
  onChange,
  onFocus,
  label,
  placeholder,
  options,
  selectOptions,
}: WidgetProps) {
  const dropRef = useRef(null) as any;

  const opt = useMemo(() => {
    if (!selectOptions.length || !options.fieldType) return [];

    return selectOptions.map((option) => ({
      value: option._raw[options.valueField as string],
      label:
        option._raw[
          (options.labelField as string) || (options.valueField as string)
        ],
    }));
  }, [options, selectOptions]);

  useEffect(() => {
    if (!value || !opt) {
      return;
    }

    const selectedOpt = opt.findIndex((opt) => opt.value === value);
    dropRef?.current && dropRef.current?.selectIndex(selectedOpt);
  }, [value, opt]);

  return (
    <div>
      <Typography.Text>
        {fieldNameToLabel(label)} {required && "*"}
      </Typography.Text>
      <Select
        options={opt}
        disabled={(options?.readOnly as boolean) || disabled || readonly}
        onSelect={(selectedItem, _) => {
          onChange(selectedItem as any);
          console.log(selectedItem, "value");
        }}
        dropdownRender={(menu) => <div>{menu}</div>}
        style={{ width: "100%" }}
        placeholder={fieldNameToLabel(label)}
        value={value}
      />
    </div>
  );
}

const enhance = withObservables(
  ["type"],
  ({ type }: { type: string | null }) => ({
    selectOptions: database
      .get(
        type === FIELD_TYPE.country ? "schemaCountryGroups" : "schemaVaccines"
      )
      .query(),
  })
);

const EnhancedDictionary = enhance(Dictionary);
export default EnhancedDictionary;
