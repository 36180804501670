import { useMemo, useCallback, memo, useState } from "react";
import { Q } from "@nozbe/watermelondb";
import { withObservables } from "@nozbe/watermelondb/react";
import uniq from "lodash/uniq";
import { useSelector } from "react-redux";
import Session from "wmelon/models/Sessions";
import { database } from "wmelon/database";
import FlightTicket from "wmelon/models/FlightTicket";
import FlightTicketBinds from "wmelon/models/FlightTicketBinds";
import FlightTicketShare from "wmelon/models/FlightTicketShare";
import { useUnvalidatedTickets } from "hooks/useUnvalidatedTickets";
import { ReduxState } from "redux/ReduxState";
import PNRItem from "./PNRItem";

interface DBPNRItemProps {
  sessions: Session[];
  tickets: FlightTicket[];
  flightTicketBinds: FlightTicketBinds[];
  shares: FlightTicketShare[];
  compact?: boolean;
  isPast: boolean;
  onSelect: (tickets: FlightTicket[], validate: boolean) => Promise<any>;
}

const DBPNRItem = ({
  tickets,
  flightTicketBinds,
  shares,
  sessions,
  compact,
  isPast,
  onSelect,
}: DBPNRItemProps) => {
  const isSyncing = useSelector((state: ReduxState) => state.app.syncing);

  const [loading, setLoading] = useState<boolean>(false);

  const { unvalidated, statuses } = useUnvalidatedTickets(
    tickets,
    flightTicketBinds,
    shares,
    sessions
  );

  const status = useMemo(() => {
    let statusesList: string[] = [];

    for (let key in statuses) {
      const group = statuses[key];
      group.status && statusesList.push(group.status);
    }

    if (statusesList.length < tickets.length) {
      return "wait";
    }

    statusesList = uniq(statusesList);
    if (statusesList.every((state) => state === "success")) {
      return "success";
    } else if (statusesList.every((state) => state === "rejected")) {
      return "rejected";
    } else {
      return "wait";
    }
  }, [statuses, tickets.length]);

  const onHandlePress = useCallback(() => {
    setLoading(true);
    onSelect(tickets, false).finally(() => setLoading(false));
  }, [tickets, onSelect]);

  const onStartValidation = useCallback(() => {
    if (unvalidated.length) {
      setLoading(true);
      onSelect(unvalidated, true).finally(() => setLoading(false));
    }
  }, [onSelect, unvalidated]);

  return (
    <PNRItem
      tickets={tickets}
      compact={compact}
      canValidate={!isPast && unvalidated.length > 0}
      loading={loading}
      syncing={isSyncing}
      onSelect={onHandlePress}
      onStartValidation={onStartValidation}
      status={status}
    />
  );
};

const MemoizedDBPNRItem = memo(
  DBPNRItem,
  (oldProps: DBPNRItemProps, newProps: DBPNRItemProps) => {
    return (
      oldProps.onSelect === newProps.onSelect &&
      oldProps.isPast === newProps.isPast &&
      oldProps.compact === newProps.compact &&
      oldProps.shares === newProps.shares &&
      oldProps.flightTicketBinds === newProps.flightTicketBinds &&
      oldProps.tickets === newProps.tickets &&
      oldProps.sessions === newProps.sessions
    );
  }
);

const enhance = withObservables(
  ["tickets"],
  ({ tickets }: { tickets: FlightTicket[] }) => {
    const ticketIds = tickets.map((it) => it.id);

    return {
      sessions: database
        .get<Session>(Session.table)
        .query(
          Q.where("flight_ticket_id", Q.oneOf(ticketIds)),
          Q.sortBy("short_id", Q.desc)
        )
        .observeWithColumns(["status", "handler"]),
      flightTicketBinds: database
        .get<FlightTicketBinds>(FlightTicketBinds.table)
        .query(Q.where("ticket_id", Q.oneOf(ticketIds)))
        .observe(),
      shares: database
        .get<FlightTicketShare>(FlightTicketShare.table)
        .query(Q.where("flight_ticket_id", Q.oneOf(ticketIds)))
        .observe(),
    };
  }
);

const EnhancedDBPNRItem = enhance(MemoizedDBPNRItem);
export default EnhancedDBPNRItem;
