import { Layout } from "antd";
import { Routes, Route } from "react-router-dom";
import Loader from "components/Loader";
export interface ContentWrapperProps {
  children?: any;
}

export default function ContentWrapper({ children }: ContentWrapperProps) {
  return (
    <Layout.Content>
      <Routes>
        <Route path='*' element={<Loader loading={false} />} />
        <Route path='/final/*' />
        <Route path='/validator' />
      </Routes>
      {children}
    </Layout.Content>
  );
}
