export const COLORS = {
    text: '#000',
    textGray: '#575757',
    lightGray: '#cccccc',
    bgGray: '#EEEEEE',
    bgLightGray: '#f6f5f5',
    textBlue: '#005889',
    white: '#fff',
    green: '#47ad4d',
    textGreen: '#389c30',
    orange: '#fa9c14',
    blueHR: 'rgba(166,217,226,0.5)',
    darkBlue: 'rgba(139,175,185,0.5)',
    blue: '#d2ecf0',
    red: 'tomato',
    link: '#3680FC',
    warning: '#ffe184',
    blueBtn: '#3651FE',
    black48: 'rgba(0,0,0,0.48)',
    background: '#3651fe',
    inputBackround: 'rgba(255, 255, 255, 0.16)',
    phoneInput: '#2D44D5',
    black: '#000'
}
export const APP_COLORS = {
  light: {
    background: "#EEE",
    preloader_bg: "#eeec",
    primary: "#444",
    primary_text_color: "#FFF",
    default_btn: "#FFF",
    default_btn_text_color: "#444",
    disabled: "#444c",
    disabled_text_color: "#fffc",
    outline_btn: "#444",
    outline_btn_color: "#444",
    outline_primary_btn: "#444",
    outline_primary_btn_color: "#444",
    active_tab_bg: "#fff",
    active_tab_color: "#000",
    inactive_tab_bg: "#444",
    inactive_tab_color: "#FFF",
    tab_bg: "#444",
    section_header_color: "#444",
    default_text_color: "#000",
    card_bg: "#444",
    phone_input_bg: "#444",
    phone_input_color: "#FFF",
    checkbox_color: "#444",
    checkbox_bg: "#EEE",
    ticket_card_bg: "#FFF",
    option_bg: "#0000000A",
    selected_option_bg: "#44444440",
    buttonGradient: ["#5DB7EE", "#1E237B", "rgba(0, 0, 0, 1)"],
    black: "#000",
    error: "#f00",
    tagsDefaultColor: "#808080",
  },
  blue: {
    background: "#3651fe",
    preloader_bg: "rgba(45,68,213,0.8)",
    primary: "#3651fe",
    primary_text_color: "#3651fe",
    default_btn: "#FFF",
    default_btn_text_color: "#3651fe",
    disabled: "rgba(255, 255, 255, 0.2)",
    disabled_text_color: "rgba(255, 255, 255, 0.2)",
    outline_btn: "#FFF",
    outline_btn_color: "#FFF",
    outline_primary_btn: "rgba(54, 81, 254, 1)",
    outline_primary_btn_color: "rgba(54, 81, 254, 1)",
    active_tab_bg: "#fff",
    active_tab_color: "#000",
    inactive_tab_bg: "#2D44D5",
    inactive_tab_color: "#FFF",
    tab_bg: "#2D44D5",
    section_header_color: "#FFF",
    default_text_color: "#FFF",
    card_bg: "#FFF",
    phone_input_bg: "#2D44D5",
    phone_input_color: "#FFF",
    checkbox_color: "#FFF",
    checkbox_bg: "#3651fe",
    ticket_card_bg: "#EEEEEE",
    option_bg: "#0000000A",
    selected_option_bg: "#3651FE40",
  },
};
function getRGB(c:any) {
    return parseInt(c, 16) || c
}

function getsRGB(c:any) {
    return getRGB(c) / 255 <= 0.03928
        ? getRGB(c) / 255 / 12.92
        : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4)
}

function getLuminance(hexColor:any) {
    return (
        0.2126 * getsRGB(hexColor.substr(1, 2)) +
        0.7152 * getsRGB(hexColor.substr(3, 2)) +
        0.0722 * getsRGB(hexColor.substr(-2))
    )
}

function getContrast(f: any, b: any) {
    const L1 = getLuminance(f)
    const L2 = getLuminance(b)
    return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05)
}

export function getTextColor(bgColor: string) {
    const whiteContrast = getContrast(bgColor, APP_COLORS.light.primary_text_color)
    const blackContrast = getContrast(
      bgColor,
      APP_COLORS.light.black
    );

    return whiteContrast > blackContrast
      ? APP_COLORS.light.primary_text_color
      : APP_COLORS.light.black;
}
