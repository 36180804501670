import { Col, Row, Select, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Flight } from "types/flights";

const { Option } = Select;

export interface FlightSelectProps {
  inputPlaceholder: string;
  flights: Flight[];
  disabled?: boolean;
  value: Flight | null;
  onSelect: (value: any) => void;
}

function getKey(flight: Flight) {
  return `${flight.id}_${flight.origin.iata}_${flight.destination.iata}`;
}

const FlightSelect = ({
  inputPlaceholder,
  flights,
  disabled,
  value,
  onSelect,
}: FlightSelectProps) => {
  const { t } = useTranslation();

  const renderOption = (flight: Flight) => (
    <Option key={getKey(flight)} value={flight.id}>
      <Row justify="space-between" align="middle" style={{ width: "100%" }}>
        <Col>
          <Typography.Text>{flight.origin.name}</Typography.Text>
        </Col>
        <Col>
          <Typography.Text>{flight.id}</Typography.Text>
        </Col>
        <Col>
          <Typography.Text>{flight.destination.name}</Typography.Text>
        </Col>
      </Row>
    </Option>
  );

  return (
    <Select
      placeholder={inputPlaceholder}
      disabled={disabled}
      value={value?.id}
      onChange={(value) => {
        const selectedFlight = flights.find((flight) => flight.id === value);
        onSelect(selectedFlight);
      }}
      style={{ width: "100%", textAlign: "center" }}
      dropdownStyle={{ maxHeight: 500, overflow: "auto" }}
      notFoundContent={t("nodata")}
    >
      {flights.map(renderOption)}
    </Select>
  );
};

export default FlightSelect;
