import { Q } from "@nozbe/watermelondb";
import { database } from "wmelon/database";
import Airport from "wmelon/models/Airport";

function getAirportsByCodes(codes: string[]): Promise<Airport[]> {
  return database
    .get<Airport>(Airport.table)
    .query(Q.where("iata", Q.oneOf(codes.map((code) => code.toUpperCase()))))
    .fetch()
    .catch((err) => {
      console.error("[getAirportsByCodes] failed to get:", err);
      return [];
    }) as any;
}

export { getAirportsByCodes };
