import {Model} from '@nozbe/watermelondb'
import {field} from '@nozbe/watermelondb/decorators'

export default class Country extends Model {
  static table = 'countries'

  @field('name') name!: string
  @field('alpha2') alpha2!: string
  @field('alpha3') alpha3!: string
}
