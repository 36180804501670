import { useMemo } from "react";
import { withObservables } from "@nozbe/watermelondb/react";
import { database } from "wmelon/database";
import Document from "../../../wmelon/models/Document";
import { Q } from "@nozbe/watermelondb";
import { getQueryValue } from "utils/db";
import TicketFooter from "./TicketFooter";
import { Observable } from "@nozbe/watermelondb/utils/rx";
import { getArray } from "utils/utils";

export interface TicketFooterContainerProps {
  sessionId: string | null;
  fasttrackTickets: Document[];
  fasttrackTicketPks: Document[];
}

const TicketFooterContainer = ({
  fasttrackTickets,
  fasttrackTicketPks,
}: TicketFooterContainerProps) => {
  const fasttrackTicket = fasttrackTickets[0];
  const fasttrackTicketPk = fasttrackTicketPks[0];

  const ticketLink = useMemo(() => {
    if (!fasttrackTicket) {
      return null;
    }

    return getArray<{ link: string }>(fasttrackTicket?.files)[0]?.link || null;
  }, [fasttrackTicket]);

  const pkpassLink = useMemo(() => {
    if (!fasttrackTicketPk) {
      return null;
    }

    return (
      getArray<{ link: string }>(fasttrackTicketPk?.files)[0]?.link || null
    );
  }, [fasttrackTicketPk]);

  return (
    <TicketFooter
      qr={fasttrackTicket?.data?.qr || null}
      ticketLink={ticketLink}
      pkpassLink={pkpassLink}
    />
  );
};

const enhance = withObservables<
  Omit<TicketFooterContainerProps, "fasttrackTickets" | "fasttrackTicketPks">,
  {
    fasttrackTickets: Observable<Document[]>;
    fasttrackTicketPks: Observable<Document[]>;
  }
>(["sessionId"], ({ sessionId }: { sessionId: string | null }) => ({
  fasttrackTickets: database
    .get<Document>(Document.table)
    .query(
      Q.where("session_id", getQueryValue(sessionId)),
      Q.where("type", Q.eq("fasttrack_ticket")),
      Q.take(1)
    )
    .observe(),
  fasttrackTicketPks: database
    .get<Document>(Document.table)
    .query(
      Q.where("session_id", getQueryValue(sessionId)),
      Q.where("type", Q.eq("fasttrack_ticket.pkpass")),
      Q.take(1)
    )
    .observe(),
}));

const EnhancedTicketFooterContainer = enhance(TicketFooterContainer);
export default EnhancedTicketFooterContainer;
