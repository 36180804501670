import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import "./Loader.css";
import { ReduxState } from "redux/ReduxState";

export interface LoaderProps {
  loading: boolean;
}

export default function Loader({ loading }) {
  const { t } = useTranslation();
  const isSyncing = useSelector((state: ReduxState) => state.app.syncing);
  const isResyncing = useSelector((state: ReduxState) => state.app.resyncing);

  if (!isSyncing && !loading && !isResyncing) {
    return null;
  }

  return (
    <div className="Loader">
      {isResyncing && <span>{t("FAILED_SYNC_RETRY")}</span>}
      <Spin
        indicator={
          <LoadingOutlined style={{ fontSize: 48, color: "white" }} spin />
        }
      />
    </div>
  );
}
