import { useCallback, useMemo } from "react";
import { formatBytes } from "../utils/file";
import { useSelector, useDispatch } from "react-redux";
import { clearUploadState } from "redux/features/app";
import { ReduxState } from "redux/ReduxState";

const PERCENTS = {
  pull_1: 3,
  push_1: 6,
  pull_2: 9,
  push_2: 12,
  upload: 15,
};

export interface UseSyncProgressResponse {
  percent: {
    process: boolean;
    count: number;
    percent: number;
    uploaded: string;
    total: string;
  };
  clear: () => void;
}

export const useSyncProgress = (): UseSyncProgressResponse => {
  const dispatch = useDispatch<any>();

  const { stage, uploads } = useSelector(
    (state: ReduxState) => state.app.syncProgress
  );

  const percent = useMemo(() => {
    let bytesTotal = 0;
    let bytesUploaded = 0;

    Object.values(uploads).forEach((item: any) => {
      bytesTotal += item.bytesTotal;
      bytesUploaded += item.bytesUploaded;
    });

    let syncPercent = (stage && PERCENTS[stage]) || 0;

    if (!bytesTotal) {
      return {
        process: !!stage,
        count: 0,
        percent: syncPercent,
        total: formatBytes(0),
        uploaded: formatBytes(0),
      };
    }

    return {
      process: !!stage,
      count: Object.keys(uploads).length,
      percent: syncPercent + (bytesUploaded / bytesTotal) * 85,
      total: formatBytes(bytesTotal),
      uploaded: formatBytes(bytesUploaded),
    };
  }, [stage, uploads]);

  const clear = useCallback(() => {
    dispatch(clearUploadState());
  }, [dispatch]);

  return useMemo(
    () => ({
      clear,
      percent,
    }),
    [clear, percent]
  );
};
