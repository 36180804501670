import { Typography } from "antd";
import React from "react";
import Button from "components/Button";
import { useTranslation } from "react-i18next";

export interface DocumentTypeSelectorProps {
  options: { value: string; label: string }[];
  onSelect: (value: string) => void;
}

function DocumentTypeSelector({
  options,
  onSelect,
}: DocumentTypeSelectorProps) {
  const { t } = useTranslation();
  return (
    <div>
      <Typography.Text>
        {t("Validator.pleaseSelectDocumentType")}
      </Typography.Text>
      <br />
      <div style={{ display: "flex", flexDirection: "column" }}>
        {options.map((item) => {
          return (
            <Button
              key={`${item.value}_${item.label}`}
              style={{
                backgroundColor: "#0000000A",
                padding: "5px 10px",
                borderRadius: 16,
                marginRight: 5,
                marginTop: 10,
              }}
              type="text"
              onClick={() => onSelect(item.value)}
              className="SelectType-btn"
            >
              <Typography.Text style={{ cursor: "pointer" }}>
                {item.label}
              </Typography.Text>
            </Button>
          );
        })}
      </div>
    </div>
  );
}

export default DocumentTypeSelector;
