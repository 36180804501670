import config from "../../config";
import * as tus from "tus-js-client";
import File from "wmelon/models/File";
import Document from "wmelon/models/Document";
import { dataURItoBlob, getMimeType } from "utils/file";

async function uploadFiles(
  document: Document,
  files: File[],
  metaFiles: any = {},
  onProgress?: (
    documentId: string,
    page: number,
    bytesUploaded: number,
    bytesTotal: number
  ) => void
) {
  const filesResult: Record<string, any> = {};
  console.log("[uploader]", files, metaFiles);
  if (!files.length) {
    return filesResult;
  }

  const getFilename = (link = "") => {
    if (!link) {
      return "";
    }
    const arr = link.split("/");
    return arr[arr.length - 1];
  };

  try {
    await Promise.all(
      files.map(async (file: File | any, idx: number) => {
        console.log("[api/upload] upload file :", file);
        const fileBlob = dataURItoBlob(file.data);
        const docFile = document.files?.find(
          (it) => it.position === file.position
        );

        return new Promise((res, rej) => {
          const upload = new tus.Upload(fileBlob, {
            endpoint: `${config.UPLOAD_HOST}`,
            chunkSize: 512 * 512,
            retryDelays: [0, 3000, 5000, 10000, 20000],
            metadata: {
              ...metaFiles,
              filename: getFilename(
                document.files?.find((it) => it.position === file.position)
                  ?.link
              ),
              fileHash: docFile?.hash || fileBlob.size.toString(), //file.hash,
              filetype: getMimeType(file.data) || "image/png",
              filePosition: file?.position.toString(),
            },
            onError: (error) => {
              console.log("[api/upload] failed to upload:", error);
              rej(
                new Error(
                  error.message.indexOf("document_already_added") > -1
                    ? "document_already_added"
                    : "failed_to_upload_document"
                )
              );
            },
            onProgress: (bytesUploaded, bytesTotal) => {
              const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(
                2
              );
              console.log(bytesUploaded, bytesTotal, percentage + "%");
              // onProgress(
              //   docFile?.link || "",
              //   (bytesUploaded / bytesTotal) * 100
              // );
              onProgress &&
                onProgress(
                  metaFiles.documentId,
                  idx,
                  bytesUploaded,
                  bytesTotal
                );
            },
            onSuccess: () => {
              console.log("Success upload file %s to %s", file, upload.url);
              if (upload.url) {
                filesResult[file?.hash] = { ...file, url: upload.url };
              } else {
                filesResult[file?.hash] = file;
              }

              res({ file, url: upload.url });
            },
            removeFingerprintOnSuccess: true,
          });

          upload.findPreviousUploads().then(function (previousUploads) {
            if (previousUploads.length) {
              upload.resumeFromPreviousUpload(previousUploads[0]);
              console.log(previousUploads + " successfully resumed");
            }
            upload.start();
          });
        });
      })
    );

    return filesResult;
  } catch (e) {
    console.error("[Uploader]", e);
    throw new Error("failed_to_upload_document");
  }
}

export default uploadFiles;
