import { jwtDecode } from "jwt-decode";
import { v4 as uuid } from "uuid";
import { AxiosResponse } from "axios";
import config from "config";
import Storage from "core/Storage/Storage";
import restApi from "./RESTApi";

export default class AuthApiService {
  static shared = () => new AuthApiService();
  static TOKEN_KEY = "token";
  static REFRESH_TOKEN_KEY = "refresh";
  static SESSION_ID_PREFIX = "session_id_";
  async startLogin(phone: string) {
    return restApi?.instance
      .post(config.LOGIN_START_EP, {
        phone,
      })
      .then(restApi.parseResponse)
      .catch((error: any) => {
        console.error("[AuthApiService].startLogin failed:", error);
        if (error.response) {
          const { data, statusText }: AxiosResponse = error.response;
          throw new Error(data?.message || statusText);
        } else {
          throw new Error(error?.message);
        }
      });
  }
  async finishLogin(phone: string, credential: any) {
    return restApi.instance
      ?.post(config.LOGIN_FINISH_EP, {
        phone,
        credential,
      })
      .then(restApi.parseResponse)
      .catch((error: any) => {
        console.error("[AuthApiService].finishLogin failed:", error);
        if (error.response) {
          const { data, statusText }: AxiosResponse = error.response;
          throw new Error(data?.message || statusText);
        } else {
          throw new Error(error?.message);
        }
      });
  }

  async startRegistration(phone: string, code: string) {
    restApi?.instance
      .post(config.START_REGISTRATION_EP, {
        phone,
        code,
      })
      .then(restApi.parseResponse)
      .catch((error: any) => {
        console.error("[AuthApiService].startRegistration failed:", error);
        if (error.response) {
          const { data, statusText }: AxiosResponse = error.response;
          throw new Error(data?.message || statusText);
        } else {
          throw new Error(error?.message);
        }
      });
  }
  async finishRegistration(phone: string, credential: any) {
    console.log("finishRegistration", credential, phone);

    return restApi.instance
      .post(config.FINISH_REGISTRATION_EP, {
        phone,
        credential,
      })
      .then(restApi.parseResponse)
      .catch((error: any) => {
        console.error("[AuthApiService].finishRegistration failed:", error);
        if (error.response) {
          const { data, statusText }: AxiosResponse = error.response;
          throw new Error(data?.message || statusText);
        } else {
          throw new Error(error?.message);
        }
      });
  }

  sendCode(phone: string) {
    return restApi.instance
      ?.post(config.SEND_CODE_EP, { phone })
      .then(restApi.parseResponse)
      .catch((error: any) => {
        console.error("[AuthApiService].sendCode failed:", error);
        if (error.response) {
          const { data, statusText }: AxiosResponse = error.response;
          throw new Error(data?.message || statusText);
        } else {
          throw new Error(error?.message);
        }
      });
  }

  getJWTByCode(phone: string, code: string) {
    return restApi.instance
      ?.post(config.AUTH_TOKEN_EP, { phone, code })
      .then(restApi.parseResponse)
      .catch((error: any) => {
        console.error("[AuthApiService].getJWTByCode failed:", error);
        if (error.response) {
          const { data, statusText }: AxiosResponse = error.response;
          throw new Error(data?.message || statusText);
        } else {
          throw new Error(error?.message);
        }
      });
  }

  getUserId() {
    try {
      const token = Storage.shared().get(AuthApiService.TOKEN_KEY);
      if (typeof token !== "string") {
        throw new Error("Invalid token specified: must be a string");
      }
      const decoded = jwtDecode(token);
      return decoded.sub;
    } catch (error) {
      console.log("[AuthApiService].getUserId", error);
      throw error;
    }
  }

  saveTokens(data: any) {
    const accessToken = data.accessToken;
    const refreshToken = data.refreshToken;

    if (accessToken) {
      Storage.shared().set(AuthApiService.TOKEN_KEY, accessToken);
    }

    if (refreshToken) {
      Storage.shared().set(AuthApiService.REFRESH_TOKEN_KEY, refreshToken);
    }
  }

  getSessionId() {
    try {
      const userId = this.getUserId();
      let sessionId = Storage.shared().get(
        `${AuthApiService.SESSION_ID_PREFIX}${userId}`
      );
      if (!sessionId) {
        sessionId = uuid();
      }

      return sessionId;
    } catch (error) {
      console.error("[AuthApiService].getSessionId", error);
      throw error;
    }
  }

  getIsExpiredToken() {
    try {
      if (!Storage.shared().get(AuthApiService.TOKEN_KEY)) {
        return false;
      }
      const token = Storage.shared().get(AuthApiService.TOKEN_KEY);
      console.log("[AuthApi].getIsExpiredToken: start", token);
      const decoded = jwtDecode(token);
      console.log("[AuthApi].getIsExpiredToken JWT:", decoded);
      if (!decoded.exp || typeof decoded.exp !== "number") {
        return false;
      }
      console.log(
        `[AuthApi] getIsExpiredToken: ${decoded.exp * 1000 - Date.now()} <= ${
          30 * 60 * 1000
        }`
      );
      return decoded.exp * 1000 - Date.now() <= 30 * 60 * 1000;
    } catch (error) {
      console.error("[AuthApi] getIsExpiredToken failed:", error);
      return false;
    }
  }

  getUserToken(): Promise<string> {
    if (this.getIsExpiredToken()) {
      return restApi.getRefreshedToken();
    }

    return Promise.resolve(Storage.shared().get(AuthApiService.TOKEN_KEY));
  }
}
