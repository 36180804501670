import { Model } from '@nozbe/watermelondb'
import { field, json } from '@nozbe/watermelondb/decorators'


export default class Bag extends Model {
  static table = 'bags'

  @json('files', s => s) files!: string
  @field('name') name!: string
  @field('tag_id') tag_id!: string
  @field('contact') contact!:string
  @field('comment') comment!:string
  @json('flights', s => s) flights!:string
  @json('routes', s => s) routes!:string

}
