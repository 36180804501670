import FuzzySet from 'fuzzyset';

export function calcPercentageOfStringMatch(value: string, value2: string) {
  const fuzzySet = FuzzySet()
  fuzzySet.add(value)
  const resp = fuzzySet.get(value2)

  if (resp && resp.length) {
    return resp[0][0]
  }

  return 0
}

export function calcPercentageOfMatch(value, value2) {
  let v = typeof value === 'string' ? value : typeof value === 'number' ? value.toString() : ''
  let v2 = typeof value2 === 'string' ? value2 : typeof value2 === 'number' ? value2.toString() : ''

  return calcPercentageOfStringMatch(v, v2)
}

export function fieldNameToLabel(str: string) {
  const result = str.replace(/([A-Z])/g, " $1");
  return (result.charAt(0).toUpperCase() + result.slice(1)).trim();
}