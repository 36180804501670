import get from "lodash/get";
import { IRecognizer } from "./IRecognizer";
import { JsonForm, JsonFormJsonSchema } from "../model/JsonForm";
import { FormProcessorParseProps } from "../model/FormProcessorParseProps";
import {
  FormProcessorResponse,
  ResultStatus,
} from "../model/FormProcessorResponse";

export default class Recognizer implements IRecognizer {
  fixFieldName(field) {
    return field.startsWith(".") ? field.replace(".", "") : field;
  }

  fixFieldsName(fields: string[]): string[] {
    return fields.map((f) => this.fixFieldName(f));
  }

  getRequiredFields(
    currentPath: string,
    jsonFormStructure: JsonFormJsonSchema
  ) {
    let result: string[] = [];
    const propertiesNode = jsonFormStructure.properties;
    const requiredFields = jsonFormStructure.required || [];

    Object.keys(propertiesNode).forEach((key) => {
      const value = propertiesNode[`${key}`];

      if (value.type.toLowerCase() === "object") {
        const fields = this.getRequiredFields(`${currentPath}.${key}`, value);
        result = result.concat(fields);
      } else if (requiredFields.includes(key)) {
        result.push(`${currentPath}.${key}`);
      }
    });

    return result;
  }

  getEmptyRequiredFields(
    jsonFormStructure: JsonFormJsonSchema,
    data: Record<string, any>,
    images: Blob[]
  ) {
    const requiredFields = this.getRequiredFields("", jsonFormStructure); // documentfile
    return requiredFields.filter((field) => {
      if (field.indexOf("documentfile") > -1) {
        return (images || []).length === 0;
      } else {
        const value = get(data, this.fixFieldName(field));
        return value === null || value === undefined;
      }
    });
  }

  process(
    props: FormProcessorParseProps,
    jsonForm: JsonForm,
    allowedDocumentTypes: string[],
    shouldRecognizeDocument: boolean,
    quickMode: boolean
  ): FormProcessorResponse {
    return {
      status: ResultStatus.OK,
      data: null,
      documentType: null,
      recognizer: null,
      fields: {
        empty: [],
        invalid: [],
        parsed: [],
      },
      doc_data: {
        date_of_expiry: null,
        passenger: null,
        meta: null,
      },
    };
  }
}
