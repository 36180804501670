import { useCallback, useContext, useEffect, useState } from "react";
import {
  Route,
  Routes,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import Flights from "screens/Flights";
import AuthorizedContext from "contexts/AuthorizedContext";
import AuthScreen from "screens/AuthScreen";
import Tickets from "screens/Tickets";
import Storage from "core/Storage/Storage";
import AuthApiService from "utils/AuthApi";
import { syncBack } from "wmelon/sync";
import Bags from "screens/Bags";
import AddBag from "screens/Bags/AddBag";
import Support from "screens/Support";
import Profile from "screens/Profile";
import AddFlight from "screens/AddFlight";
import Validator from "screens/Validator";
import DocumentRequest from "screens/DocumentRequest";
import FinalAttention from "screens/RegulaScreen/FinalAttention";
import FinalUnrecognized from "screens/RegulaScreen/FinalUnrecognized";
import EnhancedFinalFailed from "screens/RegulaScreen/FinalFailed";
import FinalSucceeded from "screens/RegulaScreen/FinalSucceeded";
import FinalWrongTicket from "screens/RegulaScreen/FinalWrongTicket";
import PreparationScreen from "screens/PreparationScreen";
import AuthProvider from "coordinators/AuthProvider";
import restApi from "utils/RESTApi";
import RefreshProvider from "coordinators/RefreshProvider";
import LinkingProvider from "coordinators/LinkingProvider";
import { getParsedUrl } from "utils/utils";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setLoaderAction } from "redux/features/loader";
import EnhancedNotifications from "screens/Notification";
import { resetDB } from "utils/db";
import { appEvent } from "utils/customEvent";
import config from "config";
import Preloader from "components/Preloader/Preloader";
import OutdatedDataProvider from "coordinators/OutdatedDataProvider";

function AppStack() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { authorized, setAuthorized } = useContext(AuthorizedContext);
  const [linkingUrl, setLinkingUrl] = useState<string | null>(null);

  useEffect(() => {
    AuthApiService.shared()
      .getUserToken()
      .then((token: string | null) => {
        setAuthorized(!!token);
        !token && navigate("/auth");
      })
      .catch((err) => {
        console.error("[AppStack].getToken failed:", err);
        navigate("/auth");
      });
  }, [navigate, setAuthorized]);

  const goTo = useCallback(
    (url: string) => {
      try {
        const { provider, pnr, share_token } = getParsedUrl(url);
        setTimeout(() => {
          appEvent("linking", { pnr, provider, share_token });
        }, 200);
      } catch (err) {
        console.error("[AppStack] failed to parse:", err);
        Modal.error({
          title: t("INVALID_URL"),
          content: t("INVALID_URL_DESC", {
            host: config.LINK_URL.replace("https://", ""),
          }),
          okText: t("ok"),
        });

        dispatch(setLoaderAction(false));
      }
    },
    [dispatch, t]
  );

  useEffect(() => {
    if (
      location.pathname.includes("/pnr") ||
      location.pathname.includes("/share")
    ) {
      setLinkingUrl(window.location.href);
    }
  }, [location]);

  useEffect(() => {
    if (authorized && linkingUrl) {
      goTo(linkingUrl);
      setLinkingUrl(null);
      navigate("/tickets");
    }
  }, [authorized, goTo, linkingUrl, navigate]);

  useEffect(() => {
    if (authorized) {
      syncBack(AuthApiService.shared().getSessionId()).catch((err) => {
        console.error("[AppStack] failed to sync:", err);
      });
    }
  }, [authorized]);

  const onLogout = useCallback(async () => {
    console.log("[AppStack] logout ---------------->");
    setAuthorized(false);
    await resetDB();
    restApi.removeBearerToken();
    Storage.shared().clear([
      AuthApiService.TOKEN_KEY,
      AuthApiService.REFRESH_TOKEN_KEY,
    ]);
    navigate("/auth");
  }, [setAuthorized, navigate]);

  return (
    <>
      <Routes>
        {authorized ? (
          <>
            <Route path="*" element={<Navigate to="/tickets/upcoming" />} />
            <Route path="/tickets">
              <Route
                index
                element={<Navigate to="/tickets/upcoming" replace />}
              />
              <Route path=":source" element={<Flights />} />
            </Route>
            <Route
              path="/tickets/:source/:flight_number/:date/:pnr/:airline/:flight_origin/:flight_destination/:flight_ticket_id"
              element={<Tickets />}
            />
            <Route path="/bags" element={<Bags />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/bags/add_bag" element={<AddBag />} />
            <Route path="/notifications">
              <Route
                index
                element={<Navigate to="/notifications/unread" replace />}
              />
              <Route path=":source" element={<EnhancedNotifications />} />
            </Route>
            <Route path="/bags/add_bag/:bag_id" element={<AddBag />} />
            <Route path="/add_flight" element={<AddFlight />} />
            <Route path="/support" element={<Support />} />
            <Route path="/validator" element={<Validator />} />
            <Route path="/document_request" element={<DocumentRequest />} />
            <Route path="/final/attention" element={<FinalAttention />} />
            <Route path="/final/unrecognized" element={<FinalUnrecognized />} />
            <Route path="/final/failed" element={<EnhancedFinalFailed />} />
            <Route path="/final/success" element={<FinalSucceeded />} />
            <Route path="/final/wrongticket" element={<FinalWrongTicket />} />
            <Route path="/preparation" element={<PreparationScreen />} />
          </>
        ) : (
          <>
            <Route path="/auth" Component={AuthScreen} />
            <Route path="/*" Component={Preloader} />
          </>
        )}
      </Routes>
      <AuthProvider isAuthorized={authorized} onLogout={onLogout} />
      {authorized && <LinkingProvider />}
      {authorized && <OutdatedDataProvider />}
      <RefreshProvider />
    </>
  );
}

export default AppStack;
