import {
  addColumns,
  schemaMigrations,
  createTable,
  unsafeExecuteSql,
} from "@nozbe/watermelondb/Schema/migrations";

export default schemaMigrations({
  migrations: [
    {
      toVersion: 17,
      steps: [
        addColumns({
          table: "notifications",
          columns: [{ name: "payload", type: "string", isOptional: true }],
        }),
      ],
    },
    {
      toVersion: 16,
      steps: [unsafeExecuteSql("DROP TABLE IF EXISTS schemas;")],
    },
    {
      toVersion: 15,
      steps: [
        addColumns({
          table: "flightTickets",
          columns: [
            { name: "passenger_document", type: "string", isOptional: true },
            { name: "passenger_firstname", type: "string", isOptional: true },
            { name: "passenger_lastname", type: "string", isOptional: true },
          ],
        }),
      ],
    },
    {
      toVersion: 14,
      steps: [
        createTable({
          name: "flightTicketShares",
          columns: [
            { name: "flight_ticket_id", type: "string" },
            { name: "token", type: "string" },
            { name: "shared_by", type: "string" },

            { name: "used_by", type: "string", isOptional: true },
            { name: "used_at", type: "number", isOptional: true },

            { name: "created_at", type: "number" },
          ],
        }),
      ],
    },
    {
      toVersion: 13,
      steps: [
        unsafeExecuteSql("DROP TABLE IF EXISTS damageBag;"),
        createTable({
          name: "damageBag",
          columns: [
            { name: "bag_id", type: "string" },
            { name: "files", type: "string" },
            { name: "name", type: "string" },
            { name: "tag_name", type: "string" },
            { name: "contact", type: "string" },
            { name: "comment", type: "string" },
          ],
        }),
      ],
    },
    {
      toVersion: 12,
      steps: [
        unsafeExecuteSql("DROP TABLE IF EXISTS schemas;"),
        createTable({
          name: "schemas",
          columns: [
            { name: "rev", type: "string" },
            { name: "is_transit", type: "boolean" },
            { name: "name", type: "string" },
            { name: "departure", type: "string" },
            { name: "arrival", type: "string" },
            { name: "transporters", type: "string" },

            { name: "start", type: "number" },
            { name: "end", type: "number" },

            { name: "editor_schema", type: "string" },
            { name: "cerber_schema", type: "string" },
            { name: "full_data_documents", type: "string" },
            { name: "full_data_group_documents", type: "string" },

            { name: "description", type: "string" },
            { name: "created_at", type: "number" },
            { name: "updated_at", type: "number" },
            { name: "documents", type: "string" },
          ],
        }),
      ],
    },
    {
      toVersion: 11,
      steps: [
        addColumns({
          table: "schemas",
          columns: [{ name: "transporters", type: "string" }],
        }),
      ],
    },
    {
      toVersion: 10,
      steps: [
        unsafeExecuteSql("DROP TABLE IF EXISTS sessions;"),
        createTable({
          name: "sessions",
          columns: [
            { name: "short_id", type: "string" },
            { name: "flight", type: "string" },
            { name: "carrier", type: "string", isOptional: true },
            { name: "origin", type: "string" },
            { name: "destination", type: "string" },
            { name: "depart_port", type: "string", isOptional: true },
            { name: "arrival_port", type: "string", isOptional: true },
            { name: "transit", type: "string", isOptional: true },
            { name: "flight_date", type: "string" },
            { name: "scheme_id", type: "string" },
            { name: "scheme_version", type: "string" },
            { name: "status", type: "string" },
            { name: "handler", type: "string" },
            { name: "owner_first_name", type: "string", isOptional: true },
            { name: "owner_last_name", type: "string", isOptional: true },
            { name: "owner_dob", type: "string", isOptional: true },
            { name: "owner_document_number", type: "string", isOptional: true },
            { name: "scheme_type", type: "string", isOptional: true },
            { name: "assign_to", type: "string", isOptional: true },
            { name: "flight_ticket_id", type: "string" },
            { name: "created_at", type: "number" },
          ],
        }),
        unsafeExecuteSql("DROP TABLE IF EXISTS notifications;"),
        createTable({
          name: "notifications",
          columns: [
            { name: "created_at", type: "number" },
            { name: "updated_at", type: "number" },
            { name: "deleted_at", type: "number", isOptional: true },
            { name: "device_id", type: "string", isOptional: true },
            { name: "user_id", type: "string" },
            { name: "session_id", type: "string" },
            { name: "flight_ticket_id", type: "string" },
            { name: "action", type: "string" },
            { name: "read_at", type: "number", isOptional: true },
          ],
        }),
        createTable({
          name: "flightTickets",
          columns: [
            { name: "identifiers", type: "string" },

            { name: "ticket_number", type: "string" },
            { name: "airline", type: "string" },

            { name: "flight_number", type: "string" },
            { name: "flight_date", type: "number" },
            { name: "flight_departure_time", type: "string", isOptional: true },
            { name: "flight_arrival_time", type: "string", isOptional: true },
            { name: "flight_origin", type: "string" },
            { name: "flight_destination", type: "string" },

            {
              name: "passenger_document_hash",
              type: "string",
              isOptional: true,
            },
            {
              name: "passenger_firstname_hash",
              type: "string",
              isOptional: true,
            },
            {
              name: "passenger_lastname_hash",
              type: "string",
              isOptional: true,
            },
          ],
        }),
        createTable({
          name: "flightTicketBinds",
          columns: [
            { name: "ticket_id", type: "string" },
            { name: "ticket_number", type: "string" },

            { name: "created_at", type: "number" },
          ],
        }),
        unsafeExecuteSql("DROP TABLE IF EXISTS pnrFlights;"),
        unsafeExecuteSql("DROP TABLE IF EXISTS pnrFlights_forms;"),
      ],
    },
    {
      toVersion: 9,
      steps: [
        addColumns({
          table: "pnrFlights",
          columns: [{ name: "type", type: "string" }],
        }),
        addColumns({
          table: "sessions",
          columns: [
            { name: "owner_document_number", type: "string", isOptional: true },
            { name: "pnr_type", type: "string" },
          ],
        }),
        addColumns({
          table: "notifications",
          columns: [{ name: "pnr_type", type: "string" }],
        }),
      ],
    },
    {
      toVersion: 8,
      steps: [
        addColumns({
          table: "pnrFlights",
          columns: [
            { name: "flight_departure_time", type: "string", isOptional: true },
            { name: "flight_arrival_time", type: "string", isOptional: true },
          ],
        }),
      ],
    },
    {
      toVersion: 7,
      steps: [
        addColumns({
          table: "pnrFlights",
          columns: [{ name: "binded_at", type: "number", isOptional: true }],
        }),
      ],
    },
    {
      toVersion: 6,
      steps: [
        addColumns({
          table: "pnrFlights",
          columns: [
            { name: "flight_origin_country", type: "string", isOptional: true },
            {
              name: "flight_destination_country",
              type: "string",
              isOptional: true,
            },
            { name: "flight_origin_city", type: "string", isOptional: true },
            {
              name: "flight_destination_city",
              type: "string",
              isOptional: true,
            },
          ],
        }),
      ],
    },
    {
      toVersion: 5,
      steps: [
        unsafeExecuteSql(
          "ALTER TABLE pnrFlights DROP COLUMN flight_origin_country;"
        ),
        unsafeExecuteSql(
          "ALTER TABLE pnrFlights DROP COLUMN flight_origin_city;"
        ),
        unsafeExecuteSql(
          "ALTER TABLE pnrFlights DROP COLUMN flight_destination_country;"
        ),
        unsafeExecuteSql(
          "ALTER TABLE pnrFlights DROP COLUMN flight_destination_city;"
        ),
      ],
    },
    {
      toVersion: 4,
      steps: [
        createTable({
          name: "countries",
          columns: [
            { name: "name", type: "string" },
            { name: "alpha2", type: "string" },
            { name: "alpha3", type: "string" },
          ],
        }),
        createTable({
          name: "airports",
          columns: [
            { name: "icao", type: "string" },
            { name: "iata", type: "string", isOptional: true },
            { name: "name", type: "string" },
            { name: "countryCode", type: "string" },
          ],
        }),
      ],
    },
    {
      toVersion: 3,
      steps: [
        addColumns({
          table: "schemas",
          columns: [{ name: "editor_schema", type: "string" }],
        }),
      ],
    },
    {
      toVersion: 2,
      steps: [
        unsafeExecuteSql("DROP TABLE IF EXISTS bags;"),
        createTable({
          name: "bags",
          columns: [
            { name: "files", type: "string" },
            { name: "name", type: "string" },
            { name: "contact", type: "string" },
            { name: "comment", type: "string" },
            { name: "flights", type: "string" },
            { name: "routes", type: "string" },
            { name: "tag_id", type: "string" },
          ],
        }),
      ],
    },
  ],
});
