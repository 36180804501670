import { Card, Popover, Typography } from "antd";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { database } from "wmelon/database";
import Notification from "wmelon/models/Notification";
import shareOffOutline from "assets/images/share-off-outline.svg";
import stamp from "assets/images/stamp.svg";
import AirLine from "assets/images/airline.svg";
import { ReactComponent as ThreeDot } from "assets/images/threeDot.svg";
import { isSafari, isMobileSafari } from "react-device-detect";

import "./style.css";
import moment from "moment";
import { getLocaleDate } from "utils/date";
import { APP_COLORS } from "utils/colors";
import Session from "wmelon/models/Sessions";
import { withObservables } from "@nozbe/watermelondb/react";
import { Q } from "@nozbe/watermelondb";
import { getQueryValue } from "utils/db";
import { NotificationActions } from "types/notification";

interface IProps {
  notification: Notification;
  sessions: Session[];
  goToTicket?: (notification: Notification) => void;
  onPressDropMenu?: (
    action: "read" | "allRead" | "delete",
    notification: Notification
  ) => void;
  isRead: boolean;
  index?: number;
  onSetSize?: (index: number, size: number) => void;
}

const NotificationCard = ({
  notification,
  sessions,
  goToTicket,
  onPressDropMenu,
  isRead,
  index,
  onSetSize,
}: IProps) => {
  const [session] = sessions;
  const { t } = useTranslation();

  const notifRef = useRef(null);
  const revokedNotifRef = useRef(null);

  useEffect(() => {
    function updateSize() {
      const notif = notifRef.current;
      const revoke = revokedNotifRef.current;
      if (notif) {
        //@ts-ignore
        setTimeout(() => {
          //@ts-ignore
          onSetSize && onSetSize(index, notif.getBoundingClientRect().height);
        }, 100);
      } else if (revoke) {
        setTimeout(() => {
          //@ts-ignore
          onSetSize && onSetSize(index, revoke.getBoundingClientRect().height);
        }, 100);
      }
    }

    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, [index, onSetSize]);

  const headerTitle: string = useMemo(() => {
    return t(`Notification.actions.${notification?.action}`);
  }, [notification?.action, t]);

  const backgroundColor = useMemo(() => {
    const colorMapping: Record<string, string> = {
      redirected_to_agent: "#FFB003",
      accepted: "#00B812",
      rejected: "red",
      wait_for_agent_confirmation: "#FFB003",
      rejected_by_pnr_change: "red",
      rejected_by_validator: "red",
      rejected_by_agent: "red",
      rejected_by_schema_change: "red",
      revalidate_by_pnr_change: "#FFB003",
      share_revoked: "#FFB003",
    };
    return colorMapping[notification?.action || ""] || "grey";
  }, [notification?.action]);

  const content = (
    <div>
      {isRead && (
        <>
          <p
            onClick={() => {
              onPressDropMenu?.("read", notification);
            }}
          >
            {t("Notification.modal.maskread")}
          </p>
          <p
            onClick={() => {
              onPressDropMenu?.("allRead", notification);
            }}
          >
            {t("Notification.modal.maskallread")}
          </p>
        </>
      )}

      <p
        onClick={() => {
          onPressDropMenu?.("delete", notification);
        }}
        className="NotificationCard-deleteText"
      >
        {t("Documents.modal.delete")}
      </p>
    </div>
  );
  if (notification?.action === NotificationActions.share_revoked) {
    return (
      <div ref={revokedNotifRef} className="notificationCardContainer">
        <Card
          type="inner"
          title={headerTitle || "Notifications"}
          headStyle={{
            backgroundColor,
            color: "white",
            fontWeight: "bold",
            textAlign: "center",
          }}
          style={{
            marginBottom: 20,
          }}
          extra={
            <div
              style={{
                zIndex: 999,
                padding: 10,
              }}
            >
              <Popover content={content}>
                <ThreeDot
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              </Popover>
            </div>
          }
        >
          <div
            onClick={() => goToTicket?.(notification)}
            style={{ cursor: "pointer" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img src={shareOffOutline} alt="logo" />
              <Typography.Text>{headerTitle}</Typography.Text>
            </div>
            {notification.createdAt && (
              <p
                style={{
                  textAlign: "right",
                  color: APP_COLORS.light.black,
                }}
              >
                {t("Notification.createdAt", {
                  date: `${moment(notification?.createdAt).format(
                    "DD/MM/YYYY HH:mm:ss"
                  )}`,
                  interpolation: { escapeValue: false },
                })}
              </p>
            )}
          </div>
        </Card>
      </div>
    );
  }

  return (
    <div ref={notifRef} className="notificationCardContainer">
      <Card
        type="inner"
        title={headerTitle || "Notifications"}
        headStyle={{
          backgroundColor,
          color: "white",
          fontWeight: "bold",
          textAlign: "center",
        }}
        style={{
          marginBottom: 20,
        }}
        extra={
          <div
            style={{
              zIndex: 999,
              padding: 10,
              position: "absolute",
              right: 10,
              top: 8,
            }}
          >
            <Popover content={content}>
              <ThreeDot
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />
            </Popover>
          </div>
        }
      >
        <div
          onClick={() => {
            goToTicket?.(notification);
          }}
          style={{ cursor: "pointer" }}
        >
          <div className="infoHeaderContainer">
            <div style={{ display: "flex", alignItems: "center" }}>
              {notification?.action === "accepted" && (
                <img src={stamp} alt="logo" />
              )}
              <Typography.Text>{session?.flight}</Typography.Text>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography.Text style={{ marginRight: 2 }}>
                {t("Notification.sessionCode")}:
              </Typography.Text>
              <Typography.Text
                style={{ fontWeight: isSafari || isMobileSafari ? 599 : 650 }}
              >
                {session?.short_id}
              </Typography.Text>
            </div>
          </div>
          <div className="infoHeaderContainer">
            <Typography.Text className="NotificationCard-airName">
              {session?.depart_port}
            </Typography.Text>
            <Typography.Text className="NotificationCard-airName">
              {session?.arrival_port}
            </Typography.Text>
          </div>
          <div className="infoHeaderContainer">
            <Typography.Text className="NotificationCard-airCode">
              {session?.origin}
            </Typography.Text>
            <div className="NotificationCard-logo-container">
              <img
                src={AirLine}
                alt="logo"
                className="NotificationCard-airline-logo"
              />
            </div>
            <Typography.Text className="NotificationCard-airCode">
              {session?.destination}
            </Typography.Text>
          </div>
          <div className="infoHeaderContainer">
            <Typography.Text>
              {getLocaleDate(
                (session?._raw as any)?.flight_date,
                "ddd, DD MMM"
              )}
            </Typography.Text>
            <Typography.Text>
              {/* {getLocaleDate(session?.arrival_datetime, "ddd, DD MMM")} */}
            </Typography.Text>
          </div>
        </div>
        {notification?.createdAt && (
          <p
            style={{
              textAlign: "right",
              color: APP_COLORS.light.black,
            }}
          >
            {t("Notification.createdAt", {
              date: `${moment(notification?.createdAt).format(
                "DD/MM/YYYY HH:mm:ss"
              )}`,
              interpolation: { escapeValue: false },
            })}
          </p>
        )}
      </Card>
    </div>
  );
};

const enhance = withObservables(
  ["notification"],
  ({ notification }: { notification: Notification }) => ({
    sessions: database
      .get<Session>(Session.table)
      .query(Q.where("id", getQueryValue(notification.sessionId))),
  })
);
const EnhancedNotificationCard = enhance(NotificationCard);
export default EnhancedNotificationCard;
