import { useCallback, useMemo } from "react";
import { useAppDispatch } from "redux/store";
import { v4 as uuid } from "uuid";
import { useSelector } from "react-redux";
import { Q } from "@nozbe/watermelondb";
import {
  addDocument as addReduxDocument,
  updateDocument as updateReduxDocument,
  clearState as clearDocumentState,
  ReduxDocument,
  ReduxDocumentFile,
  ReduxDocumentFileClone,
} from "redux/features/documents";
import { database } from "wmelon/database";
import Session from "wmelon/models/Sessions";
import Document from "wmelon/models/Document";
import File from "wmelon/models/File";
import { getProvider, getVerifiedManual } from "utils/document";
import {
  blobToBase64,
  getExtension,
  getFileSize,
  getMimeType,
} from "utils/file";
import { NextcloudPermitDoccheckResult } from "types/validator";
import { generalAPI } from "services/base/api";
import { ReduxState } from "redux/ReduxState";

export interface UseDocumentsResponse {
  addDocument: (
    data: {
      category: number | null;
      formId: number | null;
      type: string;
      recognizer: string | null;
      data: any;
    },
    images: Blob[]
  ) => Promise<string>;
  updateDocument: (
    id: string,
    data: {
      autoVerificationResult: string;
      verifiedAuto: boolean;
      verifiedManual: boolean;
    }
  ) => void;
  moveToDatabase: (session: Session) => Promise<Document[]>;
  clearDocuments: () => Promise<void>;
  removeDocuments: (sessionId: string) => Promise<void | void[]>;
  onlineValidatePermit: (data: {
    docType: "validate_doc";
    data: string;
    validator: string;
  }) => Promise<NextcloudPermitDoccheckResult["data"]>;
}

export function useDocuments(): UseDocumentsResponse {
  const sessionDocuments: ReduxDocument[] = useSelector(
    (state: ReduxState) => state.documents.items
  );
  const dispatch = useAppDispatch();

  const addDocument = useCallback(
    async (
      data: {
        category: number | null;
        formId: number | null;
        type: string;
        recognizer: string | null;
        data: any;
      },
      rawImages: Blob[]
    ) => {
      const id = uuid();
      return Promise.all(
        rawImages.map((it: Blob) => {
          return blobToBase64(it).then((base64: string | null) => {
            if (base64) {
              return {
                filename: `${id}_${uuid()}.${getExtension(
                  getMimeType(base64) || ""
                )}`,
                data: base64,
                hash: getFileSize(base64).toString(),
              };
            } else {
              return null;
            }
          });
        })
      ).then((resp) => {
        dispatch(
          addReduxDocument({
            id: id,
            category: data.category,
            form_id: data.formId,
            form_version: null,
            type: data.type,
            provider: getProvider(data.type),
            recognizer: data.recognizer,
            data: data.data,
            autoVerificationResult: null,
            files: resp.filter((it) => !!it) as {
              filename: string;
              data: string;
              hash: string;
            }[],
            verifiedManual: getVerifiedManual(data.type, data.category) === 1,
            verifiedAuto: false,
            createdAt: Date.now(),
          })
        );
        return id;
      });
    },
    [dispatch]
  );

  const updateDocument = useCallback(
    (
      id: string,
      data: {
        autoVerificationResult: string;
        verifiedAuto: boolean;
      }
    ) => {
      dispatch(
        updateReduxDocument({
          id,
          ...data,
        })
      );
    },
    [dispatch]
  );

  const moveToDatabase = useCallback(
    (session: Session) => {
      return database.write(() => {
        return Promise.all(
          sessionDocuments.map(async (doc: ReduxDocument) => {
            const savedDocument = await database
              .get<Document>(Document.table)
              .find(doc.id)
              .catch(() => {
                return null;
              });

            if (savedDocument) {
              return savedDocument.update(() => {
                if (doc.category !== null) {
                  savedDocument.category = doc.category;
                }
                if (doc.form_id !== null) {
                  savedDocument.formId = doc.form_id;
                }
                if (doc.form_version !== null) {
                  savedDocument.formVersion = doc.form_version;
                }
                savedDocument.type = doc.type;
                if (doc.provider !== null) {
                  savedDocument.provider = doc.provider;
                }

                if (doc.recognizer !== null) {
                  savedDocument.recognizer = doc.recognizer;
                }

                savedDocument.data = doc.data;
                savedDocument.files = doc.files.map(
                  (
                    it: ReduxDocumentFile | ReduxDocumentFileClone,
                    position: number
                  ) => {
                    if ("link" in it) {
                      return {
                        link: it.link,
                        hash: it.hash,
                        position: it.position,
                      };
                    } else {
                      return {
                        link: `${session.id}/${savedDocument.id}/${it.filename}`,
                        hash: it.hash,
                        position,
                      };
                    }
                  }
                );
                savedDocument.verified_manual = doc.verifiedManual;
                savedDocument.verified_auto = doc.verifiedAuto;
                if (doc.autoVerificationResult !== null) {
                  savedDocument.auto_verification_result =
                    doc.autoVerificationResult;
                }
                (savedDocument.session as any).set(session);
              });
            }

            return database
              .get<Document>(Document.table)
              .create((record) => {
                if (doc.category !== null) {
                  record.category = doc.category;
                }
                if (doc.form_id !== null) {
                  record.formId = doc.form_id;
                }
                if (doc.form_version !== null) {
                  record.formVersion = doc.form_version;
                }
                record.type = doc.type;

                if (doc.provider !== null) {
                  record.provider = doc.provider;
                }
                if (doc.recognizer !== null) {
                  record.recognizer = doc.recognizer;
                }

                record.data = doc.data;
                record.files = doc.files.map(
                  (
                    it: ReduxDocumentFile | ReduxDocumentFileClone,
                    position: number
                  ) => {
                    if ("link" in it) {
                      return {
                        link: it.link,
                        hash: it.hash,
                        position: it.position,
                      };
                    } else {
                      return {
                        link: `${session.id}/${record.id}/${it.filename}`,
                        hash: it.hash,
                        position,
                      };
                    }
                  }
                );
                record.verified_manual = doc.verifiedManual;
                record.verified_auto = doc.verifiedAuto;
                if (doc.autoVerificationResult !== null) {
                  record.auto_verification_result = doc.autoVerificationResult;
                }
                (record.session as any).set(session);
              })
              .then((document) => {
                return Promise.all(
                  doc.files.map(
                    (
                      it: ReduxDocumentFile | ReduxDocumentFileClone,
                      position: number
                    ) => {
                      if ("link" in it) {
                        return Promise.resolve();
                      }

                      return database.get<File>(File.table).create((file) => {
                        file.hash = it.hash;
                        file.data = it.data;
                        file.position = position;
                        (file.document as any).set(document);
                      });
                    }
                  )
                ).then(() => document);
              });
          })
        );
      });
    },
    [sessionDocuments]
  );

  const clearDocuments = useCallback(() => {
    dispatch(clearDocumentState());
    return Promise.resolve();
  }, [dispatch]);

  const removeDocuments = useCallback((sessionId: string) => {
    return database.write(() => {
      return database
        .get<Document>(Document.table)
        .query(Q.where("session_id", sessionId))
        .fetch()
        .then((documents) => {
          return Promise.all(
            documents.map((doc: Document) => {
              return database
                .get<File>(File.table)
                .query(Q.where("document_id", doc.id))
                .fetch()
                .then((files) => {
                  return Promise.all(
                    files.map((file) => {
                      return file.destroyPermanently();
                    })
                  );
                })
                .then(() => doc.destroyPermanently());
            })
          );
        });
    });
  }, []);

  const onlineValidatePermit = useCallback(
    (data: {
      docType: "validate_doc";
      data: string;
      validator: string;
    }): Promise<NextcloudPermitDoccheckResult["data"]> => {
      return generalAPI.validatePermit(data);
    },
    []
  );

  return useMemo(
    () => ({
      addDocument,
      updateDocument,
      moveToDatabase,
      clearDocuments,
      removeDocuments,
      onlineValidatePermit,
    }),
    [
      addDocument,
      updateDocument,
      moveToDatabase,
      clearDocuments,
      removeDocuments,
      onlineValidatePermit,
    ]
  );
}
