import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HistoryItem } from "types/history";

export interface HistoryState {
  date: string | null;
  items: HistoryItem[];
}

export const initialState: HistoryState = {
  date: null,
  items: [],
};

const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    setItems: (
      state: HistoryState,
      action: PayloadAction<{ date: string | null; items: HistoryItem[] }>
    ) => {
      state.date = action.payload.date;
      state.items = action.payload.items;
    },
    setDate: (state: HistoryState, action: PayloadAction<string | null>) => {
      state.date = action.payload;
    },
    reset: (state: HistoryState) => {
      state = initialState;
    },
  },
});

export const { setItems, setDate } = historySlice.actions;

export default historySlice.reducer;
