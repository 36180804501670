import { getUnreadNotificationsCount } from "wmelon/middleware/notification";
export const initBadges = async (num: number, isHeadless: boolean = false) => {
  let badgeCount = 0;
  if (isHeadless) {
    return;
  } else {
    console.log(`Badge count: ${badgeCount}`);
    badgeCount = await getUnreadNotificationsCount();
  }
};
