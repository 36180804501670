import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import Tag from "wmelon/models/Tag";
import "./style.css";
import { getTextColor } from "utils/colors";
import { OUTPUT_TAG_COUNT } from "constants/contants";
import { database } from "wmelon/database";
import { withObservables } from "@nozbe/watermelondb/react";
import { Observable } from "@nozbe/watermelondb/utils/rx";
interface OtherProps {
  click: () => void;
  tags: Tag[];
}

const Other = ({ click, tags }: OtherProps) => {
  const { t } = useTranslation();
  return (
    <div className="otherContainer">
      <Typography.Text className="otherTitle">
        {t("Profile.other")}
      </Typography.Text>
      <div className="tagsContainer">
        <div className="tagsContent" onClick={click}>
          <h5 className="tagsTitle">{t("Profile.tags")}</h5>
          <div className="tags">
            {tags.length > 0 &&
              tags.map(
                (tag, i) =>
                  i < OUTPUT_TAG_COUNT && (
                    <div
                      style={{
                        backgroundColor: tag?.color,
                        right: 25 * i,
                        zIndex: 4 - i,
                      }}
                      key={i}
                      className="tag"
                    >
                      <h4
                        style={{
                          color: getTextColor(tag?.color),
                          alignSelf: "center",
                          marginTop: 3,
                        }}
                        className="tagName"
                      >
                        {tag?.name?.substring(0, 3)}
                      </h4>
                    </div>
                  )
              )}
            {tags.length > OUTPUT_TAG_COUNT && (
              <h4 className="plusElem">{`+${tags.length - 3}`}</h4>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const enhance = withObservables<
  Omit<OtherProps, "tags">,
  { tags: Observable<Tag[]> }
>([], () => ({
  tags: database.get<Tag>(Tag.table).query().observe(),
}));
const EnhancedOther = enhance(Other);
export default EnhancedOther;
