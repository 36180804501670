import { Spin, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Portal from "components/Portal";
import "./style.css";

export interface PreloaderProps {
  title?: string;
  styleTitle?: any;
  style?: any;
}

export default function Preloader({
  title,
  styleTitle,
  style,
}: PreloaderProps) {
  return (
    <Portal id='preloader' renderIfNoParent>
      <div className={"container"} style={style}>
        <Spin
          indicator={
            <LoadingOutlined style={{ fontSize: 48, color: "white" }} spin />
          }
        />
        {title && (
          <Typography.Text className={"title"} style={styleTitle}>
            {title}
          </Typography.Text>
        )}
      </div>
    </Portal>
  );
}
