import { useRef, useCallback, useState } from "react";
import { Q } from "@nozbe/watermelondb";
import { VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import "../styles.css";
import { useTranslation } from "react-i18next";
import { database } from "wmelon/database";
import { Empty, Typography } from "antd";
import NotificationCard from "components/NotificationCard";
import { withObservables } from "@nozbe/watermelondb/react";
import { APP_COLORS } from "utils/colors";
import Notification from "wmelon/models/Notification";
import { Observable } from "@nozbe/watermelondb/utils/rx";
import { useFlights } from "hooks/useFlights";
import { useReduxSession } from "hooks/useReduxSession";
import Preloader from "components/Preloader/Preloader";

export interface ReadProps {
  notifications: Notification[];
  onPressAction: (
    actionKey: "read" | "allRead" | "delete",
    notification: Notification
  ) => void;
}

const Read = ({ notifications, onPressAction }: ReadProps) => {
  const { t } = useTranslation();
  const listRef = useRef(null);
  const notificationSize = useRef({});

  const { navigateToTicketById } = useFlights();
  const { initSessionByDocumentRequestId } = useReduxSession();

  const [loading, setLoading] = useState(false);

  const setNotificationSize = useCallback((index, size) => {
    notificationSize.current = { ...notificationSize.current, [index]: size };
    //@ts-ignore
    listRef?.current?.resetAfterIndex(index);
  }, []);

  const getSize = useCallback((index) => {
    return notificationSize.current[index] || 192;
  }, []);

  const goToTicket = useCallback(
    async (notification: Notification) => {
      console.log("[Read] go to ticket:", notification._raw);

      if (
        notification.action === "additional_document_request" &&
        notification.payload &&
        notification.sessionId
      ) {
        setLoading(true);
        return initSessionByDocumentRequestId(
          notification.payload.id,
          notification.sessionId
        ).finally(() => setLoading(false));
      } else {
        if (!notification.flightTicketId) return Promise.resolve();
        setLoading(true);
        return navigateToTicketById(notification.flightTicketId, true).finally(
          () => setLoading(false)
        );
      }
    },
    [navigateToTicketById, initSessionByDocumentRequestId]
  );

  const renderNotificationCard = useCallback(
    ({ index, style }) => {
      const notification = notifications[index];
      return (
        <div
          style={{
            ...style,
            top: `${parseFloat(style.top)}px`,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <NotificationCard
            isRead={false}
            key={`notification_read_${index}`}
            notification={notification}
            goToTicket={goToTicket}
            onPressDropMenu={(action) => {
              onPressAction(action, notification);
            }}
            index={index}
            onSetSize={setNotificationSize}
          />
        </div>
      );
    },
    [goToTicket, notifications, onPressAction, setNotificationSize]
  );

  return (
    <>
      {notifications?.length > 0 ? (
        <AutoSizer>
          {({ height, width }) => (
            <VariableSizeList
              className="NotificationList-container"
              ref={listRef}
              height={height}
              width={width}
              itemCount={notifications.length}
              itemSize={getSize}
            >
              {renderNotificationCard}
            </VariableSizeList>
          )}
        </AutoSizer>
      ) : (
        <Empty
          description={
            <Typography
              style={{
                color: APP_COLORS.light.primary_text_color,
              }}
            >
              {t("EmptyMessages.notification")}
            </Typography>
          }
        />
      )}
      {loading ? <Preloader /> : null}
    </>
  );
};

const enhance = withObservables<
  Omit<ReadProps, "notifications">,
  { notifications: Observable<Notification[]> }
>([], () => {
  return {
    notifications: database
      .get<Notification>(Notification.table)
      .query(Q.where("read_at", Q.notEq(null)), Q.sortBy("read_at", Q.desc))
      .observe(),
  };
});

const EnhancedRead = enhance(Read as any);
export default EnhancedRead;
