import { Q } from "@nozbe/watermelondb";
import { DirtyRaw, sanitizedRaw } from "@nozbe/watermelondb/RawRecord";
import { Modal } from "antd";
import { BIND_ERROR_CODES } from "constants/contants";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { generalAPI } from "services/base/api";
import { FlightSearchitem } from "types/flights";
import { HistoryItem } from "types/history";
import AuthApiService from "utils/AuthApi";
import { APP_COLORS } from "utils/colors";
import { getTicketInfoParams } from "utils/flights";
import { database } from "wmelon/database";
import FlightTicket from "wmelon/models/FlightTicket";
import FlightTicketBinds from "wmelon/models/FlightTicketBinds";
import FlightTicketShare from "wmelon/models/FlightTicketShare";

function promptTicket(
  ticket: FlightTicket,
  message: string,
  t: (key: string) => any
): Promise<BindResponse> {
  return new Promise((res) => {
    const onDismiss = () => {
      console.log("Cancel Pressed");
      res({
        ticket: ticket,
        navigate: false,
        exist: true,
        binded: true,
      } as BindResponse);
    };
    Modal.confirm({
      title: t("Error") as string,
      content: message,
      cancelText: t("Cancel"),
      okText: t("goToTickets"),

      onCancel: onDismiss,
      onOk: () => {
        res({
          ticket: ticket,
          navigate: true,
          exist: true,
          binded: true,
        } as BindResponse);
      },
      okButtonProps: {
        style: {
          backgroundColor: APP_COLORS.light.primary,
          color: APP_COLORS.light.default_text_color,
        },
      },
    });
  });
}

export interface BindResponse {
  ticket: FlightTicket | null;
  navigate: boolean;
  exist: boolean;
  binded: boolean;
}

export function useFlights() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const navigateToTickets = useCallback(
    (
      ticket: FlightTicket | HistoryItem,
      history: boolean,
      from: string = ""
    ) => {
      const {
        flight_number,
        date,
        pnr,
        airline,
        flight_origin,
        flight_destination,
        flight_ticket_id,
      } = getTicketInfoParams(ticket, true);
      navigate(
        `/tickets/${
          history ? `history/` : "db/"
        }${flight_number}/${date}/${pnr}/${airline}/${flight_origin}/${flight_destination}/${flight_ticket_id}`,
        { state: { from } }
      );
    },
    [navigate]
  );

  const saveBindResult = useCallback((list): Promise<BindResponse> => {
    const flightTicketsDB = database.get<FlightTicket>(FlightTicket.table);
    const flightTicketBindsDb = database.get<FlightTicketBinds>(
      FlightTicketBinds.table
    );
    const flightTicketShareDb = database.get<FlightTicketShare>(
      FlightTicketShare.table
    );

    return database.write(async () => {
      let ts = Date.now();
      let ticketRecord;
      console.log("[useFlights].saveBindResult:", list.length);
      for (const data of list) {
        const { bind, share, forms, ...pnrFlight } = data;
        const cycleTS = Date.now();

        try {
          const bindsTS = Date.now();
          if (bind) {
            await flightTicketBindsDb.create((record) => {
              record._raw = sanitizedRaw(
                { ...bind },
                flightTicketBindsDb.schema
              );
            });
          }
          console.log(
            "[useFlight][ts].saveBindResult binds:",
            Date.now() - bindsTS
          );
        } catch (err) {
          console.warn(
            "[useFlights].saveBindResult failed to create flightTicketBinds:",
            err
          );
        }

        try {
          const shareTS = Date.now();
          if (share) {
            await flightTicketShareDb.create((record) => {
              record._raw = sanitizedRaw(
                { ...share },
                flightTicketShareDb.schema
              );
            });
          }
          console.log(
            "[useFlight][ts].saveBindResult share:",
            Date.now() - shareTS
          );
        } catch (err) {
          console.warn(
            "[useFlights].saveBindResult failed to create flightTicketBinds:",
            err
          );
        }

        const ticketTS = Date.now();
        try {
          ticketRecord = await flightTicketsDB.create((record) => {
            record._raw = sanitizedRaw(
              {
                ...pnrFlight,
                flight_date: new Date(pnrFlight?.flight_date).getTime(),
              },
              flightTicketsDB.schema
            );
            record.identifiers = pnrFlight.identifiers;
          });
        } catch (err) {
          console.warn(
            "[useFlights].saveBindResult failed to create pnrFlight:",
            err
          );
          ticketRecord = await flightTicketsDB.find(pnrFlight.id.toString());
        }
        console.log(
          "[useFlight][ts].saveBindResult ticket:",
          Date.now() - ticketTS
        );
        console.log(
          "[useFlight][ts].saveBindResult cycle:",
          Date.now() - cycleTS
        );
      }
      console.log("[useFlights][ts].saveBindResult save data", Date.now() - ts);
      if (ticketRecord) {
        return {
          ticket: ticketRecord,
          navigate: true,
          exist: false,
          binded: true,
        } as BindResponse;
      } else {
        return {
          ticket: null,
          navigate: false,
          exist: false,
          binded: true,
        } as BindResponse;
      }
    });
  }, []);

  const bindPNR = useCallback(
    (identifier: string, provider: string): Promise<BindResponse> => {
      if (!identifier) {
        return Promise.resolve({
          ticket: null,
          navigate: false,
          exist: false,
          binded: true,
        } as BindResponse);
      }

      const ts = Date.now();
      return generalAPI
        .bindPnr(identifier, provider, AuthApiService.shared().getSessionId())
        .then((tickets) => {
          console.log("[useFlights] bindPNR", Date.now() - ts);
          return saveBindResult(tickets);
        });
    },
    [saveBindResult]
  );

  const createVirtualTicket = useCallback(
    (
      identifier: string,
      data: {
        airline: string;
        flight_number: string;
        flight_date: string;
        flight_origin: string;
        flight_destination: string;
        flight_departure_time: string;
        flight_arrival_time: string;
      },
      from?: string
    ): Promise<BindResponse> => {
      if (!identifier) {
        return Promise.resolve({
          ticket: null,
          navigate: false,
          exist: false,
          binded: false,
        });
      }

      const ts = Date.now();
      return generalAPI
        .createVirtualTicket(
          identifier,
          data,
          AuthApiService.shared().getSessionId()
        )
        .then((ticket) => {
          console.log(
            "[useFlights] createVirtualTicket",
            Date.now() - ts,
            ticket
          );
          return saveBindResult([ticket]);
        })
        .then((resp: BindResponse) => {
          if (resp.ticket && resp.navigate) {
            navigateToTickets(resp.ticket, false, from);
          }

          return resp;
        })
        .catch((err) => {
          console.log("[useFlights] createVirtualTicket failed:", err.message);
          if (err.response) {
            const msg = err.response?.data?.message || err.message;
            Modal.error({
              title: t("Error") as string,
              content: t(
                typeof msg === "string" ? msg : "failedToSave"
              ) as string,
              okText: t("ok"),
            });
          } else {
            console.log(
              "[useFlights] createPNR failed:",
              err.response?.data?.message || err.message
            );
            Modal.error({
              title: t("Error") as string,
              content: t(err.message) as string,
              okText: t("ok"),
            });
          }

          console.error("[useFlights]", err);
          return {
            ticket: null,
            navigate: false,
            exist: false,
            binded: false,
          };
        });
    },
    [saveBindResult, navigateToTickets, t]
  );

  const searchTicketInDB = useCallback(
    (pnr: string, provider = "OAW"): Promise<BindResponse> => {
      const identifier = `${provider}:${pnr}`;
      console.log(`[useFlights].searchTicketInDB: pnr-${pnr};prov-${provider}`);
      return database
        .get<FlightTicket>(FlightTicket.table)
        .query(
          Q.where(
            "identifiers",
            Q.like(`%"${Q.sanitizeLikeString(identifier)}"%`)
          )
        )
        .fetch()
        .then((tickets) => {
          console.log("[useFlights].searchTicketInDB result:", tickets.length);
          if (tickets.length > 0) {
            return promptTicket(
              tickets[0],
              t("PNR_ALREADY_EXISTS") as string,
              t
            );
          } else {
            return {
              ticket: null,
              navigate: false,
              exist: false,
              binded: false,
            };
          }
        });
    },
    [t]
  );

  const checkPNR = useCallback(
    (
      pnr: string,
      provider: string | null,
      byLink: boolean,
      from?: string
    ): Promise<BindResponse> => {
      return searchTicketInDB(pnr, provider || "OAW")
        .then((resp: BindResponse) => {
          if (resp.ticket) {
            console.log("[useFlights] ticket in DB:", resp);
            return Promise.resolve(resp);
          }

          return bindPNR(pnr, provider || "OAW")
            .catch((err) => {
              console.log(
                "[useFlights] failed to bind:",
                byLink,
                `"${err.message}"`,
                err.message === BIND_ERROR_CODES.FLIGHT_TICKET_NOT_FOUND
              );
              if (
                byLink ||
                err.message !== BIND_ERROR_CODES.FLIGHT_TICKET_NOT_FOUND
              ) {
                throw err;
              }

              return Promise.resolve(null);
            })
            .then((resp: BindResponse | null) => {
              console.log("[useFlights] bind result:", resp);
              if (resp?.ticket) {
                return Promise.resolve(resp);
              }

              return generalAPI
                .getTicketStatus(pnr)
                .then((resp: { exist: boolean }) => {
                  return {
                    ticket: null,
                    navigate: false,
                    exist: resp.exist,
                    binded: false,
                  };
                });
            });
        })
        .then((resp: BindResponse) => {
          console.log("[useFlights] ticket in DB:", resp);
          if (resp.ticket && resp.navigate) {
            navigateToTickets(resp.ticket, false, from);
          }

          return resp;
        })
        .catch((err) => {
          console.log("[useFlights].checkPNR failed:", err);
          throw err;
        });
    },
    [bindPNR, searchTicketInDB, navigateToTickets]
  );

  const shareTicket = useCallback((ticketId: string): Promise<DirtyRaw> => {
    return generalAPI
      .shareTicket(ticketId, AuthApiService.shared().getSessionId())
      .then((response: DirtyRaw) => {
        return database
          .write(() => {
            return database
              .get<FlightTicketShare>(FlightTicketShare.table)
              .create((record) => {
                record._raw = sanitizedRaw(
                  { ...response },
                  database.get<FlightTicketShare>(FlightTicketShare.table)
                    .schema
                );
              });
          })
          .then(() => response);
      });
  }, []);

  const unshareTicket = useCallback(
    (share: FlightTicketShare): Promise<void> => {
      return generalAPI
        .unshareTicket(share.id, AuthApiService.shared().getSessionId())
        .catch((err) => {
          if (err.message === "FLIGHT_TICKET_SHARE_NOT_FOUND") {
            return Promise.resolve();
          }

          throw err;
        })
        .then(() => {
          return database.write(() => {
            return share.destroyPermanently();
          });
        });
    },
    []
  );

  const bindTicketByShareToken = useCallback(
    (token: string): Promise<BindResponse> => {
      const time = Date.now();
      return generalAPI
        .bindTicketByShareToken(token, AuthApiService.shared().getSessionId())
        .then((tickets) => {
          console.log(
            "[useFlights][ts].bindTicketByShareToken request:",
            Date.now() - time
          );
          return saveBindResult([tickets]);
        })
        .catch((err) => {
          if (err.message === "FLIGHT_TICKET_ALREADY_BINDED") {
            return database
              .get<FlightTicket>(FlightTicket.table)
              .query(Q.on(FlightTicketShare.table, Q.where("token", token)))
              .fetch()
              .then((tickets) => {
                if (tickets.length) {
                  return promptTicket(
                    tickets[0],
                    t("FLIGHT_TICKET_ALREADY_BINDED") as string,
                    t
                  );
                } else {
                  throw err;
                }
              });
          } else {
            throw err;
          }
        })
        .then((resp: BindResponse) => {
          if (resp.ticket && resp.navigate) {
            navigateToTickets(resp.ticket, false);
          }
          console.log(
            "[useFlights][ts].bindTicketByShareToken result:",
            Date.now() - time
          );
          return resp;
        })
        .catch((err) => {
          console.log(
            "[useFlights][ts].bindTicketByShareToken failed:",
            Date.now() - time
          );
          console.log("[useFlights].bindTicketByShareToken failed:", err);
          Modal.error({
            title: t("Error") as string,
            content: t(err.response?.data?.message || err.message) as string,
            okText: t("ok"),
          });

          return {
            ticket: null,
            navigate: false,
            exist: false,
            binded: false,
          } as BindResponse;
        });
    },
    [saveBindResult, navigateToTickets, t]
  );

  const loadFlights = useCallback(
    (date: string, signal?: AbortSignal): Promise<FlightSearchitem[]> => {
      return generalAPI.loadFlights<FlightSearchitem>(date, signal);
    },
    []
  );

  const loadHistoryTicket = useCallback(
    (id: string): Promise<HistoryItem[]> => {
      return generalAPI
        .loadHistoryTicket(id)
        .then((response: { history: HistoryItem[] }) => {
          return response.history;
        })
        .catch((err) => {
          console.error("[useFlights] loadHistoryTicket failed:", err);
          return [];
        });
    },
    []
  );

  const navigateToTicketById = useCallback(
    async (ticketId: string, withWarning: boolean): Promise<boolean> => {
      const tickets = await database
        .get<FlightTicket>(FlightTicket.table)
        .query(Q.where("id", ticketId));
      !tickets.length &&
        console.log(
          "[useFlights] navigateToTicketById:",
          ticketId,
          withWarning,
          tickets.length
        );
      if (tickets.length === 0) {
        return loadHistoryTicket(ticketId).then((response) => {
          if (response.length) {
            navigateToTickets(response[0], true);
            return true;
          } else {
            withWarning &&
              Modal.error({
                title: t("Error") as string,
                content: t("Ticket_and_Info.TicketNotFound") as string,
                okText: t("ok"),
              });

            return false;
          }
        });
      } else {
        navigateToTickets(tickets[0], false);
        return true;
      }
    },
    [loadHistoryTicket, navigateToTickets, t]
  );

  return useMemo(
    () => ({
      checkPNR,
      createVirtualTicket,
      loadFlights,

      shareTicket,
      unshareTicket,
      bindTicketByShareToken,
      navigateToTicketById,
      navigateToTickets,
    }),
    [
      loadFlights,
      createVirtualTicket,
      checkPNR,
      shareTicket,
      unshareTicket,
      bindTicketByShareToken,
      navigateToTicketById,
      navigateToTickets,
    ]
  );
}
