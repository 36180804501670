import { Select as AntdSelect, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./style.css";
import { APP_COLORS } from "utils/colors";

const { Option } = AntdSelect;

export interface SelectProps {
  inputPlaceholder: string;
  options: { value: string; label: string }[];
  disabled?: boolean;
  value: string | null;
  onSelect: (value: string) => void;
}

const Select = ({
  inputPlaceholder,
  options,
  disabled,
  value,
  onSelect,
}: SelectProps) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<string | null>(value);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const handleSelect = (value: string) => {
    setSelected(value);
    onSelect(value);
  };

  return (
    <AntdSelect
      placeholder={inputPlaceholder}
      disabled={disabled}
      value={selected}
      onChange={handleSelect}
      style={{
        width: "100%",
        color: APP_COLORS.light.primary_text_color,
        textAlign: "center",
      }}
      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
      notFoundContent={
        <Typography.Text style={{ color: APP_COLORS.light.primary_text_color }}>
          {t("nodata")}
        </Typography.Text>
      }
    >
      {options.map((option) => (
        <Option
          key={option.value}
          value={option.value}
          label={option.label}
          style={{ textAlign: "center" }}
        >
          <Typography.Text
            style={{ color: APP_COLORS.light.primary, textAlign: "center" }}
          >
            {option.label}
          </Typography.Text>
        </Option>
      ))}
    </AntdSelect>
  );
};

export default Select;
