import { useMemo, useCallback } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { getTicketPNR } from "utils/tickets";
import bagIcon from "../../assets/images/bag.svg";
import warrnigIcon from "../../assets/images/warning.svg";
import errorIcon from "../../assets/images/error.svg";
import successIcon from "../../assets/images/success.svg";

import "./PNRItem.css";
import FlightDetails from "components/FlightDetails";

export interface PnrInfoProps {
  tickets: {
    flight_origin: string;
    flight_destination: string;
    flight_date: string;
    flight_departure_time?: string | null;
    flight_arrival_time?: string | null;
    airline: string;
    identifiers: string | string[];
    flight_number: string;
  }[];
  compact?: boolean;
  canValidate: boolean;
  loading: boolean;
  syncing: boolean;
  onSelect: () => void;
  onStartValidation: () => void;
  status: "wait" | "success" | "rejected";
}

function PNRItem({
  tickets,
  compact,
  canValidate,
  loading,
  syncing,
  onSelect,
  onStartValidation,
  status,
}: PnrInfoProps) {
  const { t } = useTranslation();
  const pnr = useMemo(() => {
    if (!tickets.length) {
      return null;
    }

    return getTicketPNR(tickets[0]);
  }, [tickets]);

  const onHandleStartValidation = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      onStartValidation();
    },
    [onStartValidation]
  );

  const statusImage = useMemo(() => {
    const statuses = {
      wait: warrnigIcon,
      success: successIcon,
      rejected: errorIcon,
    };

    return status ? statuses[status] : null;
  }, [status]);

  return (
    <div
      className={`PNRItem ${compact ? "compact" : ""}`}
      onClick={loading ? undefined : onSelect}
    >
      <div className="PNRItem-header">
        <span>{t("Ticket_and_Info.PNR")}</span>
        <div className="PNRItem-flight-number">
          <span> {pnr || t("na")}</span>
        </div>
      </div>
      <div className="PNRItem-header">
        <span>{t("Ticket_and_Info.FlightNumber")}</span>
        <div className="PNRItem-flight-number">
          <span>{tickets[0]?.flight_number || t("na")}</span>
          {statusImage && <img src={statusImage} alt="" />}
        </div>
      </div>
      <FlightDetails ticket={tickets[0]} />
      {compact ? null : (
        <>
          <div className="PNRItem-separator">
            <span className="PNRItem-bigdot-left" />
            <span className="PNRItem-bigdot-right" />
          </div>
          <div className="PNRItem-footer">
            <span className="PNRItem-bag">
              <img src={bagIcon} alt="" className="PNRItem-bag-icon" />
              {t("Ticket_and_Info.Bag")}: -
            </span>
            <span className="PNRItem-passenger">
              {t("Ticket_and_Info.PassengerShort")}: {tickets.length}
            </span>
            <button
              className={cn("PNRItem-button", { disabled: !canValidate })}
              onClick={onHandleStartValidation}
              disabled={!canValidate || syncing}
            >
              {t("Ticket_and_Info.startvalidation")}
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default PNRItem;
