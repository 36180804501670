import { Typography } from "antd";
import { useTranslation } from "react-i18next";

import "./style.css";
export enum CopyToClipboardSessionCodeType {
  validation,
  tickets,
}
interface ICopyToClipboardSessionCodeProps {
  text: string;
  styleType: CopyToClipboardSessionCodeType;
}

const CopyToClipboardSessionCode = ({
  text,
  styleType,
}: ICopyToClipboardSessionCodeProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={`${
        styleType === CopyToClipboardSessionCodeType.validation
          ? "validationContainerStyle"
          : "ticketsContainerStyle"
      }`}
    >
      <Typography.Text
        className={`
        ${
          styleType === CopyToClipboardSessionCodeType.validation
            ? "validationTitleStyle"
            : "ticketsTitleStyle"
        }`}
      >
        {t("SessionCodeCopyToClipboard.title")}
      </Typography.Text>
      <br />
      <Typography.Text
        copyable={{ text }}
        className={
          styleType === CopyToClipboardSessionCodeType.validation
            ? "validationCodeStyle"
            : "ticketsCodeStyle"
        }
      >
        {text}
      </Typography.Text>
    </div>
  );
};

export default CopyToClipboardSessionCode;
