import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Scheme } from "../../types/scheme";
import { SessionAdditionalDocumentRequest } from "types/validator";

export interface ValidationTicket {
  id: string;
  status?: string | null;
  sessionId?: string | null;
  validatedAt?: number | null;
}

export interface ValidationState {
  tickets: ValidationTicket[];
  scheme: Scheme | null;
  documentsRequest: SessionAdditionalDocumentRequest | null;
}

export const initialState: ValidationState = {
  tickets: [],
  scheme: null,
  documentsRequest: null,
};

const validationSlice = createSlice({
  name: "validation",
  initialState,
  reducers: {
    setTickets: (state, action: { payload: ValidationTicket[] }) => {
      console.log("[validation] start validation:", action.payload);
      state.tickets = action.payload;
    },
    setValidated: (state, action: { payload: ValidationTicket }) => {
      console.log(
        `[validation] set validated:"${action.payload.id}";`,
        action.payload,
        state.tickets
      );

      if (state.tickets.find((it) => it.id === action.payload.id)) {
        state.tickets = state.tickets.map((it) => {
          if (it.id === action.payload.id) {
            if (action.payload.status) {
              return {
                ...it,
                ...action.payload,
                validatedAt: Date.now(),
              };
            } else {
              return {
                ...it,
                ...action.payload,
                status: null,
                validatedAt: null,
              };
            }
          }

          return it;
        });
      } else if (action.payload.status) {
        state.tickets = [
          ...state.tickets,
          {
            id: action.payload.id,
            status: action.payload.status,
            validatedAt: Date.now(),
          },
        ];
      }
    },
    setSessionToTicket: (
      state,
      action: { payload: { id: string; sessionId: string | null } }
    ) => {
      console.log("[validation] setSessionToTicket:", action.payload);
      state.tickets = state.tickets.map((it) => {
        if (it.id === action.payload.id) {
          return {
            ...it,
            sessionId: action.payload.sessionId,
          };
        } else {
          return it;
        }
      });
    },
    setScheme: (
      state: ValidationState,
      action: PayloadAction<Scheme | null>
    ) => {
      state.scheme = action.payload;
    },
    setDocumentsRequest: (
      state: ValidationState,
      action: PayloadAction<SessionAdditionalDocumentRequest | null>
    ) => {
      state.documentsRequest = action.payload;
    },

    reset: (state) => {
      state = initialState;
    },
  },
  extraReducers: {},
});

export const {
  setTickets,
  setValidated,
  setSessionToTicket,
  setScheme,
  setDocumentsRequest,
  reset,
} = validationSlice.actions;

export default validationSlice.reducer;
