import { Model } from '@nozbe/watermelondb'
import { field } from '@nozbe/watermelondb/decorators'

export default class User extends Model {
  static table = 'users'

  @field('avatar') avatar!: string
  @field('name') name!: string
  @field('surname') surname!: string
  @field('dob') dob!: string
}
