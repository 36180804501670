import { Flex, Modal, Typography } from "antd";
import { withObservables } from "@nozbe/watermelondb/react";
import { database } from "wmelon/database";
import config from "config";
import { ErrorTexts } from "core/FormProcessor/ErrorTexts";
import { useReduxSession } from "hooks/useReduxSession";
import { useSession } from "hooks/useSession";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getIsExpiredTravelDocError, getParsedScheme } from "utils/scheme";
import { useTranslation } from "react-i18next";
import { SessionState } from "redux/features/session";
import { Q } from "@nozbe/watermelondb";
import { getQueryValue } from "utils/db";
import { getTicketOwner } from "utils/tickets";
import Form from "wmelon/models/Form";
import FlightTicket from "wmelon/models/FlightTicket";
import { uniq } from "lodash";
import {
  FormProcessorResponse,
  ResultStatus,
} from "core/FormProcessor/model/FormProcessorResponse";
import useFormValues from "hooks/useFormValues";
import FormProcessor from "core/FormProcessor/FormProcessor";
import "./style.css";
import { useRegula } from "hooks/useRegula";
import { useDocuments } from "hooks/useDocuments";
import validate from "core/cerber-validator";
import { Credentials } from "types/validator";
import {
  DOCUMENT_PROVIDERS,
  DOCUMENT_TYPES,
  getIsAutorecognized,
  mapDocumentToOld,
} from "utils/document";
import { getSessionInfo } from "utils/session";
import FileModal from "../../components/FileModal";
import { getFormOwner } from "utils/jsonform";
import { UNRECOGNIZED_REASONS } from "constants/contants";
import { ValidationTicket } from "redux/features/validation";
import { TicketStatus } from "types/flights";
import { APP_COLORS } from "utils/colors";
import { ReduxState } from "redux/ReduxState";
import { ReduxDocument } from "redux/features/documents";
import { Observable } from "@nozbe/watermelondb/utils/rx";
import ValidatorView from "../../components/ValidatorView";
import { FormProcessorParseProps } from "core/FormProcessor/model/FormProcessorParseProps";
import DocumentTypeSelector from "components/DocumentTypeSelector";
import { useVTEVISA } from "hooks/useVTEVISA";

const ERROR_KEYS = {
  error_oops: "Validator.error",
  empty_fields: "Validator.emptyRequiredFields",
  need_file: "Validator.youMustProvideAtLeastOnePhoto",
  [ErrorTexts.NOT_RECOGNIZED]: "Validator.documentIsNotRecognized",
  [ErrorTexts.MRZ_NOT_VALID]: "Validator.documentIsNotValid",
  [ErrorTexts.DOCUMENT_IS_NOT_VALID]: "Validator.documentIsNotValid",
  [ErrorTexts.DOCUMENT_TYPE_NOT_ALLOWED]: "Validator.documenIsNotAcceptable",
};

const LOADING_STATES = {
  validating: "Validator.validationInProgress",
  parsing: "Validator.dataRecognitionInProgress",
  save_raw_data: "Validator.addingData",
  saving: "Validator.saving",
  clearing: "Validator.dataCleaningInProgress",
  preparation: "Validator.preparation",
  online_validation: "Validator.onlineValidationRequested",
};

const INIT_FORM_DATA: FormProcessorResponse = {
  status: ResultStatus.OK,
  data: {},
  documentType: null,
  recognizer: null,
  fields: {
    empty: [],
    parsed: [],
    invalid: [],
  },
  doc_data: {
    date_of_expiry: null,
    passenger: null,
    meta: null,
  },
};

const QUICK_MODE = false;

export interface ValidatorProps {
  sessionState: SessionState;
  forms: Form[];
  tickets: FlightTicket[];
}

const Validator = ({ sessionState, forms, tickets }: ValidatorProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const regulaParser = useRegula();
  const vtevisaParser = useVTEVISA();

  const { getIsAvailableBooking, getIsBlockedTicket } = useSession();
  const {
    setSessionHandler,
    setSessionOwner,
    setETicket,
    clearSession,
    setHasUnverifiedDocuments,
  } = useReduxSession();
  const { addDocument, updateDocument, clearDocuments, onlineValidatePermit } =
    useDocuments();

  const documents = useSelector((state: ReduxState) => state.documents.items);
  const validationTickets: ValidationTicket[] = useSelector(
    (state: ReduxState) => state.validation.tickets
  );
  const rawScheme = useSelector((state: ReduxState) => state.validation.scheme);

  const [loadingState, setLoadingState] = useState<string | null>(null);
  const [validationResult, setValidatorResult] = useState<any | null>(null);
  const [docId, setDocId] = useState<string | null>(null);
  const [formData, setFormData] = useState<FormProcessorResponse>(
    cloneDeep(INIT_FORM_DATA)
  );
  const [images, setImages] = useState<Blob[]>([]);
  const [showFileUpload, setShowFileUpload] = useState(false);

  const [docData, setDocData] = useState<{
    first_name: string | null;
    last_name: string;
    document_number: string | null;
  } | null>(null);

  const stepId =
    validationResult?.scheme_resolver?.step_id || config.SUPER_GROUP_ID;
  const ignored = useMemo(() => {
    return validationResult?.ignored_documents || [];
  }, [validationResult]);

  const formDataRef = useRef<FormProcessorResponse>(formData);
  formDataRef.current = formData;
  const isManualRef = useRef<boolean>(false);
  const isCustomFormRef = useRef<boolean>(false);
  const isPassportStepRef = useRef<boolean>(false);

  useEffect(() => {
    if (sessionState && !sessionState.flight_ticket_id) {
      navigate("/tickets/upcoming");
    }
  }, [sessionState, navigate]);

  useEffect(() => {
    setLoadingState(null);
    setDocId(null);
    setFormData(cloneDeep(INIT_FORM_DATA as any));
    isManualRef.current = false;
    setImages([]);
    setDocData(null);
  }, [stepId]);

  const scheme = useMemo(() => {
    if (!rawScheme) {
      return null;
    }

    return getParsedScheme(rawScheme);
  }, [rawScheme]);

  const {
    formId,
    form,
    jsonschema,
    uischema,
    title,
    formRecognizer,
    item,
    skipId,
    fields,
    getAutoFields,
    shouldRecognizeDocument,
  } = useFormValues(scheme, documents, forms, validationResult, stepId);
  isCustomFormRef.current = formRecognizer === config.RECOGNIZERS.CUSTOM;

  const formProcessorRef = useRef<FormProcessor | null>(null);

  useEffect(() => {
    if (!form) {
      formProcessorRef.current = null;
      return;
    }

    let allowedDocumentTypes: string[] = [];

    if (item) {
      allowedDocumentTypes =
        "doc_fields" in item ? item.doc_types : [item.type];
    }

    formProcessorRef.current = new FormProcessor(
      form,
      allowedDocumentTypes,
      shouldRecognizeDocument,
      QUICK_MODE
    );
  }, [form, item, shouldRecognizeDocument]);

  const isPassportStep = stepId === "start";
  isPassportStepRef.current = isPassportStep;

  const onlyDocumentFields = useMemo(() => {
    return fields.length === 1 && fields.includes("documentFile");
  }, [fields]);

  const getDocumentType = useCallback(
    (type: string | null) => {
      console.log("[Validator] get doc type:", item, type);
      if (type || !item) {
        return Promise.resolve(type);
      }

      if ("doc_fields" in item) {
        if (item.doc_types?.length === 1) {
          return Promise.resolve(item.doc_types[0]);
        }

        let options: { value: string; label: string }[] = [];

        item.documents.forEach((id) => {
          const doc = scheme?.full_data_documents.find((it) => it.id === id);
          if (doc) {
            options.push({
              value: doc.type,
              label: doc.name,
            });
          }
        });

        if (!options.length) {
          options = item.doc_types.map((type) => ({
            value: type,
            label: type,
          }));
        }

        return new Promise((res: (value: string | null) => void) => {
          setLoadingState(null);
          console.log("SHOW_DIALOG:");
          const instance = Modal.confirm({
            title: t("Validator.selectDocumentType"),
            content: (
              <DocumentTypeSelector
                options={options}
                onSelect={(value) => {
                  setLoadingState(LOADING_STATES.saving);
                  instance.destroy();
                  res(value);
                }}
              />
            ),
            cancelText: t("Validator.cancel"),
            okButtonProps: {
              style: {
                display: "none",
              },
            },
            cancelButtonProps: {
              size: "small",
              style: {
                color: APP_COLORS.light.default_btn_text_color,
                background: APP_COLORS.light.default_btn,
              },
            },
            onCancel: () => {
              setLoadingState(LOADING_STATES.saving);
              res(null);
            },
          });
        });
      } else {
        return Promise.resolve(item.type);
      }
    },
    [item, scheme, t]
  );

  const onFinish = useCallback(
    (
      ticketId: string,
      images: Blob[],
      data: {
        reason: string;
        message?: string;
        empty_fields?: string;
        invalid_fields?: string;
        type?: string;
      }
    ) => {
      setLoadingState(LOADING_STATES.saving);
      return addDocument(
        {
          category: null,
          formId: null,
          type: DOCUMENT_TYPES.fasttrack_not_recognized,
          recognizer: null,
          data: data,
        },
        images
      )
        .then(() => {
          return setSessionHandler(
            ticketId,
            config.SESSION_STATUS_IDS.REJECTED_BY_RECOGNIZER
          );
        })
        .then(() => navigate("/final/unrecognized"));
    },
    [addDocument, setSessionHandler, navigate]
  );

  const createDocument = useCallback(
    (
      formProcessResult: FormProcessorResponse,
      images: Blob[]
    ): Promise<string | null> => {
      const isAutorecognized = getIsAutorecognized(
        formProcessResult.data,
        fields,
        formProcessResult.fields.parsed.map((it) => it.replace(/^\./, ""))
      );
      setLoadingState(LOADING_STATES.saving);
      return getDocumentType(formProcessResult.documentType).then(
        (type: any) => {
          console.log("GET_DOC_TYPE:", type);
          if (!type) {
            setLoadingState(null);
            return null;
          }
          return addDocument(
            {
              category: item?.category || null,
              formId: formId,
              type: type,
              recognizer:
                (isAutorecognized && formProcessResult.recognizer) ||
                DOCUMENT_PROVIDERS.manual,
              data: formProcessResult.data,
            },
            images
          );
        }
      );
    },
    [item, formId, addDocument, fields, getDocumentType]
  );

  const onFail = useCallback(
    (err) => {
      setLoadingState(null);
      console.error("onFail", err);
      Modal.error({
        title: t("Ticket_and_Info.failedToStartValidation"),
        content: t("Ticket_and_Info.failedToStartValidation"),
        okText: t("ok"),
        cancelButtonProps: {
          size: "small",
          style: {
            color: APP_COLORS.light.default_btn_text_color,
            background: APP_COLORS.light.default_btn,
          },
        },
        okButtonProps: {
          size: "small",
          style: {
            color: APP_COLORS.light.primary_text_color,
            background: APP_COLORS.light.primary,
          },
        },
      });
    },
    [t]
  );

  const onValidate = useCallback(
    (
      ticketId: string,
      {
        ignored_documents,
        documents,
      }: { ignored_documents: string[]; documents: ReduxDocument[] }
    ) => {
      if (!sessionState || !documents.length) {
        setLoadingState(null);
        return Promise.resolve();
      }

      setLoadingState(LOADING_STATES.validating);
      validate(
        documents.map(mapDocumentToOld),
        scheme,
        { ignored_documents, session_info: getSessionInfo(sessionState) },
        async (requestData) => {
          try {
            setLoadingState(LOADING_STATES.online_validation);

            const resp = await onlineValidatePermit(requestData)
              .then((resp) => ({
                data: resp,
                status_code: 200,
              }))
              .catch((err) => {
                console.error(
                  "[Validator] online validation request failed:",
                  err
                );
                return { status_code: err.status, data: null };
              });

            setLoadingState(LOADING_STATES.validating);
            console.log("[Validator] online validation response:", resp);
            if (resp?.status_code !== 200) {
              return {
                status_code: resp?.status_code || 404,
                data: null,
              };
            }

            return {
              status_code: resp?.status_code,
              data: resp?.data,
            };
          } catch (err) {
            console.error("[Validator] online validation failed:", err);
            return {
              status_code: 0,
              data: null,
            };
          }
        }
      )
        .then((resp: any) => {
          const [response, updatedDocuments] = resp;
          console.log(
            "onValidate",
            response?.result,
            response?.scheme_resolver?.step_id,
            documents.map((it) => it.type),
            response?.selected_path,
            response?.all_valid_documents,
            response?.errors,
            response?.submitted_errors,
            getSessionInfo(sessionState)
          );
          updatedDocuments.forEach((doc) => {
            if (doc) {
              updateDocument(doc.id, {
                autoVerificationResult: doc.auto_verification_result,
                verifiedAuto: doc.verified_auto === 1,
                verifiedManual: doc.verified_manual === 1,
              });
            }
          });
          if (response?.result) {
            setHasUnverifiedDocuments(
              (response.all_valid_documents || []).some(
                (it) => !it.verified_auto && !it.verified_manual
              )
            );
            return setSessionHandler(
              ticketId,
              config.SESSION_STATUS_IDS.PASSED_BY_LOCAL_VALIDATOR
            ).then(() => {
              navigate("/final/success");
            });
          } else if (response?.scheme_resolver?.step_id) {
            setValidatorResult(response);
            setDocId(null);
            setFormData(cloneDeep(INIT_FORM_DATA));
            isManualRef.current = false;
            setImages([]);
            setLoadingState(null);
          } else {
            const isExpired = getIsExpiredTravelDocError(response as any);
            return setSessionHandler(
              ticketId,
              isExpired
                ? config.SESSION_STATUS_IDS.EXPIRED_DOCUMENT
                : config.SESSION_STATUS_IDS.AUTOREJECTED_BY_VALIDATOR
            ).then(() =>
              navigate(isExpired ? "/final/attention" : "/final/failed")
            );
          }
        })
        .catch((err) => onFail(err));
    },
    [
      setSessionHandler,
      sessionState,
      updateDocument,
      scheme,
      setHasUnverifiedDocuments,
      onFail,
      onlineValidatePermit,
      navigate,
    ]
  );

  const onSkip = useCallback(() => {
    const ignoredDocs = uniq([...ignored, skipId]).filter((it) => !!it);
    setLoadingState(LOADING_STATES.validating);
    onValidate(sessionState.flight_ticket_id, {
      ignored_documents: ignoredDocs,
      documents,
    });
  }, [ignored, documents, onValidate, skipId, sessionState.flight_ticket_id]);

  const getFormStatus = useCallback(
    (
      formProcessResult: FormProcessorResponse,
      images: Blob[]
    ): Promise<boolean> => {
      return new Promise((res, rej) => {
        const needFile =
          fields.includes(config.JSONFORMS.DOCUMENT_FIELD) && !images.length;
        const isFilledForm =
          formProcessResult.fields.empty.length === 0 &&
          formProcessResult.status === ResultStatus.OK;

        if (needFile) {
          setLoadingState(null);
          Modal.warning({
            open: true,
            title: t("Validator.youMustProvideAtLeastOnePhoto"),
            cancelText: t("Validator.ok"),
            okButtonProps: {
              style: {
                display: "none",
              },
            },
            cancelButtonProps: {
              size: "small",
              style: {
                color: APP_COLORS.light.default_btn_text_color,
                background: APP_COLORS.light.default_btn,
              },
            },
            onCancel: () => {
              isFilledForm &&
                !isPassportStepRef.current &&
                (isManualRef.current = true);
              rej(formProcessResult.error);
            },
          });
          res(false);
          return;
        }

        if (isFilledForm) {
          return res(true);
        }

        if (isPassportStepRef.current) {
          rej(formProcessResult.error);
        } else if (!isManualRef.current && !isCustomFormRef.current) {
          if (formProcessResult.error === ErrorTexts.REMOTE_SERVER_ERROR) {
            rej(formProcessResult.error);
          }
          const emptyFields = formProcessResult.fields.empty || [];
          const needFile =
            emptyFields.length === 1 && emptyFields.includes("documentFile");
          setLoadingState(null);
          Modal.confirm({
            title: t("Validator.questionEnterManually"),
            content: (
              <Typography>
                {ERROR_KEYS[formProcessResult.error as any]
                  ? t(ERROR_KEYS[formProcessResult.error as any])
                  : emptyFields.length
                  ? t(needFile ? ERROR_KEYS.need_file : ERROR_KEYS.empty_fields)
                  : t(ERROR_KEYS.error_oops)}
              </Typography>
            ),
            cancelButtonProps: {
              size: "small",
              style: {
                color: APP_COLORS.light.default_btn_text_color,
                background: APP_COLORS.light.default_btn,
              },
            },
            okButtonProps: {
              size: "small",
              style: {
                color: APP_COLORS.light.primary_text_color,
                background: APP_COLORS.light.primary,
              },
            },
            okText: t("Validator.yes"),
            cancelText: t("Validator.no"),
            onCancel: () => {
              isManualRef.current = false;
              rej(formProcessResult.error);
            },
            onOk: () => {
              isManualRef.current = true;
              res(false);
            },
          });
        } else {
          res(false);
        }
      });
    },
    [fields, t]
  );

  const onCancelSession = useCallback(() => {
    setLoadingState(LOADING_STATES.clearing);
    setDocId(null);
    clearSession(true)
      .then(() => clearDocuments())
      .catch((err) => {
        console.error("[Validator] failed to clear session:", err);
      })
      .finally(() => navigate("/tickets"));
  }, [navigate, clearSession, clearDocuments]);

  const onWrongOwner = useCallback(
    (ticketId: string) => {
      setDocId(null);
      setLoadingState(null);
      return setSessionHandler(
        ticketId,
        config.SESSION_STATUS_IDS.WRONG_ETICKET
      ).then(() => {
        navigate("/final/wrongticket");
        return null;
      });
    },
    [setSessionHandler, navigate]
  );
  console.log(
    "VALID_SESSION:",
    sessionState?.scheme_id,
    sessionState?.scheme_version
  );
  const checkTicket = useCallback(
    (ticket: FlightTicket): Promise<string | null> => {
      if (!sessionState || !docData) {
        return Promise.resolve(ticket.id);
      }

      setLoadingState(LOADING_STATES.validating);
      return getIsAvailableBooking(
        ticket.id,
        validationTickets,
        docData.first_name as string,
        docData.last_name,
        docData.document_number || null
      ).then((resp: { status: TicketStatus; ticket?: FlightTicket }) => {
        console.log("[Validator].ticket:", resp);
        if (!resp.ticket || resp.status === TicketStatus.denied) {
          return onWrongOwner(ticket.id);
        }

        if (resp.ticket.id === ticket.id) {
          return ticket.id;
        }

        return getIsBlockedTicket(sessionState.id as any, resp.ticket.id).then(
          (isBlockedTicket: boolean) => {
            console.log("[Validator].ticket: blocked-", isBlockedTicket);
            if (isBlockedTicket) {
              return onWrongOwner(ticket.id);
            }

            const owner = getTicketOwner(ticket);

            return new Promise((res: any) => {
              setLoadingState(null);
              Modal.confirm({
                open: true,
                title: t("Validator.ticketOwnerIsDifferent"),
                okText: t("Validator.yes"),
                cancelText: t("Validator.no"),
                okButtonProps: {
                  size: "small",
                  type: "primary",
                  style: {
                    color: APP_COLORS.light.primary_text_color,
                    backgroundColor: APP_COLORS.light.primary,
                  },
                },
                cancelButtonProps: {
                  size: "small",
                  style: {
                    color: APP_COLORS.light.default_btn_text_color,
                    background: APP_COLORS.light.default_btn,
                  },
                },
                content: (
                  <Typography>
                    {owner ? (
                      <>
                        {t("ticketIssuedOn")}: <b>{owner}.</b>{" "}
                      </>
                    ) : null}
                    {t("Validator.changeSessionETicket")}
                  </Typography>
                ),
                onCancel: () => {
                  onWrongOwner(ticket.id).then(res);
                },
                onOk: () => {
                  setLoadingState(LOADING_STATES.validating);
                  setETicket(resp.ticket as FlightTicket).then(() =>
                    res(resp.ticket?.id)
                  );
                },
              });
            });
          }
        );
      });
    },
    [
      sessionState,
      getIsBlockedTicket,
      onWrongOwner,
      setETicket,
      docData,
      getIsAvailableBooking,
      validationTickets,
      t,
    ]
  );

  const onClickValidate = useCallback(async () => {
    console.log("CLICK_VALIDATE", docData);
    let ticketId: string | null = sessionState.flight_ticket_id;

    if (docData && isPassportStepRef.current) {
      ticketId = await checkTicket(tickets[0]);
      if (!ticketId) {
        return;
      }
    }

    setLoadingState(LOADING_STATES.validating);
    onValidate(ticketId, { documents, ignored_documents: ignored || [] });
  }, [
    tickets,
    docData,
    sessionState?.flight_ticket_id,
    onValidate,
    documents,
    ignored,
    checkTicket,
  ]);

  const processFormData = useCallback(
    async (formProcessResult: FormProcessorResponse, images: Blob[]) => {
      setLoadingState(LOADING_STATES.parsing);
      let documentOwner: Credentials | null = getFormOwner(
        formProcessResult.data,
        jsonschema,
        formRecognizer
      );

      if (isPassportStepRef.current) {
        if (documentOwner) {
          setDocData({
            first_name: documentOwner.first_name,
            last_name: documentOwner.last_name,
            document_number:
              formProcessResult?.doc_data?.passenger?.document_number || null,
          });
          await setSessionOwner(
            documentOwner.first_name,
            documentOwner.last_name,
            documentOwner.dob as string,
            formProcessResult?.doc_data?.passenger?.document_number || null
          );
        } else {
          throw new Error("owner_not_recognized");
        }
      }

      console.log("creat document");
      return createDocument(formProcessResult, images)
        .then((docId: string | null) => {
          console.log("DOC_CREATED:", docId);
          if (!docId) {
            setLoadingState(null);
            return;
          }

          setDocId(docId);
          setLoadingState(null);
        })
        .catch(onFail);
    },
    [createDocument, formRecognizer, jsonschema, onFail, setSessionOwner]
  );

  const processDataByFormProcessor = useCallback(
    async (ticketId: string, params: FormProcessorParseProps) => {
      if (!formProcessorRef.current) {
        setLoadingState(null);
        Modal.error({
          title: t("error"),
          cancelButtonProps: {
            size: "small",
            style: {
              color: APP_COLORS.light.default_btn_text_color,
              background: APP_COLORS.light.default_btn,
            },
          },
          okButtonProps: {
            size: "small",
            style: {
              color: APP_COLORS.light.primary_text_color,
              background: APP_COLORS.light.primary,
            },
          },
        });
        console.log(
          "[Validator] processDataByFormProcessor failed: no form processor"
        );
        return;
      }

      const autoFields = getAutoFields();
      Object.keys(autoFields).forEach((field: string) => {
        set(params.formData, field, autoFields[field]);
      });

      const formProcessorResult: FormProcessorResponse =
        formProcessorRef.current.parse(params);
      setLoadingState(LOADING_STATES.parsing);
      return getFormStatus(formProcessorResult, params.images)
        .then((succeeded: boolean) => {
          console.log("[Validator] form status:", succeeded);
          if (
            (isManualRef.current || !succeeded) &&
            formProcessorResult.data &&
            !formProcessorResult.fields.parsed.length
          ) {
            formProcessorResult.fields.parsed =
              formDataRef.current.fields.parsed;
          }
          if (succeeded) {
            return processFormData(formProcessorResult, params.images || []);
          } else {
            setLoadingState(null);
            setFormData((value) => ({
              ...value,
              ...formProcessorResult,
            }));
            setImages((value) =>
              isManualRef.current
                ? uniq(value.concat(params.images || []))
                : params.images
            );
            return;
          }
        })
        .catch((err) => {
          console.error("[Validator] failed to process:", err);
          onFinish(ticketId, params.images, {
            reason:
              formProcessorResult.status === ResultStatus.DOC_TYPE_NOT_VALID
                ? UNRECOGNIZED_REASONS.wrong_type
                : err?.message === "owner_not_recognized"
                ? UNRECOGNIZED_REASONS.owner_not_recognized
                : UNRECOGNIZED_REASONS.rejected_by_recognizer,
            message: formProcessorResult.error,
            empty_fields: JSON.stringify(
              formProcessorResult.fields.empty || []
            ),
            invalid_fields: JSON.stringify(
              formProcessorResult.fields.invalid || []
            ),
            ...(formProcessorResult.documentType
              ? { type: formProcessorResult.documentType }
              : {}),
          });
        });
    },
    [getFormStatus, processFormData, onFinish, getAutoFields, t]
  );

  const onSubmit = useCallback(() => {
    console.log("SUBMIT");
    try {
      if (!formProcessorRef.current) {
        setLoadingState(null);
        Modal.error({
          title: t("error"),
          cancelButtonProps: {
            size: "small",
            style: {
              color: APP_COLORS.light.default_btn_text_color,
              background: APP_COLORS.light.default_btn,
            },
          },
          okButtonProps: {
            size: "small",
            style: {
              color: APP_COLORS.light.primary_text_color,
              background: APP_COLORS.light.primary,
            },
          },
        });
        console.log("[Validator] onSubmit failed: no form processor");
        return;
      }

      setLoadingState(LOADING_STATES.preparation);
      const formProcessorResult: FormProcessorResponse =
        formProcessorRef.current.parse({
          formData: formDataRef.current.data,
          images,
        });
      const isWithFile = fields.includes(config.JSONFORMS.DOCUMENT_FIELD);
      console.log(
        "[VALIDATOR] submit",
        formProcessorResult,
        isWithFile,
        images,
        fields
      );
      if (
        formProcessorResult.fields.empty.length === 0 &&
        formProcessorResult.status === ResultStatus.OK
      ) {
        const needFile = isWithFile && !images.length;
        if (needFile) {
          isManualRef.current = true;
          setLoadingState(null);
          setShowFileUpload(true);
        } else {
          return processDataByFormProcessor(sessionState?.flight_ticket_id, {
            formData: formDataRef.current.data,
            images: images,
            meta: formProcessorResult.doc_data.meta || null,
            parsed: null,
          });
        }
      } else if (isWithFile) {
        setLoadingState(null);
        setShowFileUpload(true);
      } else {
        return processDataByFormProcessor(sessionState?.flight_ticket_id, {
          formData: formDataRef.current.data,
          images: images,
          meta: formProcessorResult.doc_data?.meta || null,
          parsed: null,
        });
      }
    } catch (err) {
      console.error("[Validator] failed to submit:", err);
      setLoadingState(null);
      onFail(err);
    }
  }, [
    fields,
    images,
    onFail,
    processDataByFormProcessor,
    sessionState?.flight_ticket_id,
    t,
  ]);

  const onHandleFiles = useCallback(
    (files: Blob[]) => {
      if (!formRecognizer || !files.length) {
        setLoadingState(null);
        setShowFileUpload(false);
        return;
      }

      try {
        setLoadingState(LOADING_STATES.save_raw_data);
        const isManuallyFilledForm =
          isManualRef.current || isCustomFormRef.current;
        if (isManuallyFilledForm) {
          setShowFileUpload(false);
          return processDataByFormProcessor(sessionState?.flight_ticket_id, {
            formData: formDataRef.current.data,
            images: files,
            meta: null,
            parsed: null,
          }).catch(onFail);
        } else if (formRecognizer === config.RECOGNIZERS.VTEVISA) {
          return vtevisaParser(files)
            .then((response) => {
              return processDataByFormProcessor(
                sessionState?.flight_ticket_id,
                {
                  images: files,
                  formData: {},
                  meta: null,
                  parsed: response,
                }
              );
            })
            .catch(onFail)
            .finally(() => setShowFileUpload(false));
        } else {
          return regulaParser(QUICK_MODE, files)
            .then((response) => {
              return processDataByFormProcessor(
                sessionState?.flight_ticket_id,
                {
                  images: files,
                  formData: {},
                  meta: null,
                  parsed: response,
                }
              );
            })
            .catch(onFail)
            .finally(() => setShowFileUpload(false));
        }
      } catch (err) {
        setShowFileUpload(false);
        onFail(err);
      }
    },
    [
      formRecognizer,
      processDataByFormProcessor,
      sessionState?.flight_ticket_id,
      onFail,
      regulaParser,
      vtevisaParser,
    ]
  );

  const onAddFile = useCallback(
    (file: Blob) => {
      if (item?.category?.toString() === config.DOCUMENT_CATEGORIES.PHOTO) {
        if (images.length > 4) {
          if (!showFileUpload && !isManualRef.current) {
            onHandleFiles([...images, file]);
          }
        } else {
          setImages((images) => [...images, file]);
        }
      } else {
        setImages((images) => [...images, file]);
        if (!showFileUpload && !isManualRef.current) {
          onHandleFiles([...images, file]);
        }
      }
    },
    [images, onHandleFiles, showFileUpload, item?.category]
  );

  const onRemoveFile = useCallback((index: number) => {
    setImages((value) => value.filter((_, i) => i !== index));
  }, []);

  const onChangeForm = useCallback((response: { formData: any }) => {
    if (
      Object.keys(response.formData).some(
        (key) => response.formData[key] !== undefined
      )
    ) {
      setFormData((value) => ({
        ...value,
        data: response.formData,
      }));
    }
  }, []);

  useEffect(() => {
    setLoadingState(null);
    setDocId(null);
    setFormData(cloneDeep(INIT_FORM_DATA as any));
    isManualRef.current = false;
    setImages([]);
    setDocData(null);
  }, [stepId]);

  const loading = !!loadingState;
  console.log("VALIDATOR:", !!docId);

  const onContinue = useCallback(() => {
    console.log(
      "ON_CONTINUE:",
      isPassportStep,
      onlyDocumentFields,
      item?.category?.toString() === config.DOCUMENT_CATEGORIES.PHOTO
    );
    if (
      isPassportStep ||
      onlyDocumentFields ||
      item?.category?.toString() === config.DOCUMENT_CATEGORIES.PHOTO
    ) {
      const uploader = document.getElementById("uploader");
      if (!images.length && uploader) {
        uploader.click();
      } else {
        onHandleFiles(images);
      }
    } else {
      onSubmit();
    }
  }, [
    images,
    isPassportStep,
    onHandleFiles,
    onSubmit,
    onlyDocumentFields,
    item,
  ]);

  return (
    <>
      <Flex style={{ height: "100%" }}>
        <ValidatorView
          loadingState={loadingState}
          title={title}
          documentId={docId}
          jsonschema={jsonschema}
          uischema={uischema}
          fields={fields}
          isPassportStep={isPassportStepRef.current}
          category={item?.category || null}
          formData={formData}
          onChangeForm={onChangeForm}
          onSubmit={onSubmit}
          onClickValidate={onClickValidate}
          onContinue={onContinue}
          onAddFile={onAddFile}
          onRemoveFile={onRemoveFile}
          onSkip={isPassportStepRef.current || docId ? undefined : onSkip}
          onCancelSession={onCancelSession}
          images={images}
        />
      </Flex>
      <FileModal
        visible={showFileUpload}
        uploadFiles={images}
        onCancel={() => setShowFileUpload(false)}
        onAccept={onHandleFiles}
        onRemove={onRemoveFile}
        onAddFile={onAddFile}
        disabled={loading}
      />
    </>
  );
};

const mapState = (state: any) => ({
  sessionState: state.session,
});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

const enhance = withObservables<
  Omit<ValidatorProps, "forms" | "tickets">,
  { forms: Observable<Form[]>; tickets: Observable<FlightTicket[]> }
>(["sessionState"], ({ sessionState }: { sessionState: SessionState }) => ({
  forms: database.get<Form>(Form.table).query().observe(),
  tickets: database
    .get<FlightTicket>(FlightTicket.table)
    .query(Q.where("id", getQueryValue(sessionState?.flight_ticket_id)))
    .observe(),
}));

const EnhancedValidator = enhance(Validator);
export default connector(EnhancedValidator);
