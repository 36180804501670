import { useTranslation } from "react-i18next";
import { Button, QRCode, Typography, Tooltip } from "antd";
import { LinkOutlined, CheckOutlined } from "@ant-design/icons";

import "./ShareInfo.css";
import { useCopyToClipboard } from "hooks/useCopyToClipboard";
import { APP_COLORS } from "utils/colors";

export interface ShareInfoProps {
  link: string;
}

export default function ShareInfo({ link }: ShareInfoProps) {
  const { t } = useTranslation();
  const [copiedText, onCopy] = useCopyToClipboard();
  return (
    <div className='ShareInfo'>
      <Typography className='ShareInfo-text'>
        {t("Ticket_and_Info.scanQrCode")}
      </Typography>
      <QRCode size={264} value={link} />
      <Typography className='ShareInfo-text'>
        {t("Ticket_and_Info.or")}
      </Typography>
      <Tooltip title={t("Ticket_and_Info.passLink")} trigger='click'>
        <Button
          onClick={() => {
            onCopy(link);
          }}
          size='middle'
          type='primary'
          shape='round'
          style={{
            color: APP_COLORS.light.primary,
            borderColor: APP_COLORS.light.primary,
          }}
          icon={copiedText ? <CheckOutlined /> : <LinkOutlined />}
        >
          {copiedText
            ? t("Ticket_and_Info.linkCopied")
            : t("Ticket_and_Info.copyLink")}
        </Button>
      </Tooltip>
    </div>
  );
}
