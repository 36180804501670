import { useCallback, useEffect, useState } from "react";
import { withObservables } from "@nozbe/watermelondb/react";
import { database } from "wmelon/database";
import { Q } from "@nozbe/watermelondb";
import { connect, useSelector } from "react-redux";
import { Modal, Button, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FinalPage from "./components/FinalPage";
import { useSessionInitializer } from "hooks/useSessionInitializer";
import { useReduxSession } from "hooks/useReduxSession";
import { useTimer } from "hooks/useTimer";
import { getQueryValue } from "utils/db";
import { SessionState } from "redux/features/session";
import Session from "wmelon/models/Sessions";
import FlightTicket from "wmelon/models/FlightTicket";
import { ValidationTicket } from "redux/features/validation";
import { getTicketInfoParams } from "utils/flights";
import warningIcon from "../../assets/images/attention.svg";
import successIcon from "../../assets/images/successFinal.svg";
import { APP_COLORS } from "utils/colors";
import { Observable } from "@nozbe/watermelondb/utils/rx";
import { ReduxState } from "redux/ReduxState";

export interface IProps {
  validationTickets: ValidationTicket[];
  sessionState: SessionState;
  isSyncing: boolean;
  sessions: Session[];
  unsynced: Session[];
  tickets: FlightTicket[];
  hasUnverifiedDocuments: boolean;
}

const FinalSucceeded = ({
  validationTickets,
  sessionState,
  isSyncing,
  sessions,
  tickets,
  unsynced,
  hasUnverifiedDocuments,
}: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [counter, startTimer] = useTimer("syncLimit");

  const documentsRequest = useSelector(
    (state: ReduxState) => state.validation.documentsRequest
  );

  useEffect(() => {
    console.log("[Final success] mount");
  }, []);

  const {
    initializeSession,
    nextTicketId,
    cancelSession,
    loadingState,
    initializing,
    percent,
    sessionId,
  } = useSessionInitializer(sessions, unsynced, startTimer);
  const { runByTicketId } = useReduxSession();

  const [failed, setFailed] = useState<boolean>(false);

  const initSession = useCallback(() => {
    console.log("[Final success] initSession");
    setFailed(false);
    initializeSession()
      .then(() => setFailed(false))
      .catch((err) => {
        setFailed(true);
        console.error("[FinalSucceeded] init failed:", err);
      });
  }, [initializeSession]);

  useEffect(() => {
    if (sessionState && !sessionState.flight_ticket_id) {
      navigate("/tickets/upcoming");
      return;
    }

    if (!isSyncing && !sessionId) {
      initSession();
    }
  }, [navigate, isSyncing]); //eslint-disable-line

  const onCancelSession = useCallback(() => {
    return cancelSession(true, true).finally(() => {
      navigate("/ticket/upcoming");
    });
  }, [cancelSession, navigate]);

  const onGoToTicket = useCallback(() => {
    const ticket = tickets.find(
      (it) => it.id === sessionState.flight_ticket_id
    );
    cancelSession(!ticket, true).finally(() => {
      if (ticket) {
        const {
          flight_number,
          date,
          pnr,
          airline,
          flight_origin,
          flight_destination,
          flight_ticket_id,
        } = getTicketInfoParams(ticket, true);
        navigate(
          `/tickets/${flight_number}/${date}/${pnr}/${airline}/${flight_origin}/${flight_destination}/${flight_ticket_id}`,
          { state: { from: "final" } }
        );
      }
    });
  }, [cancelSession, navigate, sessionState.flight_ticket_id, tickets]);

  const onNextPassenger = useCallback(() => {
    nextTicketId &&
      cancelSession(false, false)
        .then(() => runByTicketId(nextTicketId))
        .then(() => navigate("/preparation", { state: { from: "final" } }))
        .catch((err) => {
          Modal.error({
            title: t("RegulaScreens.failedToInitSession"),
            okText: t("ok"),
            onOk: () => cancelSession(true, true),
          });
        });
  }, [cancelSession, nextTicketId, runByTicketId, navigate, t]);

  const loading = !!loadingState;

  return (
    <FinalPage
      icon={failed ? warningIcon : successIcon}
      title={`${t("FinalSucceeded.title")}!`}
      description={
        hasUnverifiedDocuments || !!documentsRequest
          ? t("FinalSucceeded.description_warning")
          : t("FinalSucceeded.description")
      }
      initializationState={percent}
      initializing={initializing}
      sessionId={sessionId}
      isSuccess
      animationOnly
      buttons={
        <>
          <Button
            disabled={loading || initializing || counter > 0}
            type="primary"
            onClick={
              failed
                ? initSession
                : nextTicketId
                ? onNextPassenger
                : onGoToTicket
            }
            style={{
              color: APP_COLORS.light.primary_text_color,
              backgroundColor: APP_COLORS.light.primary,
            }}
            icon={
              loading || initializing ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : null
            }
          >
            {counter
              ? t("canRepeatIn", { count: counter })
              : failed
              ? t("tryAgain")
              : nextTicketId
              ? t("RegulaScreens.nextPassenger")
              : t("RegulaScreens.go_to_ticket")}
          </Button>
        </>
      }
      ticketId={sessionState.flight_ticket_id}
      tickets={tickets}
      validationTickets={validationTickets}
      loading={loading}
      onCancel={initializing ? undefined : onCancelSession}
    />
  );
};

const mapState = (state: any) => ({
  sessionState: state.session,
  isSyncing: state.app.syncing,
  validationTickets: state.validation.tickets,
  hasUnverifiedDocuments: state.session.has_unverified_documents,
});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

const enhance = withObservables<
  Omit<IProps, "sessions" | "unsynced" | "tickets">,
  {
    sessions: Observable<Session[]>;
    unsynced: Observable<Session[]>;
    tickets: Observable<FlightTicket[]>;
  }
>(
  ["sessionState", "validationTickets"],
  ({
    sessionState,
    validationTickets,
  }: {
    sessionState: SessionState;
    validationTickets: ValidationTicket[];
  }) => ({
    sessions: database
      .get<Session>(Session.table)
      .query(
        Q.where(
          "flight_ticket_id",
          getQueryValue(sessionState?.flight_ticket_id)
        ),
        Q.where("_status", "synced")
      )
      .observe(),
    unsynced: database
      .get<Session>(Session.table)
      .query(
        Q.where(
          "flight_ticket_id",
          getQueryValue(sessionState?.flight_ticket_id)
        ),
        Q.where("_status", Q.notEq("synced"))
      )
      .observe(),
    tickets: database
      .get<FlightTicket>(FlightTicket.table)
      .query(Q.where("id", Q.oneOf(validationTickets.map((it) => it.id))))
      .observe(),
  })
);

const EnhancedFinalSucceeded = connector(enhance(FinalSucceeded));
export default EnhancedFinalSucceeded;
