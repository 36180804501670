export default function getEndpoints(config: any) {
  return {
    LOGIN_START_EP: `${config.HTTP_SERVER}/api/v1/auth/login/start`,
    LOGIN_FINISH_EP: `${config.HTTP_SERVER}/api/v1/auth/login/finish`,

    START_REGISTRATION_EP: `${config.HTTP_SERVER}/api/v1/auth/registration/start`,
    FINISH_REGISTRATION_EP: `${config.HTTP_SERVER}/api/v1/auth/registration/finish`,
    SEND_CODE_EP: `${config.HTTP_SERVER}/api/v1/auth/send_code`,
    AUTH_TOKEN_EP: `${config.HTTP_SERVER}/api/v1/auth/token`,
    REFRESH_TOKEN_EP: `${config.HTTP_SERVER}/api/v1/auth/token/refresh`,

    SYNC_PULL_EP: `${config.HTTP_SERVER}/api/v1/sync/pull`,
    SYNC_PUSH_EP: `${config.HTTP_SERVER}/api/v1/sync/push`,
    SYNC_TIME_EP: `${config.HTTP_SERVER}/api/v1/sync/time`,

    USER_EP: `${config.HTTP_SERVER}/api/v1/user`,

    DOCUMENT_UPLOAD_EP: `${config.HTTP_SERVER}/api/v1/document`,
    SCHEDULE_EP: `${config.HTTP_SERVER}/api/v1/flight/schedule`,

    BOOKING_EP: `${config.HTTP_SERVER}/api/v1/booking`,
    FLIGHT_TICKET_EP: `${config.HTTP_SERVER}/api/v2/flight_ticket`,
    REGULA_EP: `${config.HTTP_SERVER}/api/v1/recognizer/regula/recognize`,
    VTEVISA_EP: `${config.HTTP_SERVER}/api/v1/recognizer/vtevisa/recognize`,
    ONLINE_VALIDATION_EP: `${config.HTTP_SERVER}/api/v1/validation`,
    HISTORY_EP: `${config.HTTP_SERVER}/api/v1/history`,
    DOCUMENT_REQUEST_EP: `${config.HTTP_SERVER}/api/v1/document_request`,
  };
}
